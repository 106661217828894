@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
// @include foundation-float-classes;
@include foundation-visibility-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;
@import 'utilities';


$flip-card-height: rem-calc(425);
$flip-card-transition-speed: 0.6s;
.cell {
  position: relative;
}
.decorator {
  position: absolute;
  text-align: center;
  width: 100%;
  color: rgba(105, 60, 60, 0.15);
  font-size: 200px;
  bottom: 0;
  pointer-events: none;
}
.flip-card {
  perspective: 1000;
  border: 0;
  background: transparent;

  &.hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

.flip-card,
.flip-card-inner-front,
.flip-card-inner-back {
  width: 100%;
  height: $flip-card-height;
}

.flip-card-inner {
  transition: $flip-card-transition-speed;
  transform-style: preserve-3d;
  position: relative;
  backface-visibility: hidden;
}

.flip-card-inner-front,
.flip-card-inner-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

// non-essential front and rear styles
.flip-card-inner-front {
  z-index: 2;
  display: flex;
}

.flip-card-inner-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
}



@mixin rMargin-b(){
	margin-bottom: rem-calc(24);
	@include breakpoint(medium){
		margin-bottom: rem-calc(40);
	}
}

.button{
	font-weight: 600;
	&.brand, &.brand-alt{
		color: $white;
		&:hover{
			color: $light-gray
		}

	}
}
$logo-fill: $white;
$logo-stroke: $logo-fill;

.h1, .h2, .h3, .h4, .stat, .text{
	&.brand{ color: $brand-color;}
	&.brand-alt{ color: $brand-alt-color;}
}



  .st0{fill:$logo-fill;}
  .st1{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;}
  .st2{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.0701,4.0234;}
  .st3{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.9075,3.9692;}
  .st4{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.2284,4.0761;}
  .st5{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.5286,3.8429;}
  .st6{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.0544,4.0181;}
  .st7{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:8.9747,2.9916;}
  .st8{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:2.3036;}
  .st9{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.1112,4.0371;}
  .st10{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:10.1768,3.3923;}
  .st11{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.3462,4.1154;}
  .st12{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:8.6055,2.8685;}
  .st13{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.004,4.0013;}
  .st14{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.1464,4.0488;}
  .st15{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.3536,3.7845;}
  .st16{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:8.3551,2.785;}
  .st17{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12.2995,4.0998;}
  .st18{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.3641,3.788;}
  .st19{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:13.7497,4.5832;}
  .st20{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.5939,3.8646;}
  .st21{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:11.7528,3.9176;}
  .st22{fill:none;stroke:$logo-stroke;stroke-miterlimit:10;stroke-dasharray:12,4;}
  .st23{font-family:'Source Sans Pro'; font-weight:900;}
  .st24{font-size:160px;}

body{
	//overflow-x: hidden;
}

.title-bar{
	position: fixed;
	background-color: rgba(0,0,0,.5);
	display: block;
	width: 100%;
	z-index: 1000;
}
nav{
	@include breakpoint(medium){
		&::after{
			content:"";
			display: block;
			background-color: $dark-gray;
			width: 100%;
			height: 80px;
		}
	}
}
iframe{
	width: 100%;
	height: 50vh;
}
.button{
	border-radius: 5px;
}
.text-brand{
	font-family: 'Source Sans Pro', sans-serif;
	text-transform: uppercase;
	font-weight: 900;
  .text-weak{
}
	font-weight: 200;
}
.text-huge{
  font-size: 300%;
 }
.text-tiny{
	font-size: 30%;
	line-height: 1rem;
}
.wallpaper{
	height: 45vh;
	width: 100vw;
	background-size: cover;
	background-position: center bottom;
	position: fixed;
	z-index: -1;
	transform: scale(1);
	transition: all 50ms linear;

		&:before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.35);
		}
	
	@include breakpoint(medium){
		height: 50vh;

	}

}
#wiscramble_logo{
	height: 70%;
}

.marqueecontainer{
	height: 40vh;
	@include breakpoint(medium){
		height: 50vh;
	}

}

.marquee{
	@include rMargin-b;
	height: 40vh;
	display: flex;
	flex: row nowrap;
	justify-content: center;
	align-items: center;
	margin-top: rem-calc(40);
	
	small{
		font-size: .5em;
	}
}
.warm-fuzzies{
	@include rMargin-b;
}
.weak{
	font-weight: 200;
}
.lead{
	font-weight: 200;
}
#logo{
	height: rem-calc(60);
}
.soldout{
	color: $medium-gray;
	position: relative;
	&:before{
		content: "Sold Out :(";
		color: #cc0000;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%) rotate(-15deg);
	}
}
.mb-0{
	margin-bottom: 0;
}

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
  
}
	/* flip the pane when triggered */
	.flip-container.activate .flipper {
		transform: rotateY(180deg);
	}

.flip-container, .front, .back {
  min-height: 584px;
	width: 100%;
	height: 100%;
}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
	left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
	transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
	transform: rotateY(180deg);
}

.accordion.dark, 
.tabs.dark{
	.tabs-title > a:focus, 
	.tabs-title > a[aria-selected='true']{
	background-color: $brand-color;

	}		
	background-color: $dark-gray;

}
section.dark{

}

.top-bar{
	width: 100vw;
	min-height: rem-calc(80);
	text-transform: uppercase;
	font-weight: bold;
	padding: 50px 0 0 ;
	background-color: rgba(0,0,0,.8);

	//background-image: linear-gradient(to bottom, $brand-color 70%, darken($brand-color,5) 100%);
	box-shadow: 0 1px 15px 1px rgba(0,0,0,.5);
	ul{
		background-color: transparent;
		&.is-dropdown-submenu{
			background-color: $brand-color;
		}
	}
	position: fixed;
	
	z-index: 20;
	&-right{
		padding: rem-calc(20);
		li{
			a{
				line-height: 1.4rem;
			}
		}
	}
	@include breakpoint(medium){
		padding: 0;
	}
}
.menu{
	a{
		color: $white;
	}
}
section {
	padding: rem-calc(20);
	@include breakpoint(medium){
		padding: rem-calc(40);
	}

	background-color: $white;
	color: $black;

	&.alt{
	background-color: $brand-color;
	color: $white;
	}
	&.dark{
		background-color: $dark-gray;
		color: $white;
	}
	&.light{
		background-color: $light-gray;
		color: black;
	}
	
	.card{
		//background-color: $brand-color;		
		//color: $white;
		//.border: none;
	}
}
#theCountdown{

	background-color: transparent;
	color: $white;
	text-align: center;
	.countdown {
    display: flex;
    justify-content: center;
    > div{
      width: rem-calc(50);
    }
	  .number{
      min-height: 1.5rem;
    }
	  span {
	    position: relative;
	    display: block;
	    font-size: rem-calc(12);
	    text-align: center;
	    
	    &:before {
	      content: '';
	      position: absolute;
	      top: -2px;
	      right: 0;
	      left: 0;
	      margin-right: auto;
	      margin-left: auto;
	      width: 20px;
	      height: 1px;
	      background: #fff;
	    }
	  }
	}
	header img {
    height: rem-calc(150);
	  max-width: rem-calc(200);
	}
}
.callout {
	&.hollow{
		background-color: transparent;
	}
	&.light{
		border-color: $white;
		color: $white;
	}
}
.event-card {
	position: relative;
	margin-bottom: 1.5rem;

	.event{
		&-buttons{
			button{
				margin-bottom: 0;
			}
		}
		&-details{
			margin-bottom: 1rem;
			p{
				margin-bottom: 0;
				line-height: 1.4rem;
			}
			svg{
				margin-right: 5px;
			}
			.date{ font-weight: bold;}
			.location{}
			.address{font-size: .85rem;}
		}
	}
}
.date-card {
  box-shadow: $dark-gray 1px 5px 15px -10px;
  border-radius: 5px;
  min-width: rem-calc(150);
  .header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: $brand-color;
    color: $white;
    font-size: 1.25rem;
    font-weight: 300;
    padding: .25rem 0;
  }
  .main {
    font-weight: 700;
    padding: 0 1rem;
    .date {
      line-height: 3.75rem;
      font-size: 3.5rem;
      margin-bottom: .25rem;
    }
    .day {
      color: $brand-color;
      font-size: .8rem;
      line-height: 0;
      margin-bottom: 1rem;
    }
  }
  .footer {
    .callToAction{
      width: rem-calc(48);
      height: rem-calc(48);
      border-top-left-radius: 50%; 
      border-top-right-radius: 50%; 
      background-color: $brand-color;
      color:$white;
      padding-top: .5rem;
    }
  }
}

.course-logo{
	width: 85%;
	position: absolute;
	top: 10px;
	z-index: 1;
	left: 50%;
	transform: translate(-50%, 0%);
}

.scorecard{
	text-align: center;
	img{
		width: 250px;
	}
}

.sprocket{
	&__image{
		position: relative;
		width: 100%;
		padding-bottom:25%; 
		overflow: hidden;
		img{
			width: 100%;
			position: absolute;
		}
	}
}
#running-total{
	text-align: center;
	font-size: rem-calc(36);
	position: fixed;
	top: 80px;
	right: -1px;
	border-radius: 5px 0 0 5px;
	@include breakpoint(medium){
		font-size: rem-calc(50);
	}
}
.event-group{
	transition: all 250ms ease-in;
	margin-left: 0;
	li{
		list-style: none;
		position: relative;
		input[type="checkbox"]{
			  margin-bottom: 0;
			  position: absolute;
			  top: rem-calc(7);
			  left: rem-calc(10);
		} 
		label{
			position: absolute;
			width: 100%;
      height: 100%;
      margin: 0;
		}
	}
	&.error{
		border: 1px solid red;
		background: rgba(100,0,0,.1);
		padding: .5rem;
	}
}
.dateCard{
	input{
		position: absolute;
	}

	text-align: center;
	border: 1px solid $brand-color;
	.month{
		color: $white;
		background-color: $brand-color;
	}
	.date{
		color: $brand-alt-color;
		font-size: rem-calc(24);
	}
	.day{
		font-size: rem-calc(12);
		font-weight: 200;
		color: #333;
	}
	.time{}
	.place{ 
		padding: 0 rem-calc(5);
		font-size: rem-calc(12);
	}
}
#invite-code{
	width: 4rem;
}
.user-registration{
	.cell{

		.input-group{
			display: flex;
			flex: row wrap;
			align-items: center;
		}
		label{ margin-left: 32px;}
		input{ margin-bottom: 0;}
		svg{margin-right: .5rem;}
	}

	input{
		transition: all 250ms ease-in;
		&.error{
			border: 1px solid red;
			background: rgba(100,0,0,.1);
		}
	}
}
#add-golfer{
	margin-left: 24px;
}

//Stripe Styles


/** Page-specific styles */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}





#example5-paymentRequest {
  max-width: 500px;
  width: 100%;
  margin-bottom: 10px;
}

.example{
	position: relative;
}

.example.submitted form,
.example.submitting form {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.example.submitted .success,
.example.submitting .success {
  pointer-events: all;
}

.example.submitting .success .icon {
  opacity: 1;
}

.example.submitted .success > * {
  opacity: 1;
  transform: none !important;
}

.example.submitted .success > :nth-child(2) {
  transition-delay: 0.1s;
}

.example.submitted .success > :nth-child(3) {
  transition-delay: 0.2s;
}

.example.submitted .success > :nth-child(4) {
  transition-delay: 0.3s;
}

.example.submitted .success .icon .border,
.example.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important;
}

.example.submitted form,
.example.submitting form {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.example.submitted .success,
.example.submitting .success {
  pointer-events: all;
}

.example.submitting .success .icon {
  opacity: 1;
}

.example.submitted .success > * {
  opacity: 1;
  transform: none !important;
}

.example.submitted .success > :nth-child(2) {
  transition-delay: 0.1s;
}

.example.submitted .success > :nth-child(3) {
  transition-delay: 0.2s;
}

.example.submitted .success > :nth-child(4) {
  transition-delay: 0.3s;
}

.example.submitted .success .icon .border,
.example.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important;
}

.example * {
  margin: 0;
  padding: 0;
}

.example .caption {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 15px 10px 0;
  color: #aab7c4;
  font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.example .caption * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.example .caption .no-charge {
  color: #cfd7df;
  margin-right: 10px;
}

.example .caption a.source {
  text-align: right;
  color: inherit;
  transition: color 0.1s ease-in-out;
  margin-left: 10px;
}

.example .caption a.source:hover {
  color: #6772e5;
}

.example .caption a.source:active {
  color: #43458b;
}

.example .caption a.source  svg {
  margin-right: 10px;
}

.example .caption a.source svg path {
  fill: currentColor;
}

.example form {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example form input::-webkit-input-placeholder {
  opacity: 1;
}

.example form input::-moz-placeholder {
  opacity: 1;
}

.example form input:-ms-input-placeholder {
  opacity: 1;
}

.example .error {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 20px;
  left: 0;
  padding: 0 15px;
  font-size: 13px !important;
  opacity: 0;
  transform: translateY(10px);
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.example .error.visible {
  opacity: 1;
  transform: none;
}

.example .error .message {
  font-size: inherit;
}

.example .error svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: -1px;
  margin-right: 10px;
}

.example .success {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
}
@media (min-width: 670px) {
  .example .success {
    padding: 40px;
  }
}

.example .success > * {
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(50px);
}


.example .success .icon {
  margin: 15px 0 30px;
  transform: translateY(70px) scale(0.75);
}

.example .success .icon svg {
  will-change: transform;
}

.example .success .icon .border {
  stroke-dasharray: 251;
  stroke-dashoffset: 62.75;
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: spin 1s linear infinite;
}

.example .success .icon .checkmark {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
}

.example .success .title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}

.example .success .message {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 1.6em;
}

.example .success .message span {
  font-size: inherit;
}

.example .success .reset:active {
  transition-duration: 0.15s;
  transition-delay: 0s;
  opacity: 0.65;
}

.example .success .reset svg {
  will-change: transform;
}


.stripe{
  fieldset {
    border: 1px solid lighten($dark-gray, 20%);
    padding: 15px;
    border-radius: 6px;
    background-color: $dark-gray;
    
    legend{
      margin: 0 auto;
      padding: 0 10px;
      text-align: center;
      font-size: 14px;
      color: $white;
      background-color: $dark-gray;

      & + *{
        clear: both;        
      }    
    }
  
  }

  .card-only{
    display: block;
  }
  .payment-request-available{
    display: none;
  }

  .row{
    display: flex;   
    margin: 0 0 10px;
  }

  .field{
    position: relative;
    width: 100%;  
    & + .field {
      margin-left: 10px;
    } 
  }

  label{
    width: 100%;
    color: lighten($dark-gray, 50%);
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .input {
    width: 100%;
    color: $white;
    background: transparent;
    padding: 5px 0 6px 0;
    border-bottom: 1px solid lighten($dark-gray, 20%);
    transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    &:focus{
    	border: none;
    	border-bottom: 1px solid;
    	box-shadow: none;
    	outline: none;
    }
  }
  .input::placeholder {
    color: lighten($dark-gray, 10%);
    font-style: italic;
  }

  .input.StripeElement--focus,
  .input:focus {
    border-color: $white;
  }
  .input.StripeElement--invalid {
    border-color: red;
  }

  input:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-text-fill-color: $brand-color;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }

  .StripeElement--webkit-autofill {
    background: transparent !important;
  }

  input,
  button,
  select {
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    border-radius: 0;
  }

  select.input,
  select:-webkit-autofill {
    background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
    background-position: 100%;
    background-size: 10px 5px;
    background-repeat: no-repeat;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }

  button {
    display: block;
    width: 100%;
    height: 40px;
    margin: 20px 0 0;
    border-radius: 6px;
    color: $dark-gray;
    background-color: $white;
    font-weight: 500;
    cursor: pointer;
  }

  button:active {
  }

  .error svg .base {
    fill: #fff;
  }

  .error svg .glyph {
    fill: #9169d8;
  }

  .error .message {
    color: #fff;
  }

  .success .icon .border {
    stroke: $brand-color;
  }

  .success .icon .checkmark {
    stroke: $brand-color;
  }

  .success .title {
    color: $white;
  }

  .success .message {
    color: $white;
  }

  .success .reset path {
    fill: $white;
  }

}

.pid, input.copyIt{
	display: inline-block;
	margin: 0 5px;
	padding: 5px;
	border: 1px solid $brand-color;
	background-color: rgba(255,255,255,.1);
}

.input-group{
	input{
		height: 36px;
	}
}

.scorecard{
	th {
		width: rem-calc(100);
	}
	tr:nth-of-type(2){
		display: none;
	}
	tr:nth-of-type(1){
		background: $brand-color;
		color: $white;
	}
	td{
		width: 25px;
		height: 25px;
		position: relative;
		text-align: center;
	}
	.brd{
		&:after{
			content: "";
			width:25px;
			height: 25px;
			border: 1px solid black;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.egl{
		@extend .brd;
		&:before{
			content: "";
			width:18px;
			height: 18px;
			border: 1px solid black;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.bgy{
		&:after{
			content: "";
			width:24px;
			height: 24px;
			border: 1px solid black;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.dblbgy{
		@extend .bgy;
		&:before{
			content: "";
			width:18px;
			height: 18px;
			border: 1px solid black;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.slick-carousel{
  div{width: 25%;}
}





