@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #f0f0f0;
  font-family: "Source Sans Pro", Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 75rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Source Sans Pro", Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #e6e6e6;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #c6c6c6; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #2b2b2b; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #2b2b2b; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #2b2b2b; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #2b2b2b;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #e6e6e6;
  color: #f0f0f0; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #c4c4c4;
    color: #f0f0f0; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #e6e6e6;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #b8b8b8;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #60A03F;
    color: #0a0a0a; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #4d8032;
      color: #0a0a0a; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #0a0a0a; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #0a0a0a; }
  .button.brand {
    background-color: #60A03F;
    color: #0a0a0a; }
    .button.brand:hover, .button.brand:focus {
      background-color: #4d8032;
      color: #0a0a0a; }
  .button.brand-alt {
    background-color: #CC732A;
    color: #0a0a0a; }
    .button.brand-alt:hover, .button.brand-alt:focus {
      background-color: #a35c22;
      color: #0a0a0a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #e6e6e6;
      color: #f0f0f0; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #e6e6e6;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #60A03F;
        color: #0a0a0a; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #0a0a0a; }
    .button.disabled.brand, .button[disabled].brand {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.brand, .button.disabled.brand:hover, .button.disabled.brand:focus, .button[disabled].brand, .button[disabled].brand:hover, .button[disabled].brand:focus {
        background-color: #60A03F;
        color: #0a0a0a; }
    .button.disabled.brand-alt, .button[disabled].brand-alt {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.brand-alt, .button.disabled.brand-alt:hover, .button.disabled.brand-alt:focus, .button[disabled].brand-alt, .button[disabled].brand-alt:hover, .button[disabled].brand-alt:focus {
        background-color: #CC732A;
        color: #0a0a0a; }
  .button.hollow {
    border: 1px solid #e6e6e6;
    color: #e6e6e6; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #737373;
      color: #737373; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #e6e6e6;
        color: #e6e6e6; }
    .button.hollow.primary {
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #737373;
        color: #737373; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #e6e6e6;
          color: #e6e6e6; }
    .button.hollow.secondary {
      border: 1px solid #60A03F;
      color: #60A03F; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #305020;
        color: #305020; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #60A03F;
          color: #60A03F; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
    .button.hollow.brand {
      border: 1px solid #60A03F;
      color: #60A03F; }
      .button.hollow.brand:hover, .button.hollow.brand:focus {
        border-color: #305020;
        color: #305020; }
        .button.hollow.brand:hover.disabled, .button.hollow.brand:hover[disabled], .button.hollow.brand:focus.disabled, .button.hollow.brand:focus[disabled] {
          border: 1px solid #60A03F;
          color: #60A03F; }
    .button.hollow.brand-alt {
      border: 1px solid #CC732A;
      color: #CC732A; }
      .button.hollow.brand-alt:hover, .button.hollow.brand-alt:focus {
        border-color: #663a15;
        color: #663a15; }
        .button.hollow.brand-alt:hover.disabled, .button.hollow.brand-alt:hover[disabled], .button.hollow.brand-alt:focus.disabled, .button.hollow.brand-alt:focus[disabled] {
          border: 1px solid #CC732A;
          color: #CC732A; }
  .button.clear {
    border: 1px solid #e6e6e6;
    color: #e6e6e6; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #737373;
      color: #737373; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #e6e6e6;
        color: #e6e6e6; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #737373;
        color: #737373; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #e6e6e6;
          color: #e6e6e6; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #60A03F;
      color: #60A03F; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #305020;
        color: #305020; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #60A03F;
          color: #60A03F; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
    .button.clear.brand {
      border: 1px solid #60A03F;
      color: #60A03F; }
      .button.clear.brand:hover, .button.clear.brand:focus {
        border-color: #305020;
        color: #305020; }
        .button.clear.brand:hover.disabled, .button.clear.brand:hover[disabled], .button.clear.brand:focus.disabled, .button.clear.brand:focus[disabled] {
          border: 1px solid #60A03F;
          color: #60A03F; }
      .button.clear.brand, .button.clear.brand.disabled, .button.clear.brand[disabled], .button.clear.brand:hover, .button.clear.brand:hover.disabled, .button.clear.brand:hover[disabled], .button.clear.brand:focus, .button.clear.brand:focus.disabled, .button.clear.brand:focus[disabled] {
        border-color: transparent; }
    .button.clear.brand-alt {
      border: 1px solid #CC732A;
      color: #CC732A; }
      .button.clear.brand-alt:hover, .button.clear.brand-alt:focus {
        border-color: #663a15;
        color: #663a15; }
        .button.clear.brand-alt:hover.disabled, .button.clear.brand-alt:hover[disabled], .button.clear.brand-alt:focus.disabled, .button.clear.brand-alt:focus[disabled] {
          border: 1px solid #CC732A;
          color: #CC732A; }
      .button.clear.brand-alt, .button.clear.brand-alt.disabled, .button.clear.brand-alt[disabled], .button.clear.brand-alt:hover, .button.clear.brand-alt:hover.disabled, .button.clear.brand-alt:hover[disabled], .button.clear.brand-alt:focus, .button.clear.brand-alt:focus.disabled, .button.clear.brand-alt:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #f0f0f0 transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #e6e6e6; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #e6e6e6; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #60A03F; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.dropdown.hollow.brand::after {
    border-top-color: #60A03F; }
  .button.dropdown.hollow.brand-alt::after {
    border-top-color: #CC732A; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #f0f0f0;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #2b2b2b;
    background-color: #f0f0f0;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #f0f0f0;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2843, 43, 43%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #2b2b2b;
    background-color: #f0f0f0;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #ece0de; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem;
    flex: 0 0 auto; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded .button {
    flex: 1 1 0px; }
  .button-group.primary .button {
    background-color: #e6e6e6;
    color: #0a0a0a; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #b8b8b8;
      color: #0a0a0a; }
  .button-group.secondary .button {
    background-color: #60A03F;
    color: #0a0a0a; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #4d8032;
      color: #0a0a0a; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #0a0a0a; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #0a0a0a; }
  .button-group.brand .button {
    background-color: #60A03F;
    color: #0a0a0a; }
    .button-group.brand .button:hover, .button-group.brand .button:focus {
      background-color: #4d8032;
      color: #0a0a0a; }
  .button-group.brand-alt .button {
    background-color: #CC732A;
    color: #0a0a0a; }
    .button-group.brand-alt .button:hover, .button-group.brand-alt .button:focus {
      background-color: #a35c22;
      color: #0a0a0a; }
  .button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
    flex-wrap: wrap; }
    .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
      flex: 0 0 100%; }
      .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
        margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
    .button-group.stacked-for-medium .button {
      flex: 1 1 0px;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.25);
  border-radius: 0;
  background-color: #fdfdfd;
  color: #0a0a0a; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #fbfbfb;
    color: #0a0a0a; }
  .callout.secondary {
    background-color: #e6f3e0;
    color: #0a0a0a; }
  .callout.success {
    background-color: #e1faea;
    color: #0a0a0a; }
  .callout.warning {
    background-color: #fff3d9;
    color: #0a0a0a; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #0a0a0a; }
  .callout.brand {
    background-color: #e6f3e0;
    color: #0a0a0a; }
  .callout.brand-alt {
    background-color: #f8eade;
    color: #0a0a0a; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background: #f0f0f0;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #2b2b2b;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #0a0a0a; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #e6e6e6;
    color: #f0f0f0; }
  .menu .active > a {
    background: #e6e6e6;
    color: #f0f0f0; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #f0f0f0;
    box-shadow: 0 7px 0 #f0f0f0, 0 14px 0 #f0f0f0;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #0a0a0a;
    box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #2b2b2b;
    box-shadow: 0 7px 0 #2b2b2b, 0 14px 0 #2b2b2b; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.drilldown a {
  padding: 0.7rem 1rem;
  background: #f0f0f0; }

.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #f0f0f0;
  transition: transform 0.15s linear; }
  .drilldown .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .drilldown .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }
  .drilldown .is-drilldown-submenu a {
    padding: 0.7rem 1rem; }

.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0; }

.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%; }

.drilldown .is-drilldown-submenu-parent > a {
  position: relative; }
  .drilldown .is-drilldown-submenu-parent > a::after {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #e6e6e6; }

.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #e6e6e6; }

.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #e6e6e6 transparent transparent; }

.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #e6e6e6 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #f0f0f0;
  font-size: 1rem; }
  .dropdown-pane.is-opening {
    display: block; }
  .dropdown-pane.is-open {
    visibility: visible;
    display: block; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #e6e6e6 transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #e6e6e6; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #e6e6e6 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #e6e6e6; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #e6e6e6 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #e6e6e6 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #e6e6e6; } }

@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #e6e6e6 transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #e6e6e6 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #e6e6e6; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #f0f0f0; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #e6e6e6 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #e6e6e6; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #e6e6e6;
  color: #f0f0f0; }
  .label.primary {
    background: #e6e6e6;
    color: #0a0a0a; }
  .label.secondary {
    background: #60A03F;
    color: #0a0a0a; }
  .label.success {
    background: #3adb76;
    color: #0a0a0a; }
  .label.warning {
    background: #ffae00;
    color: #0a0a0a; }
  .label.alert {
    background: #cc4b37;
    color: #0a0a0a; }
  .label.brand {
    background: #60A03F;
    color: #0a0a0a; }
  .label.brand-alt {
    background: #CC732A;
    color: #0a0a0a; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(240, 240, 240, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-push {
    z-index: 12; }
  .off-canvas.is-closed {
    visibility: hidden; }
  .off-canvas.is-transition-overlap {
    z-index: 13; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #e6e6e6; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-push {
    z-index: 12; }
  .off-canvas-absolute.is-closed {
    visibility: hidden; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 13; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px); }
  .off-canvas-content .off-canvas.position-left {
    transform: translateX(-250px); }
    .off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-left.has-transition-push {
    transform: translateX(250px); }
  .position-left.is-transition-push {
    box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px); }
  .off-canvas-content .off-canvas.position-right {
    transform: translateX(250px); }
    .off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-right.has-transition-push {
    transform: translateX(-250px); }
  .position-right.is-transition-push {
    box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25); }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px); }
  .off-canvas-content .off-canvas.position-top {
    transform: translateY(-250px); }
    .off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-top.has-transition-push {
    transform: translateY(250px); }
  .position-top.is-transition-push {
    box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25); }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px); }
  .off-canvas-content .off-canvas.position-bottom {
    transform: translateY(250px); }
    .off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
      transform: translate(0, 0); }
  .off-canvas-content.is-open-bottom.has-transition-push {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push {
    box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25); }

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden; }
  .off-canvas-content.has-transition-push {
    transform: translate(0, 0); }
  .off-canvas-content .off-canvas.is-open {
    transform: translate(0, 0); }

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-medium .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-medium {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-left.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-left.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-left {
      margin-left: 250px; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 250px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-right.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-right.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-right {
      margin-right: 250px; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 250px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-top.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-top.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-top {
      margin-top: 250px; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 250px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible; }
    .position-bottom.reveal-for-large .close-button {
      display: none; }
    .off-canvas-content .position-bottom.reveal-for-large {
      transform: none; }
    .off-canvas-content.has-reveal-bottom {
      margin-bottom: 250px; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 250px; } }

@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-medium .close-button {
      display: none; } }

@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit; }
    .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
      box-shadow: none;
      transform: none; }
    .off-canvas.in-canvas-for-large .close-button {
      display: none; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #f0f0f0; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #f0f0f0; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: rgba(10, 10, 10, 0.5); }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #cacaca; }
    .orbit-bullets button:hover {
      background-color: #2b2b2b; }
    .orbit-bullets button.is-active {
      background-color: #2b2b2b; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #e6e6e6;
    color: #f0f0f0;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #e6e6e6; }
  .progress.secondary .progress-meter {
    background-color: #60A03F; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }
  .progress.brand .progress-meter {
    background-color: #60A03F; }
  .progress.brand-alt .progress-meter {
    background-color: #CC732A; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #e6e6e6; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #f0f0f0;
  white-space: nowrap; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #e6e6e6;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #c4c4c4; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #f0f0f0;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 75rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 75rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 75rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #f0f0f0;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #f0f0f0;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #e6e6e6; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #e4e4e4;
    background-color: #f0f0f0; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #eaeaea;
    color: #0a0a0a; }
  table tfoot {
    background: #e4e4e4;
    color: #0a0a0a; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #e4e4e4; }
  table.unstriped tbody {
    background-color: #f0f0f0; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #e4e4e4;
      background-color: #f0f0f0; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #e5e5e5; }

table.hover tfoot tr:hover {
  background-color: #dfdfdf; }

table.hover tbody tr:hover {
  background-color: #ebebeb; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #dedede; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #f0f0f0;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #e6e6e6; }
  .tabs.primary > li > a {
    color: #0a0a0a; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #dbdbdb; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.75rem;
    line-height: 1;
    color: #e6e6e6; }
    .tabs-title > a:hover {
      background: #f0f0f0;
      color: #c6c6c6; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #e6e6e6;
      color: #e6e6e6; }

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #f0f0f0;
  color: #0a0a0a;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel.is-active {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #f0f0f0;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(230, 230, 230, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #f0f0f0;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px; }

.title-bar-right {
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #2b2b2b;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #f0f0f0; }
  .tooltip::before {
    position: absolute; }
  .tooltip.bottom::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #0a0a0a;
    bottom: 100%; }
  .tooltip.bottom.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #0a0a0a transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.top.align-center::before {
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #0a0a0a;
    left: 100%; }
  .tooltip.left.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #0a0a0a transparent transparent;
    right: 100%;
    left: auto; }
  .tooltip.right.align-center::before {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%); }
  .tooltip.align-top::before {
    bottom: auto;
    top: 10%; }
  .tooltip.align-bottom::before {
    bottom: 10%;
    top: auto; }
  .tooltip.align-left::before {
    left: 10%;
    right: auto; }
  .tooltip.align-right::before {
    left: auto;
    right: 10%; }

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      flex-wrap: nowrap; }
      .top-bar .top-bar-left {
        flex: 1 1 auto;
        margin-right: auto; }
      .top-bar .top-bar-right {
        flex: 0 1 auto;
        margin-left: auto; } }
  @media screen and (max-width: 63.9375em) {
    .top-bar.stacked-for-medium {
      flex-wrap: wrap; }
      .top-bar.stacked-for-medium .top-bar-left,
      .top-bar.stacked-for-medium .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large {
      flex-wrap: wrap; }
      .top-bar.stacked-for-large .top-bar-left,
      .top-bar.stacked-for-large .top-bar-right {
        flex: 0 0 100%;
        max-width: 100%; } }

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0; }

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.list-reset {
  list-style: none;
  padding: 0; }

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.bg-fixed {
  background-attachment: fixed; }

.bg-local {
  background-attachment: local; }

.bg-scroll {
  background-attachment: scroll; }

.bg-transparent {
  background-color: transparent; }

.bg-black {
  background-color: #22292f; }

.bg-grey-darkest {
  background-color: #3d4852; }

.bg-grey-darker {
  background-color: #606f7b; }

.bg-grey-dark {
  background-color: #8795a1; }

.bg-grey {
  background-color: #b8c2cc; }

.bg-grey-light {
  background-color: #dae1e7; }

.bg-grey-lighter {
  background-color: #f1f5f8; }

.bg-grey-lightest {
  background-color: #f8fafc; }

.bg-white {
  background-color: #fff; }

.bg-red-darkest {
  background-color: #3b0d0c; }

.bg-red-darker {
  background-color: #621b18; }

.bg-red-dark {
  background-color: #cc1f1a; }

.bg-red {
  background-color: #e3342f; }

.bg-red-light {
  background-color: #ef5753; }

.bg-red-lighter {
  background-color: #f9acaa; }

.bg-red-lightest {
  background-color: #fcebea; }

.bg-orange-darkest {
  background-color: #4C2C14; }

.bg-orange-darker {
  background-color: #663B17; }

.bg-orange-dark {
  background-color: #995724; }

.bg-orange {
  background-color: #CC7329; }

.bg-orange-light {
  background-color: #faad63; }

.bg-orange-lighter {
  background-color: #fcd9b6; }

.bg-orange-lightest {
  background-color: #F6C193; }

.bg-yellow-darkest {
  background-color: #453411; }

.bg-yellow-darker {
  background-color: #684f1d; }

.bg-yellow-dark {
  background-color: #f2d024; }

.bg-yellow {
  background-color: #ffed4a; }

.bg-yellow-light {
  background-color: #fff382; }

.bg-yellow-lighter {
  background-color: #fff9c2; }

.bg-yellow-lightest {
  background-color: #fcfbeb; }

.bg-green-darkest {
  background-color: #213319; }

.bg-green-darker {
  background-color: #304D23; }

.bg-green-dark {
  background-color: #4E803B; }

.bg-green {
  background-color: #669940; }

.bg-green-light {
  background-color: #99CC67; }

.bg-green-lighter {
  background-color: #99CC67; }

.bg-green-lightest {
  background-color: #A8D057; }

.bg-teal-darkest {
  background-color: #0d3331; }

.bg-teal-darker {
  background-color: #20504f; }

.bg-teal-dark {
  background-color: #38a89d; }

.bg-teal {
  background-color: #4dc0b5; }

.bg-teal-light {
  background-color: #64d5ca; }

.bg-teal-lighter {
  background-color: #a0f0ed; }

.bg-teal-lightest {
  background-color: #e8fffe; }

.bg-blue-darkest {
  background-color: #12283a; }

.bg-blue-darker {
  background-color: #1c3d5a; }

.bg-blue-dark {
  background-color: #2779bd; }

.bg-blue {
  background-color: #3490dc; }

.bg-blue-light {
  background-color: #6cb2eb; }

.bg-blue-lighter {
  background-color: #bcdefa; }

.bg-blue-lightest {
  background-color: #eff8ff; }

.bg-indigo-darkest {
  background-color: #191e38; }

.bg-indigo-darker {
  background-color: #2f365f; }

.bg-indigo-dark {
  background-color: #5661b3; }

.bg-indigo {
  background-color: #6574cd; }

.bg-indigo-light {
  background-color: #7886d7; }

.bg-indigo-lighter {
  background-color: #b2b7ff; }

.bg-indigo-lightest {
  background-color: #e6e8ff; }

.bg-purple-darkest {
  background-color: #21183c; }

.bg-purple-darker {
  background-color: #382b5f; }

.bg-purple-dark {
  background-color: #794acf; }

.bg-purple {
  background-color: #9561e2; }

.bg-purple-light {
  background-color: #a779e9; }

.bg-purple-lighter {
  background-color: #d6bbfc; }

.bg-purple-lightest {
  background-color: #f3ebff; }

.bg-pink-darkest {
  background-color: #451225; }

.bg-pink-darker {
  background-color: #6f213f; }

.bg-pink-dark {
  background-color: #eb5286; }

.bg-pink {
  background-color: #f66d9b; }

.bg-pink-light {
  background-color: #fa7ea8; }

.bg-pink-lighter {
  background-color: #ffbbca; }

.bg-pink-lightest {
  background-color: #ffebef; }

.hover\:bg-transparent:hover {
  background-color: transparent; }

.hover\:bg-black:hover {
  background-color: #22292f; }

.hover\:bg-grey-darkest:hover {
  background-color: #3d4852; }

.hover\:bg-grey-darker:hover {
  background-color: #606f7b; }

.hover\:bg-grey-dark:hover {
  background-color: #8795a1; }

.hover\:bg-grey:hover {
  background-color: #b8c2cc; }

.hover\:bg-grey-light:hover {
  background-color: #dae1e7; }

.hover\:bg-grey-lighter:hover {
  background-color: #f1f5f8; }

.hover\:bg-grey-lightest:hover {
  background-color: #f8fafc; }

.hover\:bg-white:hover {
  background-color: #fff; }

.hover\:bg-red-darkest:hover {
  background-color: #3b0d0c; }

.hover\:bg-red-darker:hover {
  background-color: #621b18; }

.hover\:bg-red-dark:hover {
  background-color: #cc1f1a; }

.hover\:bg-red:hover {
  background-color: #e3342f; }

.hover\:bg-red-light:hover {
  background-color: #ef5753; }

.hover\:bg-red-lighter:hover {
  background-color: #f9acaa; }

.hover\:bg-red-lightest:hover {
  background-color: #fcebea; }

.hover\:bg-orange-darkest:hover {
  background-color: #462a16; }

.hover\:bg-orange-darker:hover {
  background-color: #613b1f; }

.hover\:bg-orange-dark:hover {
  background-color: #de751f; }

.hover\:bg-orange:hover {
  background-color: #f6993f; }

.hover\:bg-orange-light:hover {
  background-color: #faad63; }

.hover\:bg-orange-lighter:hover {
  background-color: #fcd9b6; }

.hover\:bg-orange-lightest:hover {
  background-color: #fff5eb; }

.hover\:bg-yellow-darkest:hover {
  background-color: #453411; }

.hover\:bg-yellow-darker:hover {
  background-color: #684f1d; }

.hover\:bg-yellow-dark:hover {
  background-color: #f2d024; }

.hover\:bg-yellow:hover {
  background-color: #ffed4a; }

.hover\:bg-yellow-light:hover {
  background-color: #fff382; }

.hover\:bg-yellow-lighter:hover {
  background-color: #fff9c2; }

.hover\:bg-yellow-lightest:hover {
  background-color: #fcfbeb; }

.hover\:bg-green-darkest:hover {
  background-color: #0f2f21; }

.hover\:bg-green-darker:hover {
  background-color: #1a4731; }

.hover\:bg-green-dark:hover {
  background-color: #1f9d55; }

.hover\:bg-green:hover {
  background-color: #38c172; }

.hover\:bg-green-light:hover {
  background-color: #51d88a; }

.hover\:bg-green-lighter:hover {
  background-color: #a2f5bf; }

.hover\:bg-green-lightest:hover {
  background-color: #e3fcec; }

.hover\:bg-teal-darkest:hover {
  background-color: #0d3331; }

.hover\:bg-teal-darker:hover {
  background-color: #20504f; }

.hover\:bg-teal-dark:hover {
  background-color: #38a89d; }

.hover\:bg-teal:hover {
  background-color: #4dc0b5; }

.hover\:bg-teal-light:hover {
  background-color: #64d5ca; }

.hover\:bg-teal-lighter:hover {
  background-color: #a0f0ed; }

.hover\:bg-teal-lightest:hover {
  background-color: #e8fffe; }

.hover\:bg-blue-darkest:hover {
  background-color: #12283a; }

.hover\:bg-blue-darker:hover {
  background-color: #1c3d5a; }

.hover\:bg-blue-dark:hover {
  background-color: #2779bd; }

.hover\:bg-blue:hover {
  background-color: #3490dc; }

.hover\:bg-blue-light:hover {
  background-color: #6cb2eb; }

.hover\:bg-blue-lighter:hover {
  background-color: #bcdefa; }

.hover\:bg-blue-lightest:hover {
  background-color: #eff8ff; }

.hover\:bg-indigo-darkest:hover {
  background-color: #191e38; }

.hover\:bg-indigo-darker:hover {
  background-color: #2f365f; }

.hover\:bg-indigo-dark:hover {
  background-color: #5661b3; }

.hover\:bg-indigo:hover {
  background-color: #6574cd; }

.hover\:bg-indigo-light:hover {
  background-color: #7886d7; }

.hover\:bg-indigo-lighter:hover {
  background-color: #b2b7ff; }

.hover\:bg-indigo-lightest:hover {
  background-color: #e6e8ff; }

.hover\:bg-purple-darkest:hover {
  background-color: #21183c; }

.hover\:bg-purple-darker:hover {
  background-color: #382b5f; }

.hover\:bg-purple-dark:hover {
  background-color: #794acf; }

.hover\:bg-purple:hover {
  background-color: #9561e2; }

.hover\:bg-purple-light:hover {
  background-color: #a779e9; }

.hover\:bg-purple-lighter:hover {
  background-color: #d6bbfc; }

.hover\:bg-purple-lightest:hover {
  background-color: #f3ebff; }

.hover\:bg-pink-darkest:hover {
  background-color: #451225; }

.hover\:bg-pink-darker:hover {
  background-color: #6f213f; }

.hover\:bg-pink-dark:hover {
  background-color: #eb5286; }

.hover\:bg-pink:hover {
  background-color: #f66d9b; }

.hover\:bg-pink-light:hover {
  background-color: #fa7ea8; }

.hover\:bg-pink-lighter:hover {
  background-color: #ffbbca; }

.hover\:bg-pink-lightest:hover {
  background-color: #ffebef; }

.focus\:bg-transparent:focus {
  background-color: transparent; }

.focus\:bg-black:focus {
  background-color: #22292f; }

.focus\:bg-grey-darkest:focus {
  background-color: #3d4852; }

.focus\:bg-grey-darker:focus {
  background-color: #606f7b; }

.focus\:bg-grey-dark:focus {
  background-color: #8795a1; }

.focus\:bg-grey:focus {
  background-color: #b8c2cc; }

.focus\:bg-grey-light:focus {
  background-color: #dae1e7; }

.focus\:bg-grey-lighter:focus {
  background-color: #f1f5f8; }

.focus\:bg-grey-lightest:focus {
  background-color: #f8fafc; }

.focus\:bg-white:focus {
  background-color: #fff; }

.focus\:bg-red-darkest:focus {
  background-color: #3b0d0c; }

.focus\:bg-red-darker:focus {
  background-color: #621b18; }

.focus\:bg-red-dark:focus {
  background-color: #cc1f1a; }

.focus\:bg-red:focus {
  background-color: #e3342f; }

.focus\:bg-red-light:focus {
  background-color: #ef5753; }

.focus\:bg-red-lighter:focus {
  background-color: #f9acaa; }

.focus\:bg-red-lightest:focus {
  background-color: #fcebea; }

.focus\:bg-orange-darkest:focus {
  background-color: #462a16; }

.focus\:bg-orange-darker:focus {
  background-color: #613b1f; }

.focus\:bg-orange-dark:focus {
  background-color: #de751f; }

.focus\:bg-orange:focus {
  background-color: #f6993f; }

.focus\:bg-orange-light:focus {
  background-color: #faad63; }

.focus\:bg-orange-lighter:focus {
  background-color: #fcd9b6; }

.focus\:bg-orange-lightest:focus {
  background-color: #fff5eb; }

.focus\:bg-yellow-darkest:focus {
  background-color: #453411; }

.focus\:bg-yellow-darker:focus {
  background-color: #684f1d; }

.focus\:bg-yellow-dark:focus {
  background-color: #f2d024; }

.focus\:bg-yellow:focus {
  background-color: #ffed4a; }

.focus\:bg-yellow-light:focus {
  background-color: #fff382; }

.focus\:bg-yellow-lighter:focus {
  background-color: #fff9c2; }

.focus\:bg-yellow-lightest:focus {
  background-color: #fcfbeb; }

.focus\:bg-green-darkest:focus {
  background-color: #0f2f21; }

.focus\:bg-green-darker:focus {
  background-color: #1a4731; }

.focus\:bg-green-dark:focus {
  background-color: #1f9d55; }

.focus\:bg-green:focus {
  background-color: #38c172; }

.focus\:bg-green-light:focus {
  background-color: #51d88a; }

.focus\:bg-green-lighter:focus {
  background-color: #a2f5bf; }

.focus\:bg-green-lightest:focus {
  background-color: #e3fcec; }

.focus\:bg-teal-darkest:focus {
  background-color: #0d3331; }

.focus\:bg-teal-darker:focus {
  background-color: #20504f; }

.focus\:bg-teal-dark:focus {
  background-color: #38a89d; }

.focus\:bg-teal:focus {
  background-color: #4dc0b5; }

.focus\:bg-teal-light:focus {
  background-color: #64d5ca; }

.focus\:bg-teal-lighter:focus {
  background-color: #a0f0ed; }

.focus\:bg-teal-lightest:focus {
  background-color: #e8fffe; }

.focus\:bg-blue-darkest:focus {
  background-color: #12283a; }

.focus\:bg-blue-darker:focus {
  background-color: #1c3d5a; }

.focus\:bg-blue-dark:focus {
  background-color: #2779bd; }

.focus\:bg-blue:focus {
  background-color: #3490dc; }

.focus\:bg-blue-light:focus {
  background-color: #6cb2eb; }

.focus\:bg-blue-lighter:focus {
  background-color: #bcdefa; }

.focus\:bg-blue-lightest:focus {
  background-color: #eff8ff; }

.focus\:bg-indigo-darkest:focus {
  background-color: #191e38; }

.focus\:bg-indigo-darker:focus {
  background-color: #2f365f; }

.focus\:bg-indigo-dark:focus {
  background-color: #5661b3; }

.focus\:bg-indigo:focus {
  background-color: #6574cd; }

.focus\:bg-indigo-light:focus {
  background-color: #7886d7; }

.focus\:bg-indigo-lighter:focus {
  background-color: #b2b7ff; }

.focus\:bg-indigo-lightest:focus {
  background-color: #e6e8ff; }

.focus\:bg-purple-darkest:focus {
  background-color: #21183c; }

.focus\:bg-purple-darker:focus {
  background-color: #382b5f; }

.focus\:bg-purple-dark:focus {
  background-color: #794acf; }

.focus\:bg-purple:focus {
  background-color: #9561e2; }

.focus\:bg-purple-light:focus {
  background-color: #a779e9; }

.focus\:bg-purple-lighter:focus {
  background-color: #d6bbfc; }

.focus\:bg-purple-lightest:focus {
  background-color: #f3ebff; }

.focus\:bg-pink-darkest:focus {
  background-color: #451225; }

.focus\:bg-pink-darker:focus {
  background-color: #6f213f; }

.focus\:bg-pink-dark:focus {
  background-color: #eb5286; }

.focus\:bg-pink:focus {
  background-color: #f66d9b; }

.focus\:bg-pink-light:focus {
  background-color: #fa7ea8; }

.focus\:bg-pink-lighter:focus {
  background-color: #ffbbca; }

.focus\:bg-pink-lightest:focus {
  background-color: #ffebef; }

.bg-bottom {
  background-position: bottom; }

.bg-center {
  background-position: center; }

.bg-left {
  background-position: left; }

.bg-left-bottom {
  background-position: left bottom; }

.bg-left-top {
  background-position: left top; }

.bg-right {
  background-position: right; }

.bg-right-bottom {
  background-position: right bottom; }

.bg-right-top {
  background-position: right top; }

.bg-top {
  background-position: top; }

.bg-repeat {
  background-repeat: repeat; }

.bg-no-repeat {
  background-repeat: no-repeat; }

.bg-repeat-x {
  background-repeat: repeat-x; }

.bg-repeat-y {
  background-repeat: repeat-y; }

.bg-auto {
  background-size: auto; }

.bg-cover {
  background-size: cover; }

.bg-contain {
  background-size: contain; }

.border-collapse {
  border-collapse: collapse; }

.border-separate {
  border-collapse: separate; }

.border-transparent {
  border-color: transparent; }

.border-black {
  border-color: #22292f; }

.border-grey-darkest {
  border-color: #3d4852; }

.border-grey-darker {
  border-color: #606f7b; }

.border-grey-dark {
  border-color: #8795a1; }

.border-grey {
  border-color: #b8c2cc; }

.border-grey-light {
  border-color: #dae1e7; }

.border-grey-lighter {
  border-color: #f1f5f8; }

.border-grey-lightest {
  border-color: #f8fafc; }

.border-white {
  border-color: #fff; }

.border-red-darkest {
  border-color: #3b0d0c; }

.border-red-darker {
  border-color: #621b18; }

.border-red-dark {
  border-color: #cc1f1a; }

.border-red {
  border-color: #e3342f; }

.border-red-light {
  border-color: #ef5753; }

.border-red-lighter {
  border-color: #f9acaa; }

.border-red-lightest {
  border-color: #fcebea; }

.border-orange-darkest {
  border-color: #462a16; }

.border-orange-darker {
  border-color: #613b1f; }

.border-orange-dark {
  border-color: #de751f; }

.border-orange {
  border-color: #f6993f; }

.border-orange-light {
  border-color: #faad63; }

.border-orange-lighter {
  border-color: #fcd9b6; }

.border-orange-lightest {
  border-color: #fff5eb; }

.border-yellow-darkest {
  border-color: #453411; }

.border-yellow-darker {
  border-color: #684f1d; }

.border-yellow-dark {
  border-color: #f2d024; }

.border-yellow {
  border-color: #ffed4a; }

.border-yellow-light {
  border-color: #fff382; }

.border-yellow-lighter {
  border-color: #fff9c2; }

.border-yellow-lightest {
  border-color: #fcfbeb; }

.border-green-darkest {
  border-color: #0f2f21; }

.border-green-darker {
  border-color: #1a4731; }

.border-green-dark {
  border-color: #1f9d55; }

.border-green {
  border-color: #38c172; }

.border-green-light {
  border-color: #51d88a; }

.border-green-lighter {
  border-color: #a2f5bf; }

.border-green-lightest {
  border-color: #e3fcec; }

.border-teal-darkest {
  border-color: #0d3331; }

.border-teal-darker {
  border-color: #20504f; }

.border-teal-dark {
  border-color: #38a89d; }

.border-teal {
  border-color: #4dc0b5; }

.border-teal-light {
  border-color: #64d5ca; }

.border-teal-lighter {
  border-color: #a0f0ed; }

.border-teal-lightest {
  border-color: #e8fffe; }

.border-blue-darkest {
  border-color: #12283a; }

.border-blue-darker {
  border-color: #1c3d5a; }

.border-blue-dark {
  border-color: #2779bd; }

.border-blue {
  border-color: #3490dc; }

.border-blue-light {
  border-color: #6cb2eb; }

.border-blue-lighter {
  border-color: #bcdefa; }

.border-blue-lightest {
  border-color: #eff8ff; }

.border-indigo-darkest {
  border-color: #191e38; }

.border-indigo-darker {
  border-color: #2f365f; }

.border-indigo-dark {
  border-color: #5661b3; }

.border-indigo {
  border-color: #6574cd; }

.border-indigo-light {
  border-color: #7886d7; }

.border-indigo-lighter {
  border-color: #b2b7ff; }

.border-indigo-lightest {
  border-color: #e6e8ff; }

.border-purple-darkest {
  border-color: #21183c; }

.border-purple-darker {
  border-color: #382b5f; }

.border-purple-dark {
  border-color: #794acf; }

.border-purple {
  border-color: #9561e2; }

.border-purple-light {
  border-color: #a779e9; }

.border-purple-lighter {
  border-color: #d6bbfc; }

.border-purple-lightest {
  border-color: #f3ebff; }

.border-pink-darkest {
  border-color: #451225; }

.border-pink-darker {
  border-color: #6f213f; }

.border-pink-dark {
  border-color: #eb5286; }

.border-pink {
  border-color: #f66d9b; }

.border-pink-light {
  border-color: #fa7ea8; }

.border-pink-lighter {
  border-color: #ffbbca; }

.border-pink-lightest {
  border-color: #ffebef; }

.hover\:border-transparent:hover {
  border-color: transparent; }

.hover\:border-black:hover {
  border-color: #22292f; }

.hover\:border-grey-darkest:hover {
  border-color: #3d4852; }

.hover\:border-grey-darker:hover {
  border-color: #606f7b; }

.hover\:border-grey-dark:hover {
  border-color: #8795a1; }

.hover\:border-grey:hover {
  border-color: #b8c2cc; }

.hover\:border-grey-light:hover {
  border-color: #dae1e7; }

.hover\:border-grey-lighter:hover {
  border-color: #f1f5f8; }

.hover\:border-grey-lightest:hover {
  border-color: #f8fafc; }

.hover\:border-white:hover {
  border-color: #fff; }

.hover\:border-red-darkest:hover {
  border-color: #3b0d0c; }

.hover\:border-red-darker:hover {
  border-color: #621b18; }

.hover\:border-red-dark:hover {
  border-color: #cc1f1a; }

.hover\:border-red:hover {
  border-color: #e3342f; }

.hover\:border-red-light:hover {
  border-color: #ef5753; }

.hover\:border-red-lighter:hover {
  border-color: #f9acaa; }

.hover\:border-red-lightest:hover {
  border-color: #fcebea; }

.hover\:border-orange-darkest:hover {
  border-color: #462a16; }

.hover\:border-orange-darker:hover {
  border-color: #613b1f; }

.hover\:border-orange-dark:hover {
  border-color: #de751f; }

.hover\:border-orange:hover {
  border-color: #f6993f; }

.hover\:border-orange-light:hover {
  border-color: #faad63; }

.hover\:border-orange-lighter:hover {
  border-color: #fcd9b6; }

.hover\:border-orange-lightest:hover {
  border-color: #fff5eb; }

.hover\:border-yellow-darkest:hover {
  border-color: #453411; }

.hover\:border-yellow-darker:hover {
  border-color: #684f1d; }

.hover\:border-yellow-dark:hover {
  border-color: #f2d024; }

.hover\:border-yellow:hover {
  border-color: #ffed4a; }

.hover\:border-yellow-light:hover {
  border-color: #fff382; }

.hover\:border-yellow-lighter:hover {
  border-color: #fff9c2; }

.hover\:border-yellow-lightest:hover {
  border-color: #fcfbeb; }

.hover\:border-green-darkest:hover {
  border-color: #0f2f21; }

.hover\:border-green-darker:hover {
  border-color: #1a4731; }

.hover\:border-green-dark:hover {
  border-color: #1f9d55; }

.hover\:border-green:hover {
  border-color: #38c172; }

.hover\:border-green-light:hover {
  border-color: #51d88a; }

.hover\:border-green-lighter:hover {
  border-color: #a2f5bf; }

.hover\:border-green-lightest:hover {
  border-color: #e3fcec; }

.hover\:border-teal-darkest:hover {
  border-color: #0d3331; }

.hover\:border-teal-darker:hover {
  border-color: #20504f; }

.hover\:border-teal-dark:hover {
  border-color: #38a89d; }

.hover\:border-teal:hover {
  border-color: #4dc0b5; }

.hover\:border-teal-light:hover {
  border-color: #64d5ca; }

.hover\:border-teal-lighter:hover {
  border-color: #a0f0ed; }

.hover\:border-teal-lightest:hover {
  border-color: #e8fffe; }

.hover\:border-blue-darkest:hover {
  border-color: #12283a; }

.hover\:border-blue-darker:hover {
  border-color: #1c3d5a; }

.hover\:border-blue-dark:hover {
  border-color: #2779bd; }

.hover\:border-blue:hover {
  border-color: #3490dc; }

.hover\:border-blue-light:hover {
  border-color: #6cb2eb; }

.hover\:border-blue-lighter:hover {
  border-color: #bcdefa; }

.hover\:border-blue-lightest:hover {
  border-color: #eff8ff; }

.hover\:border-indigo-darkest:hover {
  border-color: #191e38; }

.hover\:border-indigo-darker:hover {
  border-color: #2f365f; }

.hover\:border-indigo-dark:hover {
  border-color: #5661b3; }

.hover\:border-indigo:hover {
  border-color: #6574cd; }

.hover\:border-indigo-light:hover {
  border-color: #7886d7; }

.hover\:border-indigo-lighter:hover {
  border-color: #b2b7ff; }

.hover\:border-indigo-lightest:hover {
  border-color: #e6e8ff; }

.hover\:border-purple-darkest:hover {
  border-color: #21183c; }

.hover\:border-purple-darker:hover {
  border-color: #382b5f; }

.hover\:border-purple-dark:hover {
  border-color: #794acf; }

.hover\:border-purple:hover {
  border-color: #9561e2; }

.hover\:border-purple-light:hover {
  border-color: #a779e9; }

.hover\:border-purple-lighter:hover {
  border-color: #d6bbfc; }

.hover\:border-purple-lightest:hover {
  border-color: #f3ebff; }

.hover\:border-pink-darkest:hover {
  border-color: #451225; }

.hover\:border-pink-darker:hover {
  border-color: #6f213f; }

.hover\:border-pink-dark:hover {
  border-color: #eb5286; }

.hover\:border-pink:hover {
  border-color: #f66d9b; }

.hover\:border-pink-light:hover {
  border-color: #fa7ea8; }

.hover\:border-pink-lighter:hover {
  border-color: #ffbbca; }

.hover\:border-pink-lightest:hover {
  border-color: #ffebef; }

.focus\:border-transparent:focus {
  border-color: transparent; }

.focus\:border-black:focus {
  border-color: #22292f; }

.focus\:border-grey-darkest:focus {
  border-color: #3d4852; }

.focus\:border-grey-darker:focus {
  border-color: #606f7b; }

.focus\:border-grey-dark:focus {
  border-color: #8795a1; }

.focus\:border-grey:focus {
  border-color: #b8c2cc; }

.focus\:border-grey-light:focus {
  border-color: #dae1e7; }

.focus\:border-grey-lighter:focus {
  border-color: #f1f5f8; }

.focus\:border-grey-lightest:focus {
  border-color: #f8fafc; }

.focus\:border-white:focus {
  border-color: #fff; }

.focus\:border-red-darkest:focus {
  border-color: #3b0d0c; }

.focus\:border-red-darker:focus {
  border-color: #621b18; }

.focus\:border-red-dark:focus {
  border-color: #cc1f1a; }

.focus\:border-red:focus {
  border-color: #e3342f; }

.focus\:border-red-light:focus {
  border-color: #ef5753; }

.focus\:border-red-lighter:focus {
  border-color: #f9acaa; }

.focus\:border-red-lightest:focus {
  border-color: #fcebea; }

.focus\:border-orange-darkest:focus {
  border-color: #462a16; }

.focus\:border-orange-darker:focus {
  border-color: #613b1f; }

.focus\:border-orange-dark:focus {
  border-color: #de751f; }

.focus\:border-orange:focus {
  border-color: #f6993f; }

.focus\:border-orange-light:focus {
  border-color: #faad63; }

.focus\:border-orange-lighter:focus {
  border-color: #fcd9b6; }

.focus\:border-orange-lightest:focus {
  border-color: #fff5eb; }

.focus\:border-yellow-darkest:focus {
  border-color: #453411; }

.focus\:border-yellow-darker:focus {
  border-color: #684f1d; }

.focus\:border-yellow-dark:focus {
  border-color: #f2d024; }

.focus\:border-yellow:focus {
  border-color: #ffed4a; }

.focus\:border-yellow-light:focus {
  border-color: #fff382; }

.focus\:border-yellow-lighter:focus {
  border-color: #fff9c2; }

.focus\:border-yellow-lightest:focus {
  border-color: #fcfbeb; }

.focus\:border-green-darkest:focus {
  border-color: #0f2f21; }

.focus\:border-green-darker:focus {
  border-color: #1a4731; }

.focus\:border-green-dark:focus {
  border-color: #1f9d55; }

.focus\:border-green:focus {
  border-color: #38c172; }

.focus\:border-green-light:focus {
  border-color: #51d88a; }

.focus\:border-green-lighter:focus {
  border-color: #a2f5bf; }

.focus\:border-green-lightest:focus {
  border-color: #e3fcec; }

.focus\:border-teal-darkest:focus {
  border-color: #0d3331; }

.focus\:border-teal-darker:focus {
  border-color: #20504f; }

.focus\:border-teal-dark:focus {
  border-color: #38a89d; }

.focus\:border-teal:focus {
  border-color: #4dc0b5; }

.focus\:border-teal-light:focus {
  border-color: #64d5ca; }

.focus\:border-teal-lighter:focus {
  border-color: #a0f0ed; }

.focus\:border-teal-lightest:focus {
  border-color: #e8fffe; }

.focus\:border-blue-darkest:focus {
  border-color: #12283a; }

.focus\:border-blue-darker:focus {
  border-color: #1c3d5a; }

.focus\:border-blue-dark:focus {
  border-color: #2779bd; }

.focus\:border-blue:focus {
  border-color: #3490dc; }

.focus\:border-blue-light:focus {
  border-color: #6cb2eb; }

.focus\:border-blue-lighter:focus {
  border-color: #bcdefa; }

.focus\:border-blue-lightest:focus {
  border-color: #eff8ff; }

.focus\:border-indigo-darkest:focus {
  border-color: #191e38; }

.focus\:border-indigo-darker:focus {
  border-color: #2f365f; }

.focus\:border-indigo-dark:focus {
  border-color: #5661b3; }

.focus\:border-indigo:focus {
  border-color: #6574cd; }

.focus\:border-indigo-light:focus {
  border-color: #7886d7; }

.focus\:border-indigo-lighter:focus {
  border-color: #b2b7ff; }

.focus\:border-indigo-lightest:focus {
  border-color: #e6e8ff; }

.focus\:border-purple-darkest:focus {
  border-color: #21183c; }

.focus\:border-purple-darker:focus {
  border-color: #382b5f; }

.focus\:border-purple-dark:focus {
  border-color: #794acf; }

.focus\:border-purple:focus {
  border-color: #9561e2; }

.focus\:border-purple-light:focus {
  border-color: #a779e9; }

.focus\:border-purple-lighter:focus {
  border-color: #d6bbfc; }

.focus\:border-purple-lightest:focus {
  border-color: #f3ebff; }

.focus\:border-pink-darkest:focus {
  border-color: #451225; }

.focus\:border-pink-darker:focus {
  border-color: #6f213f; }

.focus\:border-pink-dark:focus {
  border-color: #eb5286; }

.focus\:border-pink:focus {
  border-color: #f66d9b; }

.focus\:border-pink-light:focus {
  border-color: #fa7ea8; }

.focus\:border-pink-lighter:focus {
  border-color: #ffbbca; }

.focus\:border-pink-lightest:focus {
  border-color: #ffebef; }

.rounded-none {
  border-radius: 0; }

.rounded-sm {
  border-radius: .125rem; }

.rounded {
  border-radius: .25rem; }

.rounded-lg {
  border-radius: .5rem; }

.rounded-full {
  border-radius: 9999px; }

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem; }

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem; }

.rounded-b-sm {
  border-bottom-right-radius: .125rem;
  border-bottom-left-radius: .125rem; }

.rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem; }

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem; }

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem; }

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem; }

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem; }

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px; }

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px; }

.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px; }

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px; }

.rounded-tl-none {
  border-top-left-radius: 0; }

.rounded-tr-none {
  border-top-right-radius: 0; }

.rounded-br-none {
  border-bottom-right-radius: 0; }

.rounded-bl-none {
  border-bottom-left-radius: 0; }

.rounded-tl-sm {
  border-top-left-radius: .125rem; }

.rounded-tr-sm {
  border-top-right-radius: .125rem; }

.rounded-br-sm {
  border-bottom-right-radius: .125rem; }

.rounded-bl-sm {
  border-bottom-left-radius: .125rem; }

.rounded-tl {
  border-top-left-radius: .25rem; }

.rounded-tr {
  border-top-right-radius: .25rem; }

.rounded-br {
  border-bottom-right-radius: .25rem; }

.rounded-bl {
  border-bottom-left-radius: .25rem; }

.rounded-tl-lg {
  border-top-left-radius: .5rem; }

.rounded-tr-lg {
  border-top-right-radius: .5rem; }

.rounded-br-lg {
  border-bottom-right-radius: .5rem; }

.rounded-bl-lg {
  border-bottom-left-radius: .5rem; }

.rounded-tl-full {
  border-top-left-radius: 9999px; }

.rounded-tr-full {
  border-top-right-radius: 9999px; }

.rounded-br-full {
  border-bottom-right-radius: 9999px; }

.rounded-bl-full {
  border-bottom-left-radius: 9999px; }

.border-solid {
  border-style: solid; }

.border-dashed {
  border-style: dashed; }

.border-dotted {
  border-style: dotted; }

.border-none {
  border-style: none; }

.border-0 {
  border-width: 0; }

.border-2 {
  border-width: 2px; }

.border-4 {
  border-width: 4px; }

.border-8 {
  border-width: 8px; }

.border {
  border-width: 1px; }

.border-t-0 {
  border-top-width: 0; }

.border-r-0 {
  border-right-width: 0; }

.border-b-0 {
  border-bottom-width: 0; }

.border-l-0 {
  border-left-width: 0; }

.border-t-2 {
  border-top-width: 2px; }

.border-r-2 {
  border-right-width: 2px; }

.border-b-2 {
  border-bottom-width: 2px; }

.border-l-2 {
  border-left-width: 2px; }

.border-t-4 {
  border-top-width: 4px; }

.border-r-4 {
  border-right-width: 4px; }

.border-b-4 {
  border-bottom-width: 4px; }

.border-l-4 {
  border-left-width: 4px; }

.border-t-8 {
  border-top-width: 8px; }

.border-r-8 {
  border-right-width: 8px; }

.border-b-8 {
  border-bottom-width: 8px; }

.border-l-8 {
  border-left-width: 8px; }

.border-t {
  border-top-width: 1px; }

.border-r {
  border-right-width: 1px; }

.border-b {
  border-bottom-width: 1px; }

.border-l {
  border-left-width: 1px; }

.cursor-auto {
  cursor: auto; }

.cursor-default {
  cursor: default; }

.cursor-pointer {
  cursor: pointer; }

.cursor-wait {
  cursor: wait; }

.cursor-move {
  cursor: move; }

.cursor-not-allowed {
  cursor: not-allowed; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.table {
  display: table; }

.table-row {
  display: table-row; }

.table-cell {
  display: table-cell; }

.hidden {
  display: none; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-col {
  flex-direction: column; }

.flex-col-reverse {
  flex-direction: column-reverse; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-no-wrap {
  flex-wrap: nowrap; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-center {
  align-items: center; }

.items-baseline {
  align-items: baseline; }

.items-stretch {
  align-items: stretch; }

.self-auto {
  align-self: auto; }

.self-start {
  align-self: flex-start; }

.self-end {
  align-self: flex-end; }

.self-center {
  align-self: center; }

.self-stretch {
  align-self: stretch; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.content-center {
  align-content: center; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-between {
  align-content: space-between; }

.content-around {
  align-content: space-around; }

.flex-1 {
  flex: 1 1 0%; }

.flex-auto {
  flex: 1 1 auto; }

.flex-initial {
  flex: 0 1 auto; }

.flex-none {
  flex: none; }

.flex-grow {
  flex-grow: 1; }

.flex-shrink {
  flex-shrink: 1; }

.flex-no-grow {
  flex-grow: 0; }

.flex-no-shrink {
  flex-shrink: 0; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.float-none {
  float: none; }

.clearfix:after {
  content: "";
  display: table;
  clear: both; }

.font-sans {
  font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }

.font-serif {
  font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif; }

.font-mono {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }

.font-hairline {
  font-weight: 100; }

.font-thin {
  font-weight: 200; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold {
  font-weight: 700; }

.font-extrabold {
  font-weight: 800; }

.font-black {
  font-weight: 900; }

.hover\:font-hairline:hover {
  font-weight: 100; }

.hover\:font-thin:hover {
  font-weight: 200; }

.hover\:font-light:hover {
  font-weight: 300; }

.hover\:font-normal:hover {
  font-weight: 400; }

.hover\:font-medium:hover {
  font-weight: 500; }

.hover\:font-semibold:hover {
  font-weight: 600; }

.hover\:font-bold:hover {
  font-weight: 700; }

.hover\:font-extrabold:hover {
  font-weight: 800; }

.hover\:font-black:hover {
  font-weight: 900; }

.focus\:font-hairline:focus {
  font-weight: 100; }

.focus\:font-thin:focus {
  font-weight: 200; }

.focus\:font-light:focus {
  font-weight: 300; }

.focus\:font-normal:focus {
  font-weight: 400; }

.focus\:font-medium:focus {
  font-weight: 500; }

.focus\:font-semibold:focus {
  font-weight: 600; }

.focus\:font-bold:focus {
  font-weight: 700; }

.focus\:font-extrabold:focus {
  font-weight: 800; }

.focus\:font-black:focus {
  font-weight: 900; }

.h-1 {
  height: .25rem; }

.h-2 {
  height: .5rem; }

.h-3 {
  height: .75rem; }

.h-4 {
  height: 1rem; }

.h-5 {
  height: 1.25rem; }

.h-6 {
  height: 1.5rem; }

.h-8 {
  height: 2rem; }

.h-10 {
  height: 2.5rem; }

.h-12 {
  height: 3rem; }

.h-16 {
  height: 4rem; }

.h-24 {
  height: 6rem; }

.h-32 {
  height: 8rem; }

.h-48 {
  height: 12rem; }

.h-64 {
  height: 16rem; }

.h-auto {
  height: auto; }

.h-px {
  height: 1px; }

.h-full {
  height: 100%; }

.h-screen {
  height: 100vh; }

.leading-none {
  line-height: 1; }

.leading-tight {
  line-height: 1.25; }

.leading-normal {
  line-height: 1.5; }

.leading-loose {
  line-height: 2; }

.m-0 {
  margin: 0; }

.m-1 {
  margin: .25rem; }

.m-2 {
  margin: .5rem; }

.m-3 {
  margin: .75rem; }

.m-4 {
  margin: 1rem; }

.m-5 {
  margin: 1.25rem; }

.m-6 {
  margin: 1.5rem; }

.m-8 {
  margin: 2rem; }

.m-10 {
  margin: 2.5rem; }

.m-12 {
  margin: 3rem; }

.m-16 {
  margin: 4rem; }

.m-20 {
  margin: 5rem; }

.m-24 {
  margin: 6rem; }

.m-32 {
  margin: 8rem; }

.m-auto {
  margin: auto; }

.m-px {
  margin: 1px; }

.my-0 {
  margin-top: 0;
  margin-bottom: 0; }

.mx-0 {
  margin-left: 0;
  margin-right: 0; }

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem; }

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem; }

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem; }

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem; }

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem; }

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem; }

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem; }

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem; }

.my-auto {
  margin-top: auto;
  margin-bottom: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.my-px {
  margin-top: 1px;
  margin-bottom: 1px; }

.mx-px {
  margin-left: 1px;
  margin-right: 1px; }

.mt-0 {
  margin-top: 0; }

.mr-0 {
  margin-right: 0; }

.mb-0 {
  margin-bottom: 0; }

.ml-0 {
  margin-left: 0; }

.mt-1 {
  margin-top: .25rem; }

.mr-1 {
  margin-right: .25rem; }

.mb-1 {
  margin-bottom: .25rem; }

.ml-1 {
  margin-left: .25rem; }

.mt-2 {
  margin-top: .5rem; }

.mr-2 {
  margin-right: .5rem; }

.mb-2 {
  margin-bottom: .5rem; }

.ml-2 {
  margin-left: .5rem; }

.mt-3 {
  margin-top: .75rem; }

.mr-3 {
  margin-right: .75rem; }

.mb-3 {
  margin-bottom: .75rem; }

.ml-3 {
  margin-left: .75rem; }

.mt-4 {
  margin-top: 1rem; }

.mr-4 {
  margin-right: 1rem; }

.mb-4 {
  margin-bottom: 1rem; }

.ml-4 {
  margin-left: 1rem; }

.mt-5 {
  margin-top: 1.25rem; }

.mr-5 {
  margin-right: 1.25rem; }

.mb-5 {
  margin-bottom: 1.25rem; }

.ml-5 {
  margin-left: 1.25rem; }

.mt-6 {
  margin-top: 1.5rem; }

.mr-6 {
  margin-right: 1.5rem; }

.mb-6 {
  margin-bottom: 1.5rem; }

.ml-6 {
  margin-left: 1.5rem; }

.mt-8 {
  margin-top: 2rem; }

.mr-8 {
  margin-right: 2rem; }

.mb-8 {
  margin-bottom: 2rem; }

.ml-8 {
  margin-left: 2rem; }

.mt-10 {
  margin-top: 2.5rem; }

.mr-10 {
  margin-right: 2.5rem; }

.mb-10 {
  margin-bottom: 2.5rem; }

.ml-10 {
  margin-left: 2.5rem; }

.mt-12 {
  margin-top: 3rem; }

.mr-12 {
  margin-right: 3rem; }

.mb-12 {
  margin-bottom: 3rem; }

.ml-12 {
  margin-left: 3rem; }

.mt-16 {
  margin-top: 4rem; }

.mr-16 {
  margin-right: 4rem; }

.mb-16 {
  margin-bottom: 4rem; }

.ml-16 {
  margin-left: 4rem; }

.mt-20 {
  margin-top: 5rem; }

.mr-20 {
  margin-right: 5rem; }

.mb-20 {
  margin-bottom: 5rem; }

.ml-20 {
  margin-left: 5rem; }

.mt-24 {
  margin-top: 6rem; }

.mr-24 {
  margin-right: 6rem; }

.mb-24 {
  margin-bottom: 6rem; }

.ml-24 {
  margin-left: 6rem; }

.mt-32 {
  margin-top: 8rem; }

.mr-32 {
  margin-right: 8rem; }

.mb-32 {
  margin-bottom: 8rem; }

.ml-32 {
  margin-left: 8rem; }

.mt-auto {
  margin-top: auto; }

.mr-auto {
  margin-right: auto; }

.mb-auto {
  margin-bottom: auto; }

.ml-auto {
  margin-left: auto; }

.mt-px {
  margin-top: 1px; }

.mr-px {
  margin-right: 1px; }

.mb-px {
  margin-bottom: 1px; }

.ml-px {
  margin-left: 1px; }

.max-h-full {
  max-height: 100%; }

.max-h-screen {
  max-height: 100vh; }

.max-w-xs {
  max-width: 20rem; }

.max-w-sm {
  max-width: 30rem; }

.max-w-md {
  max-width: 40rem; }

.max-w-lg {
  max-width: 50rem; }

.max-w-xl {
  max-width: 60rem; }

.max-w-2xl {
  max-width: 70rem; }

.max-w-3xl {
  max-width: 80rem; }

.max-w-4xl {
  max-width: 90rem; }

.max-w-5xl {
  max-width: 100rem; }

.max-w-full {
  max-width: 100%; }

.min-h-0 {
  min-height: 0; }

.min-h-full {
  min-height: 100%; }

.min-h-screen {
  min-height: 100vh; }

.min-w-0 {
  min-width: 0; }

.min-w-full {
  min-width: 100%; }

.-m-0 {
  margin: 0; }

.-m-1 {
  margin: -0.25rem; }

.-m-2 {
  margin: -0.5rem; }

.-m-3 {
  margin: -0.75rem; }

.-m-4 {
  margin: -1rem; }

.-m-5 {
  margin: -1.25rem; }

.-m-6 {
  margin: -1.5rem; }

.-m-8 {
  margin: -2rem; }

.-m-10 {
  margin: -2.5rem; }

.-m-12 {
  margin: -3rem; }

.-m-16 {
  margin: -4rem; }

.-m-20 {
  margin: -5rem; }

.-m-24 {
  margin: -6rem; }

.-m-32 {
  margin: -8rem; }

.-m-px {
  margin: -1px; }

.-my-0 {
  margin-top: 0;
  margin-bottom: 0; }

.-mx-0 {
  margin-left: 0;
  margin-right: 0; }

.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem; }

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem; }

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem; }

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem; }

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem; }

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem; }

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem; }

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem; }

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem; }

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem; }

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem; }

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem; }

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem; }

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem; }

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem; }

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem; }

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem; }

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem; }

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem; }

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px; }

.-mx-px {
  margin-left: -1px;
  margin-right: -1px; }

.-mt-0 {
  margin-top: 0; }

.-mr-0 {
  margin-right: 0; }

.-mb-0 {
  margin-bottom: 0; }

.-ml-0 {
  margin-left: 0; }

.-mt-1 {
  margin-top: -0.25rem; }

.-mr-1 {
  margin-right: -0.25rem; }

.-mb-1 {
  margin-bottom: -0.25rem; }

.-ml-1 {
  margin-left: -0.25rem; }

.-mt-2 {
  margin-top: -0.5rem; }

.-mr-2 {
  margin-right: -0.5rem; }

.-mb-2 {
  margin-bottom: -0.5rem; }

.-ml-2 {
  margin-left: -0.5rem; }

.-mt-3 {
  margin-top: -0.75rem; }

.-mr-3 {
  margin-right: -0.75rem; }

.-mb-3 {
  margin-bottom: -0.75rem; }

.-ml-3 {
  margin-left: -0.75rem; }

.-mt-4 {
  margin-top: -1rem; }

.-mr-4 {
  margin-right: -1rem; }

.-mb-4 {
  margin-bottom: -1rem; }

.-ml-4 {
  margin-left: -1rem; }

.-mt-5 {
  margin-top: -1.25rem; }

.-mr-5 {
  margin-right: -1.25rem; }

.-mb-5 {
  margin-bottom: -1.25rem; }

.-ml-5 {
  margin-left: -1.25rem; }

.-mt-6 {
  margin-top: -1.5rem; }

.-mr-6 {
  margin-right: -1.5rem; }

.-mb-6 {
  margin-bottom: -1.5rem; }

.-ml-6 {
  margin-left: -1.5rem; }

.-mt-8 {
  margin-top: -2rem; }

.-mr-8 {
  margin-right: -2rem; }

.-mb-8 {
  margin-bottom: -2rem; }

.-ml-8 {
  margin-left: -2rem; }

.-mt-10 {
  margin-top: -2.5rem; }

.-mr-10 {
  margin-right: -2.5rem; }

.-mb-10 {
  margin-bottom: -2.5rem; }

.-ml-10 {
  margin-left: -2.5rem; }

.-mt-12 {
  margin-top: -3rem; }

.-mr-12 {
  margin-right: -3rem; }

.-mb-12 {
  margin-bottom: -3rem; }

.-ml-12 {
  margin-left: -3rem; }

.-mt-16 {
  margin-top: -4rem; }

.-mr-16 {
  margin-right: -4rem; }

.-mb-16 {
  margin-bottom: -4rem; }

.-ml-16 {
  margin-left: -4rem; }

.-mt-20 {
  margin-top: -5rem; }

.-mr-20 {
  margin-right: -5rem; }

.-mb-20 {
  margin-bottom: -5rem; }

.-ml-20 {
  margin-left: -5rem; }

.-mt-24 {
  margin-top: -6rem; }

.-mr-24 {
  margin-right: -6rem; }

.-mb-24 {
  margin-bottom: -6rem; }

.-ml-24 {
  margin-left: -6rem; }

.-mt-32 {
  margin-top: -8rem; }

.-mr-32 {
  margin-right: -8rem; }

.-mb-32 {
  margin-bottom: -8rem; }

.-ml-32 {
  margin-left: -8rem; }

.-mt-px {
  margin-top: -1px; }

.-mr-px {
  margin-right: -1px; }

.-mb-px {
  margin-bottom: -1px; }

.-ml-px {
  margin-left: -1px; }

.opacity-0 {
  opacity: 0; }

.opacity-25 {
  opacity: .25; }

.opacity-50 {
  opacity: .5; }

.opacity-75 {
  opacity: .75; }

.opacity-100 {
  opacity: 1; }

.outline-none {
  outline: 0; }

.focus\:outline-none:focus {
  outline: 0; }

.overflow-auto {
  overflow: auto; }

.overflow-hidden {
  overflow: hidden; }

.overflow-visible {
  overflow: visible; }

.overflow-scroll {
  overflow: scroll; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-auto {
  overflow-y: auto; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-x-visible {
  overflow-x: visible; }

.overflow-y-visible {
  overflow-y: visible; }

.overflow-x-scroll {
  overflow-x: scroll; }

.overflow-y-scroll {
  overflow-y: scroll; }

.scrolling-touch {
  -webkit-overflow-scrolling: touch; }

.scrolling-auto {
  -webkit-overflow-scrolling: auto; }

.p-0 {
  padding: 0; }

.p-1 {
  padding: .25rem; }

.p-2 {
  padding: .5rem; }

.p-3 {
  padding: .75rem; }

.p-4 {
  padding: 1rem; }

.p-5 {
  padding: 1.25rem; }

.p-6 {
  padding: 1.5rem; }

.p-8 {
  padding: 2rem; }

.p-10 {
  padding: 2.5rem; }

.p-12 {
  padding: 3rem; }

.p-16 {
  padding: 4rem; }

.p-20 {
  padding: 5rem; }

.p-24 {
  padding: 6rem; }

.p-32 {
  padding: 8rem; }

.p-px {
  padding: 1px; }

.py-0 {
  padding-top: 0;
  padding-bottom: 0; }

.px-0 {
  padding-left: 0;
  padding-right: 0; }

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem; }

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem; }

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem; }

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem; }

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem; }

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem; }

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem; }

.py-px {
  padding-top: 1px;
  padding-bottom: 1px; }

.px-px {
  padding-left: 1px;
  padding-right: 1px; }

.pt-0 {
  padding-top: 0; }

.pr-0 {
  padding-right: 0; }

.pb-0 {
  padding-bottom: 0; }

.pl-0 {
  padding-left: 0; }

.pt-1 {
  padding-top: .25rem; }

.pr-1 {
  padding-right: .25rem; }

.pb-1 {
  padding-bottom: .25rem; }

.pl-1 {
  padding-left: .25rem; }

.pt-2 {
  padding-top: .5rem; }

.pr-2 {
  padding-right: .5rem; }

.pb-2 {
  padding-bottom: .5rem; }

.pl-2 {
  padding-left: .5rem; }

.pt-3 {
  padding-top: .75rem; }

.pr-3 {
  padding-right: .75rem; }

.pb-3 {
  padding-bottom: .75rem; }

.pl-3 {
  padding-left: .75rem; }

.pt-4 {
  padding-top: 1rem; }

.pr-4 {
  padding-right: 1rem; }

.pb-4 {
  padding-bottom: 1rem; }

.pl-4 {
  padding-left: 1rem; }

.pt-5 {
  padding-top: 1.25rem; }

.pr-5 {
  padding-right: 1.25rem; }

.pb-5 {
  padding-bottom: 1.25rem; }

.pl-5 {
  padding-left: 1.25rem; }

.pt-6 {
  padding-top: 1.5rem; }

.pr-6 {
  padding-right: 1.5rem; }

.pb-6 {
  padding-bottom: 1.5rem; }

.pl-6 {
  padding-left: 1.5rem; }

.pt-8 {
  padding-top: 2rem; }

.pr-8 {
  padding-right: 2rem; }

.pb-8 {
  padding-bottom: 2rem; }

.pl-8 {
  padding-left: 2rem; }

.pt-10 {
  padding-top: 2.5rem; }

.pr-10 {
  padding-right: 2.5rem; }

.pb-10 {
  padding-bottom: 2.5rem; }

.pl-10 {
  padding-left: 2.5rem; }

.pt-12 {
  padding-top: 3rem; }

.pr-12 {
  padding-right: 3rem; }

.pb-12 {
  padding-bottom: 3rem; }

.pl-12 {
  padding-left: 3rem; }

.pt-16 {
  padding-top: 4rem; }

.pr-16 {
  padding-right: 4rem; }

.pb-16 {
  padding-bottom: 4rem; }

.pl-16 {
  padding-left: 4rem; }

.pt-20 {
  padding-top: 5rem; }

.pr-20 {
  padding-right: 5rem; }

.pb-20 {
  padding-bottom: 5rem; }

.pl-20 {
  padding-left: 5rem; }

.pt-24 {
  padding-top: 6rem; }

.pr-24 {
  padding-right: 6rem; }

.pb-24 {
  padding-bottom: 6rem; }

.pl-24 {
  padding-left: 6rem; }

.pt-32 {
  padding-top: 8rem; }

.pr-32 {
  padding-right: 8rem; }

.pb-32 {
  padding-bottom: 8rem; }

.pl-32 {
  padding-left: 8rem; }

.pt-px {
  padding-top: 1px; }

.pr-px {
  padding-right: 1px; }

.pb-px {
  padding-bottom: 1px; }

.pl-px {
  padding-left: 1px; }

.pointer-events-none {
  pointer-events: none; }

.pointer-events-auto {
  pointer-events: auto; }

.static {
  position: static; }

.fixed {
  position: fixed; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.sticky {
  position: -webkit-sticky;
  position: sticky; }

.pin-none {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto; }

.pin {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.pin-y {
  top: 0;
  bottom: 0; }

.pin-x {
  right: 0;
  left: 0; }

.pin-t {
  top: 0; }

.pin-r {
  right: 0; }

.pin-b {
  bottom: 0; }

.pin-l {
  left: 0; }

.resize-none {
  resize: none; }

.resize-y {
  resize: vertical; }

.resize-x {
  resize: horizontal; }

.resize {
  resize: both; }

.shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.shadow-md {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }

.shadow-lg {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }

.shadow-inner {
  box-shadow: inset 0 0px 40px 10px rgba(0, 0, 0, 0.1); }

.shadow-outline {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }

.shadow-none {
  box-shadow: none; }

.hover\:shadow:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.hover\:shadow-md:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }

.hover\:shadow-lg:hover {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }

.hover\:shadow-none:hover {
  box-shadow: none; }

.focus\:shadow:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

.focus\:shadow-md:focus {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }

.focus\:shadow-lg:focus {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }

.focus\:shadow-none:focus {
  box-shadow: none; }

.fill-current {
  fill: currentColor; }

.stroke-current {
  stroke: currentColor; }

.table-auto {
  table-layout: auto; }

.table-fixed {
  table-layout: fixed; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.text-transparent {
  color: transparent; }

.text-black {
  color: #22292f; }

.text-grey-darkest {
  color: #3d4852; }

.text-grey-darker {
  color: #606f7b; }

.text-grey-dark {
  color: #8795a1; }

.text-grey {
  color: #b8c2cc; }

.text-grey-light {
  color: #dae1e7; }

.text-grey-lighter {
  color: #f1f5f8; }

.text-grey-lightest {
  color: #f8fafc; }

.text-white {
  color: #fff; }

.text-red-darkest {
  color: #3b0d0c; }

.text-red-darker {
  color: #621b18; }

.text-red-dark {
  color: #cc1f1a; }

.text-red {
  color: #e3342f; }

.text-red-light {
  color: #ef5753; }

.text-red-lighter {
  color: #f9acaa; }

.text-red-lightest {
  color: #fcebea; }

.text-orange-darkest {
  color: #462a16; }

.text-orange-darker {
  color: #613b1f; }

.text-orange-dark {
  color: #de751f; }

.text-orange {
  color: #f6993f; }

.text-orange-light {
  color: #faad63; }

.text-orange-lighter {
  color: #fcd9b6; }

.text-orange-lightest {
  color: #fff5eb; }

.text-yellow-darkest {
  color: #453411; }

.text-yellow-darker {
  color: #684f1d; }

.text-yellow-dark {
  color: #f2d024; }

.text-yellow {
  color: #ffed4a; }

.text-yellow-light {
  color: #fff382; }

.text-yellow-lighter {
  color: #fff9c2; }

.text-yellow-lightest {
  color: #fcfbeb; }

.text-green-darkest {
  color: #0f2f21; }

.text-green-darker {
  color: #1a4731; }

.text-green-dark {
  color: #1f9d55; }

.text-green {
  color: #38c172; }

.text-green-light {
  color: #51d88a; }

.text-green-lighter {
  color: #a2f5bf; }

.text-green-lightest {
  color: #e3fcec; }

.text-teal-darkest {
  color: #0d3331; }

.text-teal-darker {
  color: #20504f; }

.text-teal-dark {
  color: #38a89d; }

.text-teal {
  color: #4dc0b5; }

.text-teal-light {
  color: #64d5ca; }

.text-teal-lighter {
  color: #a0f0ed; }

.text-teal-lightest {
  color: #e8fffe; }

.text-blue-darkest {
  color: #12283a; }

.text-blue-darker {
  color: #1c3d5a; }

.text-blue-dark {
  color: #2779bd; }

.text-blue {
  color: #3490dc; }

.text-blue-light {
  color: #6cb2eb; }

.text-blue-lighter {
  color: #bcdefa; }

.text-blue-lightest {
  color: #eff8ff; }

.text-indigo-darkest {
  color: #191e38; }

.text-indigo-darker {
  color: #2f365f; }

.text-indigo-dark {
  color: #5661b3; }

.text-indigo {
  color: #6574cd; }

.text-indigo-light {
  color: #7886d7; }

.text-indigo-lighter {
  color: #b2b7ff; }

.text-indigo-lightest {
  color: #e6e8ff; }

.text-purple-darkest {
  color: #21183c; }

.text-purple-darker {
  color: #382b5f; }

.text-purple-dark {
  color: #794acf; }

.text-purple {
  color: #9561e2; }

.text-purple-light {
  color: #a779e9; }

.text-purple-lighter {
  color: #d6bbfc; }

.text-purple-lightest {
  color: #f3ebff; }

.text-pink-darkest {
  color: #451225; }

.text-pink-darker {
  color: #6f213f; }

.text-pink-dark {
  color: #eb5286; }

.text-pink {
  color: #f66d9b; }

.text-pink-light {
  color: #fa7ea8; }

.text-pink-lighter {
  color: #ffbbca; }

.text-pink-lightest {
  color: #ffebef; }

.hover\:text-transparent:hover {
  color: transparent; }

.hover\:text-black:hover {
  color: #22292f; }

.hover\:text-grey-darkest:hover {
  color: #3d4852; }

.hover\:text-grey-darker:hover {
  color: #606f7b; }

.hover\:text-grey-dark:hover {
  color: #8795a1; }

.hover\:text-grey:hover {
  color: #b8c2cc; }

.hover\:text-grey-light:hover {
  color: #dae1e7; }

.hover\:text-grey-lighter:hover {
  color: #f1f5f8; }

.hover\:text-grey-lightest:hover {
  color: #f8fafc; }

.hover\:text-white:hover {
  color: #fff; }

.hover\:text-red-darkest:hover {
  color: #3b0d0c; }

.hover\:text-red-darker:hover {
  color: #621b18; }

.hover\:text-red-dark:hover {
  color: #cc1f1a; }

.hover\:text-red:hover {
  color: #e3342f; }

.hover\:text-red-light:hover {
  color: #ef5753; }

.hover\:text-red-lighter:hover {
  color: #f9acaa; }

.hover\:text-red-lightest:hover {
  color: #fcebea; }

.hover\:text-orange-darkest:hover {
  color: #462a16; }

.hover\:text-orange-darker:hover {
  color: #613b1f; }

.hover\:text-orange-dark:hover {
  color: #de751f; }

.hover\:text-orange:hover {
  color: #f6993f; }

.hover\:text-orange-light:hover {
  color: #faad63; }

.hover\:text-orange-lighter:hover {
  color: #fcd9b6; }

.hover\:text-orange-lightest:hover {
  color: #fff5eb; }

.hover\:text-yellow-darkest:hover {
  color: #453411; }

.hover\:text-yellow-darker:hover {
  color: #684f1d; }

.hover\:text-yellow-dark:hover {
  color: #f2d024; }

.hover\:text-yellow:hover {
  color: #ffed4a; }

.hover\:text-yellow-light:hover {
  color: #fff382; }

.hover\:text-yellow-lighter:hover {
  color: #fff9c2; }

.hover\:text-yellow-lightest:hover {
  color: #fcfbeb; }

.hover\:text-green-darkest:hover {
  color: #0f2f21; }

.hover\:text-green-darker:hover {
  color: #1a4731; }

.hover\:text-green-dark:hover {
  color: #1f9d55; }

.hover\:text-green:hover {
  color: #38c172; }

.hover\:text-green-light:hover {
  color: #51d88a; }

.hover\:text-green-lighter:hover {
  color: #a2f5bf; }

.hover\:text-green-lightest:hover {
  color: #e3fcec; }

.hover\:text-teal-darkest:hover {
  color: #0d3331; }

.hover\:text-teal-darker:hover {
  color: #20504f; }

.hover\:text-teal-dark:hover {
  color: #38a89d; }

.hover\:text-teal:hover {
  color: #4dc0b5; }

.hover\:text-teal-light:hover {
  color: #64d5ca; }

.hover\:text-teal-lighter:hover {
  color: #a0f0ed; }

.hover\:text-teal-lightest:hover {
  color: #e8fffe; }

.hover\:text-blue-darkest:hover {
  color: #12283a; }

.hover\:text-blue-darker:hover {
  color: #1c3d5a; }

.hover\:text-blue-dark:hover {
  color: #2779bd; }

.hover\:text-blue:hover {
  color: #3490dc; }

.hover\:text-blue-light:hover {
  color: #6cb2eb; }

.hover\:text-blue-lighter:hover {
  color: #bcdefa; }

.hover\:text-blue-lightest:hover {
  color: #eff8ff; }

.hover\:text-indigo-darkest:hover {
  color: #191e38; }

.hover\:text-indigo-darker:hover {
  color: #2f365f; }

.hover\:text-indigo-dark:hover {
  color: #5661b3; }

.hover\:text-indigo:hover {
  color: #6574cd; }

.hover\:text-indigo-light:hover {
  color: #7886d7; }

.hover\:text-indigo-lighter:hover {
  color: #b2b7ff; }

.hover\:text-indigo-lightest:hover {
  color: #e6e8ff; }

.hover\:text-purple-darkest:hover {
  color: #21183c; }

.hover\:text-purple-darker:hover {
  color: #382b5f; }

.hover\:text-purple-dark:hover {
  color: #794acf; }

.hover\:text-purple:hover {
  color: #9561e2; }

.hover\:text-purple-light:hover {
  color: #a779e9; }

.hover\:text-purple-lighter:hover {
  color: #d6bbfc; }

.hover\:text-purple-lightest:hover {
  color: #f3ebff; }

.hover\:text-pink-darkest:hover {
  color: #451225; }

.hover\:text-pink-darker:hover {
  color: #6f213f; }

.hover\:text-pink-dark:hover {
  color: #eb5286; }

.hover\:text-pink:hover {
  color: #f66d9b; }

.hover\:text-pink-light:hover {
  color: #fa7ea8; }

.hover\:text-pink-lighter:hover {
  color: #ffbbca; }

.hover\:text-pink-lightest:hover {
  color: #ffebef; }

.focus\:text-transparent:focus {
  color: transparent; }

.focus\:text-black:focus {
  color: #22292f; }

.focus\:text-grey-darkest:focus {
  color: #3d4852; }

.focus\:text-grey-darker:focus {
  color: #606f7b; }

.focus\:text-grey-dark:focus {
  color: #8795a1; }

.focus\:text-grey:focus {
  color: #b8c2cc; }

.focus\:text-grey-light:focus {
  color: #dae1e7; }

.focus\:text-grey-lighter:focus {
  color: #f1f5f8; }

.focus\:text-grey-lightest:focus {
  color: #f8fafc; }

.focus\:text-white:focus {
  color: #fff; }

.focus\:text-red-darkest:focus {
  color: #3b0d0c; }

.focus\:text-red-darker:focus {
  color: #621b18; }

.focus\:text-red-dark:focus {
  color: #cc1f1a; }

.focus\:text-red:focus {
  color: #e3342f; }

.focus\:text-red-light:focus {
  color: #ef5753; }

.focus\:text-red-lighter:focus {
  color: #f9acaa; }

.focus\:text-red-lightest:focus {
  color: #fcebea; }

.focus\:text-orange-darkest:focus {
  color: #462a16; }

.focus\:text-orange-darker:focus {
  color: #613b1f; }

.focus\:text-orange-dark:focus {
  color: #de751f; }

.focus\:text-orange:focus {
  color: #f6993f; }

.focus\:text-orange-light:focus {
  color: #faad63; }

.focus\:text-orange-lighter:focus {
  color: #fcd9b6; }

.focus\:text-orange-lightest:focus {
  color: #fff5eb; }

.focus\:text-yellow-darkest:focus {
  color: #453411; }

.focus\:text-yellow-darker:focus {
  color: #684f1d; }

.focus\:text-yellow-dark:focus {
  color: #f2d024; }

.focus\:text-yellow:focus {
  color: #ffed4a; }

.focus\:text-yellow-light:focus {
  color: #fff382; }

.focus\:text-yellow-lighter:focus {
  color: #fff9c2; }

.focus\:text-yellow-lightest:focus {
  color: #fcfbeb; }

.focus\:text-green-darkest:focus {
  color: #0f2f21; }

.focus\:text-green-darker:focus {
  color: #1a4731; }

.focus\:text-green-dark:focus {
  color: #1f9d55; }

.focus\:text-green:focus {
  color: #38c172; }

.focus\:text-green-light:focus {
  color: #51d88a; }

.focus\:text-green-lighter:focus {
  color: #a2f5bf; }

.focus\:text-green-lightest:focus {
  color: #e3fcec; }

.focus\:text-teal-darkest:focus {
  color: #0d3331; }

.focus\:text-teal-darker:focus {
  color: #20504f; }

.focus\:text-teal-dark:focus {
  color: #38a89d; }

.focus\:text-teal:focus {
  color: #4dc0b5; }

.focus\:text-teal-light:focus {
  color: #64d5ca; }

.focus\:text-teal-lighter:focus {
  color: #a0f0ed; }

.focus\:text-teal-lightest:focus {
  color: #e8fffe; }

.focus\:text-blue-darkest:focus {
  color: #12283a; }

.focus\:text-blue-darker:focus {
  color: #1c3d5a; }

.focus\:text-blue-dark:focus {
  color: #2779bd; }

.focus\:text-blue:focus {
  color: #3490dc; }

.focus\:text-blue-light:focus {
  color: #6cb2eb; }

.focus\:text-blue-lighter:focus {
  color: #bcdefa; }

.focus\:text-blue-lightest:focus {
  color: #eff8ff; }

.focus\:text-indigo-darkest:focus {
  color: #191e38; }

.focus\:text-indigo-darker:focus {
  color: #2f365f; }

.focus\:text-indigo-dark:focus {
  color: #5661b3; }

.focus\:text-indigo:focus {
  color: #6574cd; }

.focus\:text-indigo-light:focus {
  color: #7886d7; }

.focus\:text-indigo-lighter:focus {
  color: #b2b7ff; }

.focus\:text-indigo-lightest:focus {
  color: #e6e8ff; }

.focus\:text-purple-darkest:focus {
  color: #21183c; }

.focus\:text-purple-darker:focus {
  color: #382b5f; }

.focus\:text-purple-dark:focus {
  color: #794acf; }

.focus\:text-purple:focus {
  color: #9561e2; }

.focus\:text-purple-light:focus {
  color: #a779e9; }

.focus\:text-purple-lighter:focus {
  color: #d6bbfc; }

.focus\:text-purple-lightest:focus {
  color: #f3ebff; }

.focus\:text-pink-darkest:focus {
  color: #451225; }

.focus\:text-pink-darker:focus {
  color: #6f213f; }

.focus\:text-pink-dark:focus {
  color: #eb5286; }

.focus\:text-pink:focus {
  color: #f66d9b; }

.focus\:text-pink-light:focus {
  color: #fa7ea8; }

.focus\:text-pink-lighter:focus {
  color: #ffbbca; }

.focus\:text-pink-lightest:focus {
  color: #ffebef; }

.text-xs {
  font-size: .75rem; }

.text-sm {
  font-size: .875rem; }

.text-base {
  font-size: 1rem; }

.text-lg {
  font-size: 1.125rem; }

.text-xl {
  font-size: 1.25rem; }

.text-2xl {
  font-size: 1.5rem; }

.text-3xl {
  font-size: 1.875rem; }

.text-4xl {
  font-size: 2.25rem; }

.text-5xl {
  font-size: 3rem; }

.italic {
  font-style: italic; }

.roman {
  font-style: normal; }

.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.normal-case {
  text-transform: none; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

.no-underline {
  text-decoration: none; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

.hover\:italic:hover {
  font-style: italic; }

.hover\:roman:hover {
  font-style: normal; }

.hover\:uppercase:hover {
  text-transform: uppercase; }

.hover\:lowercase:hover {
  text-transform: lowercase; }

.hover\:capitalize:hover {
  text-transform: capitalize; }

.hover\:normal-case:hover {
  text-transform: none; }

.hover\:underline:hover {
  text-decoration: underline; }

.hover\:line-through:hover {
  text-decoration: line-through; }

.hover\:no-underline:hover {
  text-decoration: none; }

.hover\:antialiased:hover {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.hover\:subpixel-antialiased:hover {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

.focus\:italic:focus {
  font-style: italic; }

.focus\:roman:focus {
  font-style: normal; }

.focus\:uppercase:focus {
  text-transform: uppercase; }

.focus\:lowercase:focus {
  text-transform: lowercase; }

.focus\:capitalize:focus {
  text-transform: capitalize; }

.focus\:normal-case:focus {
  text-transform: none; }

.focus\:underline:focus {
  text-decoration: underline; }

.focus\:line-through:focus {
  text-decoration: line-through; }

.focus\:no-underline:focus {
  text-decoration: none; }

.focus\:antialiased:focus {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.focus\:subpixel-antialiased:focus {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

.tracking-tight {
  letter-spacing: -0.05em; }

.tracking-normal {
  letter-spacing: 0; }

.tracking-wide {
  letter-spacing: .05em; }

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.select-text {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.align-baseline {
  vertical-align: baseline; }

.align-top {
  vertical-align: top; }

.align-middle {
  vertical-align: middle; }

.align-bottom {
  vertical-align: bottom; }

.align-text-top {
  vertical-align: text-top; }

.align-text-bottom {
  vertical-align: text-bottom; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.whitespace-normal {
  white-space: normal; }

.whitespace-no-wrap {
  white-space: nowrap; }

.whitespace-pre {
  white-space: pre; }

.whitespace-pre-line {
  white-space: pre-line; }

.whitespace-pre-wrap {
  white-space: pre-wrap; }

.break-words {
  word-wrap: break-word; }

.break-normal {
  word-wrap: normal; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.w-1 {
  width: .25rem; }

.w-2 {
  width: .5rem; }

.w-3 {
  width: .75rem; }

.w-4 {
  width: 1rem; }

.w-5 {
  width: 1.25rem; }

.w-6 {
  width: 1.5rem; }

.w-8 {
  width: 2rem; }

.w-10 {
  width: 2.5rem; }

.w-12 {
  width: 3rem; }

.w-16 {
  width: 4rem; }

.w-24 {
  width: 6rem; }

.w-32 {
  width: 8rem; }

.w-48 {
  width: 12rem; }

.w-64 {
  width: 16rem; }

.w-auto {
  width: auto; }

.w-px {
  width: 1px; }

.w-1\/2 {
  width: 50%; }

.w-1\/3 {
  width: 33.33333%; }

.w-2\/3 {
  width: 66.66667%; }

.w-1\/4 {
  width: 25%; }

.w-3\/4 {
  width: 75%; }

.w-1\/5 {
  width: 20%; }

.w-2\/5 {
  width: 40%; }

.w-3\/5 {
  width: 60%; }

.w-4\/5 {
  width: 80%; }

.w-1\/6 {
  width: 16.66667%; }

.w-5\/6 {
  width: 83.33333%; }

.w-full {
  width: 100%; }

.w-screen {
  width: 100vw; }

.z-0 {
  z-index: 0; }

.z-10 {
  z-index: 10; }

.z-20 {
  z-index: 20; }

.z-30 {
  z-index: 30; }

.z-40 {
  z-index: 40; }

.z-50 {
  z-index: 50; }

.z-auto {
  z-index: auto; }

@media (min-width: 576px) {
  .sm\:list-reset {
    list-style: none;
    padding: 0; }
  .sm\:appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .sm\:bg-fixed {
    background-attachment: fixed; }
  .sm\:bg-local {
    background-attachment: local; }
  .sm\:bg-scroll {
    background-attachment: scroll; }
  .sm\:bg-transparent {
    background-color: transparent; }
  .sm\:bg-black {
    background-color: #22292f; }
  .sm\:bg-grey-darkest {
    background-color: #3d4852; }
  .sm\:bg-grey-darker {
    background-color: #606f7b; }
  .sm\:bg-grey-dark {
    background-color: #8795a1; }
  .sm\:bg-grey {
    background-color: #b8c2cc; }
  .sm\:bg-grey-light {
    background-color: #dae1e7; }
  .sm\:bg-grey-lighter {
    background-color: #f1f5f8; }
  .sm\:bg-grey-lightest {
    background-color: #f8fafc; }
  .sm\:bg-white {
    background-color: #fff; }
  .sm\:bg-red-darkest {
    background-color: #3b0d0c; }
  .sm\:bg-red-darker {
    background-color: #621b18; }
  .sm\:bg-red-dark {
    background-color: #cc1f1a; }
  .sm\:bg-red {
    background-color: #e3342f; }
  .sm\:bg-red-light {
    background-color: #ef5753; }
  .sm\:bg-red-lighter {
    background-color: #f9acaa; }
  .sm\:bg-red-lightest {
    background-color: #fcebea; }
  .sm\:bg-orange-darkest {
    background-color: #462a16; }
  .sm\:bg-orange-darker {
    background-color: #613b1f; }
  .sm\:bg-orange-dark {
    background-color: #de751f; }
  .sm\:bg-orange {
    background-color: #f6993f; }
  .sm\:bg-orange-light {
    background-color: #faad63; }
  .sm\:bg-orange-lighter {
    background-color: #fcd9b6; }
  .sm\:bg-orange-lightest {
    background-color: #fff5eb; }
  .sm\:bg-yellow-darkest {
    background-color: #453411; }
  .sm\:bg-yellow-darker {
    background-color: #684f1d; }
  .sm\:bg-yellow-dark {
    background-color: #f2d024; }
  .sm\:bg-yellow {
    background-color: #ffed4a; }
  .sm\:bg-yellow-light {
    background-color: #fff382; }
  .sm\:bg-yellow-lighter {
    background-color: #fff9c2; }
  .sm\:bg-yellow-lightest {
    background-color: #fcfbeb; }
  .sm\:bg-green-darkest {
    background-color: #0f2f21; }
  .sm\:bg-green-darker {
    background-color: #1a4731; }
  .sm\:bg-green-dark {
    background-color: #1f9d55; }
  .sm\:bg-green {
    background-color: #38c172; }
  .sm\:bg-green-light {
    background-color: #51d88a; }
  .sm\:bg-green-lighter {
    background-color: #a2f5bf; }
  .sm\:bg-green-lightest {
    background-color: #e3fcec; }
  .sm\:bg-teal-darkest {
    background-color: #0d3331; }
  .sm\:bg-teal-darker {
    background-color: #20504f; }
  .sm\:bg-teal-dark {
    background-color: #38a89d; }
  .sm\:bg-teal {
    background-color: #4dc0b5; }
  .sm\:bg-teal-light {
    background-color: #64d5ca; }
  .sm\:bg-teal-lighter {
    background-color: #a0f0ed; }
  .sm\:bg-teal-lightest {
    background-color: #e8fffe; }
  .sm\:bg-blue-darkest {
    background-color: #12283a; }
  .sm\:bg-blue-darker {
    background-color: #1c3d5a; }
  .sm\:bg-blue-dark {
    background-color: #2779bd; }
  .sm\:bg-blue {
    background-color: #3490dc; }
  .sm\:bg-blue-light {
    background-color: #6cb2eb; }
  .sm\:bg-blue-lighter {
    background-color: #bcdefa; }
  .sm\:bg-blue-lightest {
    background-color: #eff8ff; }
  .sm\:bg-indigo-darkest {
    background-color: #191e38; }
  .sm\:bg-indigo-darker {
    background-color: #2f365f; }
  .sm\:bg-indigo-dark {
    background-color: #5661b3; }
  .sm\:bg-indigo {
    background-color: #6574cd; }
  .sm\:bg-indigo-light {
    background-color: #7886d7; }
  .sm\:bg-indigo-lighter {
    background-color: #b2b7ff; }
  .sm\:bg-indigo-lightest {
    background-color: #e6e8ff; }
  .sm\:bg-purple-darkest {
    background-color: #21183c; }
  .sm\:bg-purple-darker {
    background-color: #382b5f; }
  .sm\:bg-purple-dark {
    background-color: #794acf; }
  .sm\:bg-purple {
    background-color: #9561e2; }
  .sm\:bg-purple-light {
    background-color: #a779e9; }
  .sm\:bg-purple-lighter {
    background-color: #d6bbfc; }
  .sm\:bg-purple-lightest {
    background-color: #f3ebff; }
  .sm\:bg-pink-darkest {
    background-color: #451225; }
  .sm\:bg-pink-darker {
    background-color: #6f213f; }
  .sm\:bg-pink-dark {
    background-color: #eb5286; }
  .sm\:bg-pink {
    background-color: #f66d9b; }
  .sm\:bg-pink-light {
    background-color: #fa7ea8; }
  .sm\:bg-pink-lighter {
    background-color: #ffbbca; }
  .sm\:bg-pink-lightest {
    background-color: #ffebef; }
  .sm\:hover\:bg-transparent:hover {
    background-color: transparent; }
  .sm\:hover\:bg-black:hover {
    background-color: #22292f; }
  .sm\:hover\:bg-grey-darkest:hover {
    background-color: #3d4852; }
  .sm\:hover\:bg-grey-darker:hover {
    background-color: #606f7b; }
  .sm\:hover\:bg-grey-dark:hover {
    background-color: #8795a1; }
  .sm\:hover\:bg-grey:hover {
    background-color: #b8c2cc; }
  .sm\:hover\:bg-grey-light:hover {
    background-color: #dae1e7; }
  .sm\:hover\:bg-grey-lighter:hover {
    background-color: #f1f5f8; }
  .sm\:hover\:bg-grey-lightest:hover {
    background-color: #f8fafc; }
  .sm\:hover\:bg-white:hover {
    background-color: #fff; }
  .sm\:hover\:bg-red-darkest:hover {
    background-color: #3b0d0c; }
  .sm\:hover\:bg-red-darker:hover {
    background-color: #621b18; }
  .sm\:hover\:bg-red-dark:hover {
    background-color: #cc1f1a; }
  .sm\:hover\:bg-red:hover {
    background-color: #e3342f; }
  .sm\:hover\:bg-red-light:hover {
    background-color: #ef5753; }
  .sm\:hover\:bg-red-lighter:hover {
    background-color: #f9acaa; }
  .sm\:hover\:bg-red-lightest:hover {
    background-color: #fcebea; }
  .sm\:hover\:bg-orange-darkest:hover {
    background-color: #462a16; }
  .sm\:hover\:bg-orange-darker:hover {
    background-color: #613b1f; }
  .sm\:hover\:bg-orange-dark:hover {
    background-color: #de751f; }
  .sm\:hover\:bg-orange:hover {
    background-color: #f6993f; }
  .sm\:hover\:bg-orange-light:hover {
    background-color: #faad63; }
  .sm\:hover\:bg-orange-lighter:hover {
    background-color: #fcd9b6; }
  .sm\:hover\:bg-orange-lightest:hover {
    background-color: #fff5eb; }
  .sm\:hover\:bg-yellow-darkest:hover {
    background-color: #453411; }
  .sm\:hover\:bg-yellow-darker:hover {
    background-color: #684f1d; }
  .sm\:hover\:bg-yellow-dark:hover {
    background-color: #f2d024; }
  .sm\:hover\:bg-yellow:hover {
    background-color: #ffed4a; }
  .sm\:hover\:bg-yellow-light:hover {
    background-color: #fff382; }
  .sm\:hover\:bg-yellow-lighter:hover {
    background-color: #fff9c2; }
  .sm\:hover\:bg-yellow-lightest:hover {
    background-color: #fcfbeb; }
  .sm\:hover\:bg-green-darkest:hover {
    background-color: #0f2f21; }
  .sm\:hover\:bg-green-darker:hover {
    background-color: #1a4731; }
  .sm\:hover\:bg-green-dark:hover {
    background-color: #1f9d55; }
  .sm\:hover\:bg-green:hover {
    background-color: #38c172; }
  .sm\:hover\:bg-green-light:hover {
    background-color: #51d88a; }
  .sm\:hover\:bg-green-lighter:hover {
    background-color: #a2f5bf; }
  .sm\:hover\:bg-green-lightest:hover {
    background-color: #e3fcec; }
  .sm\:hover\:bg-teal-darkest:hover {
    background-color: #0d3331; }
  .sm\:hover\:bg-teal-darker:hover {
    background-color: #20504f; }
  .sm\:hover\:bg-teal-dark:hover {
    background-color: #38a89d; }
  .sm\:hover\:bg-teal:hover {
    background-color: #4dc0b5; }
  .sm\:hover\:bg-teal-light:hover {
    background-color: #64d5ca; }
  .sm\:hover\:bg-teal-lighter:hover {
    background-color: #a0f0ed; }
  .sm\:hover\:bg-teal-lightest:hover {
    background-color: #e8fffe; }
  .sm\:hover\:bg-blue-darkest:hover {
    background-color: #12283a; }
  .sm\:hover\:bg-blue-darker:hover {
    background-color: #1c3d5a; }
  .sm\:hover\:bg-blue-dark:hover {
    background-color: #2779bd; }
  .sm\:hover\:bg-blue:hover {
    background-color: #3490dc; }
  .sm\:hover\:bg-blue-light:hover {
    background-color: #6cb2eb; }
  .sm\:hover\:bg-blue-lighter:hover {
    background-color: #bcdefa; }
  .sm\:hover\:bg-blue-lightest:hover {
    background-color: #eff8ff; }
  .sm\:hover\:bg-indigo-darkest:hover {
    background-color: #191e38; }
  .sm\:hover\:bg-indigo-darker:hover {
    background-color: #2f365f; }
  .sm\:hover\:bg-indigo-dark:hover {
    background-color: #5661b3; }
  .sm\:hover\:bg-indigo:hover {
    background-color: #6574cd; }
  .sm\:hover\:bg-indigo-light:hover {
    background-color: #7886d7; }
  .sm\:hover\:bg-indigo-lighter:hover {
    background-color: #b2b7ff; }
  .sm\:hover\:bg-indigo-lightest:hover {
    background-color: #e6e8ff; }
  .sm\:hover\:bg-purple-darkest:hover {
    background-color: #21183c; }
  .sm\:hover\:bg-purple-darker:hover {
    background-color: #382b5f; }
  .sm\:hover\:bg-purple-dark:hover {
    background-color: #794acf; }
  .sm\:hover\:bg-purple:hover {
    background-color: #9561e2; }
  .sm\:hover\:bg-purple-light:hover {
    background-color: #a779e9; }
  .sm\:hover\:bg-purple-lighter:hover {
    background-color: #d6bbfc; }
  .sm\:hover\:bg-purple-lightest:hover {
    background-color: #f3ebff; }
  .sm\:hover\:bg-pink-darkest:hover {
    background-color: #451225; }
  .sm\:hover\:bg-pink-darker:hover {
    background-color: #6f213f; }
  .sm\:hover\:bg-pink-dark:hover {
    background-color: #eb5286; }
  .sm\:hover\:bg-pink:hover {
    background-color: #f66d9b; }
  .sm\:hover\:bg-pink-light:hover {
    background-color: #fa7ea8; }
  .sm\:hover\:bg-pink-lighter:hover {
    background-color: #ffbbca; }
  .sm\:hover\:bg-pink-lightest:hover {
    background-color: #ffebef; }
  .sm\:focus\:bg-transparent:focus {
    background-color: transparent; }
  .sm\:focus\:bg-black:focus {
    background-color: #22292f; }
  .sm\:focus\:bg-grey-darkest:focus {
    background-color: #3d4852; }
  .sm\:focus\:bg-grey-darker:focus {
    background-color: #606f7b; }
  .sm\:focus\:bg-grey-dark:focus {
    background-color: #8795a1; }
  .sm\:focus\:bg-grey:focus {
    background-color: #b8c2cc; }
  .sm\:focus\:bg-grey-light:focus {
    background-color: #dae1e7; }
  .sm\:focus\:bg-grey-lighter:focus {
    background-color: #f1f5f8; }
  .sm\:focus\:bg-grey-lightest:focus {
    background-color: #f8fafc; }
  .sm\:focus\:bg-white:focus {
    background-color: #fff; }
  .sm\:focus\:bg-red-darkest:focus {
    background-color: #3b0d0c; }
  .sm\:focus\:bg-red-darker:focus {
    background-color: #621b18; }
  .sm\:focus\:bg-red-dark:focus {
    background-color: #cc1f1a; }
  .sm\:focus\:bg-red:focus {
    background-color: #e3342f; }
  .sm\:focus\:bg-red-light:focus {
    background-color: #ef5753; }
  .sm\:focus\:bg-red-lighter:focus {
    background-color: #f9acaa; }
  .sm\:focus\:bg-red-lightest:focus {
    background-color: #fcebea; }
  .sm\:focus\:bg-orange-darkest:focus {
    background-color: #462a16; }
  .sm\:focus\:bg-orange-darker:focus {
    background-color: #613b1f; }
  .sm\:focus\:bg-orange-dark:focus {
    background-color: #de751f; }
  .sm\:focus\:bg-orange:focus {
    background-color: #f6993f; }
  .sm\:focus\:bg-orange-light:focus {
    background-color: #faad63; }
  .sm\:focus\:bg-orange-lighter:focus {
    background-color: #fcd9b6; }
  .sm\:focus\:bg-orange-lightest:focus {
    background-color: #fff5eb; }
  .sm\:focus\:bg-yellow-darkest:focus {
    background-color: #453411; }
  .sm\:focus\:bg-yellow-darker:focus {
    background-color: #684f1d; }
  .sm\:focus\:bg-yellow-dark:focus {
    background-color: #f2d024; }
  .sm\:focus\:bg-yellow:focus {
    background-color: #ffed4a; }
  .sm\:focus\:bg-yellow-light:focus {
    background-color: #fff382; }
  .sm\:focus\:bg-yellow-lighter:focus {
    background-color: #fff9c2; }
  .sm\:focus\:bg-yellow-lightest:focus {
    background-color: #fcfbeb; }
  .sm\:focus\:bg-green-darkest:focus {
    background-color: #0f2f21; }
  .sm\:focus\:bg-green-darker:focus {
    background-color: #1a4731; }
  .sm\:focus\:bg-green-dark:focus {
    background-color: #1f9d55; }
  .sm\:focus\:bg-green:focus {
    background-color: #38c172; }
  .sm\:focus\:bg-green-light:focus {
    background-color: #51d88a; }
  .sm\:focus\:bg-green-lighter:focus {
    background-color: #a2f5bf; }
  .sm\:focus\:bg-green-lightest:focus {
    background-color: #e3fcec; }
  .sm\:focus\:bg-teal-darkest:focus {
    background-color: #0d3331; }
  .sm\:focus\:bg-teal-darker:focus {
    background-color: #20504f; }
  .sm\:focus\:bg-teal-dark:focus {
    background-color: #38a89d; }
  .sm\:focus\:bg-teal:focus {
    background-color: #4dc0b5; }
  .sm\:focus\:bg-teal-light:focus {
    background-color: #64d5ca; }
  .sm\:focus\:bg-teal-lighter:focus {
    background-color: #a0f0ed; }
  .sm\:focus\:bg-teal-lightest:focus {
    background-color: #e8fffe; }
  .sm\:focus\:bg-blue-darkest:focus {
    background-color: #12283a; }
  .sm\:focus\:bg-blue-darker:focus {
    background-color: #1c3d5a; }
  .sm\:focus\:bg-blue-dark:focus {
    background-color: #2779bd; }
  .sm\:focus\:bg-blue:focus {
    background-color: #3490dc; }
  .sm\:focus\:bg-blue-light:focus {
    background-color: #6cb2eb; }
  .sm\:focus\:bg-blue-lighter:focus {
    background-color: #bcdefa; }
  .sm\:focus\:bg-blue-lightest:focus {
    background-color: #eff8ff; }
  .sm\:focus\:bg-indigo-darkest:focus {
    background-color: #191e38; }
  .sm\:focus\:bg-indigo-darker:focus {
    background-color: #2f365f; }
  .sm\:focus\:bg-indigo-dark:focus {
    background-color: #5661b3; }
  .sm\:focus\:bg-indigo:focus {
    background-color: #6574cd; }
  .sm\:focus\:bg-indigo-light:focus {
    background-color: #7886d7; }
  .sm\:focus\:bg-indigo-lighter:focus {
    background-color: #b2b7ff; }
  .sm\:focus\:bg-indigo-lightest:focus {
    background-color: #e6e8ff; }
  .sm\:focus\:bg-purple-darkest:focus {
    background-color: #21183c; }
  .sm\:focus\:bg-purple-darker:focus {
    background-color: #382b5f; }
  .sm\:focus\:bg-purple-dark:focus {
    background-color: #794acf; }
  .sm\:focus\:bg-purple:focus {
    background-color: #9561e2; }
  .sm\:focus\:bg-purple-light:focus {
    background-color: #a779e9; }
  .sm\:focus\:bg-purple-lighter:focus {
    background-color: #d6bbfc; }
  .sm\:focus\:bg-purple-lightest:focus {
    background-color: #f3ebff; }
  .sm\:focus\:bg-pink-darkest:focus {
    background-color: #451225; }
  .sm\:focus\:bg-pink-darker:focus {
    background-color: #6f213f; }
  .sm\:focus\:bg-pink-dark:focus {
    background-color: #eb5286; }
  .sm\:focus\:bg-pink:focus {
    background-color: #f66d9b; }
  .sm\:focus\:bg-pink-light:focus {
    background-color: #fa7ea8; }
  .sm\:focus\:bg-pink-lighter:focus {
    background-color: #ffbbca; }
  .sm\:focus\:bg-pink-lightest:focus {
    background-color: #ffebef; }
  .sm\:bg-bottom {
    background-position: bottom; }
  .sm\:bg-center {
    background-position: center; }
  .sm\:bg-left {
    background-position: left; }
  .sm\:bg-left-bottom {
    background-position: left bottom; }
  .sm\:bg-left-top {
    background-position: left top; }
  .sm\:bg-right {
    background-position: right; }
  .sm\:bg-right-bottom {
    background-position: right bottom; }
  .sm\:bg-right-top {
    background-position: right top; }
  .sm\:bg-top {
    background-position: top; }
  .sm\:bg-repeat {
    background-repeat: repeat; }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat; }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x; }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y; }
  .sm\:bg-auto {
    background-size: auto; }
  .sm\:bg-cover {
    background-size: cover; }
  .sm\:bg-contain {
    background-size: contain; }
  .sm\:border-transparent {
    border-color: transparent; }
  .sm\:border-black {
    border-color: #22292f; }
  .sm\:border-grey-darkest {
    border-color: #3d4852; }
  .sm\:border-grey-darker {
    border-color: #606f7b; }
  .sm\:border-grey-dark {
    border-color: #8795a1; }
  .sm\:border-grey {
    border-color: #b8c2cc; }
  .sm\:border-grey-light {
    border-color: #dae1e7; }
  .sm\:border-grey-lighter {
    border-color: #f1f5f8; }
  .sm\:border-grey-lightest {
    border-color: #f8fafc; }
  .sm\:border-white {
    border-color: #fff; }
  .sm\:border-red-darkest {
    border-color: #3b0d0c; }
  .sm\:border-red-darker {
    border-color: #621b18; }
  .sm\:border-red-dark {
    border-color: #cc1f1a; }
  .sm\:border-red {
    border-color: #e3342f; }
  .sm\:border-red-light {
    border-color: #ef5753; }
  .sm\:border-red-lighter {
    border-color: #f9acaa; }
  .sm\:border-red-lightest {
    border-color: #fcebea; }
  .sm\:border-orange-darkest {
    border-color: #462a16; }
  .sm\:border-orange-darker {
    border-color: #613b1f; }
  .sm\:border-orange-dark {
    border-color: #de751f; }
  .sm\:border-orange {
    border-color: #f6993f; }
  .sm\:border-orange-light {
    border-color: #faad63; }
  .sm\:border-orange-lighter {
    border-color: #fcd9b6; }
  .sm\:border-orange-lightest {
    border-color: #fff5eb; }
  .sm\:border-yellow-darkest {
    border-color: #453411; }
  .sm\:border-yellow-darker {
    border-color: #684f1d; }
  .sm\:border-yellow-dark {
    border-color: #f2d024; }
  .sm\:border-yellow {
    border-color: #ffed4a; }
  .sm\:border-yellow-light {
    border-color: #fff382; }
  .sm\:border-yellow-lighter {
    border-color: #fff9c2; }
  .sm\:border-yellow-lightest {
    border-color: #fcfbeb; }
  .sm\:border-green-darkest {
    border-color: #0f2f21; }
  .sm\:border-green-darker {
    border-color: #1a4731; }
  .sm\:border-green-dark {
    border-color: #1f9d55; }
  .sm\:border-green {
    border-color: #38c172; }
  .sm\:border-green-light {
    border-color: #51d88a; }
  .sm\:border-green-lighter {
    border-color: #a2f5bf; }
  .sm\:border-green-lightest {
    border-color: #e3fcec; }
  .sm\:border-teal-darkest {
    border-color: #0d3331; }
  .sm\:border-teal-darker {
    border-color: #20504f; }
  .sm\:border-teal-dark {
    border-color: #38a89d; }
  .sm\:border-teal {
    border-color: #4dc0b5; }
  .sm\:border-teal-light {
    border-color: #64d5ca; }
  .sm\:border-teal-lighter {
    border-color: #a0f0ed; }
  .sm\:border-teal-lightest {
    border-color: #e8fffe; }
  .sm\:border-blue-darkest {
    border-color: #12283a; }
  .sm\:border-blue-darker {
    border-color: #1c3d5a; }
  .sm\:border-blue-dark {
    border-color: #2779bd; }
  .sm\:border-blue {
    border-color: #3490dc; }
  .sm\:border-blue-light {
    border-color: #6cb2eb; }
  .sm\:border-blue-lighter {
    border-color: #bcdefa; }
  .sm\:border-blue-lightest {
    border-color: #eff8ff; }
  .sm\:border-indigo-darkest {
    border-color: #191e38; }
  .sm\:border-indigo-darker {
    border-color: #2f365f; }
  .sm\:border-indigo-dark {
    border-color: #5661b3; }
  .sm\:border-indigo {
    border-color: #6574cd; }
  .sm\:border-indigo-light {
    border-color: #7886d7; }
  .sm\:border-indigo-lighter {
    border-color: #b2b7ff; }
  .sm\:border-indigo-lightest {
    border-color: #e6e8ff; }
  .sm\:border-purple-darkest {
    border-color: #21183c; }
  .sm\:border-purple-darker {
    border-color: #382b5f; }
  .sm\:border-purple-dark {
    border-color: #794acf; }
  .sm\:border-purple {
    border-color: #9561e2; }
  .sm\:border-purple-light {
    border-color: #a779e9; }
  .sm\:border-purple-lighter {
    border-color: #d6bbfc; }
  .sm\:border-purple-lightest {
    border-color: #f3ebff; }
  .sm\:border-pink-darkest {
    border-color: #451225; }
  .sm\:border-pink-darker {
    border-color: #6f213f; }
  .sm\:border-pink-dark {
    border-color: #eb5286; }
  .sm\:border-pink {
    border-color: #f66d9b; }
  .sm\:border-pink-light {
    border-color: #fa7ea8; }
  .sm\:border-pink-lighter {
    border-color: #ffbbca; }
  .sm\:border-pink-lightest {
    border-color: #ffebef; }
  .sm\:hover\:border-transparent:hover {
    border-color: transparent; }
  .sm\:hover\:border-black:hover {
    border-color: #22292f; }
  .sm\:hover\:border-grey-darkest:hover {
    border-color: #3d4852; }
  .sm\:hover\:border-grey-darker:hover {
    border-color: #606f7b; }
  .sm\:hover\:border-grey-dark:hover {
    border-color: #8795a1; }
  .sm\:hover\:border-grey:hover {
    border-color: #b8c2cc; }
  .sm\:hover\:border-grey-light:hover {
    border-color: #dae1e7; }
  .sm\:hover\:border-grey-lighter:hover {
    border-color: #f1f5f8; }
  .sm\:hover\:border-grey-lightest:hover {
    border-color: #f8fafc; }
  .sm\:hover\:border-white:hover {
    border-color: #fff; }
  .sm\:hover\:border-red-darkest:hover {
    border-color: #3b0d0c; }
  .sm\:hover\:border-red-darker:hover {
    border-color: #621b18; }
  .sm\:hover\:border-red-dark:hover {
    border-color: #cc1f1a; }
  .sm\:hover\:border-red:hover {
    border-color: #e3342f; }
  .sm\:hover\:border-red-light:hover {
    border-color: #ef5753; }
  .sm\:hover\:border-red-lighter:hover {
    border-color: #f9acaa; }
  .sm\:hover\:border-red-lightest:hover {
    border-color: #fcebea; }
  .sm\:hover\:border-orange-darkest:hover {
    border-color: #462a16; }
  .sm\:hover\:border-orange-darker:hover {
    border-color: #613b1f; }
  .sm\:hover\:border-orange-dark:hover {
    border-color: #de751f; }
  .sm\:hover\:border-orange:hover {
    border-color: #f6993f; }
  .sm\:hover\:border-orange-light:hover {
    border-color: #faad63; }
  .sm\:hover\:border-orange-lighter:hover {
    border-color: #fcd9b6; }
  .sm\:hover\:border-orange-lightest:hover {
    border-color: #fff5eb; }
  .sm\:hover\:border-yellow-darkest:hover {
    border-color: #453411; }
  .sm\:hover\:border-yellow-darker:hover {
    border-color: #684f1d; }
  .sm\:hover\:border-yellow-dark:hover {
    border-color: #f2d024; }
  .sm\:hover\:border-yellow:hover {
    border-color: #ffed4a; }
  .sm\:hover\:border-yellow-light:hover {
    border-color: #fff382; }
  .sm\:hover\:border-yellow-lighter:hover {
    border-color: #fff9c2; }
  .sm\:hover\:border-yellow-lightest:hover {
    border-color: #fcfbeb; }
  .sm\:hover\:border-green-darkest:hover {
    border-color: #0f2f21; }
  .sm\:hover\:border-green-darker:hover {
    border-color: #1a4731; }
  .sm\:hover\:border-green-dark:hover {
    border-color: #1f9d55; }
  .sm\:hover\:border-green:hover {
    border-color: #38c172; }
  .sm\:hover\:border-green-light:hover {
    border-color: #51d88a; }
  .sm\:hover\:border-green-lighter:hover {
    border-color: #a2f5bf; }
  .sm\:hover\:border-green-lightest:hover {
    border-color: #e3fcec; }
  .sm\:hover\:border-teal-darkest:hover {
    border-color: #0d3331; }
  .sm\:hover\:border-teal-darker:hover {
    border-color: #20504f; }
  .sm\:hover\:border-teal-dark:hover {
    border-color: #38a89d; }
  .sm\:hover\:border-teal:hover {
    border-color: #4dc0b5; }
  .sm\:hover\:border-teal-light:hover {
    border-color: #64d5ca; }
  .sm\:hover\:border-teal-lighter:hover {
    border-color: #a0f0ed; }
  .sm\:hover\:border-teal-lightest:hover {
    border-color: #e8fffe; }
  .sm\:hover\:border-blue-darkest:hover {
    border-color: #12283a; }
  .sm\:hover\:border-blue-darker:hover {
    border-color: #1c3d5a; }
  .sm\:hover\:border-blue-dark:hover {
    border-color: #2779bd; }
  .sm\:hover\:border-blue:hover {
    border-color: #3490dc; }
  .sm\:hover\:border-blue-light:hover {
    border-color: #6cb2eb; }
  .sm\:hover\:border-blue-lighter:hover {
    border-color: #bcdefa; }
  .sm\:hover\:border-blue-lightest:hover {
    border-color: #eff8ff; }
  .sm\:hover\:border-indigo-darkest:hover {
    border-color: #191e38; }
  .sm\:hover\:border-indigo-darker:hover {
    border-color: #2f365f; }
  .sm\:hover\:border-indigo-dark:hover {
    border-color: #5661b3; }
  .sm\:hover\:border-indigo:hover {
    border-color: #6574cd; }
  .sm\:hover\:border-indigo-light:hover {
    border-color: #7886d7; }
  .sm\:hover\:border-indigo-lighter:hover {
    border-color: #b2b7ff; }
  .sm\:hover\:border-indigo-lightest:hover {
    border-color: #e6e8ff; }
  .sm\:hover\:border-purple-darkest:hover {
    border-color: #21183c; }
  .sm\:hover\:border-purple-darker:hover {
    border-color: #382b5f; }
  .sm\:hover\:border-purple-dark:hover {
    border-color: #794acf; }
  .sm\:hover\:border-purple:hover {
    border-color: #9561e2; }
  .sm\:hover\:border-purple-light:hover {
    border-color: #a779e9; }
  .sm\:hover\:border-purple-lighter:hover {
    border-color: #d6bbfc; }
  .sm\:hover\:border-purple-lightest:hover {
    border-color: #f3ebff; }
  .sm\:hover\:border-pink-darkest:hover {
    border-color: #451225; }
  .sm\:hover\:border-pink-darker:hover {
    border-color: #6f213f; }
  .sm\:hover\:border-pink-dark:hover {
    border-color: #eb5286; }
  .sm\:hover\:border-pink:hover {
    border-color: #f66d9b; }
  .sm\:hover\:border-pink-light:hover {
    border-color: #fa7ea8; }
  .sm\:hover\:border-pink-lighter:hover {
    border-color: #ffbbca; }
  .sm\:hover\:border-pink-lightest:hover {
    border-color: #ffebef; }
  .sm\:focus\:border-transparent:focus {
    border-color: transparent; }
  .sm\:focus\:border-black:focus {
    border-color: #22292f; }
  .sm\:focus\:border-grey-darkest:focus {
    border-color: #3d4852; }
  .sm\:focus\:border-grey-darker:focus {
    border-color: #606f7b; }
  .sm\:focus\:border-grey-dark:focus {
    border-color: #8795a1; }
  .sm\:focus\:border-grey:focus {
    border-color: #b8c2cc; }
  .sm\:focus\:border-grey-light:focus {
    border-color: #dae1e7; }
  .sm\:focus\:border-grey-lighter:focus {
    border-color: #f1f5f8; }
  .sm\:focus\:border-grey-lightest:focus {
    border-color: #f8fafc; }
  .sm\:focus\:border-white:focus {
    border-color: #fff; }
  .sm\:focus\:border-red-darkest:focus {
    border-color: #3b0d0c; }
  .sm\:focus\:border-red-darker:focus {
    border-color: #621b18; }
  .sm\:focus\:border-red-dark:focus {
    border-color: #cc1f1a; }
  .sm\:focus\:border-red:focus {
    border-color: #e3342f; }
  .sm\:focus\:border-red-light:focus {
    border-color: #ef5753; }
  .sm\:focus\:border-red-lighter:focus {
    border-color: #f9acaa; }
  .sm\:focus\:border-red-lightest:focus {
    border-color: #fcebea; }
  .sm\:focus\:border-orange-darkest:focus {
    border-color: #462a16; }
  .sm\:focus\:border-orange-darker:focus {
    border-color: #613b1f; }
  .sm\:focus\:border-orange-dark:focus {
    border-color: #de751f; }
  .sm\:focus\:border-orange:focus {
    border-color: #f6993f; }
  .sm\:focus\:border-orange-light:focus {
    border-color: #faad63; }
  .sm\:focus\:border-orange-lighter:focus {
    border-color: #fcd9b6; }
  .sm\:focus\:border-orange-lightest:focus {
    border-color: #fff5eb; }
  .sm\:focus\:border-yellow-darkest:focus {
    border-color: #453411; }
  .sm\:focus\:border-yellow-darker:focus {
    border-color: #684f1d; }
  .sm\:focus\:border-yellow-dark:focus {
    border-color: #f2d024; }
  .sm\:focus\:border-yellow:focus {
    border-color: #ffed4a; }
  .sm\:focus\:border-yellow-light:focus {
    border-color: #fff382; }
  .sm\:focus\:border-yellow-lighter:focus {
    border-color: #fff9c2; }
  .sm\:focus\:border-yellow-lightest:focus {
    border-color: #fcfbeb; }
  .sm\:focus\:border-green-darkest:focus {
    border-color: #0f2f21; }
  .sm\:focus\:border-green-darker:focus {
    border-color: #1a4731; }
  .sm\:focus\:border-green-dark:focus {
    border-color: #1f9d55; }
  .sm\:focus\:border-green:focus {
    border-color: #38c172; }
  .sm\:focus\:border-green-light:focus {
    border-color: #51d88a; }
  .sm\:focus\:border-green-lighter:focus {
    border-color: #a2f5bf; }
  .sm\:focus\:border-green-lightest:focus {
    border-color: #e3fcec; }
  .sm\:focus\:border-teal-darkest:focus {
    border-color: #0d3331; }
  .sm\:focus\:border-teal-darker:focus {
    border-color: #20504f; }
  .sm\:focus\:border-teal-dark:focus {
    border-color: #38a89d; }
  .sm\:focus\:border-teal:focus {
    border-color: #4dc0b5; }
  .sm\:focus\:border-teal-light:focus {
    border-color: #64d5ca; }
  .sm\:focus\:border-teal-lighter:focus {
    border-color: #a0f0ed; }
  .sm\:focus\:border-teal-lightest:focus {
    border-color: #e8fffe; }
  .sm\:focus\:border-blue-darkest:focus {
    border-color: #12283a; }
  .sm\:focus\:border-blue-darker:focus {
    border-color: #1c3d5a; }
  .sm\:focus\:border-blue-dark:focus {
    border-color: #2779bd; }
  .sm\:focus\:border-blue:focus {
    border-color: #3490dc; }
  .sm\:focus\:border-blue-light:focus {
    border-color: #6cb2eb; }
  .sm\:focus\:border-blue-lighter:focus {
    border-color: #bcdefa; }
  .sm\:focus\:border-blue-lightest:focus {
    border-color: #eff8ff; }
  .sm\:focus\:border-indigo-darkest:focus {
    border-color: #191e38; }
  .sm\:focus\:border-indigo-darker:focus {
    border-color: #2f365f; }
  .sm\:focus\:border-indigo-dark:focus {
    border-color: #5661b3; }
  .sm\:focus\:border-indigo:focus {
    border-color: #6574cd; }
  .sm\:focus\:border-indigo-light:focus {
    border-color: #7886d7; }
  .sm\:focus\:border-indigo-lighter:focus {
    border-color: #b2b7ff; }
  .sm\:focus\:border-indigo-lightest:focus {
    border-color: #e6e8ff; }
  .sm\:focus\:border-purple-darkest:focus {
    border-color: #21183c; }
  .sm\:focus\:border-purple-darker:focus {
    border-color: #382b5f; }
  .sm\:focus\:border-purple-dark:focus {
    border-color: #794acf; }
  .sm\:focus\:border-purple:focus {
    border-color: #9561e2; }
  .sm\:focus\:border-purple-light:focus {
    border-color: #a779e9; }
  .sm\:focus\:border-purple-lighter:focus {
    border-color: #d6bbfc; }
  .sm\:focus\:border-purple-lightest:focus {
    border-color: #f3ebff; }
  .sm\:focus\:border-pink-darkest:focus {
    border-color: #451225; }
  .sm\:focus\:border-pink-darker:focus {
    border-color: #6f213f; }
  .sm\:focus\:border-pink-dark:focus {
    border-color: #eb5286; }
  .sm\:focus\:border-pink:focus {
    border-color: #f66d9b; }
  .sm\:focus\:border-pink-light:focus {
    border-color: #fa7ea8; }
  .sm\:focus\:border-pink-lighter:focus {
    border-color: #ffbbca; }
  .sm\:focus\:border-pink-lightest:focus {
    border-color: #ffebef; }
  .sm\:rounded-none {
    border-radius: 0; }
  .sm\:rounded-sm {
    border-radius: .125rem; }
  .sm\:rounded {
    border-radius: .25rem; }
  .sm\:rounded-lg {
    border-radius: .5rem; }
  .sm\:rounded-full {
    border-radius: 9999px; }
  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .sm\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem; }
  .sm\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem; }
  .sm\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .sm\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .sm\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .sm\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem; }
  .sm\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .sm\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .sm\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }
  .sm\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem; }
  .sm\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .sm\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px; }
  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px; }
  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .sm\:rounded-tl-none {
    border-top-left-radius: 0; }
  .sm\:rounded-tr-none {
    border-top-right-radius: 0; }
  .sm\:rounded-br-none {
    border-bottom-right-radius: 0; }
  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0; }
  .sm\:rounded-tl-sm {
    border-top-left-radius: .125rem; }
  .sm\:rounded-tr-sm {
    border-top-right-radius: .125rem; }
  .sm\:rounded-br-sm {
    border-bottom-right-radius: .125rem; }
  .sm\:rounded-bl-sm {
    border-bottom-left-radius: .125rem; }
  .sm\:rounded-tl {
    border-top-left-radius: .25rem; }
  .sm\:rounded-tr {
    border-top-right-radius: .25rem; }
  .sm\:rounded-br {
    border-bottom-right-radius: .25rem; }
  .sm\:rounded-bl {
    border-bottom-left-radius: .25rem; }
  .sm\:rounded-tl-lg {
    border-top-left-radius: .5rem; }
  .sm\:rounded-tr-lg {
    border-top-right-radius: .5rem; }
  .sm\:rounded-br-lg {
    border-bottom-right-radius: .5rem; }
  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .5rem; }
  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px; }
  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px; }
  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px; }
  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px; }
  .sm\:border-solid {
    border-style: solid; }
  .sm\:border-dashed {
    border-style: dashed; }
  .sm\:border-dotted {
    border-style: dotted; }
  .sm\:border-none {
    border-style: none; }
  .sm\:border-0 {
    border-width: 0; }
  .sm\:border-2 {
    border-width: 2px; }
  .sm\:border-4 {
    border-width: 4px; }
  .sm\:border-8 {
    border-width: 8px; }
  .sm\:border {
    border-width: 1px; }
  .sm\:border-t-0 {
    border-top-width: 0; }
  .sm\:border-r-0 {
    border-right-width: 0; }
  .sm\:border-b-0 {
    border-bottom-width: 0; }
  .sm\:border-l-0 {
    border-left-width: 0; }
  .sm\:border-t-2 {
    border-top-width: 2px; }
  .sm\:border-r-2 {
    border-right-width: 2px; }
  .sm\:border-b-2 {
    border-bottom-width: 2px; }
  .sm\:border-l-2 {
    border-left-width: 2px; }
  .sm\:border-t-4 {
    border-top-width: 4px; }
  .sm\:border-r-4 {
    border-right-width: 4px; }
  .sm\:border-b-4 {
    border-bottom-width: 4px; }
  .sm\:border-l-4 {
    border-left-width: 4px; }
  .sm\:border-t-8 {
    border-top-width: 8px; }
  .sm\:border-r-8 {
    border-right-width: 8px; }
  .sm\:border-b-8 {
    border-bottom-width: 8px; }
  .sm\:border-l-8 {
    border-left-width: 8px; }
  .sm\:border-t {
    border-top-width: 1px; }
  .sm\:border-r {
    border-right-width: 1px; }
  .sm\:border-b {
    border-bottom-width: 1px; }
  .sm\:border-l {
    border-left-width: 1px; }
  .sm\:cursor-auto {
    cursor: auto; }
  .sm\:cursor-default {
    cursor: default; }
  .sm\:cursor-pointer {
    cursor: pointer; }
  .sm\:cursor-wait {
    cursor: wait; }
  .sm\:cursor-move {
    cursor: move; }
  .sm\:cursor-not-allowed {
    cursor: not-allowed; }
  .sm\:block {
    display: block; }
  .sm\:inline-block {
    display: inline-block; }
  .sm\:inline {
    display: inline; }
  .sm\:table {
    display: table; }
  .sm\:table-row {
    display: table-row; }
  .sm\:table-cell {
    display: table-cell; }
  .sm\:hidden {
    display: none; }
  .sm\:flex {
    display: flex; }
  .sm\:inline-flex {
    display: inline-flex; }
  .sm\:flex-row {
    flex-direction: row; }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse; }
  .sm\:flex-col {
    flex-direction: column; }
  .sm\:flex-col-reverse {
    flex-direction: column-reverse; }
  .sm\:flex-wrap {
    flex-wrap: wrap; }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse; }
  .sm\:flex-no-wrap {
    flex-wrap: nowrap; }
  .sm\:items-start {
    align-items: flex-start; }
  .sm\:items-end {
    align-items: flex-end; }
  .sm\:items-center {
    align-items: center; }
  .sm\:items-baseline {
    align-items: baseline; }
  .sm\:items-stretch {
    align-items: stretch; }
  .sm\:self-auto {
    align-self: auto; }
  .sm\:self-start {
    align-self: flex-start; }
  .sm\:self-end {
    align-self: flex-end; }
  .sm\:self-center {
    align-self: center; }
  .sm\:self-stretch {
    align-self: stretch; }
  .sm\:justify-start {
    justify-content: flex-start; }
  .sm\:justify-end {
    justify-content: flex-end; }
  .sm\:justify-center {
    justify-content: center; }
  .sm\:justify-between {
    justify-content: space-between; }
  .sm\:justify-around {
    justify-content: space-around; }
  .sm\:content-center {
    align-content: center; }
  .sm\:content-start {
    align-content: flex-start; }
  .sm\:content-end {
    align-content: flex-end; }
  .sm\:content-between {
    align-content: space-between; }
  .sm\:content-around {
    align-content: space-around; }
  .sm\:flex-1 {
    flex: 1 1 0%; }
  .sm\:flex-auto {
    flex: 1 1 auto; }
  .sm\:flex-initial {
    flex: 0 1 auto; }
  .sm\:flex-none {
    flex: none; }
  .sm\:flex-grow {
    flex-grow: 1; }
  .sm\:flex-shrink {
    flex-shrink: 1; }
  .sm\:flex-no-grow {
    flex-grow: 0; }
  .sm\:flex-no-shrink {
    flex-shrink: 0; }
  .sm\:float-right {
    float: right; }
  .sm\:float-left {
    float: left; }
  .sm\:float-none {
    float: none; }
  .sm\:clearfix:after {
    content: "";
    display: table;
    clear: both; }
  .sm\:font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
  .sm\:font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif; }
  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }
  .sm\:font-hairline {
    font-weight: 100; }
  .sm\:font-thin {
    font-weight: 200; }
  .sm\:font-light {
    font-weight: 300; }
  .sm\:font-normal {
    font-weight: 400; }
  .sm\:font-medium {
    font-weight: 500; }
  .sm\:font-semibold {
    font-weight: 600; }
  .sm\:font-bold {
    font-weight: 700; }
  .sm\:font-extrabold {
    font-weight: 800; }
  .sm\:font-black {
    font-weight: 900; }
  .sm\:hover\:font-hairline:hover {
    font-weight: 100; }
  .sm\:hover\:font-thin:hover {
    font-weight: 200; }
  .sm\:hover\:font-light:hover {
    font-weight: 300; }
  .sm\:hover\:font-normal:hover {
    font-weight: 400; }
  .sm\:hover\:font-medium:hover {
    font-weight: 500; }
  .sm\:hover\:font-semibold:hover {
    font-weight: 600; }
  .sm\:hover\:font-bold:hover {
    font-weight: 700; }
  .sm\:hover\:font-extrabold:hover {
    font-weight: 800; }
  .sm\:hover\:font-black:hover {
    font-weight: 900; }
  .sm\:focus\:font-hairline:focus {
    font-weight: 100; }
  .sm\:focus\:font-thin:focus {
    font-weight: 200; }
  .sm\:focus\:font-light:focus {
    font-weight: 300; }
  .sm\:focus\:font-normal:focus {
    font-weight: 400; }
  .sm\:focus\:font-medium:focus {
    font-weight: 500; }
  .sm\:focus\:font-semibold:focus {
    font-weight: 600; }
  .sm\:focus\:font-bold:focus {
    font-weight: 700; }
  .sm\:focus\:font-extrabold:focus {
    font-weight: 800; }
  .sm\:focus\:font-black:focus {
    font-weight: 900; }
  .sm\:h-1 {
    height: .25rem; }
  .sm\:h-2 {
    height: .5rem; }
  .sm\:h-3 {
    height: .75rem; }
  .sm\:h-4 {
    height: 1rem; }
  .sm\:h-5 {
    height: 1.25rem; }
  .sm\:h-6 {
    height: 1.5rem; }
  .sm\:h-8 {
    height: 2rem; }
  .sm\:h-10 {
    height: 2.5rem; }
  .sm\:h-12 {
    height: 3rem; }
  .sm\:h-16 {
    height: 4rem; }
  .sm\:h-24 {
    height: 6rem; }
  .sm\:h-32 {
    height: 8rem; }
  .sm\:h-48 {
    height: 12rem; }
  .sm\:h-64 {
    height: 16rem; }
  .sm\:h-auto {
    height: auto; }
  .sm\:h-px {
    height: 1px; }
  .sm\:h-full {
    height: 100%; }
  .sm\:h-screen {
    height: 100vh; }
  .sm\:leading-none {
    line-height: 1; }
  .sm\:leading-tight {
    line-height: 1.25; }
  .sm\:leading-normal {
    line-height: 1.5; }
  .sm\:leading-loose {
    line-height: 2; }
  .sm\:m-0 {
    margin: 0; }
  .sm\:m-1 {
    margin: .25rem; }
  .sm\:m-2 {
    margin: .5rem; }
  .sm\:m-3 {
    margin: .75rem; }
  .sm\:m-4 {
    margin: 1rem; }
  .sm\:m-5 {
    margin: 1.25rem; }
  .sm\:m-6 {
    margin: 1.5rem; }
  .sm\:m-8 {
    margin: 2rem; }
  .sm\:m-10 {
    margin: 2.5rem; }
  .sm\:m-12 {
    margin: 3rem; }
  .sm\:m-16 {
    margin: 4rem; }
  .sm\:m-20 {
    margin: 5rem; }
  .sm\:m-24 {
    margin: 6rem; }
  .sm\:m-32 {
    margin: 8rem; }
  .sm\:m-auto {
    margin: auto; }
  .sm\:m-px {
    margin: 1px; }
  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .sm\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem; }
  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem; }
  .sm\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .sm\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem; }
  .sm\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem; }
  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem; }
  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px; }
  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px; }
  .sm\:mt-0 {
    margin-top: 0; }
  .sm\:mr-0 {
    margin-right: 0; }
  .sm\:mb-0 {
    margin-bottom: 0; }
  .sm\:ml-0 {
    margin-left: 0; }
  .sm\:mt-1 {
    margin-top: .25rem; }
  .sm\:mr-1 {
    margin-right: .25rem; }
  .sm\:mb-1 {
    margin-bottom: .25rem; }
  .sm\:ml-1 {
    margin-left: .25rem; }
  .sm\:mt-2 {
    margin-top: .5rem; }
  .sm\:mr-2 {
    margin-right: .5rem; }
  .sm\:mb-2 {
    margin-bottom: .5rem; }
  .sm\:ml-2 {
    margin-left: .5rem; }
  .sm\:mt-3 {
    margin-top: .75rem; }
  .sm\:mr-3 {
    margin-right: .75rem; }
  .sm\:mb-3 {
    margin-bottom: .75rem; }
  .sm\:ml-3 {
    margin-left: .75rem; }
  .sm\:mt-4 {
    margin-top: 1rem; }
  .sm\:mr-4 {
    margin-right: 1rem; }
  .sm\:mb-4 {
    margin-bottom: 1rem; }
  .sm\:ml-4 {
    margin-left: 1rem; }
  .sm\:mt-5 {
    margin-top: 1.25rem; }
  .sm\:mr-5 {
    margin-right: 1.25rem; }
  .sm\:mb-5 {
    margin-bottom: 1.25rem; }
  .sm\:ml-5 {
    margin-left: 1.25rem; }
  .sm\:mt-6 {
    margin-top: 1.5rem; }
  .sm\:mr-6 {
    margin-right: 1.5rem; }
  .sm\:mb-6 {
    margin-bottom: 1.5rem; }
  .sm\:ml-6 {
    margin-left: 1.5rem; }
  .sm\:mt-8 {
    margin-top: 2rem; }
  .sm\:mr-8 {
    margin-right: 2rem; }
  .sm\:mb-8 {
    margin-bottom: 2rem; }
  .sm\:ml-8 {
    margin-left: 2rem; }
  .sm\:mt-10 {
    margin-top: 2.5rem; }
  .sm\:mr-10 {
    margin-right: 2.5rem; }
  .sm\:mb-10 {
    margin-bottom: 2.5rem; }
  .sm\:ml-10 {
    margin-left: 2.5rem; }
  .sm\:mt-12 {
    margin-top: 3rem; }
  .sm\:mr-12 {
    margin-right: 3rem; }
  .sm\:mb-12 {
    margin-bottom: 3rem; }
  .sm\:ml-12 {
    margin-left: 3rem; }
  .sm\:mt-16 {
    margin-top: 4rem; }
  .sm\:mr-16 {
    margin-right: 4rem; }
  .sm\:mb-16 {
    margin-bottom: 4rem; }
  .sm\:ml-16 {
    margin-left: 4rem; }
  .sm\:mt-20 {
    margin-top: 5rem; }
  .sm\:mr-20 {
    margin-right: 5rem; }
  .sm\:mb-20 {
    margin-bottom: 5rem; }
  .sm\:ml-20 {
    margin-left: 5rem; }
  .sm\:mt-24 {
    margin-top: 6rem; }
  .sm\:mr-24 {
    margin-right: 6rem; }
  .sm\:mb-24 {
    margin-bottom: 6rem; }
  .sm\:ml-24 {
    margin-left: 6rem; }
  .sm\:mt-32 {
    margin-top: 8rem; }
  .sm\:mr-32 {
    margin-right: 8rem; }
  .sm\:mb-32 {
    margin-bottom: 8rem; }
  .sm\:ml-32 {
    margin-left: 8rem; }
  .sm\:mt-auto {
    margin-top: auto; }
  .sm\:mr-auto {
    margin-right: auto; }
  .sm\:mb-auto {
    margin-bottom: auto; }
  .sm\:ml-auto {
    margin-left: auto; }
  .sm\:mt-px {
    margin-top: 1px; }
  .sm\:mr-px {
    margin-right: 1px; }
  .sm\:mb-px {
    margin-bottom: 1px; }
  .sm\:ml-px {
    margin-left: 1px; }
  .sm\:max-h-full {
    max-height: 100%; }
  .sm\:max-h-screen {
    max-height: 100vh; }
  .sm\:max-w-xs {
    max-width: 20rem; }
  .sm\:max-w-sm {
    max-width: 30rem; }
  .sm\:max-w-md {
    max-width: 40rem; }
  .sm\:max-w-lg {
    max-width: 50rem; }
  .sm\:max-w-xl {
    max-width: 60rem; }
  .sm\:max-w-2xl {
    max-width: 70rem; }
  .sm\:max-w-3xl {
    max-width: 80rem; }
  .sm\:max-w-4xl {
    max-width: 90rem; }
  .sm\:max-w-5xl {
    max-width: 100rem; }
  .sm\:max-w-full {
    max-width: 100%; }
  .sm\:min-h-0 {
    min-height: 0; }
  .sm\:min-h-full {
    min-height: 100%; }
  .sm\:min-h-screen {
    min-height: 100vh; }
  .sm\:min-w-0 {
    min-width: 0; }
  .sm\:min-w-full {
    min-width: 100%; }
  .sm\:-m-0 {
    margin: 0; }
  .sm\:-m-1 {
    margin: -0.25rem; }
  .sm\:-m-2 {
    margin: -0.5rem; }
  .sm\:-m-3 {
    margin: -0.75rem; }
  .sm\:-m-4 {
    margin: -1rem; }
  .sm\:-m-5 {
    margin: -1.25rem; }
  .sm\:-m-6 {
    margin: -1.5rem; }
  .sm\:-m-8 {
    margin: -2rem; }
  .sm\:-m-10 {
    margin: -2.5rem; }
  .sm\:-m-12 {
    margin: -3rem; }
  .sm\:-m-16 {
    margin: -4rem; }
  .sm\:-m-20 {
    margin: -5rem; }
  .sm\:-m-24 {
    margin: -6rem; }
  .sm\:-m-32 {
    margin: -8rem; }
  .sm\:-m-px {
    margin: -1px; }
  .sm\:-my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem; }
  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem; }
  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem; }
  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem; }
  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem; }
  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem; }
  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem; }
  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem; }
  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem; }
  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem; }
  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem; }
  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem; }
  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem; }
  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px; }
  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px; }
  .sm\:-mt-0 {
    margin-top: 0; }
  .sm\:-mr-0 {
    margin-right: 0; }
  .sm\:-mb-0 {
    margin-bottom: 0; }
  .sm\:-ml-0 {
    margin-left: 0; }
  .sm\:-mt-1 {
    margin-top: -0.25rem; }
  .sm\:-mr-1 {
    margin-right: -0.25rem; }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem; }
  .sm\:-ml-1 {
    margin-left: -0.25rem; }
  .sm\:-mt-2 {
    margin-top: -0.5rem; }
  .sm\:-mr-2 {
    margin-right: -0.5rem; }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem; }
  .sm\:-ml-2 {
    margin-left: -0.5rem; }
  .sm\:-mt-3 {
    margin-top: -0.75rem; }
  .sm\:-mr-3 {
    margin-right: -0.75rem; }
  .sm\:-mb-3 {
    margin-bottom: -0.75rem; }
  .sm\:-ml-3 {
    margin-left: -0.75rem; }
  .sm\:-mt-4 {
    margin-top: -1rem; }
  .sm\:-mr-4 {
    margin-right: -1rem; }
  .sm\:-mb-4 {
    margin-bottom: -1rem; }
  .sm\:-ml-4 {
    margin-left: -1rem; }
  .sm\:-mt-5 {
    margin-top: -1.25rem; }
  .sm\:-mr-5 {
    margin-right: -1.25rem; }
  .sm\:-mb-5 {
    margin-bottom: -1.25rem; }
  .sm\:-ml-5 {
    margin-left: -1.25rem; }
  .sm\:-mt-6 {
    margin-top: -1.5rem; }
  .sm\:-mr-6 {
    margin-right: -1.5rem; }
  .sm\:-mb-6 {
    margin-bottom: -1.5rem; }
  .sm\:-ml-6 {
    margin-left: -1.5rem; }
  .sm\:-mt-8 {
    margin-top: -2rem; }
  .sm\:-mr-8 {
    margin-right: -2rem; }
  .sm\:-mb-8 {
    margin-bottom: -2rem; }
  .sm\:-ml-8 {
    margin-left: -2rem; }
  .sm\:-mt-10 {
    margin-top: -2.5rem; }
  .sm\:-mr-10 {
    margin-right: -2.5rem; }
  .sm\:-mb-10 {
    margin-bottom: -2.5rem; }
  .sm\:-ml-10 {
    margin-left: -2.5rem; }
  .sm\:-mt-12 {
    margin-top: -3rem; }
  .sm\:-mr-12 {
    margin-right: -3rem; }
  .sm\:-mb-12 {
    margin-bottom: -3rem; }
  .sm\:-ml-12 {
    margin-left: -3rem; }
  .sm\:-mt-16 {
    margin-top: -4rem; }
  .sm\:-mr-16 {
    margin-right: -4rem; }
  .sm\:-mb-16 {
    margin-bottom: -4rem; }
  .sm\:-ml-16 {
    margin-left: -4rem; }
  .sm\:-mt-20 {
    margin-top: -5rem; }
  .sm\:-mr-20 {
    margin-right: -5rem; }
  .sm\:-mb-20 {
    margin-bottom: -5rem; }
  .sm\:-ml-20 {
    margin-left: -5rem; }
  .sm\:-mt-24 {
    margin-top: -6rem; }
  .sm\:-mr-24 {
    margin-right: -6rem; }
  .sm\:-mb-24 {
    margin-bottom: -6rem; }
  .sm\:-ml-24 {
    margin-left: -6rem; }
  .sm\:-mt-32 {
    margin-top: -8rem; }
  .sm\:-mr-32 {
    margin-right: -8rem; }
  .sm\:-mb-32 {
    margin-bottom: -8rem; }
  .sm\:-ml-32 {
    margin-left: -8rem; }
  .sm\:-mt-px {
    margin-top: -1px; }
  .sm\:-mr-px {
    margin-right: -1px; }
  .sm\:-mb-px {
    margin-bottom: -1px; }
  .sm\:-ml-px {
    margin-left: -1px; }
  .sm\:opacity-0 {
    opacity: 0; }
  .sm\:opacity-25 {
    opacity: .25; }
  .sm\:opacity-50 {
    opacity: .5; }
  .sm\:opacity-75 {
    opacity: .75; }
  .sm\:opacity-100 {
    opacity: 1; }
  .sm\:overflow-auto {
    overflow: auto; }
  .sm\:overflow-hidden {
    overflow: hidden; }
  .sm\:overflow-visible {
    overflow: visible; }
  .sm\:overflow-scroll {
    overflow: scroll; }
  .sm\:overflow-x-auto {
    overflow-x: auto; }
  .sm\:overflow-y-auto {
    overflow-y: auto; }
  .sm\:overflow-x-hidden {
    overflow-x: hidden; }
  .sm\:overflow-y-hidden {
    overflow-y: hidden; }
  .sm\:overflow-x-visible {
    overflow-x: visible; }
  .sm\:overflow-y-visible {
    overflow-y: visible; }
  .sm\:overflow-x-scroll {
    overflow-x: scroll; }
  .sm\:overflow-y-scroll {
    overflow-y: scroll; }
  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch; }
  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto; }
  .sm\:p-0 {
    padding: 0; }
  .sm\:p-1 {
    padding: .25rem; }
  .sm\:p-2 {
    padding: .5rem; }
  .sm\:p-3 {
    padding: .75rem; }
  .sm\:p-4 {
    padding: 1rem; }
  .sm\:p-5 {
    padding: 1.25rem; }
  .sm\:p-6 {
    padding: 1.5rem; }
  .sm\:p-8 {
    padding: 2rem; }
  .sm\:p-10 {
    padding: 2.5rem; }
  .sm\:p-12 {
    padding: 3rem; }
  .sm\:p-16 {
    padding: 4rem; }
  .sm\:p-20 {
    padding: 5rem; }
  .sm\:p-24 {
    padding: 6rem; }
  .sm\:p-32 {
    padding: 8rem; }
  .sm\:p-px {
    padding: 1px; }
  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0; }
  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem; }
  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem; }
  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem; }
  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem; }
  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem; }
  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px; }
  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px; }
  .sm\:pt-0 {
    padding-top: 0; }
  .sm\:pr-0 {
    padding-right: 0; }
  .sm\:pb-0 {
    padding-bottom: 0; }
  .sm\:pl-0 {
    padding-left: 0; }
  .sm\:pt-1 {
    padding-top: .25rem; }
  .sm\:pr-1 {
    padding-right: .25rem; }
  .sm\:pb-1 {
    padding-bottom: .25rem; }
  .sm\:pl-1 {
    padding-left: .25rem; }
  .sm\:pt-2 {
    padding-top: .5rem; }
  .sm\:pr-2 {
    padding-right: .5rem; }
  .sm\:pb-2 {
    padding-bottom: .5rem; }
  .sm\:pl-2 {
    padding-left: .5rem; }
  .sm\:pt-3 {
    padding-top: .75rem; }
  .sm\:pr-3 {
    padding-right: .75rem; }
  .sm\:pb-3 {
    padding-bottom: .75rem; }
  .sm\:pl-3 {
    padding-left: .75rem; }
  .sm\:pt-4 {
    padding-top: 1rem; }
  .sm\:pr-4 {
    padding-right: 1rem; }
  .sm\:pb-4 {
    padding-bottom: 1rem; }
  .sm\:pl-4 {
    padding-left: 1rem; }
  .sm\:pt-5 {
    padding-top: 1.25rem; }
  .sm\:pr-5 {
    padding-right: 1.25rem; }
  .sm\:pb-5 {
    padding-bottom: 1.25rem; }
  .sm\:pl-5 {
    padding-left: 1.25rem; }
  .sm\:pt-6 {
    padding-top: 1.5rem; }
  .sm\:pr-6 {
    padding-right: 1.5rem; }
  .sm\:pb-6 {
    padding-bottom: 1.5rem; }
  .sm\:pl-6 {
    padding-left: 1.5rem; }
  .sm\:pt-8 {
    padding-top: 2rem; }
  .sm\:pr-8 {
    padding-right: 2rem; }
  .sm\:pb-8 {
    padding-bottom: 2rem; }
  .sm\:pl-8 {
    padding-left: 2rem; }
  .sm\:pt-10 {
    padding-top: 2.5rem; }
  .sm\:pr-10 {
    padding-right: 2.5rem; }
  .sm\:pb-10 {
    padding-bottom: 2.5rem; }
  .sm\:pl-10 {
    padding-left: 2.5rem; }
  .sm\:pt-12 {
    padding-top: 3rem; }
  .sm\:pr-12 {
    padding-right: 3rem; }
  .sm\:pb-12 {
    padding-bottom: 3rem; }
  .sm\:pl-12 {
    padding-left: 3rem; }
  .sm\:pt-16 {
    padding-top: 4rem; }
  .sm\:pr-16 {
    padding-right: 4rem; }
  .sm\:pb-16 {
    padding-bottom: 4rem; }
  .sm\:pl-16 {
    padding-left: 4rem; }
  .sm\:pt-20 {
    padding-top: 5rem; }
  .sm\:pr-20 {
    padding-right: 5rem; }
  .sm\:pb-20 {
    padding-bottom: 5rem; }
  .sm\:pl-20 {
    padding-left: 5rem; }
  .sm\:pt-24 {
    padding-top: 6rem; }
  .sm\:pr-24 {
    padding-right: 6rem; }
  .sm\:pb-24 {
    padding-bottom: 6rem; }
  .sm\:pl-24 {
    padding-left: 6rem; }
  .sm\:pt-32 {
    padding-top: 8rem; }
  .sm\:pr-32 {
    padding-right: 8rem; }
  .sm\:pb-32 {
    padding-bottom: 8rem; }
  .sm\:pl-32 {
    padding-left: 8rem; }
  .sm\:pt-px {
    padding-top: 1px; }
  .sm\:pr-px {
    padding-right: 1px; }
  .sm\:pb-px {
    padding-bottom: 1px; }
  .sm\:pl-px {
    padding-left: 1px; }
  .sm\:pointer-events-none {
    pointer-events: none; }
  .sm\:pointer-events-auto {
    pointer-events: auto; }
  .sm\:static {
    position: static; }
  .sm\:fixed {
    position: fixed; }
  .sm\:absolute {
    position: absolute; }
  .sm\:relative {
    position: relative; }
  .sm\:sticky {
    position: -webkit-sticky;
    position: sticky; }
  .sm\:pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto; }
  .sm\:pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .sm\:pin-y {
    top: 0;
    bottom: 0; }
  .sm\:pin-x {
    right: 0;
    left: 0; }
  .sm\:pin-t {
    top: 0; }
  .sm\:pin-r {
    right: 0; }
  .sm\:pin-b {
    bottom: 0; }
  .sm\:pin-l {
    left: 0; }
  .sm\:resize-none {
    resize: none; }
  .sm\:resize-y {
    resize: vertical; }
  .sm\:resize-x {
    resize: horizontal; }
  .sm\:resize {
    resize: both; }
  .sm\:shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .sm\:shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .sm\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .sm\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .sm\:shadow-none {
    box-shadow: none; }
  .sm\:hover\:shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .sm\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .sm\:hover\:shadow-none:hover {
    box-shadow: none; }
  .sm\:focus\:shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .sm\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .sm\:focus\:shadow-none:focus {
    box-shadow: none; }
  .sm\:table-auto {
    table-layout: auto; }
  .sm\:table-fixed {
    table-layout: fixed; }
  .sm\:text-left {
    text-align: left; }
  .sm\:text-center {
    text-align: center; }
  .sm\:text-right {
    text-align: right; }
  .sm\:text-justify {
    text-align: justify; }
  .sm\:text-transparent {
    color: transparent; }
  .sm\:text-black {
    color: #22292f; }
  .sm\:text-grey-darkest {
    color: #3d4852; }
  .sm\:text-grey-darker {
    color: #606f7b; }
  .sm\:text-grey-dark {
    color: #8795a1; }
  .sm\:text-grey {
    color: #b8c2cc; }
  .sm\:text-grey-light {
    color: #dae1e7; }
  .sm\:text-grey-lighter {
    color: #f1f5f8; }
  .sm\:text-grey-lightest {
    color: #f8fafc; }
  .sm\:text-white {
    color: #fff; }
  .sm\:text-red-darkest {
    color: #3b0d0c; }
  .sm\:text-red-darker {
    color: #621b18; }
  .sm\:text-red-dark {
    color: #cc1f1a; }
  .sm\:text-red {
    color: #e3342f; }
  .sm\:text-red-light {
    color: #ef5753; }
  .sm\:text-red-lighter {
    color: #f9acaa; }
  .sm\:text-red-lightest {
    color: #fcebea; }
  .sm\:text-orange-darkest {
    color: #462a16; }
  .sm\:text-orange-darker {
    color: #613b1f; }
  .sm\:text-orange-dark {
    color: #de751f; }
  .sm\:text-orange {
    color: #f6993f; }
  .sm\:text-orange-light {
    color: #faad63; }
  .sm\:text-orange-lighter {
    color: #fcd9b6; }
  .sm\:text-orange-lightest {
    color: #fff5eb; }
  .sm\:text-yellow-darkest {
    color: #453411; }
  .sm\:text-yellow-darker {
    color: #684f1d; }
  .sm\:text-yellow-dark {
    color: #f2d024; }
  .sm\:text-yellow {
    color: #ffed4a; }
  .sm\:text-yellow-light {
    color: #fff382; }
  .sm\:text-yellow-lighter {
    color: #fff9c2; }
  .sm\:text-yellow-lightest {
    color: #fcfbeb; }
  .sm\:text-green-darkest {
    color: #0f2f21; }
  .sm\:text-green-darker {
    color: #1a4731; }
  .sm\:text-green-dark {
    color: #1f9d55; }
  .sm\:text-green {
    color: #38c172; }
  .sm\:text-green-light {
    color: #51d88a; }
  .sm\:text-green-lighter {
    color: #a2f5bf; }
  .sm\:text-green-lightest {
    color: #e3fcec; }
  .sm\:text-teal-darkest {
    color: #0d3331; }
  .sm\:text-teal-darker {
    color: #20504f; }
  .sm\:text-teal-dark {
    color: #38a89d; }
  .sm\:text-teal {
    color: #4dc0b5; }
  .sm\:text-teal-light {
    color: #64d5ca; }
  .sm\:text-teal-lighter {
    color: #a0f0ed; }
  .sm\:text-teal-lightest {
    color: #e8fffe; }
  .sm\:text-blue-darkest {
    color: #12283a; }
  .sm\:text-blue-darker {
    color: #1c3d5a; }
  .sm\:text-blue-dark {
    color: #2779bd; }
  .sm\:text-blue {
    color: #3490dc; }
  .sm\:text-blue-light {
    color: #6cb2eb; }
  .sm\:text-blue-lighter {
    color: #bcdefa; }
  .sm\:text-blue-lightest {
    color: #eff8ff; }
  .sm\:text-indigo-darkest {
    color: #191e38; }
  .sm\:text-indigo-darker {
    color: #2f365f; }
  .sm\:text-indigo-dark {
    color: #5661b3; }
  .sm\:text-indigo {
    color: #6574cd; }
  .sm\:text-indigo-light {
    color: #7886d7; }
  .sm\:text-indigo-lighter {
    color: #b2b7ff; }
  .sm\:text-indigo-lightest {
    color: #e6e8ff; }
  .sm\:text-purple-darkest {
    color: #21183c; }
  .sm\:text-purple-darker {
    color: #382b5f; }
  .sm\:text-purple-dark {
    color: #794acf; }
  .sm\:text-purple {
    color: #9561e2; }
  .sm\:text-purple-light {
    color: #a779e9; }
  .sm\:text-purple-lighter {
    color: #d6bbfc; }
  .sm\:text-purple-lightest {
    color: #f3ebff; }
  .sm\:text-pink-darkest {
    color: #451225; }
  .sm\:text-pink-darker {
    color: #6f213f; }
  .sm\:text-pink-dark {
    color: #eb5286; }
  .sm\:text-pink {
    color: #f66d9b; }
  .sm\:text-pink-light {
    color: #fa7ea8; }
  .sm\:text-pink-lighter {
    color: #ffbbca; }
  .sm\:text-pink-lightest {
    color: #ffebef; }
  .sm\:hover\:text-transparent:hover {
    color: transparent; }
  .sm\:hover\:text-black:hover {
    color: #22292f; }
  .sm\:hover\:text-grey-darkest:hover {
    color: #3d4852; }
  .sm\:hover\:text-grey-darker:hover {
    color: #606f7b; }
  .sm\:hover\:text-grey-dark:hover {
    color: #8795a1; }
  .sm\:hover\:text-grey:hover {
    color: #b8c2cc; }
  .sm\:hover\:text-grey-light:hover {
    color: #dae1e7; }
  .sm\:hover\:text-grey-lighter:hover {
    color: #f1f5f8; }
  .sm\:hover\:text-grey-lightest:hover {
    color: #f8fafc; }
  .sm\:hover\:text-white:hover {
    color: #fff; }
  .sm\:hover\:text-red-darkest:hover {
    color: #3b0d0c; }
  .sm\:hover\:text-red-darker:hover {
    color: #621b18; }
  .sm\:hover\:text-red-dark:hover {
    color: #cc1f1a; }
  .sm\:hover\:text-red:hover {
    color: #e3342f; }
  .sm\:hover\:text-red-light:hover {
    color: #ef5753; }
  .sm\:hover\:text-red-lighter:hover {
    color: #f9acaa; }
  .sm\:hover\:text-red-lightest:hover {
    color: #fcebea; }
  .sm\:hover\:text-orange-darkest:hover {
    color: #462a16; }
  .sm\:hover\:text-orange-darker:hover {
    color: #613b1f; }
  .sm\:hover\:text-orange-dark:hover {
    color: #de751f; }
  .sm\:hover\:text-orange:hover {
    color: #f6993f; }
  .sm\:hover\:text-orange-light:hover {
    color: #faad63; }
  .sm\:hover\:text-orange-lighter:hover {
    color: #fcd9b6; }
  .sm\:hover\:text-orange-lightest:hover {
    color: #fff5eb; }
  .sm\:hover\:text-yellow-darkest:hover {
    color: #453411; }
  .sm\:hover\:text-yellow-darker:hover {
    color: #684f1d; }
  .sm\:hover\:text-yellow-dark:hover {
    color: #f2d024; }
  .sm\:hover\:text-yellow:hover {
    color: #ffed4a; }
  .sm\:hover\:text-yellow-light:hover {
    color: #fff382; }
  .sm\:hover\:text-yellow-lighter:hover {
    color: #fff9c2; }
  .sm\:hover\:text-yellow-lightest:hover {
    color: #fcfbeb; }
  .sm\:hover\:text-green-darkest:hover {
    color: #0f2f21; }
  .sm\:hover\:text-green-darker:hover {
    color: #1a4731; }
  .sm\:hover\:text-green-dark:hover {
    color: #1f9d55; }
  .sm\:hover\:text-green:hover {
    color: #38c172; }
  .sm\:hover\:text-green-light:hover {
    color: #51d88a; }
  .sm\:hover\:text-green-lighter:hover {
    color: #a2f5bf; }
  .sm\:hover\:text-green-lightest:hover {
    color: #e3fcec; }
  .sm\:hover\:text-teal-darkest:hover {
    color: #0d3331; }
  .sm\:hover\:text-teal-darker:hover {
    color: #20504f; }
  .sm\:hover\:text-teal-dark:hover {
    color: #38a89d; }
  .sm\:hover\:text-teal:hover {
    color: #4dc0b5; }
  .sm\:hover\:text-teal-light:hover {
    color: #64d5ca; }
  .sm\:hover\:text-teal-lighter:hover {
    color: #a0f0ed; }
  .sm\:hover\:text-teal-lightest:hover {
    color: #e8fffe; }
  .sm\:hover\:text-blue-darkest:hover {
    color: #12283a; }
  .sm\:hover\:text-blue-darker:hover {
    color: #1c3d5a; }
  .sm\:hover\:text-blue-dark:hover {
    color: #2779bd; }
  .sm\:hover\:text-blue:hover {
    color: #3490dc; }
  .sm\:hover\:text-blue-light:hover {
    color: #6cb2eb; }
  .sm\:hover\:text-blue-lighter:hover {
    color: #bcdefa; }
  .sm\:hover\:text-blue-lightest:hover {
    color: #eff8ff; }
  .sm\:hover\:text-indigo-darkest:hover {
    color: #191e38; }
  .sm\:hover\:text-indigo-darker:hover {
    color: #2f365f; }
  .sm\:hover\:text-indigo-dark:hover {
    color: #5661b3; }
  .sm\:hover\:text-indigo:hover {
    color: #6574cd; }
  .sm\:hover\:text-indigo-light:hover {
    color: #7886d7; }
  .sm\:hover\:text-indigo-lighter:hover {
    color: #b2b7ff; }
  .sm\:hover\:text-indigo-lightest:hover {
    color: #e6e8ff; }
  .sm\:hover\:text-purple-darkest:hover {
    color: #21183c; }
  .sm\:hover\:text-purple-darker:hover {
    color: #382b5f; }
  .sm\:hover\:text-purple-dark:hover {
    color: #794acf; }
  .sm\:hover\:text-purple:hover {
    color: #9561e2; }
  .sm\:hover\:text-purple-light:hover {
    color: #a779e9; }
  .sm\:hover\:text-purple-lighter:hover {
    color: #d6bbfc; }
  .sm\:hover\:text-purple-lightest:hover {
    color: #f3ebff; }
  .sm\:hover\:text-pink-darkest:hover {
    color: #451225; }
  .sm\:hover\:text-pink-darker:hover {
    color: #6f213f; }
  .sm\:hover\:text-pink-dark:hover {
    color: #eb5286; }
  .sm\:hover\:text-pink:hover {
    color: #f66d9b; }
  .sm\:hover\:text-pink-light:hover {
    color: #fa7ea8; }
  .sm\:hover\:text-pink-lighter:hover {
    color: #ffbbca; }
  .sm\:hover\:text-pink-lightest:hover {
    color: #ffebef; }
  .sm\:focus\:text-transparent:focus {
    color: transparent; }
  .sm\:focus\:text-black:focus {
    color: #22292f; }
  .sm\:focus\:text-grey-darkest:focus {
    color: #3d4852; }
  .sm\:focus\:text-grey-darker:focus {
    color: #606f7b; }
  .sm\:focus\:text-grey-dark:focus {
    color: #8795a1; }
  .sm\:focus\:text-grey:focus {
    color: #b8c2cc; }
  .sm\:focus\:text-grey-light:focus {
    color: #dae1e7; }
  .sm\:focus\:text-grey-lighter:focus {
    color: #f1f5f8; }
  .sm\:focus\:text-grey-lightest:focus {
    color: #f8fafc; }
  .sm\:focus\:text-white:focus {
    color: #fff; }
  .sm\:focus\:text-red-darkest:focus {
    color: #3b0d0c; }
  .sm\:focus\:text-red-darker:focus {
    color: #621b18; }
  .sm\:focus\:text-red-dark:focus {
    color: #cc1f1a; }
  .sm\:focus\:text-red:focus {
    color: #e3342f; }
  .sm\:focus\:text-red-light:focus {
    color: #ef5753; }
  .sm\:focus\:text-red-lighter:focus {
    color: #f9acaa; }
  .sm\:focus\:text-red-lightest:focus {
    color: #fcebea; }
  .sm\:focus\:text-orange-darkest:focus {
    color: #462a16; }
  .sm\:focus\:text-orange-darker:focus {
    color: #613b1f; }
  .sm\:focus\:text-orange-dark:focus {
    color: #de751f; }
  .sm\:focus\:text-orange:focus {
    color: #f6993f; }
  .sm\:focus\:text-orange-light:focus {
    color: #faad63; }
  .sm\:focus\:text-orange-lighter:focus {
    color: #fcd9b6; }
  .sm\:focus\:text-orange-lightest:focus {
    color: #fff5eb; }
  .sm\:focus\:text-yellow-darkest:focus {
    color: #453411; }
  .sm\:focus\:text-yellow-darker:focus {
    color: #684f1d; }
  .sm\:focus\:text-yellow-dark:focus {
    color: #f2d024; }
  .sm\:focus\:text-yellow:focus {
    color: #ffed4a; }
  .sm\:focus\:text-yellow-light:focus {
    color: #fff382; }
  .sm\:focus\:text-yellow-lighter:focus {
    color: #fff9c2; }
  .sm\:focus\:text-yellow-lightest:focus {
    color: #fcfbeb; }
  .sm\:focus\:text-green-darkest:focus {
    color: #0f2f21; }
  .sm\:focus\:text-green-darker:focus {
    color: #1a4731; }
  .sm\:focus\:text-green-dark:focus {
    color: #1f9d55; }
  .sm\:focus\:text-green:focus {
    color: #38c172; }
  .sm\:focus\:text-green-light:focus {
    color: #51d88a; }
  .sm\:focus\:text-green-lighter:focus {
    color: #a2f5bf; }
  .sm\:focus\:text-green-lightest:focus {
    color: #e3fcec; }
  .sm\:focus\:text-teal-darkest:focus {
    color: #0d3331; }
  .sm\:focus\:text-teal-darker:focus {
    color: #20504f; }
  .sm\:focus\:text-teal-dark:focus {
    color: #38a89d; }
  .sm\:focus\:text-teal:focus {
    color: #4dc0b5; }
  .sm\:focus\:text-teal-light:focus {
    color: #64d5ca; }
  .sm\:focus\:text-teal-lighter:focus {
    color: #a0f0ed; }
  .sm\:focus\:text-teal-lightest:focus {
    color: #e8fffe; }
  .sm\:focus\:text-blue-darkest:focus {
    color: #12283a; }
  .sm\:focus\:text-blue-darker:focus {
    color: #1c3d5a; }
  .sm\:focus\:text-blue-dark:focus {
    color: #2779bd; }
  .sm\:focus\:text-blue:focus {
    color: #3490dc; }
  .sm\:focus\:text-blue-light:focus {
    color: #6cb2eb; }
  .sm\:focus\:text-blue-lighter:focus {
    color: #bcdefa; }
  .sm\:focus\:text-blue-lightest:focus {
    color: #eff8ff; }
  .sm\:focus\:text-indigo-darkest:focus {
    color: #191e38; }
  .sm\:focus\:text-indigo-darker:focus {
    color: #2f365f; }
  .sm\:focus\:text-indigo-dark:focus {
    color: #5661b3; }
  .sm\:focus\:text-indigo:focus {
    color: #6574cd; }
  .sm\:focus\:text-indigo-light:focus {
    color: #7886d7; }
  .sm\:focus\:text-indigo-lighter:focus {
    color: #b2b7ff; }
  .sm\:focus\:text-indigo-lightest:focus {
    color: #e6e8ff; }
  .sm\:focus\:text-purple-darkest:focus {
    color: #21183c; }
  .sm\:focus\:text-purple-darker:focus {
    color: #382b5f; }
  .sm\:focus\:text-purple-dark:focus {
    color: #794acf; }
  .sm\:focus\:text-purple:focus {
    color: #9561e2; }
  .sm\:focus\:text-purple-light:focus {
    color: #a779e9; }
  .sm\:focus\:text-purple-lighter:focus {
    color: #d6bbfc; }
  .sm\:focus\:text-purple-lightest:focus {
    color: #f3ebff; }
  .sm\:focus\:text-pink-darkest:focus {
    color: #451225; }
  .sm\:focus\:text-pink-darker:focus {
    color: #6f213f; }
  .sm\:focus\:text-pink-dark:focus {
    color: #eb5286; }
  .sm\:focus\:text-pink:focus {
    color: #f66d9b; }
  .sm\:focus\:text-pink-light:focus {
    color: #fa7ea8; }
  .sm\:focus\:text-pink-lighter:focus {
    color: #ffbbca; }
  .sm\:focus\:text-pink-lightest:focus {
    color: #ffebef; }
  .sm\:text-xs {
    font-size: .75rem; }
  .sm\:text-sm {
    font-size: .875rem; }
  .sm\:text-base {
    font-size: 1rem; }
  .sm\:text-lg {
    font-size: 1.125rem; }
  .sm\:text-xl {
    font-size: 1.25rem; }
  .sm\:text-2xl {
    font-size: 1.5rem; }
  .sm\:text-3xl {
    font-size: 1.875rem; }
  .sm\:text-4xl {
    font-size: 2.25rem; }
  .sm\:text-5xl {
    font-size: 3rem; }
  .sm\:italic {
    font-style: italic; }
  .sm\:roman {
    font-style: normal; }
  .sm\:uppercase {
    text-transform: uppercase; }
  .sm\:lowercase {
    text-transform: lowercase; }
  .sm\:capitalize {
    text-transform: capitalize; }
  .sm\:normal-case {
    text-transform: none; }
  .sm\:underline {
    text-decoration: underline; }
  .sm\:line-through {
    text-decoration: line-through; }
  .sm\:no-underline {
    text-decoration: none; }
  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .sm\:hover\:italic:hover {
    font-style: italic; }
  .sm\:hover\:roman:hover {
    font-style: normal; }
  .sm\:hover\:uppercase:hover {
    text-transform: uppercase; }
  .sm\:hover\:lowercase:hover {
    text-transform: lowercase; }
  .sm\:hover\:capitalize:hover {
    text-transform: capitalize; }
  .sm\:hover\:normal-case:hover {
    text-transform: none; }
  .sm\:hover\:underline:hover {
    text-decoration: underline; }
  .sm\:hover\:line-through:hover {
    text-decoration: line-through; }
  .sm\:hover\:no-underline:hover {
    text-decoration: none; }
  .sm\:hover\:antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .sm\:hover\:subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .sm\:focus\:italic:focus {
    font-style: italic; }
  .sm\:focus\:roman:focus {
    font-style: normal; }
  .sm\:focus\:uppercase:focus {
    text-transform: uppercase; }
  .sm\:focus\:lowercase:focus {
    text-transform: lowercase; }
  .sm\:focus\:capitalize:focus {
    text-transform: capitalize; }
  .sm\:focus\:normal-case:focus {
    text-transform: none; }
  .sm\:focus\:underline:focus {
    text-decoration: underline; }
  .sm\:focus\:line-through:focus {
    text-decoration: line-through; }
  .sm\:focus\:no-underline:focus {
    text-decoration: none; }
  .sm\:focus\:antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .sm\:focus\:subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .sm\:tracking-tight {
    letter-spacing: -0.05em; }
  .sm\:tracking-normal {
    letter-spacing: 0; }
  .sm\:tracking-wide {
    letter-spacing: .05em; }
  .sm\:select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .sm\:select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  .sm\:align-baseline {
    vertical-align: baseline; }
  .sm\:align-top {
    vertical-align: top; }
  .sm\:align-middle {
    vertical-align: middle; }
  .sm\:align-bottom {
    vertical-align: bottom; }
  .sm\:align-text-top {
    vertical-align: text-top; }
  .sm\:align-text-bottom {
    vertical-align: text-bottom; }
  .sm\:visible {
    visibility: visible; }
  .sm\:invisible {
    visibility: hidden; }
  .sm\:whitespace-normal {
    white-space: normal; }
  .sm\:whitespace-no-wrap {
    white-space: nowrap; }
  .sm\:whitespace-pre {
    white-space: pre; }
  .sm\:whitespace-pre-line {
    white-space: pre-line; }
  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap; }
  .sm\:break-words {
    word-wrap: break-word; }
  .sm\:break-normal {
    word-wrap: normal; }
  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sm\:w-1 {
    width: .25rem; }
  .sm\:w-2 {
    width: .5rem; }
  .sm\:w-3 {
    width: .75rem; }
  .sm\:w-4 {
    width: 1rem; }
  .sm\:w-5 {
    width: 1.25rem; }
  .sm\:w-6 {
    width: 1.5rem; }
  .sm\:w-8 {
    width: 2rem; }
  .sm\:w-10 {
    width: 2.5rem; }
  .sm\:w-12 {
    width: 3rem; }
  .sm\:w-16 {
    width: 4rem; }
  .sm\:w-24 {
    width: 6rem; }
  .sm\:w-32 {
    width: 8rem; }
  .sm\:w-48 {
    width: 12rem; }
  .sm\:w-64 {
    width: 16rem; }
  .sm\:w-auto {
    width: auto; }
  .sm\:w-px {
    width: 1px; }
  .sm\:w-1\/2 {
    width: 50%; }
  .sm\:w-1\/3 {
    width: 33.33333%; }
  .sm\:w-2\/3 {
    width: 66.66667%; }
  .sm\:w-1\/4 {
    width: 25%; }
  .sm\:w-3\/4 {
    width: 75%; }
  .sm\:w-1\/5 {
    width: 20%; }
  .sm\:w-2\/5 {
    width: 40%; }
  .sm\:w-3\/5 {
    width: 60%; }
  .sm\:w-4\/5 {
    width: 80%; }
  .sm\:w-1\/6 {
    width: 16.66667%; }
  .sm\:w-5\/6 {
    width: 83.33333%; }
  .sm\:w-full {
    width: 100%; }
  .sm\:w-screen {
    width: 100vw; }
  .sm\:z-0 {
    z-index: 0; }
  .sm\:z-10 {
    z-index: 10; }
  .sm\:z-20 {
    z-index: 20; }
  .sm\:z-30 {
    z-index: 30; }
  .sm\:z-40 {
    z-index: 40; }
  .sm\:z-50 {
    z-index: 50; }
  .sm\:z-auto {
    z-index: auto; } }

@media (min-width: 768px) {
  .md\:list-reset {
    list-style: none;
    padding: 0; }
  .md\:appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .md\:bg-fixed {
    background-attachment: fixed; }
  .md\:bg-local {
    background-attachment: local; }
  .md\:bg-scroll {
    background-attachment: scroll; }
  .md\:bg-transparent {
    background-color: transparent; }
  .md\:bg-black {
    background-color: #22292f; }
  .md\:bg-grey-darkest {
    background-color: #3d4852; }
  .md\:bg-grey-darker {
    background-color: #606f7b; }
  .md\:bg-grey-dark {
    background-color: #8795a1; }
  .md\:bg-grey {
    background-color: #b8c2cc; }
  .md\:bg-grey-light {
    background-color: #dae1e7; }
  .md\:bg-grey-lighter {
    background-color: #f1f5f8; }
  .md\:bg-grey-lightest {
    background-color: #f8fafc; }
  .md\:bg-white {
    background-color: #fff; }
  .md\:bg-red-darkest {
    background-color: #3b0d0c; }
  .md\:bg-red-darker {
    background-color: #621b18; }
  .md\:bg-red-dark {
    background-color: #cc1f1a; }
  .md\:bg-red {
    background-color: #e3342f; }
  .md\:bg-red-light {
    background-color: #ef5753; }
  .md\:bg-red-lighter {
    background-color: #f9acaa; }
  .md\:bg-red-lightest {
    background-color: #fcebea; }
  .md\:bg-orange-darkest {
    background-color: #462a16; }
  .md\:bg-orange-darker {
    background-color: #613b1f; }
  .md\:bg-orange-dark {
    background-color: #de751f; }
  .md\:bg-orange {
    background-color: #f6993f; }
  .md\:bg-orange-light {
    background-color: #faad63; }
  .md\:bg-orange-lighter {
    background-color: #fcd9b6; }
  .md\:bg-orange-lightest {
    background-color: #fff5eb; }
  .md\:bg-yellow-darkest {
    background-color: #453411; }
  .md\:bg-yellow-darker {
    background-color: #684f1d; }
  .md\:bg-yellow-dark {
    background-color: #f2d024; }
  .md\:bg-yellow {
    background-color: #ffed4a; }
  .md\:bg-yellow-light {
    background-color: #fff382; }
  .md\:bg-yellow-lighter {
    background-color: #fff9c2; }
  .md\:bg-yellow-lightest {
    background-color: #fcfbeb; }
  .md\:bg-green-darkest {
    background-color: #0f2f21; }
  .md\:bg-green-darker {
    background-color: #1a4731; }
  .md\:bg-green-dark {
    background-color: #1f9d55; }
  .md\:bg-green {
    background-color: #38c172; }
  .md\:bg-green-light {
    background-color: #51d88a; }
  .md\:bg-green-lighter {
    background-color: #a2f5bf; }
  .md\:bg-green-lightest {
    background-color: #e3fcec; }
  .md\:bg-teal-darkest {
    background-color: #0d3331; }
  .md\:bg-teal-darker {
    background-color: #20504f; }
  .md\:bg-teal-dark {
    background-color: #38a89d; }
  .md\:bg-teal {
    background-color: #4dc0b5; }
  .md\:bg-teal-light {
    background-color: #64d5ca; }
  .md\:bg-teal-lighter {
    background-color: #a0f0ed; }
  .md\:bg-teal-lightest {
    background-color: #e8fffe; }
  .md\:bg-blue-darkest {
    background-color: #12283a; }
  .md\:bg-blue-darker {
    background-color: #1c3d5a; }
  .md\:bg-blue-dark {
    background-color: #2779bd; }
  .md\:bg-blue {
    background-color: #3490dc; }
  .md\:bg-blue-light {
    background-color: #6cb2eb; }
  .md\:bg-blue-lighter {
    background-color: #bcdefa; }
  .md\:bg-blue-lightest {
    background-color: #eff8ff; }
  .md\:bg-indigo-darkest {
    background-color: #191e38; }
  .md\:bg-indigo-darker {
    background-color: #2f365f; }
  .md\:bg-indigo-dark {
    background-color: #5661b3; }
  .md\:bg-indigo {
    background-color: #6574cd; }
  .md\:bg-indigo-light {
    background-color: #7886d7; }
  .md\:bg-indigo-lighter {
    background-color: #b2b7ff; }
  .md\:bg-indigo-lightest {
    background-color: #e6e8ff; }
  .md\:bg-purple-darkest {
    background-color: #21183c; }
  .md\:bg-purple-darker {
    background-color: #382b5f; }
  .md\:bg-purple-dark {
    background-color: #794acf; }
  .md\:bg-purple {
    background-color: #9561e2; }
  .md\:bg-purple-light {
    background-color: #a779e9; }
  .md\:bg-purple-lighter {
    background-color: #d6bbfc; }
  .md\:bg-purple-lightest {
    background-color: #f3ebff; }
  .md\:bg-pink-darkest {
    background-color: #451225; }
  .md\:bg-pink-darker {
    background-color: #6f213f; }
  .md\:bg-pink-dark {
    background-color: #eb5286; }
  .md\:bg-pink {
    background-color: #f66d9b; }
  .md\:bg-pink-light {
    background-color: #fa7ea8; }
  .md\:bg-pink-lighter {
    background-color: #ffbbca; }
  .md\:bg-pink-lightest {
    background-color: #ffebef; }
  .md\:hover\:bg-transparent:hover {
    background-color: transparent; }
  .md\:hover\:bg-black:hover {
    background-color: #22292f; }
  .md\:hover\:bg-grey-darkest:hover {
    background-color: #3d4852; }
  .md\:hover\:bg-grey-darker:hover {
    background-color: #606f7b; }
  .md\:hover\:bg-grey-dark:hover {
    background-color: #8795a1; }
  .md\:hover\:bg-grey:hover {
    background-color: #b8c2cc; }
  .md\:hover\:bg-grey-light:hover {
    background-color: #dae1e7; }
  .md\:hover\:bg-grey-lighter:hover {
    background-color: #f1f5f8; }
  .md\:hover\:bg-grey-lightest:hover {
    background-color: #f8fafc; }
  .md\:hover\:bg-white:hover {
    background-color: #fff; }
  .md\:hover\:bg-red-darkest:hover {
    background-color: #3b0d0c; }
  .md\:hover\:bg-red-darker:hover {
    background-color: #621b18; }
  .md\:hover\:bg-red-dark:hover {
    background-color: #cc1f1a; }
  .md\:hover\:bg-red:hover {
    background-color: #e3342f; }
  .md\:hover\:bg-red-light:hover {
    background-color: #ef5753; }
  .md\:hover\:bg-red-lighter:hover {
    background-color: #f9acaa; }
  .md\:hover\:bg-red-lightest:hover {
    background-color: #fcebea; }
  .md\:hover\:bg-orange-darkest:hover {
    background-color: #462a16; }
  .md\:hover\:bg-orange-darker:hover {
    background-color: #613b1f; }
  .md\:hover\:bg-orange-dark:hover {
    background-color: #de751f; }
  .md\:hover\:bg-orange:hover {
    background-color: #f6993f; }
  .md\:hover\:bg-orange-light:hover {
    background-color: #faad63; }
  .md\:hover\:bg-orange-lighter:hover {
    background-color: #fcd9b6; }
  .md\:hover\:bg-orange-lightest:hover {
    background-color: #fff5eb; }
  .md\:hover\:bg-yellow-darkest:hover {
    background-color: #453411; }
  .md\:hover\:bg-yellow-darker:hover {
    background-color: #684f1d; }
  .md\:hover\:bg-yellow-dark:hover {
    background-color: #f2d024; }
  .md\:hover\:bg-yellow:hover {
    background-color: #ffed4a; }
  .md\:hover\:bg-yellow-light:hover {
    background-color: #fff382; }
  .md\:hover\:bg-yellow-lighter:hover {
    background-color: #fff9c2; }
  .md\:hover\:bg-yellow-lightest:hover {
    background-color: #fcfbeb; }
  .md\:hover\:bg-green-darkest:hover {
    background-color: #0f2f21; }
  .md\:hover\:bg-green-darker:hover {
    background-color: #1a4731; }
  .md\:hover\:bg-green-dark:hover {
    background-color: #1f9d55; }
  .md\:hover\:bg-green:hover {
    background-color: #38c172; }
  .md\:hover\:bg-green-light:hover {
    background-color: #51d88a; }
  .md\:hover\:bg-green-lighter:hover {
    background-color: #a2f5bf; }
  .md\:hover\:bg-green-lightest:hover {
    background-color: #e3fcec; }
  .md\:hover\:bg-teal-darkest:hover {
    background-color: #0d3331; }
  .md\:hover\:bg-teal-darker:hover {
    background-color: #20504f; }
  .md\:hover\:bg-teal-dark:hover {
    background-color: #38a89d; }
  .md\:hover\:bg-teal:hover {
    background-color: #4dc0b5; }
  .md\:hover\:bg-teal-light:hover {
    background-color: #64d5ca; }
  .md\:hover\:bg-teal-lighter:hover {
    background-color: #a0f0ed; }
  .md\:hover\:bg-teal-lightest:hover {
    background-color: #e8fffe; }
  .md\:hover\:bg-blue-darkest:hover {
    background-color: #12283a; }
  .md\:hover\:bg-blue-darker:hover {
    background-color: #1c3d5a; }
  .md\:hover\:bg-blue-dark:hover {
    background-color: #2779bd; }
  .md\:hover\:bg-blue:hover {
    background-color: #3490dc; }
  .md\:hover\:bg-blue-light:hover {
    background-color: #6cb2eb; }
  .md\:hover\:bg-blue-lighter:hover {
    background-color: #bcdefa; }
  .md\:hover\:bg-blue-lightest:hover {
    background-color: #eff8ff; }
  .md\:hover\:bg-indigo-darkest:hover {
    background-color: #191e38; }
  .md\:hover\:bg-indigo-darker:hover {
    background-color: #2f365f; }
  .md\:hover\:bg-indigo-dark:hover {
    background-color: #5661b3; }
  .md\:hover\:bg-indigo:hover {
    background-color: #6574cd; }
  .md\:hover\:bg-indigo-light:hover {
    background-color: #7886d7; }
  .md\:hover\:bg-indigo-lighter:hover {
    background-color: #b2b7ff; }
  .md\:hover\:bg-indigo-lightest:hover {
    background-color: #e6e8ff; }
  .md\:hover\:bg-purple-darkest:hover {
    background-color: #21183c; }
  .md\:hover\:bg-purple-darker:hover {
    background-color: #382b5f; }
  .md\:hover\:bg-purple-dark:hover {
    background-color: #794acf; }
  .md\:hover\:bg-purple:hover {
    background-color: #9561e2; }
  .md\:hover\:bg-purple-light:hover {
    background-color: #a779e9; }
  .md\:hover\:bg-purple-lighter:hover {
    background-color: #d6bbfc; }
  .md\:hover\:bg-purple-lightest:hover {
    background-color: #f3ebff; }
  .md\:hover\:bg-pink-darkest:hover {
    background-color: #451225; }
  .md\:hover\:bg-pink-darker:hover {
    background-color: #6f213f; }
  .md\:hover\:bg-pink-dark:hover {
    background-color: #eb5286; }
  .md\:hover\:bg-pink:hover {
    background-color: #f66d9b; }
  .md\:hover\:bg-pink-light:hover {
    background-color: #fa7ea8; }
  .md\:hover\:bg-pink-lighter:hover {
    background-color: #ffbbca; }
  .md\:hover\:bg-pink-lightest:hover {
    background-color: #ffebef; }
  .md\:focus\:bg-transparent:focus {
    background-color: transparent; }
  .md\:focus\:bg-black:focus {
    background-color: #22292f; }
  .md\:focus\:bg-grey-darkest:focus {
    background-color: #3d4852; }
  .md\:focus\:bg-grey-darker:focus {
    background-color: #606f7b; }
  .md\:focus\:bg-grey-dark:focus {
    background-color: #8795a1; }
  .md\:focus\:bg-grey:focus {
    background-color: #b8c2cc; }
  .md\:focus\:bg-grey-light:focus {
    background-color: #dae1e7; }
  .md\:focus\:bg-grey-lighter:focus {
    background-color: #f1f5f8; }
  .md\:focus\:bg-grey-lightest:focus {
    background-color: #f8fafc; }
  .md\:focus\:bg-white:focus {
    background-color: #fff; }
  .md\:focus\:bg-red-darkest:focus {
    background-color: #3b0d0c; }
  .md\:focus\:bg-red-darker:focus {
    background-color: #621b18; }
  .md\:focus\:bg-red-dark:focus {
    background-color: #cc1f1a; }
  .md\:focus\:bg-red:focus {
    background-color: #e3342f; }
  .md\:focus\:bg-red-light:focus {
    background-color: #ef5753; }
  .md\:focus\:bg-red-lighter:focus {
    background-color: #f9acaa; }
  .md\:focus\:bg-red-lightest:focus {
    background-color: #fcebea; }
  .md\:focus\:bg-orange-darkest:focus {
    background-color: #462a16; }
  .md\:focus\:bg-orange-darker:focus {
    background-color: #613b1f; }
  .md\:focus\:bg-orange-dark:focus {
    background-color: #de751f; }
  .md\:focus\:bg-orange:focus {
    background-color: #f6993f; }
  .md\:focus\:bg-orange-light:focus {
    background-color: #faad63; }
  .md\:focus\:bg-orange-lighter:focus {
    background-color: #fcd9b6; }
  .md\:focus\:bg-orange-lightest:focus {
    background-color: #fff5eb; }
  .md\:focus\:bg-yellow-darkest:focus {
    background-color: #453411; }
  .md\:focus\:bg-yellow-darker:focus {
    background-color: #684f1d; }
  .md\:focus\:bg-yellow-dark:focus {
    background-color: #f2d024; }
  .md\:focus\:bg-yellow:focus {
    background-color: #ffed4a; }
  .md\:focus\:bg-yellow-light:focus {
    background-color: #fff382; }
  .md\:focus\:bg-yellow-lighter:focus {
    background-color: #fff9c2; }
  .md\:focus\:bg-yellow-lightest:focus {
    background-color: #fcfbeb; }
  .md\:focus\:bg-green-darkest:focus {
    background-color: #0f2f21; }
  .md\:focus\:bg-green-darker:focus {
    background-color: #1a4731; }
  .md\:focus\:bg-green-dark:focus {
    background-color: #1f9d55; }
  .md\:focus\:bg-green:focus {
    background-color: #38c172; }
  .md\:focus\:bg-green-light:focus {
    background-color: #51d88a; }
  .md\:focus\:bg-green-lighter:focus {
    background-color: #a2f5bf; }
  .md\:focus\:bg-green-lightest:focus {
    background-color: #e3fcec; }
  .md\:focus\:bg-teal-darkest:focus {
    background-color: #0d3331; }
  .md\:focus\:bg-teal-darker:focus {
    background-color: #20504f; }
  .md\:focus\:bg-teal-dark:focus {
    background-color: #38a89d; }
  .md\:focus\:bg-teal:focus {
    background-color: #4dc0b5; }
  .md\:focus\:bg-teal-light:focus {
    background-color: #64d5ca; }
  .md\:focus\:bg-teal-lighter:focus {
    background-color: #a0f0ed; }
  .md\:focus\:bg-teal-lightest:focus {
    background-color: #e8fffe; }
  .md\:focus\:bg-blue-darkest:focus {
    background-color: #12283a; }
  .md\:focus\:bg-blue-darker:focus {
    background-color: #1c3d5a; }
  .md\:focus\:bg-blue-dark:focus {
    background-color: #2779bd; }
  .md\:focus\:bg-blue:focus {
    background-color: #3490dc; }
  .md\:focus\:bg-blue-light:focus {
    background-color: #6cb2eb; }
  .md\:focus\:bg-blue-lighter:focus {
    background-color: #bcdefa; }
  .md\:focus\:bg-blue-lightest:focus {
    background-color: #eff8ff; }
  .md\:focus\:bg-indigo-darkest:focus {
    background-color: #191e38; }
  .md\:focus\:bg-indigo-darker:focus {
    background-color: #2f365f; }
  .md\:focus\:bg-indigo-dark:focus {
    background-color: #5661b3; }
  .md\:focus\:bg-indigo:focus {
    background-color: #6574cd; }
  .md\:focus\:bg-indigo-light:focus {
    background-color: #7886d7; }
  .md\:focus\:bg-indigo-lighter:focus {
    background-color: #b2b7ff; }
  .md\:focus\:bg-indigo-lightest:focus {
    background-color: #e6e8ff; }
  .md\:focus\:bg-purple-darkest:focus {
    background-color: #21183c; }
  .md\:focus\:bg-purple-darker:focus {
    background-color: #382b5f; }
  .md\:focus\:bg-purple-dark:focus {
    background-color: #794acf; }
  .md\:focus\:bg-purple:focus {
    background-color: #9561e2; }
  .md\:focus\:bg-purple-light:focus {
    background-color: #a779e9; }
  .md\:focus\:bg-purple-lighter:focus {
    background-color: #d6bbfc; }
  .md\:focus\:bg-purple-lightest:focus {
    background-color: #f3ebff; }
  .md\:focus\:bg-pink-darkest:focus {
    background-color: #451225; }
  .md\:focus\:bg-pink-darker:focus {
    background-color: #6f213f; }
  .md\:focus\:bg-pink-dark:focus {
    background-color: #eb5286; }
  .md\:focus\:bg-pink:focus {
    background-color: #f66d9b; }
  .md\:focus\:bg-pink-light:focus {
    background-color: #fa7ea8; }
  .md\:focus\:bg-pink-lighter:focus {
    background-color: #ffbbca; }
  .md\:focus\:bg-pink-lightest:focus {
    background-color: #ffebef; }
  .md\:bg-bottom {
    background-position: bottom; }
  .md\:bg-center {
    background-position: center; }
  .md\:bg-left {
    background-position: left; }
  .md\:bg-left-bottom {
    background-position: left bottom; }
  .md\:bg-left-top {
    background-position: left top; }
  .md\:bg-right {
    background-position: right; }
  .md\:bg-right-bottom {
    background-position: right bottom; }
  .md\:bg-right-top {
    background-position: right top; }
  .md\:bg-top {
    background-position: top; }
  .md\:bg-repeat {
    background-repeat: repeat; }
  .md\:bg-no-repeat {
    background-repeat: no-repeat; }
  .md\:bg-repeat-x {
    background-repeat: repeat-x; }
  .md\:bg-repeat-y {
    background-repeat: repeat-y; }
  .md\:bg-auto {
    background-size: auto; }
  .md\:bg-cover {
    background-size: cover; }
  .md\:bg-contain {
    background-size: contain; }
  .md\:border-transparent {
    border-color: transparent; }
  .md\:border-black {
    border-color: #22292f; }
  .md\:border-grey-darkest {
    border-color: #3d4852; }
  .md\:border-grey-darker {
    border-color: #606f7b; }
  .md\:border-grey-dark {
    border-color: #8795a1; }
  .md\:border-grey {
    border-color: #b8c2cc; }
  .md\:border-grey-light {
    border-color: #dae1e7; }
  .md\:border-grey-lighter {
    border-color: #f1f5f8; }
  .md\:border-grey-lightest {
    border-color: #f8fafc; }
  .md\:border-white {
    border-color: #fff; }
  .md\:border-red-darkest {
    border-color: #3b0d0c; }
  .md\:border-red-darker {
    border-color: #621b18; }
  .md\:border-red-dark {
    border-color: #cc1f1a; }
  .md\:border-red {
    border-color: #e3342f; }
  .md\:border-red-light {
    border-color: #ef5753; }
  .md\:border-red-lighter {
    border-color: #f9acaa; }
  .md\:border-red-lightest {
    border-color: #fcebea; }
  .md\:border-orange-darkest {
    border-color: #462a16; }
  .md\:border-orange-darker {
    border-color: #613b1f; }
  .md\:border-orange-dark {
    border-color: #de751f; }
  .md\:border-orange {
    border-color: #f6993f; }
  .md\:border-orange-light {
    border-color: #faad63; }
  .md\:border-orange-lighter {
    border-color: #fcd9b6; }
  .md\:border-orange-lightest {
    border-color: #fff5eb; }
  .md\:border-yellow-darkest {
    border-color: #453411; }
  .md\:border-yellow-darker {
    border-color: #684f1d; }
  .md\:border-yellow-dark {
    border-color: #f2d024; }
  .md\:border-yellow {
    border-color: #ffed4a; }
  .md\:border-yellow-light {
    border-color: #fff382; }
  .md\:border-yellow-lighter {
    border-color: #fff9c2; }
  .md\:border-yellow-lightest {
    border-color: #fcfbeb; }
  .md\:border-green-darkest {
    border-color: #0f2f21; }
  .md\:border-green-darker {
    border-color: #1a4731; }
  .md\:border-green-dark {
    border-color: #1f9d55; }
  .md\:border-green {
    border-color: #38c172; }
  .md\:border-green-light {
    border-color: #51d88a; }
  .md\:border-green-lighter {
    border-color: #a2f5bf; }
  .md\:border-green-lightest {
    border-color: #e3fcec; }
  .md\:border-teal-darkest {
    border-color: #0d3331; }
  .md\:border-teal-darker {
    border-color: #20504f; }
  .md\:border-teal-dark {
    border-color: #38a89d; }
  .md\:border-teal {
    border-color: #4dc0b5; }
  .md\:border-teal-light {
    border-color: #64d5ca; }
  .md\:border-teal-lighter {
    border-color: #a0f0ed; }
  .md\:border-teal-lightest {
    border-color: #e8fffe; }
  .md\:border-blue-darkest {
    border-color: #12283a; }
  .md\:border-blue-darker {
    border-color: #1c3d5a; }
  .md\:border-blue-dark {
    border-color: #2779bd; }
  .md\:border-blue {
    border-color: #3490dc; }
  .md\:border-blue-light {
    border-color: #6cb2eb; }
  .md\:border-blue-lighter {
    border-color: #bcdefa; }
  .md\:border-blue-lightest {
    border-color: #eff8ff; }
  .md\:border-indigo-darkest {
    border-color: #191e38; }
  .md\:border-indigo-darker {
    border-color: #2f365f; }
  .md\:border-indigo-dark {
    border-color: #5661b3; }
  .md\:border-indigo {
    border-color: #6574cd; }
  .md\:border-indigo-light {
    border-color: #7886d7; }
  .md\:border-indigo-lighter {
    border-color: #b2b7ff; }
  .md\:border-indigo-lightest {
    border-color: #e6e8ff; }
  .md\:border-purple-darkest {
    border-color: #21183c; }
  .md\:border-purple-darker {
    border-color: #382b5f; }
  .md\:border-purple-dark {
    border-color: #794acf; }
  .md\:border-purple {
    border-color: #9561e2; }
  .md\:border-purple-light {
    border-color: #a779e9; }
  .md\:border-purple-lighter {
    border-color: #d6bbfc; }
  .md\:border-purple-lightest {
    border-color: #f3ebff; }
  .md\:border-pink-darkest {
    border-color: #451225; }
  .md\:border-pink-darker {
    border-color: #6f213f; }
  .md\:border-pink-dark {
    border-color: #eb5286; }
  .md\:border-pink {
    border-color: #f66d9b; }
  .md\:border-pink-light {
    border-color: #fa7ea8; }
  .md\:border-pink-lighter {
    border-color: #ffbbca; }
  .md\:border-pink-lightest {
    border-color: #ffebef; }
  .md\:hover\:border-transparent:hover {
    border-color: transparent; }
  .md\:hover\:border-black:hover {
    border-color: #22292f; }
  .md\:hover\:border-grey-darkest:hover {
    border-color: #3d4852; }
  .md\:hover\:border-grey-darker:hover {
    border-color: #606f7b; }
  .md\:hover\:border-grey-dark:hover {
    border-color: #8795a1; }
  .md\:hover\:border-grey:hover {
    border-color: #b8c2cc; }
  .md\:hover\:border-grey-light:hover {
    border-color: #dae1e7; }
  .md\:hover\:border-grey-lighter:hover {
    border-color: #f1f5f8; }
  .md\:hover\:border-grey-lightest:hover {
    border-color: #f8fafc; }
  .md\:hover\:border-white:hover {
    border-color: #fff; }
  .md\:hover\:border-red-darkest:hover {
    border-color: #3b0d0c; }
  .md\:hover\:border-red-darker:hover {
    border-color: #621b18; }
  .md\:hover\:border-red-dark:hover {
    border-color: #cc1f1a; }
  .md\:hover\:border-red:hover {
    border-color: #e3342f; }
  .md\:hover\:border-red-light:hover {
    border-color: #ef5753; }
  .md\:hover\:border-red-lighter:hover {
    border-color: #f9acaa; }
  .md\:hover\:border-red-lightest:hover {
    border-color: #fcebea; }
  .md\:hover\:border-orange-darkest:hover {
    border-color: #462a16; }
  .md\:hover\:border-orange-darker:hover {
    border-color: #613b1f; }
  .md\:hover\:border-orange-dark:hover {
    border-color: #de751f; }
  .md\:hover\:border-orange:hover {
    border-color: #f6993f; }
  .md\:hover\:border-orange-light:hover {
    border-color: #faad63; }
  .md\:hover\:border-orange-lighter:hover {
    border-color: #fcd9b6; }
  .md\:hover\:border-orange-lightest:hover {
    border-color: #fff5eb; }
  .md\:hover\:border-yellow-darkest:hover {
    border-color: #453411; }
  .md\:hover\:border-yellow-darker:hover {
    border-color: #684f1d; }
  .md\:hover\:border-yellow-dark:hover {
    border-color: #f2d024; }
  .md\:hover\:border-yellow:hover {
    border-color: #ffed4a; }
  .md\:hover\:border-yellow-light:hover {
    border-color: #fff382; }
  .md\:hover\:border-yellow-lighter:hover {
    border-color: #fff9c2; }
  .md\:hover\:border-yellow-lightest:hover {
    border-color: #fcfbeb; }
  .md\:hover\:border-green-darkest:hover {
    border-color: #0f2f21; }
  .md\:hover\:border-green-darker:hover {
    border-color: #1a4731; }
  .md\:hover\:border-green-dark:hover {
    border-color: #1f9d55; }
  .md\:hover\:border-green:hover {
    border-color: #38c172; }
  .md\:hover\:border-green-light:hover {
    border-color: #51d88a; }
  .md\:hover\:border-green-lighter:hover {
    border-color: #a2f5bf; }
  .md\:hover\:border-green-lightest:hover {
    border-color: #e3fcec; }
  .md\:hover\:border-teal-darkest:hover {
    border-color: #0d3331; }
  .md\:hover\:border-teal-darker:hover {
    border-color: #20504f; }
  .md\:hover\:border-teal-dark:hover {
    border-color: #38a89d; }
  .md\:hover\:border-teal:hover {
    border-color: #4dc0b5; }
  .md\:hover\:border-teal-light:hover {
    border-color: #64d5ca; }
  .md\:hover\:border-teal-lighter:hover {
    border-color: #a0f0ed; }
  .md\:hover\:border-teal-lightest:hover {
    border-color: #e8fffe; }
  .md\:hover\:border-blue-darkest:hover {
    border-color: #12283a; }
  .md\:hover\:border-blue-darker:hover {
    border-color: #1c3d5a; }
  .md\:hover\:border-blue-dark:hover {
    border-color: #2779bd; }
  .md\:hover\:border-blue:hover {
    border-color: #3490dc; }
  .md\:hover\:border-blue-light:hover {
    border-color: #6cb2eb; }
  .md\:hover\:border-blue-lighter:hover {
    border-color: #bcdefa; }
  .md\:hover\:border-blue-lightest:hover {
    border-color: #eff8ff; }
  .md\:hover\:border-indigo-darkest:hover {
    border-color: #191e38; }
  .md\:hover\:border-indigo-darker:hover {
    border-color: #2f365f; }
  .md\:hover\:border-indigo-dark:hover {
    border-color: #5661b3; }
  .md\:hover\:border-indigo:hover {
    border-color: #6574cd; }
  .md\:hover\:border-indigo-light:hover {
    border-color: #7886d7; }
  .md\:hover\:border-indigo-lighter:hover {
    border-color: #b2b7ff; }
  .md\:hover\:border-indigo-lightest:hover {
    border-color: #e6e8ff; }
  .md\:hover\:border-purple-darkest:hover {
    border-color: #21183c; }
  .md\:hover\:border-purple-darker:hover {
    border-color: #382b5f; }
  .md\:hover\:border-purple-dark:hover {
    border-color: #794acf; }
  .md\:hover\:border-purple:hover {
    border-color: #9561e2; }
  .md\:hover\:border-purple-light:hover {
    border-color: #a779e9; }
  .md\:hover\:border-purple-lighter:hover {
    border-color: #d6bbfc; }
  .md\:hover\:border-purple-lightest:hover {
    border-color: #f3ebff; }
  .md\:hover\:border-pink-darkest:hover {
    border-color: #451225; }
  .md\:hover\:border-pink-darker:hover {
    border-color: #6f213f; }
  .md\:hover\:border-pink-dark:hover {
    border-color: #eb5286; }
  .md\:hover\:border-pink:hover {
    border-color: #f66d9b; }
  .md\:hover\:border-pink-light:hover {
    border-color: #fa7ea8; }
  .md\:hover\:border-pink-lighter:hover {
    border-color: #ffbbca; }
  .md\:hover\:border-pink-lightest:hover {
    border-color: #ffebef; }
  .md\:focus\:border-transparent:focus {
    border-color: transparent; }
  .md\:focus\:border-black:focus {
    border-color: #22292f; }
  .md\:focus\:border-grey-darkest:focus {
    border-color: #3d4852; }
  .md\:focus\:border-grey-darker:focus {
    border-color: #606f7b; }
  .md\:focus\:border-grey-dark:focus {
    border-color: #8795a1; }
  .md\:focus\:border-grey:focus {
    border-color: #b8c2cc; }
  .md\:focus\:border-grey-light:focus {
    border-color: #dae1e7; }
  .md\:focus\:border-grey-lighter:focus {
    border-color: #f1f5f8; }
  .md\:focus\:border-grey-lightest:focus {
    border-color: #f8fafc; }
  .md\:focus\:border-white:focus {
    border-color: #fff; }
  .md\:focus\:border-red-darkest:focus {
    border-color: #3b0d0c; }
  .md\:focus\:border-red-darker:focus {
    border-color: #621b18; }
  .md\:focus\:border-red-dark:focus {
    border-color: #cc1f1a; }
  .md\:focus\:border-red:focus {
    border-color: #e3342f; }
  .md\:focus\:border-red-light:focus {
    border-color: #ef5753; }
  .md\:focus\:border-red-lighter:focus {
    border-color: #f9acaa; }
  .md\:focus\:border-red-lightest:focus {
    border-color: #fcebea; }
  .md\:focus\:border-orange-darkest:focus {
    border-color: #462a16; }
  .md\:focus\:border-orange-darker:focus {
    border-color: #613b1f; }
  .md\:focus\:border-orange-dark:focus {
    border-color: #de751f; }
  .md\:focus\:border-orange:focus {
    border-color: #f6993f; }
  .md\:focus\:border-orange-light:focus {
    border-color: #faad63; }
  .md\:focus\:border-orange-lighter:focus {
    border-color: #fcd9b6; }
  .md\:focus\:border-orange-lightest:focus {
    border-color: #fff5eb; }
  .md\:focus\:border-yellow-darkest:focus {
    border-color: #453411; }
  .md\:focus\:border-yellow-darker:focus {
    border-color: #684f1d; }
  .md\:focus\:border-yellow-dark:focus {
    border-color: #f2d024; }
  .md\:focus\:border-yellow:focus {
    border-color: #ffed4a; }
  .md\:focus\:border-yellow-light:focus {
    border-color: #fff382; }
  .md\:focus\:border-yellow-lighter:focus {
    border-color: #fff9c2; }
  .md\:focus\:border-yellow-lightest:focus {
    border-color: #fcfbeb; }
  .md\:focus\:border-green-darkest:focus {
    border-color: #0f2f21; }
  .md\:focus\:border-green-darker:focus {
    border-color: #1a4731; }
  .md\:focus\:border-green-dark:focus {
    border-color: #1f9d55; }
  .md\:focus\:border-green:focus {
    border-color: #38c172; }
  .md\:focus\:border-green-light:focus {
    border-color: #51d88a; }
  .md\:focus\:border-green-lighter:focus {
    border-color: #a2f5bf; }
  .md\:focus\:border-green-lightest:focus {
    border-color: #e3fcec; }
  .md\:focus\:border-teal-darkest:focus {
    border-color: #0d3331; }
  .md\:focus\:border-teal-darker:focus {
    border-color: #20504f; }
  .md\:focus\:border-teal-dark:focus {
    border-color: #38a89d; }
  .md\:focus\:border-teal:focus {
    border-color: #4dc0b5; }
  .md\:focus\:border-teal-light:focus {
    border-color: #64d5ca; }
  .md\:focus\:border-teal-lighter:focus {
    border-color: #a0f0ed; }
  .md\:focus\:border-teal-lightest:focus {
    border-color: #e8fffe; }
  .md\:focus\:border-blue-darkest:focus {
    border-color: #12283a; }
  .md\:focus\:border-blue-darker:focus {
    border-color: #1c3d5a; }
  .md\:focus\:border-blue-dark:focus {
    border-color: #2779bd; }
  .md\:focus\:border-blue:focus {
    border-color: #3490dc; }
  .md\:focus\:border-blue-light:focus {
    border-color: #6cb2eb; }
  .md\:focus\:border-blue-lighter:focus {
    border-color: #bcdefa; }
  .md\:focus\:border-blue-lightest:focus {
    border-color: #eff8ff; }
  .md\:focus\:border-indigo-darkest:focus {
    border-color: #191e38; }
  .md\:focus\:border-indigo-darker:focus {
    border-color: #2f365f; }
  .md\:focus\:border-indigo-dark:focus {
    border-color: #5661b3; }
  .md\:focus\:border-indigo:focus {
    border-color: #6574cd; }
  .md\:focus\:border-indigo-light:focus {
    border-color: #7886d7; }
  .md\:focus\:border-indigo-lighter:focus {
    border-color: #b2b7ff; }
  .md\:focus\:border-indigo-lightest:focus {
    border-color: #e6e8ff; }
  .md\:focus\:border-purple-darkest:focus {
    border-color: #21183c; }
  .md\:focus\:border-purple-darker:focus {
    border-color: #382b5f; }
  .md\:focus\:border-purple-dark:focus {
    border-color: #794acf; }
  .md\:focus\:border-purple:focus {
    border-color: #9561e2; }
  .md\:focus\:border-purple-light:focus {
    border-color: #a779e9; }
  .md\:focus\:border-purple-lighter:focus {
    border-color: #d6bbfc; }
  .md\:focus\:border-purple-lightest:focus {
    border-color: #f3ebff; }
  .md\:focus\:border-pink-darkest:focus {
    border-color: #451225; }
  .md\:focus\:border-pink-darker:focus {
    border-color: #6f213f; }
  .md\:focus\:border-pink-dark:focus {
    border-color: #eb5286; }
  .md\:focus\:border-pink:focus {
    border-color: #f66d9b; }
  .md\:focus\:border-pink-light:focus {
    border-color: #fa7ea8; }
  .md\:focus\:border-pink-lighter:focus {
    border-color: #ffbbca; }
  .md\:focus\:border-pink-lightest:focus {
    border-color: #ffebef; }
  .md\:rounded-none {
    border-radius: 0; }
  .md\:rounded-sm {
    border-radius: .125rem; }
  .md\:rounded {
    border-radius: .25rem; }
  .md\:rounded-lg {
    border-radius: .5rem; }
  .md\:rounded-full {
    border-radius: 9999px; }
  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .md\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem; }
  .md\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem; }
  .md\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .md\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .md\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .md\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem; }
  .md\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .md\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .md\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }
  .md\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem; }
  .md\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px; }
  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px; }
  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .md\:rounded-tl-none {
    border-top-left-radius: 0; }
  .md\:rounded-tr-none {
    border-top-right-radius: 0; }
  .md\:rounded-br-none {
    border-bottom-right-radius: 0; }
  .md\:rounded-bl-none {
    border-bottom-left-radius: 0; }
  .md\:rounded-tl-sm {
    border-top-left-radius: .125rem; }
  .md\:rounded-tr-sm {
    border-top-right-radius: .125rem; }
  .md\:rounded-br-sm {
    border-bottom-right-radius: .125rem; }
  .md\:rounded-bl-sm {
    border-bottom-left-radius: .125rem; }
  .md\:rounded-tl {
    border-top-left-radius: .25rem; }
  .md\:rounded-tr {
    border-top-right-radius: .25rem; }
  .md\:rounded-br {
    border-bottom-right-radius: .25rem; }
  .md\:rounded-bl {
    border-bottom-left-radius: .25rem; }
  .md\:rounded-tl-lg {
    border-top-left-radius: .5rem; }
  .md\:rounded-tr-lg {
    border-top-right-radius: .5rem; }
  .md\:rounded-br-lg {
    border-bottom-right-radius: .5rem; }
  .md\:rounded-bl-lg {
    border-bottom-left-radius: .5rem; }
  .md\:rounded-tl-full {
    border-top-left-radius: 9999px; }
  .md\:rounded-tr-full {
    border-top-right-radius: 9999px; }
  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px; }
  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px; }
  .md\:border-solid {
    border-style: solid; }
  .md\:border-dashed {
    border-style: dashed; }
  .md\:border-dotted {
    border-style: dotted; }
  .md\:border-none {
    border-style: none; }
  .md\:border-0 {
    border-width: 0; }
  .md\:border-2 {
    border-width: 2px; }
  .md\:border-4 {
    border-width: 4px; }
  .md\:border-8 {
    border-width: 8px; }
  .md\:border {
    border-width: 1px; }
  .md\:border-t-0 {
    border-top-width: 0; }
  .md\:border-r-0 {
    border-right-width: 0; }
  .md\:border-b-0 {
    border-bottom-width: 0; }
  .md\:border-l-0 {
    border-left-width: 0; }
  .md\:border-t-2 {
    border-top-width: 2px; }
  .md\:border-r-2 {
    border-right-width: 2px; }
  .md\:border-b-2 {
    border-bottom-width: 2px; }
  .md\:border-l-2 {
    border-left-width: 2px; }
  .md\:border-t-4 {
    border-top-width: 4px; }
  .md\:border-r-4 {
    border-right-width: 4px; }
  .md\:border-b-4 {
    border-bottom-width: 4px; }
  .md\:border-l-4 {
    border-left-width: 4px; }
  .md\:border-t-8 {
    border-top-width: 8px; }
  .md\:border-r-8 {
    border-right-width: 8px; }
  .md\:border-b-8 {
    border-bottom-width: 8px; }
  .md\:border-l-8 {
    border-left-width: 8px; }
  .md\:border-t {
    border-top-width: 1px; }
  .md\:border-r {
    border-right-width: 1px; }
  .md\:border-b {
    border-bottom-width: 1px; }
  .md\:border-l {
    border-left-width: 1px; }
  .md\:cursor-auto {
    cursor: auto; }
  .md\:cursor-default {
    cursor: default; }
  .md\:cursor-pointer {
    cursor: pointer; }
  .md\:cursor-wait {
    cursor: wait; }
  .md\:cursor-move {
    cursor: move; }
  .md\:cursor-not-allowed {
    cursor: not-allowed; }
  .md\:block {
    display: block; }
  .md\:inline-block {
    display: inline-block; }
  .md\:inline {
    display: inline; }
  .md\:table {
    display: table; }
  .md\:table-row {
    display: table-row; }
  .md\:table-cell {
    display: table-cell; }
  .md\:hidden {
    display: none; }
  .md\:flex {
    display: flex; }
  .md\:inline-flex {
    display: inline-flex; }
  .md\:flex-row {
    flex-direction: row; }
  .md\:flex-row-reverse {
    flex-direction: row-reverse; }
  .md\:flex-col {
    flex-direction: column; }
  .md\:flex-col-reverse {
    flex-direction: column-reverse; }
  .md\:flex-wrap {
    flex-wrap: wrap; }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse; }
  .md\:flex-no-wrap {
    flex-wrap: nowrap; }
  .md\:items-start {
    align-items: flex-start; }
  .md\:items-end {
    align-items: flex-end; }
  .md\:items-center {
    align-items: center; }
  .md\:items-baseline {
    align-items: baseline; }
  .md\:items-stretch {
    align-items: stretch; }
  .md\:self-auto {
    align-self: auto; }
  .md\:self-start {
    align-self: flex-start; }
  .md\:self-end {
    align-self: flex-end; }
  .md\:self-center {
    align-self: center; }
  .md\:self-stretch {
    align-self: stretch; }
  .md\:justify-start {
    justify-content: flex-start; }
  .md\:justify-end {
    justify-content: flex-end; }
  .md\:justify-center {
    justify-content: center; }
  .md\:justify-between {
    justify-content: space-between; }
  .md\:justify-around {
    justify-content: space-around; }
  .md\:content-center {
    align-content: center; }
  .md\:content-start {
    align-content: flex-start; }
  .md\:content-end {
    align-content: flex-end; }
  .md\:content-between {
    align-content: space-between; }
  .md\:content-around {
    align-content: space-around; }
  .md\:flex-1 {
    flex: 1 1 0%; }
  .md\:flex-auto {
    flex: 1 1 auto; }
  .md\:flex-initial {
    flex: 0 1 auto; }
  .md\:flex-none {
    flex: none; }
  .md\:flex-grow {
    flex-grow: 1; }
  .md\:flex-shrink {
    flex-shrink: 1; }
  .md\:flex-no-grow {
    flex-grow: 0; }
  .md\:flex-no-shrink {
    flex-shrink: 0; }
  .md\:float-right {
    float: right; }
  .md\:float-left {
    float: left; }
  .md\:float-none {
    float: none; }
  .md\:clearfix:after {
    content: "";
    display: table;
    clear: both; }
  .md\:font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
  .md\:font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif; }
  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }
  .md\:font-hairline {
    font-weight: 100; }
  .md\:font-thin {
    font-weight: 200; }
  .md\:font-light {
    font-weight: 300; }
  .md\:font-normal {
    font-weight: 400; }
  .md\:font-medium {
    font-weight: 500; }
  .md\:font-semibold {
    font-weight: 600; }
  .md\:font-bold {
    font-weight: 700; }
  .md\:font-extrabold {
    font-weight: 800; }
  .md\:font-black {
    font-weight: 900; }
  .md\:hover\:font-hairline:hover {
    font-weight: 100; }
  .md\:hover\:font-thin:hover {
    font-weight: 200; }
  .md\:hover\:font-light:hover {
    font-weight: 300; }
  .md\:hover\:font-normal:hover {
    font-weight: 400; }
  .md\:hover\:font-medium:hover {
    font-weight: 500; }
  .md\:hover\:font-semibold:hover {
    font-weight: 600; }
  .md\:hover\:font-bold:hover {
    font-weight: 700; }
  .md\:hover\:font-extrabold:hover {
    font-weight: 800; }
  .md\:hover\:font-black:hover {
    font-weight: 900; }
  .md\:focus\:font-hairline:focus {
    font-weight: 100; }
  .md\:focus\:font-thin:focus {
    font-weight: 200; }
  .md\:focus\:font-light:focus {
    font-weight: 300; }
  .md\:focus\:font-normal:focus {
    font-weight: 400; }
  .md\:focus\:font-medium:focus {
    font-weight: 500; }
  .md\:focus\:font-semibold:focus {
    font-weight: 600; }
  .md\:focus\:font-bold:focus {
    font-weight: 700; }
  .md\:focus\:font-extrabold:focus {
    font-weight: 800; }
  .md\:focus\:font-black:focus {
    font-weight: 900; }
  .md\:h-1 {
    height: .25rem; }
  .md\:h-2 {
    height: .5rem; }
  .md\:h-3 {
    height: .75rem; }
  .md\:h-4 {
    height: 1rem; }
  .md\:h-5 {
    height: 1.25rem; }
  .md\:h-6 {
    height: 1.5rem; }
  .md\:h-8 {
    height: 2rem; }
  .md\:h-10 {
    height: 2.5rem; }
  .md\:h-12 {
    height: 3rem; }
  .md\:h-16 {
    height: 4rem; }
  .md\:h-24 {
    height: 6rem; }
  .md\:h-32 {
    height: 8rem; }
  .md\:h-48 {
    height: 12rem; }
  .md\:h-64 {
    height: 16rem; }
  .md\:h-auto {
    height: auto; }
  .md\:h-px {
    height: 1px; }
  .md\:h-full {
    height: 100%; }
  .md\:h-screen {
    height: 100vh; }
  .md\:leading-none {
    line-height: 1; }
  .md\:leading-tight {
    line-height: 1.25; }
  .md\:leading-normal {
    line-height: 1.5; }
  .md\:leading-loose {
    line-height: 2; }
  .md\:m-0 {
    margin: 0; }
  .md\:m-1 {
    margin: .25rem; }
  .md\:m-2 {
    margin: .5rem; }
  .md\:m-3 {
    margin: .75rem; }
  .md\:m-4 {
    margin: 1rem; }
  .md\:m-5 {
    margin: 1.25rem; }
  .md\:m-6 {
    margin: 1.5rem; }
  .md\:m-8 {
    margin: 2rem; }
  .md\:m-10 {
    margin: 2.5rem; }
  .md\:m-12 {
    margin: 3rem; }
  .md\:m-16 {
    margin: 4rem; }
  .md\:m-20 {
    margin: 5rem; }
  .md\:m-24 {
    margin: 6rem; }
  .md\:m-32 {
    margin: 8rem; }
  .md\:m-auto {
    margin: auto; }
  .md\:m-px {
    margin: 1px; }
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .md\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem; }
  .md\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem; }
  .md\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem; }
  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem; }
  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem; }
  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px; }
  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px; }
  .md\:mt-0 {
    margin-top: 0; }
  .md\:mr-0 {
    margin-right: 0; }
  .md\:mb-0 {
    margin-bottom: 0; }
  .md\:ml-0 {
    margin-left: 0; }
  .md\:mt-1 {
    margin-top: .25rem; }
  .md\:mr-1 {
    margin-right: .25rem; }
  .md\:mb-1 {
    margin-bottom: .25rem; }
  .md\:ml-1 {
    margin-left: .25rem; }
  .md\:mt-2 {
    margin-top: .5rem; }
  .md\:mr-2 {
    margin-right: .5rem; }
  .md\:mb-2 {
    margin-bottom: .5rem; }
  .md\:ml-2 {
    margin-left: .5rem; }
  .md\:mt-3 {
    margin-top: .75rem; }
  .md\:mr-3 {
    margin-right: .75rem; }
  .md\:mb-3 {
    margin-bottom: .75rem; }
  .md\:ml-3 {
    margin-left: .75rem; }
  .md\:mt-4 {
    margin-top: 1rem; }
  .md\:mr-4 {
    margin-right: 1rem; }
  .md\:mb-4 {
    margin-bottom: 1rem; }
  .md\:ml-4 {
    margin-left: 1rem; }
  .md\:mt-5 {
    margin-top: 1.25rem; }
  .md\:mr-5 {
    margin-right: 1.25rem; }
  .md\:mb-5 {
    margin-bottom: 1.25rem; }
  .md\:ml-5 {
    margin-left: 1.25rem; }
  .md\:mt-6 {
    margin-top: 1.5rem; }
  .md\:mr-6 {
    margin-right: 1.5rem; }
  .md\:mb-6 {
    margin-bottom: 1.5rem; }
  .md\:ml-6 {
    margin-left: 1.5rem; }
  .md\:mt-8 {
    margin-top: 2rem; }
  .md\:mr-8 {
    margin-right: 2rem; }
  .md\:mb-8 {
    margin-bottom: 2rem; }
  .md\:ml-8 {
    margin-left: 2rem; }
  .md\:mt-10 {
    margin-top: 2.5rem; }
  .md\:mr-10 {
    margin-right: 2.5rem; }
  .md\:mb-10 {
    margin-bottom: 2.5rem; }
  .md\:ml-10 {
    margin-left: 2.5rem; }
  .md\:mt-12 {
    margin-top: 3rem; }
  .md\:mr-12 {
    margin-right: 3rem; }
  .md\:mb-12 {
    margin-bottom: 3rem; }
  .md\:ml-12 {
    margin-left: 3rem; }
  .md\:mt-16 {
    margin-top: 4rem; }
  .md\:mr-16 {
    margin-right: 4rem; }
  .md\:mb-16 {
    margin-bottom: 4rem; }
  .md\:ml-16 {
    margin-left: 4rem; }
  .md\:mt-20 {
    margin-top: 5rem; }
  .md\:mr-20 {
    margin-right: 5rem; }
  .md\:mb-20 {
    margin-bottom: 5rem; }
  .md\:ml-20 {
    margin-left: 5rem; }
  .md\:mt-24 {
    margin-top: 6rem; }
  .md\:mr-24 {
    margin-right: 6rem; }
  .md\:mb-24 {
    margin-bottom: 6rem; }
  .md\:ml-24 {
    margin-left: 6rem; }
  .md\:mt-32 {
    margin-top: 8rem; }
  .md\:mr-32 {
    margin-right: 8rem; }
  .md\:mb-32 {
    margin-bottom: 8rem; }
  .md\:ml-32 {
    margin-left: 8rem; }
  .md\:mt-auto {
    margin-top: auto; }
  .md\:mr-auto {
    margin-right: auto; }
  .md\:mb-auto {
    margin-bottom: auto; }
  .md\:ml-auto {
    margin-left: auto; }
  .md\:mt-px {
    margin-top: 1px; }
  .md\:mr-px {
    margin-right: 1px; }
  .md\:mb-px {
    margin-bottom: 1px; }
  .md\:ml-px {
    margin-left: 1px; }
  .md\:max-h-full {
    max-height: 100%; }
  .md\:max-h-screen {
    max-height: 100vh; }
  .md\:max-w-xs {
    max-width: 20rem; }
  .md\:max-w-sm {
    max-width: 30rem; }
  .md\:max-w-md {
    max-width: 40rem; }
  .md\:max-w-lg {
    max-width: 50rem; }
  .md\:max-w-xl {
    max-width: 60rem; }
  .md\:max-w-2xl {
    max-width: 70rem; }
  .md\:max-w-3xl {
    max-width: 80rem; }
  .md\:max-w-4xl {
    max-width: 90rem; }
  .md\:max-w-5xl {
    max-width: 100rem; }
  .md\:max-w-full {
    max-width: 100%; }
  .md\:min-h-0 {
    min-height: 0; }
  .md\:min-h-full {
    min-height: 100%; }
  .md\:min-h-screen {
    min-height: 100vh; }
  .md\:min-w-0 {
    min-width: 0; }
  .md\:min-w-full {
    min-width: 100%; }
  .md\:-m-0 {
    margin: 0; }
  .md\:-m-1 {
    margin: -0.25rem; }
  .md\:-m-2 {
    margin: -0.5rem; }
  .md\:-m-3 {
    margin: -0.75rem; }
  .md\:-m-4 {
    margin: -1rem; }
  .md\:-m-5 {
    margin: -1.25rem; }
  .md\:-m-6 {
    margin: -1.5rem; }
  .md\:-m-8 {
    margin: -2rem; }
  .md\:-m-10 {
    margin: -2.5rem; }
  .md\:-m-12 {
    margin: -3rem; }
  .md\:-m-16 {
    margin: -4rem; }
  .md\:-m-20 {
    margin: -5rem; }
  .md\:-m-24 {
    margin: -6rem; }
  .md\:-m-32 {
    margin: -8rem; }
  .md\:-m-px {
    margin: -1px; }
  .md\:-my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .md\:-mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem; }
  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem; }
  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem; }
  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem; }
  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem; }
  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem; }
  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem; }
  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem; }
  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem; }
  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem; }
  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem; }
  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem; }
  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem; }
  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px; }
  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px; }
  .md\:-mt-0 {
    margin-top: 0; }
  .md\:-mr-0 {
    margin-right: 0; }
  .md\:-mb-0 {
    margin-bottom: 0; }
  .md\:-ml-0 {
    margin-left: 0; }
  .md\:-mt-1 {
    margin-top: -0.25rem; }
  .md\:-mr-1 {
    margin-right: -0.25rem; }
  .md\:-mb-1 {
    margin-bottom: -0.25rem; }
  .md\:-ml-1 {
    margin-left: -0.25rem; }
  .md\:-mt-2 {
    margin-top: -0.5rem; }
  .md\:-mr-2 {
    margin-right: -0.5rem; }
  .md\:-mb-2 {
    margin-bottom: -0.5rem; }
  .md\:-ml-2 {
    margin-left: -0.5rem; }
  .md\:-mt-3 {
    margin-top: -0.75rem; }
  .md\:-mr-3 {
    margin-right: -0.75rem; }
  .md\:-mb-3 {
    margin-bottom: -0.75rem; }
  .md\:-ml-3 {
    margin-left: -0.75rem; }
  .md\:-mt-4 {
    margin-top: -1rem; }
  .md\:-mr-4 {
    margin-right: -1rem; }
  .md\:-mb-4 {
    margin-bottom: -1rem; }
  .md\:-ml-4 {
    margin-left: -1rem; }
  .md\:-mt-5 {
    margin-top: -1.25rem; }
  .md\:-mr-5 {
    margin-right: -1.25rem; }
  .md\:-mb-5 {
    margin-bottom: -1.25rem; }
  .md\:-ml-5 {
    margin-left: -1.25rem; }
  .md\:-mt-6 {
    margin-top: -1.5rem; }
  .md\:-mr-6 {
    margin-right: -1.5rem; }
  .md\:-mb-6 {
    margin-bottom: -1.5rem; }
  .md\:-ml-6 {
    margin-left: -1.5rem; }
  .md\:-mt-8 {
    margin-top: -2rem; }
  .md\:-mr-8 {
    margin-right: -2rem; }
  .md\:-mb-8 {
    margin-bottom: -2rem; }
  .md\:-ml-8 {
    margin-left: -2rem; }
  .md\:-mt-10 {
    margin-top: -2.5rem; }
  .md\:-mr-10 {
    margin-right: -2.5rem; }
  .md\:-mb-10 {
    margin-bottom: -2.5rem; }
  .md\:-ml-10 {
    margin-left: -2.5rem; }
  .md\:-mt-12 {
    margin-top: -3rem; }
  .md\:-mr-12 {
    margin-right: -3rem; }
  .md\:-mb-12 {
    margin-bottom: -3rem; }
  .md\:-ml-12 {
    margin-left: -3rem; }
  .md\:-mt-16 {
    margin-top: -4rem; }
  .md\:-mr-16 {
    margin-right: -4rem; }
  .md\:-mb-16 {
    margin-bottom: -4rem; }
  .md\:-ml-16 {
    margin-left: -4rem; }
  .md\:-mt-20 {
    margin-top: -5rem; }
  .md\:-mr-20 {
    margin-right: -5rem; }
  .md\:-mb-20 {
    margin-bottom: -5rem; }
  .md\:-ml-20 {
    margin-left: -5rem; }
  .md\:-mt-24 {
    margin-top: -6rem; }
  .md\:-mr-24 {
    margin-right: -6rem; }
  .md\:-mb-24 {
    margin-bottom: -6rem; }
  .md\:-ml-24 {
    margin-left: -6rem; }
  .md\:-mt-32 {
    margin-top: -8rem; }
  .md\:-mr-32 {
    margin-right: -8rem; }
  .md\:-mb-32 {
    margin-bottom: -8rem; }
  .md\:-ml-32 {
    margin-left: -8rem; }
  .md\:-mt-px {
    margin-top: -1px; }
  .md\:-mr-px {
    margin-right: -1px; }
  .md\:-mb-px {
    margin-bottom: -1px; }
  .md\:-ml-px {
    margin-left: -1px; }
  .md\:opacity-0 {
    opacity: 0; }
  .md\:opacity-25 {
    opacity: .25; }
  .md\:opacity-50 {
    opacity: .5; }
  .md\:opacity-75 {
    opacity: .75; }
  .md\:opacity-100 {
    opacity: 1; }
  .md\:overflow-auto {
    overflow: auto; }
  .md\:overflow-hidden {
    overflow: hidden; }
  .md\:overflow-visible {
    overflow: visible; }
  .md\:overflow-scroll {
    overflow: scroll; }
  .md\:overflow-x-auto {
    overflow-x: auto; }
  .md\:overflow-y-auto {
    overflow-y: auto; }
  .md\:overflow-x-hidden {
    overflow-x: hidden; }
  .md\:overflow-y-hidden {
    overflow-y: hidden; }
  .md\:overflow-x-visible {
    overflow-x: visible; }
  .md\:overflow-y-visible {
    overflow-y: visible; }
  .md\:overflow-x-scroll {
    overflow-x: scroll; }
  .md\:overflow-y-scroll {
    overflow-y: scroll; }
  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch; }
  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto; }
  .md\:p-0 {
    padding: 0; }
  .md\:p-1 {
    padding: .25rem; }
  .md\:p-2 {
    padding: .5rem; }
  .md\:p-3 {
    padding: .75rem; }
  .md\:p-4 {
    padding: 1rem; }
  .md\:p-5 {
    padding: 1.25rem; }
  .md\:p-6 {
    padding: 1.5rem; }
  .md\:p-8 {
    padding: 2rem; }
  .md\:p-10 {
    padding: 2.5rem; }
  .md\:p-12 {
    padding: 3rem; }
  .md\:p-16 {
    padding: 4rem; }
  .md\:p-20 {
    padding: 5rem; }
  .md\:p-24 {
    padding: 6rem; }
  .md\:p-32 {
    padding: 8rem; }
  .md\:p-px {
    padding: 1px; }
  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0; }
  .md\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem; }
  .md\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem; }
  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem; }
  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem; }
  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem; }
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px; }
  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px; }
  .md\:pt-0 {
    padding-top: 0; }
  .md\:pr-0 {
    padding-right: 0; }
  .md\:pb-0 {
    padding-bottom: 0; }
  .md\:pl-0 {
    padding-left: 0; }
  .md\:pt-1 {
    padding-top: .25rem; }
  .md\:pr-1 {
    padding-right: .25rem; }
  .md\:pb-1 {
    padding-bottom: .25rem; }
  .md\:pl-1 {
    padding-left: .25rem; }
  .md\:pt-2 {
    padding-top: .5rem; }
  .md\:pr-2 {
    padding-right: .5rem; }
  .md\:pb-2 {
    padding-bottom: .5rem; }
  .md\:pl-2 {
    padding-left: .5rem; }
  .md\:pt-3 {
    padding-top: .75rem; }
  .md\:pr-3 {
    padding-right: .75rem; }
  .md\:pb-3 {
    padding-bottom: .75rem; }
  .md\:pl-3 {
    padding-left: .75rem; }
  .md\:pt-4 {
    padding-top: 1rem; }
  .md\:pr-4 {
    padding-right: 1rem; }
  .md\:pb-4 {
    padding-bottom: 1rem; }
  .md\:pl-4 {
    padding-left: 1rem; }
  .md\:pt-5 {
    padding-top: 1.25rem; }
  .md\:pr-5 {
    padding-right: 1.25rem; }
  .md\:pb-5 {
    padding-bottom: 1.25rem; }
  .md\:pl-5 {
    padding-left: 1.25rem; }
  .md\:pt-6 {
    padding-top: 1.5rem; }
  .md\:pr-6 {
    padding-right: 1.5rem; }
  .md\:pb-6 {
    padding-bottom: 1.5rem; }
  .md\:pl-6 {
    padding-left: 1.5rem; }
  .md\:pt-8 {
    padding-top: 2rem; }
  .md\:pr-8 {
    padding-right: 2rem; }
  .md\:pb-8 {
    padding-bottom: 2rem; }
  .md\:pl-8 {
    padding-left: 2rem; }
  .md\:pt-10 {
    padding-top: 2.5rem; }
  .md\:pr-10 {
    padding-right: 2.5rem; }
  .md\:pb-10 {
    padding-bottom: 2.5rem; }
  .md\:pl-10 {
    padding-left: 2.5rem; }
  .md\:pt-12 {
    padding-top: 3rem; }
  .md\:pr-12 {
    padding-right: 3rem; }
  .md\:pb-12 {
    padding-bottom: 3rem; }
  .md\:pl-12 {
    padding-left: 3rem; }
  .md\:pt-16 {
    padding-top: 4rem; }
  .md\:pr-16 {
    padding-right: 4rem; }
  .md\:pb-16 {
    padding-bottom: 4rem; }
  .md\:pl-16 {
    padding-left: 4rem; }
  .md\:pt-20 {
    padding-top: 5rem; }
  .md\:pr-20 {
    padding-right: 5rem; }
  .md\:pb-20 {
    padding-bottom: 5rem; }
  .md\:pl-20 {
    padding-left: 5rem; }
  .md\:pt-24 {
    padding-top: 6rem; }
  .md\:pr-24 {
    padding-right: 6rem; }
  .md\:pb-24 {
    padding-bottom: 6rem; }
  .md\:pl-24 {
    padding-left: 6rem; }
  .md\:pt-32 {
    padding-top: 8rem; }
  .md\:pr-32 {
    padding-right: 8rem; }
  .md\:pb-32 {
    padding-bottom: 8rem; }
  .md\:pl-32 {
    padding-left: 8rem; }
  .md\:pt-px {
    padding-top: 1px; }
  .md\:pr-px {
    padding-right: 1px; }
  .md\:pb-px {
    padding-bottom: 1px; }
  .md\:pl-px {
    padding-left: 1px; }
  .md\:pointer-events-none {
    pointer-events: none; }
  .md\:pointer-events-auto {
    pointer-events: auto; }
  .md\:static {
    position: static; }
  .md\:fixed {
    position: fixed; }
  .md\:absolute {
    position: absolute; }
  .md\:relative {
    position: relative; }
  .md\:sticky {
    position: -webkit-sticky;
    position: sticky; }
  .md\:pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto; }
  .md\:pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .md\:pin-y {
    top: 0;
    bottom: 0; }
  .md\:pin-x {
    right: 0;
    left: 0; }
  .md\:pin-t {
    top: 0; }
  .md\:pin-r {
    right: 0; }
  .md\:pin-b {
    bottom: 0; }
  .md\:pin-l {
    left: 0; }
  .md\:resize-none {
    resize: none; }
  .md\:resize-y {
    resize: vertical; }
  .md\:resize-x {
    resize: horizontal; }
  .md\:resize {
    resize: both; }
  .md\:shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .md\:shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .md\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .md\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .md\:shadow-none {
    box-shadow: none; }
  .md\:hover\:shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .md\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .md\:hover\:shadow-none:hover {
    box-shadow: none; }
  .md\:focus\:shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .md\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .md\:focus\:shadow-none:focus {
    box-shadow: none; }
  .md\:table-auto {
    table-layout: auto; }
  .md\:table-fixed {
    table-layout: fixed; }
  .md\:text-left {
    text-align: left; }
  .md\:text-center {
    text-align: center; }
  .md\:text-right {
    text-align: right; }
  .md\:text-justify {
    text-align: justify; }
  .md\:text-transparent {
    color: transparent; }
  .md\:text-black {
    color: #22292f; }
  .md\:text-grey-darkest {
    color: #3d4852; }
  .md\:text-grey-darker {
    color: #606f7b; }
  .md\:text-grey-dark {
    color: #8795a1; }
  .md\:text-grey {
    color: #b8c2cc; }
  .md\:text-grey-light {
    color: #dae1e7; }
  .md\:text-grey-lighter {
    color: #f1f5f8; }
  .md\:text-grey-lightest {
    color: #f8fafc; }
  .md\:text-white {
    color: #fff; }
  .md\:text-red-darkest {
    color: #3b0d0c; }
  .md\:text-red-darker {
    color: #621b18; }
  .md\:text-red-dark {
    color: #cc1f1a; }
  .md\:text-red {
    color: #e3342f; }
  .md\:text-red-light {
    color: #ef5753; }
  .md\:text-red-lighter {
    color: #f9acaa; }
  .md\:text-red-lightest {
    color: #fcebea; }
  .md\:text-orange-darkest {
    color: #462a16; }
  .md\:text-orange-darker {
    color: #613b1f; }
  .md\:text-orange-dark {
    color: #de751f; }
  .md\:text-orange {
    color: #f6993f; }
  .md\:text-orange-light {
    color: #faad63; }
  .md\:text-orange-lighter {
    color: #fcd9b6; }
  .md\:text-orange-lightest {
    color: #fff5eb; }
  .md\:text-yellow-darkest {
    color: #453411; }
  .md\:text-yellow-darker {
    color: #684f1d; }
  .md\:text-yellow-dark {
    color: #f2d024; }
  .md\:text-yellow {
    color: #ffed4a; }
  .md\:text-yellow-light {
    color: #fff382; }
  .md\:text-yellow-lighter {
    color: #fff9c2; }
  .md\:text-yellow-lightest {
    color: #fcfbeb; }
  .md\:text-green-darkest {
    color: #0f2f21; }
  .md\:text-green-darker {
    color: #1a4731; }
  .md\:text-green-dark {
    color: #1f9d55; }
  .md\:text-green {
    color: #38c172; }
  .md\:text-green-light {
    color: #51d88a; }
  .md\:text-green-lighter {
    color: #a2f5bf; }
  .md\:text-green-lightest {
    color: #e3fcec; }
  .md\:text-teal-darkest {
    color: #0d3331; }
  .md\:text-teal-darker {
    color: #20504f; }
  .md\:text-teal-dark {
    color: #38a89d; }
  .md\:text-teal {
    color: #4dc0b5; }
  .md\:text-teal-light {
    color: #64d5ca; }
  .md\:text-teal-lighter {
    color: #a0f0ed; }
  .md\:text-teal-lightest {
    color: #e8fffe; }
  .md\:text-blue-darkest {
    color: #12283a; }
  .md\:text-blue-darker {
    color: #1c3d5a; }
  .md\:text-blue-dark {
    color: #2779bd; }
  .md\:text-blue {
    color: #3490dc; }
  .md\:text-blue-light {
    color: #6cb2eb; }
  .md\:text-blue-lighter {
    color: #bcdefa; }
  .md\:text-blue-lightest {
    color: #eff8ff; }
  .md\:text-indigo-darkest {
    color: #191e38; }
  .md\:text-indigo-darker {
    color: #2f365f; }
  .md\:text-indigo-dark {
    color: #5661b3; }
  .md\:text-indigo {
    color: #6574cd; }
  .md\:text-indigo-light {
    color: #7886d7; }
  .md\:text-indigo-lighter {
    color: #b2b7ff; }
  .md\:text-indigo-lightest {
    color: #e6e8ff; }
  .md\:text-purple-darkest {
    color: #21183c; }
  .md\:text-purple-darker {
    color: #382b5f; }
  .md\:text-purple-dark {
    color: #794acf; }
  .md\:text-purple {
    color: #9561e2; }
  .md\:text-purple-light {
    color: #a779e9; }
  .md\:text-purple-lighter {
    color: #d6bbfc; }
  .md\:text-purple-lightest {
    color: #f3ebff; }
  .md\:text-pink-darkest {
    color: #451225; }
  .md\:text-pink-darker {
    color: #6f213f; }
  .md\:text-pink-dark {
    color: #eb5286; }
  .md\:text-pink {
    color: #f66d9b; }
  .md\:text-pink-light {
    color: #fa7ea8; }
  .md\:text-pink-lighter {
    color: #ffbbca; }
  .md\:text-pink-lightest {
    color: #ffebef; }
  .md\:hover\:text-transparent:hover {
    color: transparent; }
  .md\:hover\:text-black:hover {
    color: #22292f; }
  .md\:hover\:text-grey-darkest:hover {
    color: #3d4852; }
  .md\:hover\:text-grey-darker:hover {
    color: #606f7b; }
  .md\:hover\:text-grey-dark:hover {
    color: #8795a1; }
  .md\:hover\:text-grey:hover {
    color: #b8c2cc; }
  .md\:hover\:text-grey-light:hover {
    color: #dae1e7; }
  .md\:hover\:text-grey-lighter:hover {
    color: #f1f5f8; }
  .md\:hover\:text-grey-lightest:hover {
    color: #f8fafc; }
  .md\:hover\:text-white:hover {
    color: #fff; }
  .md\:hover\:text-red-darkest:hover {
    color: #3b0d0c; }
  .md\:hover\:text-red-darker:hover {
    color: #621b18; }
  .md\:hover\:text-red-dark:hover {
    color: #cc1f1a; }
  .md\:hover\:text-red:hover {
    color: #e3342f; }
  .md\:hover\:text-red-light:hover {
    color: #ef5753; }
  .md\:hover\:text-red-lighter:hover {
    color: #f9acaa; }
  .md\:hover\:text-red-lightest:hover {
    color: #fcebea; }
  .md\:hover\:text-orange-darkest:hover {
    color: #462a16; }
  .md\:hover\:text-orange-darker:hover {
    color: #613b1f; }
  .md\:hover\:text-orange-dark:hover {
    color: #de751f; }
  .md\:hover\:text-orange:hover {
    color: #f6993f; }
  .md\:hover\:text-orange-light:hover {
    color: #faad63; }
  .md\:hover\:text-orange-lighter:hover {
    color: #fcd9b6; }
  .md\:hover\:text-orange-lightest:hover {
    color: #fff5eb; }
  .md\:hover\:text-yellow-darkest:hover {
    color: #453411; }
  .md\:hover\:text-yellow-darker:hover {
    color: #684f1d; }
  .md\:hover\:text-yellow-dark:hover {
    color: #f2d024; }
  .md\:hover\:text-yellow:hover {
    color: #ffed4a; }
  .md\:hover\:text-yellow-light:hover {
    color: #fff382; }
  .md\:hover\:text-yellow-lighter:hover {
    color: #fff9c2; }
  .md\:hover\:text-yellow-lightest:hover {
    color: #fcfbeb; }
  .md\:hover\:text-green-darkest:hover {
    color: #0f2f21; }
  .md\:hover\:text-green-darker:hover {
    color: #1a4731; }
  .md\:hover\:text-green-dark:hover {
    color: #1f9d55; }
  .md\:hover\:text-green:hover {
    color: #38c172; }
  .md\:hover\:text-green-light:hover {
    color: #51d88a; }
  .md\:hover\:text-green-lighter:hover {
    color: #a2f5bf; }
  .md\:hover\:text-green-lightest:hover {
    color: #e3fcec; }
  .md\:hover\:text-teal-darkest:hover {
    color: #0d3331; }
  .md\:hover\:text-teal-darker:hover {
    color: #20504f; }
  .md\:hover\:text-teal-dark:hover {
    color: #38a89d; }
  .md\:hover\:text-teal:hover {
    color: #4dc0b5; }
  .md\:hover\:text-teal-light:hover {
    color: #64d5ca; }
  .md\:hover\:text-teal-lighter:hover {
    color: #a0f0ed; }
  .md\:hover\:text-teal-lightest:hover {
    color: #e8fffe; }
  .md\:hover\:text-blue-darkest:hover {
    color: #12283a; }
  .md\:hover\:text-blue-darker:hover {
    color: #1c3d5a; }
  .md\:hover\:text-blue-dark:hover {
    color: #2779bd; }
  .md\:hover\:text-blue:hover {
    color: #3490dc; }
  .md\:hover\:text-blue-light:hover {
    color: #6cb2eb; }
  .md\:hover\:text-blue-lighter:hover {
    color: #bcdefa; }
  .md\:hover\:text-blue-lightest:hover {
    color: #eff8ff; }
  .md\:hover\:text-indigo-darkest:hover {
    color: #191e38; }
  .md\:hover\:text-indigo-darker:hover {
    color: #2f365f; }
  .md\:hover\:text-indigo-dark:hover {
    color: #5661b3; }
  .md\:hover\:text-indigo:hover {
    color: #6574cd; }
  .md\:hover\:text-indigo-light:hover {
    color: #7886d7; }
  .md\:hover\:text-indigo-lighter:hover {
    color: #b2b7ff; }
  .md\:hover\:text-indigo-lightest:hover {
    color: #e6e8ff; }
  .md\:hover\:text-purple-darkest:hover {
    color: #21183c; }
  .md\:hover\:text-purple-darker:hover {
    color: #382b5f; }
  .md\:hover\:text-purple-dark:hover {
    color: #794acf; }
  .md\:hover\:text-purple:hover {
    color: #9561e2; }
  .md\:hover\:text-purple-light:hover {
    color: #a779e9; }
  .md\:hover\:text-purple-lighter:hover {
    color: #d6bbfc; }
  .md\:hover\:text-purple-lightest:hover {
    color: #f3ebff; }
  .md\:hover\:text-pink-darkest:hover {
    color: #451225; }
  .md\:hover\:text-pink-darker:hover {
    color: #6f213f; }
  .md\:hover\:text-pink-dark:hover {
    color: #eb5286; }
  .md\:hover\:text-pink:hover {
    color: #f66d9b; }
  .md\:hover\:text-pink-light:hover {
    color: #fa7ea8; }
  .md\:hover\:text-pink-lighter:hover {
    color: #ffbbca; }
  .md\:hover\:text-pink-lightest:hover {
    color: #ffebef; }
  .md\:focus\:text-transparent:focus {
    color: transparent; }
  .md\:focus\:text-black:focus {
    color: #22292f; }
  .md\:focus\:text-grey-darkest:focus {
    color: #3d4852; }
  .md\:focus\:text-grey-darker:focus {
    color: #606f7b; }
  .md\:focus\:text-grey-dark:focus {
    color: #8795a1; }
  .md\:focus\:text-grey:focus {
    color: #b8c2cc; }
  .md\:focus\:text-grey-light:focus {
    color: #dae1e7; }
  .md\:focus\:text-grey-lighter:focus {
    color: #f1f5f8; }
  .md\:focus\:text-grey-lightest:focus {
    color: #f8fafc; }
  .md\:focus\:text-white:focus {
    color: #fff; }
  .md\:focus\:text-red-darkest:focus {
    color: #3b0d0c; }
  .md\:focus\:text-red-darker:focus {
    color: #621b18; }
  .md\:focus\:text-red-dark:focus {
    color: #cc1f1a; }
  .md\:focus\:text-red:focus {
    color: #e3342f; }
  .md\:focus\:text-red-light:focus {
    color: #ef5753; }
  .md\:focus\:text-red-lighter:focus {
    color: #f9acaa; }
  .md\:focus\:text-red-lightest:focus {
    color: #fcebea; }
  .md\:focus\:text-orange-darkest:focus {
    color: #462a16; }
  .md\:focus\:text-orange-darker:focus {
    color: #613b1f; }
  .md\:focus\:text-orange-dark:focus {
    color: #de751f; }
  .md\:focus\:text-orange:focus {
    color: #f6993f; }
  .md\:focus\:text-orange-light:focus {
    color: #faad63; }
  .md\:focus\:text-orange-lighter:focus {
    color: #fcd9b6; }
  .md\:focus\:text-orange-lightest:focus {
    color: #fff5eb; }
  .md\:focus\:text-yellow-darkest:focus {
    color: #453411; }
  .md\:focus\:text-yellow-darker:focus {
    color: #684f1d; }
  .md\:focus\:text-yellow-dark:focus {
    color: #f2d024; }
  .md\:focus\:text-yellow:focus {
    color: #ffed4a; }
  .md\:focus\:text-yellow-light:focus {
    color: #fff382; }
  .md\:focus\:text-yellow-lighter:focus {
    color: #fff9c2; }
  .md\:focus\:text-yellow-lightest:focus {
    color: #fcfbeb; }
  .md\:focus\:text-green-darkest:focus {
    color: #0f2f21; }
  .md\:focus\:text-green-darker:focus {
    color: #1a4731; }
  .md\:focus\:text-green-dark:focus {
    color: #1f9d55; }
  .md\:focus\:text-green:focus {
    color: #38c172; }
  .md\:focus\:text-green-light:focus {
    color: #51d88a; }
  .md\:focus\:text-green-lighter:focus {
    color: #a2f5bf; }
  .md\:focus\:text-green-lightest:focus {
    color: #e3fcec; }
  .md\:focus\:text-teal-darkest:focus {
    color: #0d3331; }
  .md\:focus\:text-teal-darker:focus {
    color: #20504f; }
  .md\:focus\:text-teal-dark:focus {
    color: #38a89d; }
  .md\:focus\:text-teal:focus {
    color: #4dc0b5; }
  .md\:focus\:text-teal-light:focus {
    color: #64d5ca; }
  .md\:focus\:text-teal-lighter:focus {
    color: #a0f0ed; }
  .md\:focus\:text-teal-lightest:focus {
    color: #e8fffe; }
  .md\:focus\:text-blue-darkest:focus {
    color: #12283a; }
  .md\:focus\:text-blue-darker:focus {
    color: #1c3d5a; }
  .md\:focus\:text-blue-dark:focus {
    color: #2779bd; }
  .md\:focus\:text-blue:focus {
    color: #3490dc; }
  .md\:focus\:text-blue-light:focus {
    color: #6cb2eb; }
  .md\:focus\:text-blue-lighter:focus {
    color: #bcdefa; }
  .md\:focus\:text-blue-lightest:focus {
    color: #eff8ff; }
  .md\:focus\:text-indigo-darkest:focus {
    color: #191e38; }
  .md\:focus\:text-indigo-darker:focus {
    color: #2f365f; }
  .md\:focus\:text-indigo-dark:focus {
    color: #5661b3; }
  .md\:focus\:text-indigo:focus {
    color: #6574cd; }
  .md\:focus\:text-indigo-light:focus {
    color: #7886d7; }
  .md\:focus\:text-indigo-lighter:focus {
    color: #b2b7ff; }
  .md\:focus\:text-indigo-lightest:focus {
    color: #e6e8ff; }
  .md\:focus\:text-purple-darkest:focus {
    color: #21183c; }
  .md\:focus\:text-purple-darker:focus {
    color: #382b5f; }
  .md\:focus\:text-purple-dark:focus {
    color: #794acf; }
  .md\:focus\:text-purple:focus {
    color: #9561e2; }
  .md\:focus\:text-purple-light:focus {
    color: #a779e9; }
  .md\:focus\:text-purple-lighter:focus {
    color: #d6bbfc; }
  .md\:focus\:text-purple-lightest:focus {
    color: #f3ebff; }
  .md\:focus\:text-pink-darkest:focus {
    color: #451225; }
  .md\:focus\:text-pink-darker:focus {
    color: #6f213f; }
  .md\:focus\:text-pink-dark:focus {
    color: #eb5286; }
  .md\:focus\:text-pink:focus {
    color: #f66d9b; }
  .md\:focus\:text-pink-light:focus {
    color: #fa7ea8; }
  .md\:focus\:text-pink-lighter:focus {
    color: #ffbbca; }
  .md\:focus\:text-pink-lightest:focus {
    color: #ffebef; }
  .md\:text-xs {
    font-size: .75rem; }
  .md\:text-sm {
    font-size: .875rem; }
  .md\:text-base {
    font-size: 1rem; }
  .md\:text-lg {
    font-size: 1.125rem; }
  .md\:text-xl {
    font-size: 1.25rem; }
  .md\:text-2xl {
    font-size: 1.5rem; }
  .md\:text-3xl {
    font-size: 1.875rem; }
  .md\:text-4xl {
    font-size: 2.25rem; }
  .md\:text-5xl {
    font-size: 3rem; }
  .md\:italic {
    font-style: italic; }
  .md\:roman {
    font-style: normal; }
  .md\:uppercase {
    text-transform: uppercase; }
  .md\:lowercase {
    text-transform: lowercase; }
  .md\:capitalize {
    text-transform: capitalize; }
  .md\:normal-case {
    text-transform: none; }
  .md\:underline {
    text-decoration: underline; }
  .md\:line-through {
    text-decoration: line-through; }
  .md\:no-underline {
    text-decoration: none; }
  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .md\:hover\:italic:hover {
    font-style: italic; }
  .md\:hover\:roman:hover {
    font-style: normal; }
  .md\:hover\:uppercase:hover {
    text-transform: uppercase; }
  .md\:hover\:lowercase:hover {
    text-transform: lowercase; }
  .md\:hover\:capitalize:hover {
    text-transform: capitalize; }
  .md\:hover\:normal-case:hover {
    text-transform: none; }
  .md\:hover\:underline:hover {
    text-decoration: underline; }
  .md\:hover\:line-through:hover {
    text-decoration: line-through; }
  .md\:hover\:no-underline:hover {
    text-decoration: none; }
  .md\:hover\:antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .md\:hover\:subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .md\:focus\:italic:focus {
    font-style: italic; }
  .md\:focus\:roman:focus {
    font-style: normal; }
  .md\:focus\:uppercase:focus {
    text-transform: uppercase; }
  .md\:focus\:lowercase:focus {
    text-transform: lowercase; }
  .md\:focus\:capitalize:focus {
    text-transform: capitalize; }
  .md\:focus\:normal-case:focus {
    text-transform: none; }
  .md\:focus\:underline:focus {
    text-decoration: underline; }
  .md\:focus\:line-through:focus {
    text-decoration: line-through; }
  .md\:focus\:no-underline:focus {
    text-decoration: none; }
  .md\:focus\:antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .md\:focus\:subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .md\:tracking-tight {
    letter-spacing: -0.05em; }
  .md\:tracking-normal {
    letter-spacing: 0; }
  .md\:tracking-wide {
    letter-spacing: .05em; }
  .md\:select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .md\:select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  .md\:align-baseline {
    vertical-align: baseline; }
  .md\:align-top {
    vertical-align: top; }
  .md\:align-middle {
    vertical-align: middle; }
  .md\:align-bottom {
    vertical-align: bottom; }
  .md\:align-text-top {
    vertical-align: text-top; }
  .md\:align-text-bottom {
    vertical-align: text-bottom; }
  .md\:visible {
    visibility: visible; }
  .md\:invisible {
    visibility: hidden; }
  .md\:whitespace-normal {
    white-space: normal; }
  .md\:whitespace-no-wrap {
    white-space: nowrap; }
  .md\:whitespace-pre {
    white-space: pre; }
  .md\:whitespace-pre-line {
    white-space: pre-line; }
  .md\:whitespace-pre-wrap {
    white-space: pre-wrap; }
  .md\:break-words {
    word-wrap: break-word; }
  .md\:break-normal {
    word-wrap: normal; }
  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .md\:w-1 {
    width: .25rem; }
  .md\:w-2 {
    width: .5rem; }
  .md\:w-3 {
    width: .75rem; }
  .md\:w-4 {
    width: 1rem; }
  .md\:w-5 {
    width: 1.25rem; }
  .md\:w-6 {
    width: 1.5rem; }
  .md\:w-8 {
    width: 2rem; }
  .md\:w-10 {
    width: 2.5rem; }
  .md\:w-12 {
    width: 3rem; }
  .md\:w-16 {
    width: 4rem; }
  .md\:w-24 {
    width: 6rem; }
  .md\:w-32 {
    width: 8rem; }
  .md\:w-48 {
    width: 12rem; }
  .md\:w-64 {
    width: 16rem; }
  .md\:w-auto {
    width: auto; }
  .md\:w-px {
    width: 1px; }
  .md\:w-1\/2 {
    width: 50%; }
  .md\:w-1\/3 {
    width: 33.33333%; }
  .md\:w-2\/3 {
    width: 66.66667%; }
  .md\:w-1\/4 {
    width: 25%; }
  .md\:w-3\/4 {
    width: 75%; }
  .md\:w-1\/5 {
    width: 20%; }
  .md\:w-2\/5 {
    width: 40%; }
  .md\:w-3\/5 {
    width: 60%; }
  .md\:w-4\/5 {
    width: 80%; }
  .md\:w-1\/6 {
    width: 16.66667%; }
  .md\:w-5\/6 {
    width: 83.33333%; }
  .md\:w-full {
    width: 100%; }
  .md\:w-screen {
    width: 100vw; }
  .md\:z-0 {
    z-index: 0; }
  .md\:z-10 {
    z-index: 10; }
  .md\:z-20 {
    z-index: 20; }
  .md\:z-30 {
    z-index: 30; }
  .md\:z-40 {
    z-index: 40; }
  .md\:z-50 {
    z-index: 50; }
  .md\:z-auto {
    z-index: auto; } }

@media (min-width: 992px) {
  .lg\:list-reset {
    list-style: none;
    padding: 0; }
  .lg\:appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .lg\:bg-fixed {
    background-attachment: fixed; }
  .lg\:bg-local {
    background-attachment: local; }
  .lg\:bg-scroll {
    background-attachment: scroll; }
  .lg\:bg-transparent {
    background-color: transparent; }
  .lg\:bg-black {
    background-color: #22292f; }
  .lg\:bg-grey-darkest {
    background-color: #3d4852; }
  .lg\:bg-grey-darker {
    background-color: #606f7b; }
  .lg\:bg-grey-dark {
    background-color: #8795a1; }
  .lg\:bg-grey {
    background-color: #b8c2cc; }
  .lg\:bg-grey-light {
    background-color: #dae1e7; }
  .lg\:bg-grey-lighter {
    background-color: #f1f5f8; }
  .lg\:bg-grey-lightest {
    background-color: #f8fafc; }
  .lg\:bg-white {
    background-color: #fff; }
  .lg\:bg-red-darkest {
    background-color: #3b0d0c; }
  .lg\:bg-red-darker {
    background-color: #621b18; }
  .lg\:bg-red-dark {
    background-color: #cc1f1a; }
  .lg\:bg-red {
    background-color: #e3342f; }
  .lg\:bg-red-light {
    background-color: #ef5753; }
  .lg\:bg-red-lighter {
    background-color: #f9acaa; }
  .lg\:bg-red-lightest {
    background-color: #fcebea; }
  .lg\:bg-orange-darkest {
    background-color: #462a16; }
  .lg\:bg-orange-darker {
    background-color: #613b1f; }
  .lg\:bg-orange-dark {
    background-color: #de751f; }
  .lg\:bg-orange {
    background-color: #f6993f; }
  .lg\:bg-orange-light {
    background-color: #faad63; }
  .lg\:bg-orange-lighter {
    background-color: #fcd9b6; }
  .lg\:bg-orange-lightest {
    background-color: #fff5eb; }
  .lg\:bg-yellow-darkest {
    background-color: #453411; }
  .lg\:bg-yellow-darker {
    background-color: #684f1d; }
  .lg\:bg-yellow-dark {
    background-color: #f2d024; }
  .lg\:bg-yellow {
    background-color: #ffed4a; }
  .lg\:bg-yellow-light {
    background-color: #fff382; }
  .lg\:bg-yellow-lighter {
    background-color: #fff9c2; }
  .lg\:bg-yellow-lightest {
    background-color: #fcfbeb; }
  .lg\:bg-green-darkest {
    background-color: #0f2f21; }
  .lg\:bg-green-darker {
    background-color: #1a4731; }
  .lg\:bg-green-dark {
    background-color: #1f9d55; }
  .lg\:bg-green {
    background-color: #38c172; }
  .lg\:bg-green-light {
    background-color: #51d88a; }
  .lg\:bg-green-lighter {
    background-color: #a2f5bf; }
  .lg\:bg-green-lightest {
    background-color: #e3fcec; }
  .lg\:bg-teal-darkest {
    background-color: #0d3331; }
  .lg\:bg-teal-darker {
    background-color: #20504f; }
  .lg\:bg-teal-dark {
    background-color: #38a89d; }
  .lg\:bg-teal {
    background-color: #4dc0b5; }
  .lg\:bg-teal-light {
    background-color: #64d5ca; }
  .lg\:bg-teal-lighter {
    background-color: #a0f0ed; }
  .lg\:bg-teal-lightest {
    background-color: #e8fffe; }
  .lg\:bg-blue-darkest {
    background-color: #12283a; }
  .lg\:bg-blue-darker {
    background-color: #1c3d5a; }
  .lg\:bg-blue-dark {
    background-color: #2779bd; }
  .lg\:bg-blue {
    background-color: #3490dc; }
  .lg\:bg-blue-light {
    background-color: #6cb2eb; }
  .lg\:bg-blue-lighter {
    background-color: #bcdefa; }
  .lg\:bg-blue-lightest {
    background-color: #eff8ff; }
  .lg\:bg-indigo-darkest {
    background-color: #191e38; }
  .lg\:bg-indigo-darker {
    background-color: #2f365f; }
  .lg\:bg-indigo-dark {
    background-color: #5661b3; }
  .lg\:bg-indigo {
    background-color: #6574cd; }
  .lg\:bg-indigo-light {
    background-color: #7886d7; }
  .lg\:bg-indigo-lighter {
    background-color: #b2b7ff; }
  .lg\:bg-indigo-lightest {
    background-color: #e6e8ff; }
  .lg\:bg-purple-darkest {
    background-color: #21183c; }
  .lg\:bg-purple-darker {
    background-color: #382b5f; }
  .lg\:bg-purple-dark {
    background-color: #794acf; }
  .lg\:bg-purple {
    background-color: #9561e2; }
  .lg\:bg-purple-light {
    background-color: #a779e9; }
  .lg\:bg-purple-lighter {
    background-color: #d6bbfc; }
  .lg\:bg-purple-lightest {
    background-color: #f3ebff; }
  .lg\:bg-pink-darkest {
    background-color: #451225; }
  .lg\:bg-pink-darker {
    background-color: #6f213f; }
  .lg\:bg-pink-dark {
    background-color: #eb5286; }
  .lg\:bg-pink {
    background-color: #f66d9b; }
  .lg\:bg-pink-light {
    background-color: #fa7ea8; }
  .lg\:bg-pink-lighter {
    background-color: #ffbbca; }
  .lg\:bg-pink-lightest {
    background-color: #ffebef; }
  .lg\:hover\:bg-transparent:hover {
    background-color: transparent; }
  .lg\:hover\:bg-black:hover {
    background-color: #22292f; }
  .lg\:hover\:bg-grey-darkest:hover {
    background-color: #3d4852; }
  .lg\:hover\:bg-grey-darker:hover {
    background-color: #606f7b; }
  .lg\:hover\:bg-grey-dark:hover {
    background-color: #8795a1; }
  .lg\:hover\:bg-grey:hover {
    background-color: #b8c2cc; }
  .lg\:hover\:bg-grey-light:hover {
    background-color: #dae1e7; }
  .lg\:hover\:bg-grey-lighter:hover {
    background-color: #f1f5f8; }
  .lg\:hover\:bg-grey-lightest:hover {
    background-color: #f8fafc; }
  .lg\:hover\:bg-white:hover {
    background-color: #fff; }
  .lg\:hover\:bg-red-darkest:hover {
    background-color: #3b0d0c; }
  .lg\:hover\:bg-red-darker:hover {
    background-color: #621b18; }
  .lg\:hover\:bg-red-dark:hover {
    background-color: #cc1f1a; }
  .lg\:hover\:bg-red:hover {
    background-color: #e3342f; }
  .lg\:hover\:bg-red-light:hover {
    background-color: #ef5753; }
  .lg\:hover\:bg-red-lighter:hover {
    background-color: #f9acaa; }
  .lg\:hover\:bg-red-lightest:hover {
    background-color: #fcebea; }
  .lg\:hover\:bg-orange-darkest:hover {
    background-color: #462a16; }
  .lg\:hover\:bg-orange-darker:hover {
    background-color: #613b1f; }
  .lg\:hover\:bg-orange-dark:hover {
    background-color: #de751f; }
  .lg\:hover\:bg-orange:hover {
    background-color: #f6993f; }
  .lg\:hover\:bg-orange-light:hover {
    background-color: #faad63; }
  .lg\:hover\:bg-orange-lighter:hover {
    background-color: #fcd9b6; }
  .lg\:hover\:bg-orange-lightest:hover {
    background-color: #fff5eb; }
  .lg\:hover\:bg-yellow-darkest:hover {
    background-color: #453411; }
  .lg\:hover\:bg-yellow-darker:hover {
    background-color: #684f1d; }
  .lg\:hover\:bg-yellow-dark:hover {
    background-color: #f2d024; }
  .lg\:hover\:bg-yellow:hover {
    background-color: #ffed4a; }
  .lg\:hover\:bg-yellow-light:hover {
    background-color: #fff382; }
  .lg\:hover\:bg-yellow-lighter:hover {
    background-color: #fff9c2; }
  .lg\:hover\:bg-yellow-lightest:hover {
    background-color: #fcfbeb; }
  .lg\:hover\:bg-green-darkest:hover {
    background-color: #0f2f21; }
  .lg\:hover\:bg-green-darker:hover {
    background-color: #1a4731; }
  .lg\:hover\:bg-green-dark:hover {
    background-color: #1f9d55; }
  .lg\:hover\:bg-green:hover {
    background-color: #38c172; }
  .lg\:hover\:bg-green-light:hover {
    background-color: #51d88a; }
  .lg\:hover\:bg-green-lighter:hover {
    background-color: #a2f5bf; }
  .lg\:hover\:bg-green-lightest:hover {
    background-color: #e3fcec; }
  .lg\:hover\:bg-teal-darkest:hover {
    background-color: #0d3331; }
  .lg\:hover\:bg-teal-darker:hover {
    background-color: #20504f; }
  .lg\:hover\:bg-teal-dark:hover {
    background-color: #38a89d; }
  .lg\:hover\:bg-teal:hover {
    background-color: #4dc0b5; }
  .lg\:hover\:bg-teal-light:hover {
    background-color: #64d5ca; }
  .lg\:hover\:bg-teal-lighter:hover {
    background-color: #a0f0ed; }
  .lg\:hover\:bg-teal-lightest:hover {
    background-color: #e8fffe; }
  .lg\:hover\:bg-blue-darkest:hover {
    background-color: #12283a; }
  .lg\:hover\:bg-blue-darker:hover {
    background-color: #1c3d5a; }
  .lg\:hover\:bg-blue-dark:hover {
    background-color: #2779bd; }
  .lg\:hover\:bg-blue:hover {
    background-color: #3490dc; }
  .lg\:hover\:bg-blue-light:hover {
    background-color: #6cb2eb; }
  .lg\:hover\:bg-blue-lighter:hover {
    background-color: #bcdefa; }
  .lg\:hover\:bg-blue-lightest:hover {
    background-color: #eff8ff; }
  .lg\:hover\:bg-indigo-darkest:hover {
    background-color: #191e38; }
  .lg\:hover\:bg-indigo-darker:hover {
    background-color: #2f365f; }
  .lg\:hover\:bg-indigo-dark:hover {
    background-color: #5661b3; }
  .lg\:hover\:bg-indigo:hover {
    background-color: #6574cd; }
  .lg\:hover\:bg-indigo-light:hover {
    background-color: #7886d7; }
  .lg\:hover\:bg-indigo-lighter:hover {
    background-color: #b2b7ff; }
  .lg\:hover\:bg-indigo-lightest:hover {
    background-color: #e6e8ff; }
  .lg\:hover\:bg-purple-darkest:hover {
    background-color: #21183c; }
  .lg\:hover\:bg-purple-darker:hover {
    background-color: #382b5f; }
  .lg\:hover\:bg-purple-dark:hover {
    background-color: #794acf; }
  .lg\:hover\:bg-purple:hover {
    background-color: #9561e2; }
  .lg\:hover\:bg-purple-light:hover {
    background-color: #a779e9; }
  .lg\:hover\:bg-purple-lighter:hover {
    background-color: #d6bbfc; }
  .lg\:hover\:bg-purple-lightest:hover {
    background-color: #f3ebff; }
  .lg\:hover\:bg-pink-darkest:hover {
    background-color: #451225; }
  .lg\:hover\:bg-pink-darker:hover {
    background-color: #6f213f; }
  .lg\:hover\:bg-pink-dark:hover {
    background-color: #eb5286; }
  .lg\:hover\:bg-pink:hover {
    background-color: #f66d9b; }
  .lg\:hover\:bg-pink-light:hover {
    background-color: #fa7ea8; }
  .lg\:hover\:bg-pink-lighter:hover {
    background-color: #ffbbca; }
  .lg\:hover\:bg-pink-lightest:hover {
    background-color: #ffebef; }
  .lg\:focus\:bg-transparent:focus {
    background-color: transparent; }
  .lg\:focus\:bg-black:focus {
    background-color: #22292f; }
  .lg\:focus\:bg-grey-darkest:focus {
    background-color: #3d4852; }
  .lg\:focus\:bg-grey-darker:focus {
    background-color: #606f7b; }
  .lg\:focus\:bg-grey-dark:focus {
    background-color: #8795a1; }
  .lg\:focus\:bg-grey:focus {
    background-color: #b8c2cc; }
  .lg\:focus\:bg-grey-light:focus {
    background-color: #dae1e7; }
  .lg\:focus\:bg-grey-lighter:focus {
    background-color: #f1f5f8; }
  .lg\:focus\:bg-grey-lightest:focus {
    background-color: #f8fafc; }
  .lg\:focus\:bg-white:focus {
    background-color: #fff; }
  .lg\:focus\:bg-red-darkest:focus {
    background-color: #3b0d0c; }
  .lg\:focus\:bg-red-darker:focus {
    background-color: #621b18; }
  .lg\:focus\:bg-red-dark:focus {
    background-color: #cc1f1a; }
  .lg\:focus\:bg-red:focus {
    background-color: #e3342f; }
  .lg\:focus\:bg-red-light:focus {
    background-color: #ef5753; }
  .lg\:focus\:bg-red-lighter:focus {
    background-color: #f9acaa; }
  .lg\:focus\:bg-red-lightest:focus {
    background-color: #fcebea; }
  .lg\:focus\:bg-orange-darkest:focus {
    background-color: #462a16; }
  .lg\:focus\:bg-orange-darker:focus {
    background-color: #613b1f; }
  .lg\:focus\:bg-orange-dark:focus {
    background-color: #de751f; }
  .lg\:focus\:bg-orange:focus {
    background-color: #f6993f; }
  .lg\:focus\:bg-orange-light:focus {
    background-color: #faad63; }
  .lg\:focus\:bg-orange-lighter:focus {
    background-color: #fcd9b6; }
  .lg\:focus\:bg-orange-lightest:focus {
    background-color: #fff5eb; }
  .lg\:focus\:bg-yellow-darkest:focus {
    background-color: #453411; }
  .lg\:focus\:bg-yellow-darker:focus {
    background-color: #684f1d; }
  .lg\:focus\:bg-yellow-dark:focus {
    background-color: #f2d024; }
  .lg\:focus\:bg-yellow:focus {
    background-color: #ffed4a; }
  .lg\:focus\:bg-yellow-light:focus {
    background-color: #fff382; }
  .lg\:focus\:bg-yellow-lighter:focus {
    background-color: #fff9c2; }
  .lg\:focus\:bg-yellow-lightest:focus {
    background-color: #fcfbeb; }
  .lg\:focus\:bg-green-darkest:focus {
    background-color: #0f2f21; }
  .lg\:focus\:bg-green-darker:focus {
    background-color: #1a4731; }
  .lg\:focus\:bg-green-dark:focus {
    background-color: #1f9d55; }
  .lg\:focus\:bg-green:focus {
    background-color: #38c172; }
  .lg\:focus\:bg-green-light:focus {
    background-color: #51d88a; }
  .lg\:focus\:bg-green-lighter:focus {
    background-color: #a2f5bf; }
  .lg\:focus\:bg-green-lightest:focus {
    background-color: #e3fcec; }
  .lg\:focus\:bg-teal-darkest:focus {
    background-color: #0d3331; }
  .lg\:focus\:bg-teal-darker:focus {
    background-color: #20504f; }
  .lg\:focus\:bg-teal-dark:focus {
    background-color: #38a89d; }
  .lg\:focus\:bg-teal:focus {
    background-color: #4dc0b5; }
  .lg\:focus\:bg-teal-light:focus {
    background-color: #64d5ca; }
  .lg\:focus\:bg-teal-lighter:focus {
    background-color: #a0f0ed; }
  .lg\:focus\:bg-teal-lightest:focus {
    background-color: #e8fffe; }
  .lg\:focus\:bg-blue-darkest:focus {
    background-color: #12283a; }
  .lg\:focus\:bg-blue-darker:focus {
    background-color: #1c3d5a; }
  .lg\:focus\:bg-blue-dark:focus {
    background-color: #2779bd; }
  .lg\:focus\:bg-blue:focus {
    background-color: #3490dc; }
  .lg\:focus\:bg-blue-light:focus {
    background-color: #6cb2eb; }
  .lg\:focus\:bg-blue-lighter:focus {
    background-color: #bcdefa; }
  .lg\:focus\:bg-blue-lightest:focus {
    background-color: #eff8ff; }
  .lg\:focus\:bg-indigo-darkest:focus {
    background-color: #191e38; }
  .lg\:focus\:bg-indigo-darker:focus {
    background-color: #2f365f; }
  .lg\:focus\:bg-indigo-dark:focus {
    background-color: #5661b3; }
  .lg\:focus\:bg-indigo:focus {
    background-color: #6574cd; }
  .lg\:focus\:bg-indigo-light:focus {
    background-color: #7886d7; }
  .lg\:focus\:bg-indigo-lighter:focus {
    background-color: #b2b7ff; }
  .lg\:focus\:bg-indigo-lightest:focus {
    background-color: #e6e8ff; }
  .lg\:focus\:bg-purple-darkest:focus {
    background-color: #21183c; }
  .lg\:focus\:bg-purple-darker:focus {
    background-color: #382b5f; }
  .lg\:focus\:bg-purple-dark:focus {
    background-color: #794acf; }
  .lg\:focus\:bg-purple:focus {
    background-color: #9561e2; }
  .lg\:focus\:bg-purple-light:focus {
    background-color: #a779e9; }
  .lg\:focus\:bg-purple-lighter:focus {
    background-color: #d6bbfc; }
  .lg\:focus\:bg-purple-lightest:focus {
    background-color: #f3ebff; }
  .lg\:focus\:bg-pink-darkest:focus {
    background-color: #451225; }
  .lg\:focus\:bg-pink-darker:focus {
    background-color: #6f213f; }
  .lg\:focus\:bg-pink-dark:focus {
    background-color: #eb5286; }
  .lg\:focus\:bg-pink:focus {
    background-color: #f66d9b; }
  .lg\:focus\:bg-pink-light:focus {
    background-color: #fa7ea8; }
  .lg\:focus\:bg-pink-lighter:focus {
    background-color: #ffbbca; }
  .lg\:focus\:bg-pink-lightest:focus {
    background-color: #ffebef; }
  .lg\:bg-bottom {
    background-position: bottom; }
  .lg\:bg-center {
    background-position: center; }
  .lg\:bg-left {
    background-position: left; }
  .lg\:bg-left-bottom {
    background-position: left bottom; }
  .lg\:bg-left-top {
    background-position: left top; }
  .lg\:bg-right {
    background-position: right; }
  .lg\:bg-right-bottom {
    background-position: right bottom; }
  .lg\:bg-right-top {
    background-position: right top; }
  .lg\:bg-top {
    background-position: top; }
  .lg\:bg-repeat {
    background-repeat: repeat; }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat; }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x; }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y; }
  .lg\:bg-auto {
    background-size: auto; }
  .lg\:bg-cover {
    background-size: cover; }
  .lg\:bg-contain {
    background-size: contain; }
  .lg\:border-transparent {
    border-color: transparent; }
  .lg\:border-black {
    border-color: #22292f; }
  .lg\:border-grey-darkest {
    border-color: #3d4852; }
  .lg\:border-grey-darker {
    border-color: #606f7b; }
  .lg\:border-grey-dark {
    border-color: #8795a1; }
  .lg\:border-grey {
    border-color: #b8c2cc; }
  .lg\:border-grey-light {
    border-color: #dae1e7; }
  .lg\:border-grey-lighter {
    border-color: #f1f5f8; }
  .lg\:border-grey-lightest {
    border-color: #f8fafc; }
  .lg\:border-white {
    border-color: #fff; }
  .lg\:border-red-darkest {
    border-color: #3b0d0c; }
  .lg\:border-red-darker {
    border-color: #621b18; }
  .lg\:border-red-dark {
    border-color: #cc1f1a; }
  .lg\:border-red {
    border-color: #e3342f; }
  .lg\:border-red-light {
    border-color: #ef5753; }
  .lg\:border-red-lighter {
    border-color: #f9acaa; }
  .lg\:border-red-lightest {
    border-color: #fcebea; }
  .lg\:border-orange-darkest {
    border-color: #462a16; }
  .lg\:border-orange-darker {
    border-color: #613b1f; }
  .lg\:border-orange-dark {
    border-color: #de751f; }
  .lg\:border-orange {
    border-color: #f6993f; }
  .lg\:border-orange-light {
    border-color: #faad63; }
  .lg\:border-orange-lighter {
    border-color: #fcd9b6; }
  .lg\:border-orange-lightest {
    border-color: #fff5eb; }
  .lg\:border-yellow-darkest {
    border-color: #453411; }
  .lg\:border-yellow-darker {
    border-color: #684f1d; }
  .lg\:border-yellow-dark {
    border-color: #f2d024; }
  .lg\:border-yellow {
    border-color: #ffed4a; }
  .lg\:border-yellow-light {
    border-color: #fff382; }
  .lg\:border-yellow-lighter {
    border-color: #fff9c2; }
  .lg\:border-yellow-lightest {
    border-color: #fcfbeb; }
  .lg\:border-green-darkest {
    border-color: #0f2f21; }
  .lg\:border-green-darker {
    border-color: #1a4731; }
  .lg\:border-green-dark {
    border-color: #1f9d55; }
  .lg\:border-green {
    border-color: #38c172; }
  .lg\:border-green-light {
    border-color: #51d88a; }
  .lg\:border-green-lighter {
    border-color: #a2f5bf; }
  .lg\:border-green-lightest {
    border-color: #e3fcec; }
  .lg\:border-teal-darkest {
    border-color: #0d3331; }
  .lg\:border-teal-darker {
    border-color: #20504f; }
  .lg\:border-teal-dark {
    border-color: #38a89d; }
  .lg\:border-teal {
    border-color: #4dc0b5; }
  .lg\:border-teal-light {
    border-color: #64d5ca; }
  .lg\:border-teal-lighter {
    border-color: #a0f0ed; }
  .lg\:border-teal-lightest {
    border-color: #e8fffe; }
  .lg\:border-blue-darkest {
    border-color: #12283a; }
  .lg\:border-blue-darker {
    border-color: #1c3d5a; }
  .lg\:border-blue-dark {
    border-color: #2779bd; }
  .lg\:border-blue {
    border-color: #3490dc; }
  .lg\:border-blue-light {
    border-color: #6cb2eb; }
  .lg\:border-blue-lighter {
    border-color: #bcdefa; }
  .lg\:border-blue-lightest {
    border-color: #eff8ff; }
  .lg\:border-indigo-darkest {
    border-color: #191e38; }
  .lg\:border-indigo-darker {
    border-color: #2f365f; }
  .lg\:border-indigo-dark {
    border-color: #5661b3; }
  .lg\:border-indigo {
    border-color: #6574cd; }
  .lg\:border-indigo-light {
    border-color: #7886d7; }
  .lg\:border-indigo-lighter {
    border-color: #b2b7ff; }
  .lg\:border-indigo-lightest {
    border-color: #e6e8ff; }
  .lg\:border-purple-darkest {
    border-color: #21183c; }
  .lg\:border-purple-darker {
    border-color: #382b5f; }
  .lg\:border-purple-dark {
    border-color: #794acf; }
  .lg\:border-purple {
    border-color: #9561e2; }
  .lg\:border-purple-light {
    border-color: #a779e9; }
  .lg\:border-purple-lighter {
    border-color: #d6bbfc; }
  .lg\:border-purple-lightest {
    border-color: #f3ebff; }
  .lg\:border-pink-darkest {
    border-color: #451225; }
  .lg\:border-pink-darker {
    border-color: #6f213f; }
  .lg\:border-pink-dark {
    border-color: #eb5286; }
  .lg\:border-pink {
    border-color: #f66d9b; }
  .lg\:border-pink-light {
    border-color: #fa7ea8; }
  .lg\:border-pink-lighter {
    border-color: #ffbbca; }
  .lg\:border-pink-lightest {
    border-color: #ffebef; }
  .lg\:hover\:border-transparent:hover {
    border-color: transparent; }
  .lg\:hover\:border-black:hover {
    border-color: #22292f; }
  .lg\:hover\:border-grey-darkest:hover {
    border-color: #3d4852; }
  .lg\:hover\:border-grey-darker:hover {
    border-color: #606f7b; }
  .lg\:hover\:border-grey-dark:hover {
    border-color: #8795a1; }
  .lg\:hover\:border-grey:hover {
    border-color: #b8c2cc; }
  .lg\:hover\:border-grey-light:hover {
    border-color: #dae1e7; }
  .lg\:hover\:border-grey-lighter:hover {
    border-color: #f1f5f8; }
  .lg\:hover\:border-grey-lightest:hover {
    border-color: #f8fafc; }
  .lg\:hover\:border-white:hover {
    border-color: #fff; }
  .lg\:hover\:border-red-darkest:hover {
    border-color: #3b0d0c; }
  .lg\:hover\:border-red-darker:hover {
    border-color: #621b18; }
  .lg\:hover\:border-red-dark:hover {
    border-color: #cc1f1a; }
  .lg\:hover\:border-red:hover {
    border-color: #e3342f; }
  .lg\:hover\:border-red-light:hover {
    border-color: #ef5753; }
  .lg\:hover\:border-red-lighter:hover {
    border-color: #f9acaa; }
  .lg\:hover\:border-red-lightest:hover {
    border-color: #fcebea; }
  .lg\:hover\:border-orange-darkest:hover {
    border-color: #462a16; }
  .lg\:hover\:border-orange-darker:hover {
    border-color: #613b1f; }
  .lg\:hover\:border-orange-dark:hover {
    border-color: #de751f; }
  .lg\:hover\:border-orange:hover {
    border-color: #f6993f; }
  .lg\:hover\:border-orange-light:hover {
    border-color: #faad63; }
  .lg\:hover\:border-orange-lighter:hover {
    border-color: #fcd9b6; }
  .lg\:hover\:border-orange-lightest:hover {
    border-color: #fff5eb; }
  .lg\:hover\:border-yellow-darkest:hover {
    border-color: #453411; }
  .lg\:hover\:border-yellow-darker:hover {
    border-color: #684f1d; }
  .lg\:hover\:border-yellow-dark:hover {
    border-color: #f2d024; }
  .lg\:hover\:border-yellow:hover {
    border-color: #ffed4a; }
  .lg\:hover\:border-yellow-light:hover {
    border-color: #fff382; }
  .lg\:hover\:border-yellow-lighter:hover {
    border-color: #fff9c2; }
  .lg\:hover\:border-yellow-lightest:hover {
    border-color: #fcfbeb; }
  .lg\:hover\:border-green-darkest:hover {
    border-color: #0f2f21; }
  .lg\:hover\:border-green-darker:hover {
    border-color: #1a4731; }
  .lg\:hover\:border-green-dark:hover {
    border-color: #1f9d55; }
  .lg\:hover\:border-green:hover {
    border-color: #38c172; }
  .lg\:hover\:border-green-light:hover {
    border-color: #51d88a; }
  .lg\:hover\:border-green-lighter:hover {
    border-color: #a2f5bf; }
  .lg\:hover\:border-green-lightest:hover {
    border-color: #e3fcec; }
  .lg\:hover\:border-teal-darkest:hover {
    border-color: #0d3331; }
  .lg\:hover\:border-teal-darker:hover {
    border-color: #20504f; }
  .lg\:hover\:border-teal-dark:hover {
    border-color: #38a89d; }
  .lg\:hover\:border-teal:hover {
    border-color: #4dc0b5; }
  .lg\:hover\:border-teal-light:hover {
    border-color: #64d5ca; }
  .lg\:hover\:border-teal-lighter:hover {
    border-color: #a0f0ed; }
  .lg\:hover\:border-teal-lightest:hover {
    border-color: #e8fffe; }
  .lg\:hover\:border-blue-darkest:hover {
    border-color: #12283a; }
  .lg\:hover\:border-blue-darker:hover {
    border-color: #1c3d5a; }
  .lg\:hover\:border-blue-dark:hover {
    border-color: #2779bd; }
  .lg\:hover\:border-blue:hover {
    border-color: #3490dc; }
  .lg\:hover\:border-blue-light:hover {
    border-color: #6cb2eb; }
  .lg\:hover\:border-blue-lighter:hover {
    border-color: #bcdefa; }
  .lg\:hover\:border-blue-lightest:hover {
    border-color: #eff8ff; }
  .lg\:hover\:border-indigo-darkest:hover {
    border-color: #191e38; }
  .lg\:hover\:border-indigo-darker:hover {
    border-color: #2f365f; }
  .lg\:hover\:border-indigo-dark:hover {
    border-color: #5661b3; }
  .lg\:hover\:border-indigo:hover {
    border-color: #6574cd; }
  .lg\:hover\:border-indigo-light:hover {
    border-color: #7886d7; }
  .lg\:hover\:border-indigo-lighter:hover {
    border-color: #b2b7ff; }
  .lg\:hover\:border-indigo-lightest:hover {
    border-color: #e6e8ff; }
  .lg\:hover\:border-purple-darkest:hover {
    border-color: #21183c; }
  .lg\:hover\:border-purple-darker:hover {
    border-color: #382b5f; }
  .lg\:hover\:border-purple-dark:hover {
    border-color: #794acf; }
  .lg\:hover\:border-purple:hover {
    border-color: #9561e2; }
  .lg\:hover\:border-purple-light:hover {
    border-color: #a779e9; }
  .lg\:hover\:border-purple-lighter:hover {
    border-color: #d6bbfc; }
  .lg\:hover\:border-purple-lightest:hover {
    border-color: #f3ebff; }
  .lg\:hover\:border-pink-darkest:hover {
    border-color: #451225; }
  .lg\:hover\:border-pink-darker:hover {
    border-color: #6f213f; }
  .lg\:hover\:border-pink-dark:hover {
    border-color: #eb5286; }
  .lg\:hover\:border-pink:hover {
    border-color: #f66d9b; }
  .lg\:hover\:border-pink-light:hover {
    border-color: #fa7ea8; }
  .lg\:hover\:border-pink-lighter:hover {
    border-color: #ffbbca; }
  .lg\:hover\:border-pink-lightest:hover {
    border-color: #ffebef; }
  .lg\:focus\:border-transparent:focus {
    border-color: transparent; }
  .lg\:focus\:border-black:focus {
    border-color: #22292f; }
  .lg\:focus\:border-grey-darkest:focus {
    border-color: #3d4852; }
  .lg\:focus\:border-grey-darker:focus {
    border-color: #606f7b; }
  .lg\:focus\:border-grey-dark:focus {
    border-color: #8795a1; }
  .lg\:focus\:border-grey:focus {
    border-color: #b8c2cc; }
  .lg\:focus\:border-grey-light:focus {
    border-color: #dae1e7; }
  .lg\:focus\:border-grey-lighter:focus {
    border-color: #f1f5f8; }
  .lg\:focus\:border-grey-lightest:focus {
    border-color: #f8fafc; }
  .lg\:focus\:border-white:focus {
    border-color: #fff; }
  .lg\:focus\:border-red-darkest:focus {
    border-color: #3b0d0c; }
  .lg\:focus\:border-red-darker:focus {
    border-color: #621b18; }
  .lg\:focus\:border-red-dark:focus {
    border-color: #cc1f1a; }
  .lg\:focus\:border-red:focus {
    border-color: #e3342f; }
  .lg\:focus\:border-red-light:focus {
    border-color: #ef5753; }
  .lg\:focus\:border-red-lighter:focus {
    border-color: #f9acaa; }
  .lg\:focus\:border-red-lightest:focus {
    border-color: #fcebea; }
  .lg\:focus\:border-orange-darkest:focus {
    border-color: #462a16; }
  .lg\:focus\:border-orange-darker:focus {
    border-color: #613b1f; }
  .lg\:focus\:border-orange-dark:focus {
    border-color: #de751f; }
  .lg\:focus\:border-orange:focus {
    border-color: #f6993f; }
  .lg\:focus\:border-orange-light:focus {
    border-color: #faad63; }
  .lg\:focus\:border-orange-lighter:focus {
    border-color: #fcd9b6; }
  .lg\:focus\:border-orange-lightest:focus {
    border-color: #fff5eb; }
  .lg\:focus\:border-yellow-darkest:focus {
    border-color: #453411; }
  .lg\:focus\:border-yellow-darker:focus {
    border-color: #684f1d; }
  .lg\:focus\:border-yellow-dark:focus {
    border-color: #f2d024; }
  .lg\:focus\:border-yellow:focus {
    border-color: #ffed4a; }
  .lg\:focus\:border-yellow-light:focus {
    border-color: #fff382; }
  .lg\:focus\:border-yellow-lighter:focus {
    border-color: #fff9c2; }
  .lg\:focus\:border-yellow-lightest:focus {
    border-color: #fcfbeb; }
  .lg\:focus\:border-green-darkest:focus {
    border-color: #0f2f21; }
  .lg\:focus\:border-green-darker:focus {
    border-color: #1a4731; }
  .lg\:focus\:border-green-dark:focus {
    border-color: #1f9d55; }
  .lg\:focus\:border-green:focus {
    border-color: #38c172; }
  .lg\:focus\:border-green-light:focus {
    border-color: #51d88a; }
  .lg\:focus\:border-green-lighter:focus {
    border-color: #a2f5bf; }
  .lg\:focus\:border-green-lightest:focus {
    border-color: #e3fcec; }
  .lg\:focus\:border-teal-darkest:focus {
    border-color: #0d3331; }
  .lg\:focus\:border-teal-darker:focus {
    border-color: #20504f; }
  .lg\:focus\:border-teal-dark:focus {
    border-color: #38a89d; }
  .lg\:focus\:border-teal:focus {
    border-color: #4dc0b5; }
  .lg\:focus\:border-teal-light:focus {
    border-color: #64d5ca; }
  .lg\:focus\:border-teal-lighter:focus {
    border-color: #a0f0ed; }
  .lg\:focus\:border-teal-lightest:focus {
    border-color: #e8fffe; }
  .lg\:focus\:border-blue-darkest:focus {
    border-color: #12283a; }
  .lg\:focus\:border-blue-darker:focus {
    border-color: #1c3d5a; }
  .lg\:focus\:border-blue-dark:focus {
    border-color: #2779bd; }
  .lg\:focus\:border-blue:focus {
    border-color: #3490dc; }
  .lg\:focus\:border-blue-light:focus {
    border-color: #6cb2eb; }
  .lg\:focus\:border-blue-lighter:focus {
    border-color: #bcdefa; }
  .lg\:focus\:border-blue-lightest:focus {
    border-color: #eff8ff; }
  .lg\:focus\:border-indigo-darkest:focus {
    border-color: #191e38; }
  .lg\:focus\:border-indigo-darker:focus {
    border-color: #2f365f; }
  .lg\:focus\:border-indigo-dark:focus {
    border-color: #5661b3; }
  .lg\:focus\:border-indigo:focus {
    border-color: #6574cd; }
  .lg\:focus\:border-indigo-light:focus {
    border-color: #7886d7; }
  .lg\:focus\:border-indigo-lighter:focus {
    border-color: #b2b7ff; }
  .lg\:focus\:border-indigo-lightest:focus {
    border-color: #e6e8ff; }
  .lg\:focus\:border-purple-darkest:focus {
    border-color: #21183c; }
  .lg\:focus\:border-purple-darker:focus {
    border-color: #382b5f; }
  .lg\:focus\:border-purple-dark:focus {
    border-color: #794acf; }
  .lg\:focus\:border-purple:focus {
    border-color: #9561e2; }
  .lg\:focus\:border-purple-light:focus {
    border-color: #a779e9; }
  .lg\:focus\:border-purple-lighter:focus {
    border-color: #d6bbfc; }
  .lg\:focus\:border-purple-lightest:focus {
    border-color: #f3ebff; }
  .lg\:focus\:border-pink-darkest:focus {
    border-color: #451225; }
  .lg\:focus\:border-pink-darker:focus {
    border-color: #6f213f; }
  .lg\:focus\:border-pink-dark:focus {
    border-color: #eb5286; }
  .lg\:focus\:border-pink:focus {
    border-color: #f66d9b; }
  .lg\:focus\:border-pink-light:focus {
    border-color: #fa7ea8; }
  .lg\:focus\:border-pink-lighter:focus {
    border-color: #ffbbca; }
  .lg\:focus\:border-pink-lightest:focus {
    border-color: #ffebef; }
  .lg\:rounded-none {
    border-radius: 0; }
  .lg\:rounded-sm {
    border-radius: .125rem; }
  .lg\:rounded {
    border-radius: .25rem; }
  .lg\:rounded-lg {
    border-radius: .5rem; }
  .lg\:rounded-full {
    border-radius: 9999px; }
  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .lg\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem; }
  .lg\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem; }
  .lg\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .lg\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .lg\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .lg\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem; }
  .lg\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .lg\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .lg\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }
  .lg\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem; }
  .lg\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .lg\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px; }
  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px; }
  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .lg\:rounded-tl-none {
    border-top-left-radius: 0; }
  .lg\:rounded-tr-none {
    border-top-right-radius: 0; }
  .lg\:rounded-br-none {
    border-bottom-right-radius: 0; }
  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0; }
  .lg\:rounded-tl-sm {
    border-top-left-radius: .125rem; }
  .lg\:rounded-tr-sm {
    border-top-right-radius: .125rem; }
  .lg\:rounded-br-sm {
    border-bottom-right-radius: .125rem; }
  .lg\:rounded-bl-sm {
    border-bottom-left-radius: .125rem; }
  .lg\:rounded-tl {
    border-top-left-radius: .25rem; }
  .lg\:rounded-tr {
    border-top-right-radius: .25rem; }
  .lg\:rounded-br {
    border-bottom-right-radius: .25rem; }
  .lg\:rounded-bl {
    border-bottom-left-radius: .25rem; }
  .lg\:rounded-tl-lg {
    border-top-left-radius: .5rem; }
  .lg\:rounded-tr-lg {
    border-top-right-radius: .5rem; }
  .lg\:rounded-br-lg {
    border-bottom-right-radius: .5rem; }
  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .5rem; }
  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px; }
  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px; }
  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px; }
  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px; }
  .lg\:border-solid {
    border-style: solid; }
  .lg\:border-dashed {
    border-style: dashed; }
  .lg\:border-dotted {
    border-style: dotted; }
  .lg\:border-none {
    border-style: none; }
  .lg\:border-0 {
    border-width: 0; }
  .lg\:border-2 {
    border-width: 2px; }
  .lg\:border-4 {
    border-width: 4px; }
  .lg\:border-8 {
    border-width: 8px; }
  .lg\:border {
    border-width: 1px; }
  .lg\:border-t-0 {
    border-top-width: 0; }
  .lg\:border-r-0 {
    border-right-width: 0; }
  .lg\:border-b-0 {
    border-bottom-width: 0; }
  .lg\:border-l-0 {
    border-left-width: 0; }
  .lg\:border-t-2 {
    border-top-width: 2px; }
  .lg\:border-r-2 {
    border-right-width: 2px; }
  .lg\:border-b-2 {
    border-bottom-width: 2px; }
  .lg\:border-l-2 {
    border-left-width: 2px; }
  .lg\:border-t-4 {
    border-top-width: 4px; }
  .lg\:border-r-4 {
    border-right-width: 4px; }
  .lg\:border-b-4 {
    border-bottom-width: 4px; }
  .lg\:border-l-4 {
    border-left-width: 4px; }
  .lg\:border-t-8 {
    border-top-width: 8px; }
  .lg\:border-r-8 {
    border-right-width: 8px; }
  .lg\:border-b-8 {
    border-bottom-width: 8px; }
  .lg\:border-l-8 {
    border-left-width: 8px; }
  .lg\:border-t {
    border-top-width: 1px; }
  .lg\:border-r {
    border-right-width: 1px; }
  .lg\:border-b {
    border-bottom-width: 1px; }
  .lg\:border-l {
    border-left-width: 1px; }
  .lg\:cursor-auto {
    cursor: auto; }
  .lg\:cursor-default {
    cursor: default; }
  .lg\:cursor-pointer {
    cursor: pointer; }
  .lg\:cursor-wait {
    cursor: wait; }
  .lg\:cursor-move {
    cursor: move; }
  .lg\:cursor-not-allowed {
    cursor: not-allowed; }
  .lg\:block {
    display: block; }
  .lg\:inline-block {
    display: inline-block; }
  .lg\:inline {
    display: inline; }
  .lg\:table {
    display: table; }
  .lg\:table-row {
    display: table-row; }
  .lg\:table-cell {
    display: table-cell; }
  .lg\:hidden {
    display: none; }
  .lg\:flex {
    display: flex; }
  .lg\:inline-flex {
    display: inline-flex; }
  .lg\:flex-row {
    flex-direction: row; }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse; }
  .lg\:flex-col {
    flex-direction: column; }
  .lg\:flex-col-reverse {
    flex-direction: column-reverse; }
  .lg\:flex-wrap {
    flex-wrap: wrap; }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse; }
  .lg\:flex-no-wrap {
    flex-wrap: nowrap; }
  .lg\:items-start {
    align-items: flex-start; }
  .lg\:items-end {
    align-items: flex-end; }
  .lg\:items-center {
    align-items: center; }
  .lg\:items-baseline {
    align-items: baseline; }
  .lg\:items-stretch {
    align-items: stretch; }
  .lg\:self-auto {
    align-self: auto; }
  .lg\:self-start {
    align-self: flex-start; }
  .lg\:self-end {
    align-self: flex-end; }
  .lg\:self-center {
    align-self: center; }
  .lg\:self-stretch {
    align-self: stretch; }
  .lg\:justify-start {
    justify-content: flex-start; }
  .lg\:justify-end {
    justify-content: flex-end; }
  .lg\:justify-center {
    justify-content: center; }
  .lg\:justify-between {
    justify-content: space-between; }
  .lg\:justify-around {
    justify-content: space-around; }
  .lg\:content-center {
    align-content: center; }
  .lg\:content-start {
    align-content: flex-start; }
  .lg\:content-end {
    align-content: flex-end; }
  .lg\:content-between {
    align-content: space-between; }
  .lg\:content-around {
    align-content: space-around; }
  .lg\:flex-1 {
    flex: 1 1 0%; }
  .lg\:flex-auto {
    flex: 1 1 auto; }
  .lg\:flex-initial {
    flex: 0 1 auto; }
  .lg\:flex-none {
    flex: none; }
  .lg\:flex-grow {
    flex-grow: 1; }
  .lg\:flex-shrink {
    flex-shrink: 1; }
  .lg\:flex-no-grow {
    flex-grow: 0; }
  .lg\:flex-no-shrink {
    flex-shrink: 0; }
  .lg\:float-right {
    float: right; }
  .lg\:float-left {
    float: left; }
  .lg\:float-none {
    float: none; }
  .lg\:clearfix:after {
    content: "";
    display: table;
    clear: both; }
  .lg\:font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
  .lg\:font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif; }
  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }
  .lg\:font-hairline {
    font-weight: 100; }
  .lg\:font-thin {
    font-weight: 200; }
  .lg\:font-light {
    font-weight: 300; }
  .lg\:font-normal {
    font-weight: 400; }
  .lg\:font-medium {
    font-weight: 500; }
  .lg\:font-semibold {
    font-weight: 600; }
  .lg\:font-bold {
    font-weight: 700; }
  .lg\:font-extrabold {
    font-weight: 800; }
  .lg\:font-black {
    font-weight: 900; }
  .lg\:hover\:font-hairline:hover {
    font-weight: 100; }
  .lg\:hover\:font-thin:hover {
    font-weight: 200; }
  .lg\:hover\:font-light:hover {
    font-weight: 300; }
  .lg\:hover\:font-normal:hover {
    font-weight: 400; }
  .lg\:hover\:font-medium:hover {
    font-weight: 500; }
  .lg\:hover\:font-semibold:hover {
    font-weight: 600; }
  .lg\:hover\:font-bold:hover {
    font-weight: 700; }
  .lg\:hover\:font-extrabold:hover {
    font-weight: 800; }
  .lg\:hover\:font-black:hover {
    font-weight: 900; }
  .lg\:focus\:font-hairline:focus {
    font-weight: 100; }
  .lg\:focus\:font-thin:focus {
    font-weight: 200; }
  .lg\:focus\:font-light:focus {
    font-weight: 300; }
  .lg\:focus\:font-normal:focus {
    font-weight: 400; }
  .lg\:focus\:font-medium:focus {
    font-weight: 500; }
  .lg\:focus\:font-semibold:focus {
    font-weight: 600; }
  .lg\:focus\:font-bold:focus {
    font-weight: 700; }
  .lg\:focus\:font-extrabold:focus {
    font-weight: 800; }
  .lg\:focus\:font-black:focus {
    font-weight: 900; }
  .lg\:h-1 {
    height: .25rem; }
  .lg\:h-2 {
    height: .5rem; }
  .lg\:h-3 {
    height: .75rem; }
  .lg\:h-4 {
    height: 1rem; }
  .lg\:h-5 {
    height: 1.25rem; }
  .lg\:h-6 {
    height: 1.5rem; }
  .lg\:h-8 {
    height: 2rem; }
  .lg\:h-10 {
    height: 2.5rem; }
  .lg\:h-12 {
    height: 3rem; }
  .lg\:h-16 {
    height: 4rem; }
  .lg\:h-24 {
    height: 6rem; }
  .lg\:h-32 {
    height: 8rem; }
  .lg\:h-48 {
    height: 12rem; }
  .lg\:h-64 {
    height: 16rem; }
  .lg\:h-auto {
    height: auto; }
  .lg\:h-px {
    height: 1px; }
  .lg\:h-full {
    height: 100%; }
  .lg\:h-screen {
    height: 100vh; }
  .lg\:leading-none {
    line-height: 1; }
  .lg\:leading-tight {
    line-height: 1.25; }
  .lg\:leading-normal {
    line-height: 1.5; }
  .lg\:leading-loose {
    line-height: 2; }
  .lg\:m-0 {
    margin: 0; }
  .lg\:m-1 {
    margin: .25rem; }
  .lg\:m-2 {
    margin: .5rem; }
  .lg\:m-3 {
    margin: .75rem; }
  .lg\:m-4 {
    margin: 1rem; }
  .lg\:m-5 {
    margin: 1.25rem; }
  .lg\:m-6 {
    margin: 1.5rem; }
  .lg\:m-8 {
    margin: 2rem; }
  .lg\:m-10 {
    margin: 2.5rem; }
  .lg\:m-12 {
    margin: 3rem; }
  .lg\:m-16 {
    margin: 4rem; }
  .lg\:m-20 {
    margin: 5rem; }
  .lg\:m-24 {
    margin: 6rem; }
  .lg\:m-32 {
    margin: 8rem; }
  .lg\:m-auto {
    margin: auto; }
  .lg\:m-px {
    margin: 1px; }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .lg\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem; }
  .lg\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem; }
  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem; }
  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem; }
  .lg\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem; }
  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px; }
  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px; }
  .lg\:mt-0 {
    margin-top: 0; }
  .lg\:mr-0 {
    margin-right: 0; }
  .lg\:mb-0 {
    margin-bottom: 0; }
  .lg\:ml-0 {
    margin-left: 0; }
  .lg\:mt-1 {
    margin-top: .25rem; }
  .lg\:mr-1 {
    margin-right: .25rem; }
  .lg\:mb-1 {
    margin-bottom: .25rem; }
  .lg\:ml-1 {
    margin-left: .25rem; }
  .lg\:mt-2 {
    margin-top: .5rem; }
  .lg\:mr-2 {
    margin-right: .5rem; }
  .lg\:mb-2 {
    margin-bottom: .5rem; }
  .lg\:ml-2 {
    margin-left: .5rem; }
  .lg\:mt-3 {
    margin-top: .75rem; }
  .lg\:mr-3 {
    margin-right: .75rem; }
  .lg\:mb-3 {
    margin-bottom: .75rem; }
  .lg\:ml-3 {
    margin-left: .75rem; }
  .lg\:mt-4 {
    margin-top: 1rem; }
  .lg\:mr-4 {
    margin-right: 1rem; }
  .lg\:mb-4 {
    margin-bottom: 1rem; }
  .lg\:ml-4 {
    margin-left: 1rem; }
  .lg\:mt-5 {
    margin-top: 1.25rem; }
  .lg\:mr-5 {
    margin-right: 1.25rem; }
  .lg\:mb-5 {
    margin-bottom: 1.25rem; }
  .lg\:ml-5 {
    margin-left: 1.25rem; }
  .lg\:mt-6 {
    margin-top: 1.5rem; }
  .lg\:mr-6 {
    margin-right: 1.5rem; }
  .lg\:mb-6 {
    margin-bottom: 1.5rem; }
  .lg\:ml-6 {
    margin-left: 1.5rem; }
  .lg\:mt-8 {
    margin-top: 2rem; }
  .lg\:mr-8 {
    margin-right: 2rem; }
  .lg\:mb-8 {
    margin-bottom: 2rem; }
  .lg\:ml-8 {
    margin-left: 2rem; }
  .lg\:mt-10 {
    margin-top: 2.5rem; }
  .lg\:mr-10 {
    margin-right: 2.5rem; }
  .lg\:mb-10 {
    margin-bottom: 2.5rem; }
  .lg\:ml-10 {
    margin-left: 2.5rem; }
  .lg\:mt-12 {
    margin-top: 3rem; }
  .lg\:mr-12 {
    margin-right: 3rem; }
  .lg\:mb-12 {
    margin-bottom: 3rem; }
  .lg\:ml-12 {
    margin-left: 3rem; }
  .lg\:mt-16 {
    margin-top: 4rem; }
  .lg\:mr-16 {
    margin-right: 4rem; }
  .lg\:mb-16 {
    margin-bottom: 4rem; }
  .lg\:ml-16 {
    margin-left: 4rem; }
  .lg\:mt-20 {
    margin-top: 5rem; }
  .lg\:mr-20 {
    margin-right: 5rem; }
  .lg\:mb-20 {
    margin-bottom: 5rem; }
  .lg\:ml-20 {
    margin-left: 5rem; }
  .lg\:mt-24 {
    margin-top: 6rem; }
  .lg\:mr-24 {
    margin-right: 6rem; }
  .lg\:mb-24 {
    margin-bottom: 6rem; }
  .lg\:ml-24 {
    margin-left: 6rem; }
  .lg\:mt-32 {
    margin-top: 8rem; }
  .lg\:mr-32 {
    margin-right: 8rem; }
  .lg\:mb-32 {
    margin-bottom: 8rem; }
  .lg\:ml-32 {
    margin-left: 8rem; }
  .lg\:mt-auto {
    margin-top: auto; }
  .lg\:mr-auto {
    margin-right: auto; }
  .lg\:mb-auto {
    margin-bottom: auto; }
  .lg\:ml-auto {
    margin-left: auto; }
  .lg\:mt-px {
    margin-top: 1px; }
  .lg\:mr-px {
    margin-right: 1px; }
  .lg\:mb-px {
    margin-bottom: 1px; }
  .lg\:ml-px {
    margin-left: 1px; }
  .lg\:max-h-full {
    max-height: 100%; }
  .lg\:max-h-screen {
    max-height: 100vh; }
  .lg\:max-w-xs {
    max-width: 20rem; }
  .lg\:max-w-sm {
    max-width: 30rem; }
  .lg\:max-w-md {
    max-width: 40rem; }
  .lg\:max-w-lg {
    max-width: 50rem; }
  .lg\:max-w-xl {
    max-width: 60rem; }
  .lg\:max-w-2xl {
    max-width: 70rem; }
  .lg\:max-w-3xl {
    max-width: 80rem; }
  .lg\:max-w-4xl {
    max-width: 90rem; }
  .lg\:max-w-5xl {
    max-width: 100rem; }
  .lg\:max-w-full {
    max-width: 100%; }
  .lg\:min-h-0 {
    min-height: 0; }
  .lg\:min-h-full {
    min-height: 100%; }
  .lg\:min-h-screen {
    min-height: 100vh; }
  .lg\:min-w-0 {
    min-width: 0; }
  .lg\:min-w-full {
    min-width: 100%; }
  .lg\:-m-0 {
    margin: 0; }
  .lg\:-m-1 {
    margin: -0.25rem; }
  .lg\:-m-2 {
    margin: -0.5rem; }
  .lg\:-m-3 {
    margin: -0.75rem; }
  .lg\:-m-4 {
    margin: -1rem; }
  .lg\:-m-5 {
    margin: -1.25rem; }
  .lg\:-m-6 {
    margin: -1.5rem; }
  .lg\:-m-8 {
    margin: -2rem; }
  .lg\:-m-10 {
    margin: -2.5rem; }
  .lg\:-m-12 {
    margin: -3rem; }
  .lg\:-m-16 {
    margin: -4rem; }
  .lg\:-m-20 {
    margin: -5rem; }
  .lg\:-m-24 {
    margin: -6rem; }
  .lg\:-m-32 {
    margin: -8rem; }
  .lg\:-m-px {
    margin: -1px; }
  .lg\:-my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .lg\:-mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem; }
  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem; }
  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem; }
  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem; }
  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem; }
  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem; }
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem; }
  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem; }
  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem; }
  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem; }
  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem; }
  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem; }
  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem; }
  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px; }
  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px; }
  .lg\:-mt-0 {
    margin-top: 0; }
  .lg\:-mr-0 {
    margin-right: 0; }
  .lg\:-mb-0 {
    margin-bottom: 0; }
  .lg\:-ml-0 {
    margin-left: 0; }
  .lg\:-mt-1 {
    margin-top: -0.25rem; }
  .lg\:-mr-1 {
    margin-right: -0.25rem; }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem; }
  .lg\:-ml-1 {
    margin-left: -0.25rem; }
  .lg\:-mt-2 {
    margin-top: -0.5rem; }
  .lg\:-mr-2 {
    margin-right: -0.5rem; }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem; }
  .lg\:-ml-2 {
    margin-left: -0.5rem; }
  .lg\:-mt-3 {
    margin-top: -0.75rem; }
  .lg\:-mr-3 {
    margin-right: -0.75rem; }
  .lg\:-mb-3 {
    margin-bottom: -0.75rem; }
  .lg\:-ml-3 {
    margin-left: -0.75rem; }
  .lg\:-mt-4 {
    margin-top: -1rem; }
  .lg\:-mr-4 {
    margin-right: -1rem; }
  .lg\:-mb-4 {
    margin-bottom: -1rem; }
  .lg\:-ml-4 {
    margin-left: -1rem; }
  .lg\:-mt-5 {
    margin-top: -1.25rem; }
  .lg\:-mr-5 {
    margin-right: -1.25rem; }
  .lg\:-mb-5 {
    margin-bottom: -1.25rem; }
  .lg\:-ml-5 {
    margin-left: -1.25rem; }
  .lg\:-mt-6 {
    margin-top: -1.5rem; }
  .lg\:-mr-6 {
    margin-right: -1.5rem; }
  .lg\:-mb-6 {
    margin-bottom: -1.5rem; }
  .lg\:-ml-6 {
    margin-left: -1.5rem; }
  .lg\:-mt-8 {
    margin-top: -2rem; }
  .lg\:-mr-8 {
    margin-right: -2rem; }
  .lg\:-mb-8 {
    margin-bottom: -2rem; }
  .lg\:-ml-8 {
    margin-left: -2rem; }
  .lg\:-mt-10 {
    margin-top: -2.5rem; }
  .lg\:-mr-10 {
    margin-right: -2.5rem; }
  .lg\:-mb-10 {
    margin-bottom: -2.5rem; }
  .lg\:-ml-10 {
    margin-left: -2.5rem; }
  .lg\:-mt-12 {
    margin-top: -3rem; }
  .lg\:-mr-12 {
    margin-right: -3rem; }
  .lg\:-mb-12 {
    margin-bottom: -3rem; }
  .lg\:-ml-12 {
    margin-left: -3rem; }
  .lg\:-mt-16 {
    margin-top: -4rem; }
  .lg\:-mr-16 {
    margin-right: -4rem; }
  .lg\:-mb-16 {
    margin-bottom: -4rem; }
  .lg\:-ml-16 {
    margin-left: -4rem; }
  .lg\:-mt-20 {
    margin-top: -5rem; }
  .lg\:-mr-20 {
    margin-right: -5rem; }
  .lg\:-mb-20 {
    margin-bottom: -5rem; }
  .lg\:-ml-20 {
    margin-left: -5rem; }
  .lg\:-mt-24 {
    margin-top: -6rem; }
  .lg\:-mr-24 {
    margin-right: -6rem; }
  .lg\:-mb-24 {
    margin-bottom: -6rem; }
  .lg\:-ml-24 {
    margin-left: -6rem; }
  .lg\:-mt-32 {
    margin-top: -8rem; }
  .lg\:-mr-32 {
    margin-right: -8rem; }
  .lg\:-mb-32 {
    margin-bottom: -8rem; }
  .lg\:-ml-32 {
    margin-left: -8rem; }
  .lg\:-mt-px {
    margin-top: -1px; }
  .lg\:-mr-px {
    margin-right: -1px; }
  .lg\:-mb-px {
    margin-bottom: -1px; }
  .lg\:-ml-px {
    margin-left: -1px; }
  .lg\:opacity-0 {
    opacity: 0; }
  .lg\:opacity-25 {
    opacity: .25; }
  .lg\:opacity-50 {
    opacity: .5; }
  .lg\:opacity-75 {
    opacity: .75; }
  .lg\:opacity-100 {
    opacity: 1; }
  .lg\:overflow-auto {
    overflow: auto; }
  .lg\:overflow-hidden {
    overflow: hidden; }
  .lg\:overflow-visible {
    overflow: visible; }
  .lg\:overflow-scroll {
    overflow: scroll; }
  .lg\:overflow-x-auto {
    overflow-x: auto; }
  .lg\:overflow-y-auto {
    overflow-y: auto; }
  .lg\:overflow-x-hidden {
    overflow-x: hidden; }
  .lg\:overflow-y-hidden {
    overflow-y: hidden; }
  .lg\:overflow-x-visible {
    overflow-x: visible; }
  .lg\:overflow-y-visible {
    overflow-y: visible; }
  .lg\:overflow-x-scroll {
    overflow-x: scroll; }
  .lg\:overflow-y-scroll {
    overflow-y: scroll; }
  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch; }
  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto; }
  .lg\:p-0 {
    padding: 0; }
  .lg\:p-1 {
    padding: .25rem; }
  .lg\:p-2 {
    padding: .5rem; }
  .lg\:p-3 {
    padding: .75rem; }
  .lg\:p-4 {
    padding: 1rem; }
  .lg\:p-5 {
    padding: 1.25rem; }
  .lg\:p-6 {
    padding: 1.5rem; }
  .lg\:p-8 {
    padding: 2rem; }
  .lg\:p-10 {
    padding: 2.5rem; }
  .lg\:p-12 {
    padding: 3rem; }
  .lg\:p-16 {
    padding: 4rem; }
  .lg\:p-20 {
    padding: 5rem; }
  .lg\:p-24 {
    padding: 6rem; }
  .lg\:p-32 {
    padding: 8rem; }
  .lg\:p-px {
    padding: 1px; }
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0; }
  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem; }
  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem; }
  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem; }
  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem; }
  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem; }
  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px; }
  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px; }
  .lg\:pt-0 {
    padding-top: 0; }
  .lg\:pr-0 {
    padding-right: 0; }
  .lg\:pb-0 {
    padding-bottom: 0; }
  .lg\:pl-0 {
    padding-left: 0; }
  .lg\:pt-1 {
    padding-top: .25rem; }
  .lg\:pr-1 {
    padding-right: .25rem; }
  .lg\:pb-1 {
    padding-bottom: .25rem; }
  .lg\:pl-1 {
    padding-left: .25rem; }
  .lg\:pt-2 {
    padding-top: .5rem; }
  .lg\:pr-2 {
    padding-right: .5rem; }
  .lg\:pb-2 {
    padding-bottom: .5rem; }
  .lg\:pl-2 {
    padding-left: .5rem; }
  .lg\:pt-3 {
    padding-top: .75rem; }
  .lg\:pr-3 {
    padding-right: .75rem; }
  .lg\:pb-3 {
    padding-bottom: .75rem; }
  .lg\:pl-3 {
    padding-left: .75rem; }
  .lg\:pt-4 {
    padding-top: 1rem; }
  .lg\:pr-4 {
    padding-right: 1rem; }
  .lg\:pb-4 {
    padding-bottom: 1rem; }
  .lg\:pl-4 {
    padding-left: 1rem; }
  .lg\:pt-5 {
    padding-top: 1.25rem; }
  .lg\:pr-5 {
    padding-right: 1.25rem; }
  .lg\:pb-5 {
    padding-bottom: 1.25rem; }
  .lg\:pl-5 {
    padding-left: 1.25rem; }
  .lg\:pt-6 {
    padding-top: 1.5rem; }
  .lg\:pr-6 {
    padding-right: 1.5rem; }
  .lg\:pb-6 {
    padding-bottom: 1.5rem; }
  .lg\:pl-6 {
    padding-left: 1.5rem; }
  .lg\:pt-8 {
    padding-top: 2rem; }
  .lg\:pr-8 {
    padding-right: 2rem; }
  .lg\:pb-8 {
    padding-bottom: 2rem; }
  .lg\:pl-8 {
    padding-left: 2rem; }
  .lg\:pt-10 {
    padding-top: 2.5rem; }
  .lg\:pr-10 {
    padding-right: 2.5rem; }
  .lg\:pb-10 {
    padding-bottom: 2.5rem; }
  .lg\:pl-10 {
    padding-left: 2.5rem; }
  .lg\:pt-12 {
    padding-top: 3rem; }
  .lg\:pr-12 {
    padding-right: 3rem; }
  .lg\:pb-12 {
    padding-bottom: 3rem; }
  .lg\:pl-12 {
    padding-left: 3rem; }
  .lg\:pt-16 {
    padding-top: 4rem; }
  .lg\:pr-16 {
    padding-right: 4rem; }
  .lg\:pb-16 {
    padding-bottom: 4rem; }
  .lg\:pl-16 {
    padding-left: 4rem; }
  .lg\:pt-20 {
    padding-top: 5rem; }
  .lg\:pr-20 {
    padding-right: 5rem; }
  .lg\:pb-20 {
    padding-bottom: 5rem; }
  .lg\:pl-20 {
    padding-left: 5rem; }
  .lg\:pt-24 {
    padding-top: 6rem; }
  .lg\:pr-24 {
    padding-right: 6rem; }
  .lg\:pb-24 {
    padding-bottom: 6rem; }
  .lg\:pl-24 {
    padding-left: 6rem; }
  .lg\:pt-32 {
    padding-top: 8rem; }
  .lg\:pr-32 {
    padding-right: 8rem; }
  .lg\:pb-32 {
    padding-bottom: 8rem; }
  .lg\:pl-32 {
    padding-left: 8rem; }
  .lg\:pt-px {
    padding-top: 1px; }
  .lg\:pr-px {
    padding-right: 1px; }
  .lg\:pb-px {
    padding-bottom: 1px; }
  .lg\:pl-px {
    padding-left: 1px; }
  .lg\:pointer-events-none {
    pointer-events: none; }
  .lg\:pointer-events-auto {
    pointer-events: auto; }
  .lg\:static {
    position: static; }
  .lg\:fixed {
    position: fixed; }
  .lg\:absolute {
    position: absolute; }
  .lg\:relative {
    position: relative; }
  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky; }
  .lg\:pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto; }
  .lg\:pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .lg\:pin-y {
    top: 0;
    bottom: 0; }
  .lg\:pin-x {
    right: 0;
    left: 0; }
  .lg\:pin-t {
    top: 0; }
  .lg\:pin-r {
    right: 0; }
  .lg\:pin-b {
    bottom: 0; }
  .lg\:pin-l {
    left: 0; }
  .lg\:resize-none {
    resize: none; }
  .lg\:resize-y {
    resize: vertical; }
  .lg\:resize-x {
    resize: horizontal; }
  .lg\:resize {
    resize: both; }
  .lg\:shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .lg\:shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .lg\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .lg\:shadow-none {
    box-shadow: none; }
  .lg\:hover\:shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .lg\:hover\:shadow-none:hover {
    box-shadow: none; }
  .lg\:focus\:shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .lg\:focus\:shadow-none:focus {
    box-shadow: none; }
  .lg\:table-auto {
    table-layout: auto; }
  .lg\:table-fixed {
    table-layout: fixed; }
  .lg\:text-left {
    text-align: left; }
  .lg\:text-center {
    text-align: center; }
  .lg\:text-right {
    text-align: right; }
  .lg\:text-justify {
    text-align: justify; }
  .lg\:text-transparent {
    color: transparent; }
  .lg\:text-black {
    color: #22292f; }
  .lg\:text-grey-darkest {
    color: #3d4852; }
  .lg\:text-grey-darker {
    color: #606f7b; }
  .lg\:text-grey-dark {
    color: #8795a1; }
  .lg\:text-grey {
    color: #b8c2cc; }
  .lg\:text-grey-light {
    color: #dae1e7; }
  .lg\:text-grey-lighter {
    color: #f1f5f8; }
  .lg\:text-grey-lightest {
    color: #f8fafc; }
  .lg\:text-white {
    color: #fff; }
  .lg\:text-red-darkest {
    color: #3b0d0c; }
  .lg\:text-red-darker {
    color: #621b18; }
  .lg\:text-red-dark {
    color: #cc1f1a; }
  .lg\:text-red {
    color: #e3342f; }
  .lg\:text-red-light {
    color: #ef5753; }
  .lg\:text-red-lighter {
    color: #f9acaa; }
  .lg\:text-red-lightest {
    color: #fcebea; }
  .lg\:text-orange-darkest {
    color: #462a16; }
  .lg\:text-orange-darker {
    color: #613b1f; }
  .lg\:text-orange-dark {
    color: #de751f; }
  .lg\:text-orange {
    color: #f6993f; }
  .lg\:text-orange-light {
    color: #faad63; }
  .lg\:text-orange-lighter {
    color: #fcd9b6; }
  .lg\:text-orange-lightest {
    color: #fff5eb; }
  .lg\:text-yellow-darkest {
    color: #453411; }
  .lg\:text-yellow-darker {
    color: #684f1d; }
  .lg\:text-yellow-dark {
    color: #f2d024; }
  .lg\:text-yellow {
    color: #ffed4a; }
  .lg\:text-yellow-light {
    color: #fff382; }
  .lg\:text-yellow-lighter {
    color: #fff9c2; }
  .lg\:text-yellow-lightest {
    color: #fcfbeb; }
  .lg\:text-green-darkest {
    color: #0f2f21; }
  .lg\:text-green-darker {
    color: #1a4731; }
  .lg\:text-green-dark {
    color: #1f9d55; }
  .lg\:text-green {
    color: #38c172; }
  .lg\:text-green-light {
    color: #51d88a; }
  .lg\:text-green-lighter {
    color: #a2f5bf; }
  .lg\:text-green-lightest {
    color: #e3fcec; }
  .lg\:text-teal-darkest {
    color: #0d3331; }
  .lg\:text-teal-darker {
    color: #20504f; }
  .lg\:text-teal-dark {
    color: #38a89d; }
  .lg\:text-teal {
    color: #4dc0b5; }
  .lg\:text-teal-light {
    color: #64d5ca; }
  .lg\:text-teal-lighter {
    color: #a0f0ed; }
  .lg\:text-teal-lightest {
    color: #e8fffe; }
  .lg\:text-blue-darkest {
    color: #12283a; }
  .lg\:text-blue-darker {
    color: #1c3d5a; }
  .lg\:text-blue-dark {
    color: #2779bd; }
  .lg\:text-blue {
    color: #3490dc; }
  .lg\:text-blue-light {
    color: #6cb2eb; }
  .lg\:text-blue-lighter {
    color: #bcdefa; }
  .lg\:text-blue-lightest {
    color: #eff8ff; }
  .lg\:text-indigo-darkest {
    color: #191e38; }
  .lg\:text-indigo-darker {
    color: #2f365f; }
  .lg\:text-indigo-dark {
    color: #5661b3; }
  .lg\:text-indigo {
    color: #6574cd; }
  .lg\:text-indigo-light {
    color: #7886d7; }
  .lg\:text-indigo-lighter {
    color: #b2b7ff; }
  .lg\:text-indigo-lightest {
    color: #e6e8ff; }
  .lg\:text-purple-darkest {
    color: #21183c; }
  .lg\:text-purple-darker {
    color: #382b5f; }
  .lg\:text-purple-dark {
    color: #794acf; }
  .lg\:text-purple {
    color: #9561e2; }
  .lg\:text-purple-light {
    color: #a779e9; }
  .lg\:text-purple-lighter {
    color: #d6bbfc; }
  .lg\:text-purple-lightest {
    color: #f3ebff; }
  .lg\:text-pink-darkest {
    color: #451225; }
  .lg\:text-pink-darker {
    color: #6f213f; }
  .lg\:text-pink-dark {
    color: #eb5286; }
  .lg\:text-pink {
    color: #f66d9b; }
  .lg\:text-pink-light {
    color: #fa7ea8; }
  .lg\:text-pink-lighter {
    color: #ffbbca; }
  .lg\:text-pink-lightest {
    color: #ffebef; }
  .lg\:hover\:text-transparent:hover {
    color: transparent; }
  .lg\:hover\:text-black:hover {
    color: #22292f; }
  .lg\:hover\:text-grey-darkest:hover {
    color: #3d4852; }
  .lg\:hover\:text-grey-darker:hover {
    color: #606f7b; }
  .lg\:hover\:text-grey-dark:hover {
    color: #8795a1; }
  .lg\:hover\:text-grey:hover {
    color: #b8c2cc; }
  .lg\:hover\:text-grey-light:hover {
    color: #dae1e7; }
  .lg\:hover\:text-grey-lighter:hover {
    color: #f1f5f8; }
  .lg\:hover\:text-grey-lightest:hover {
    color: #f8fafc; }
  .lg\:hover\:text-white:hover {
    color: #fff; }
  .lg\:hover\:text-red-darkest:hover {
    color: #3b0d0c; }
  .lg\:hover\:text-red-darker:hover {
    color: #621b18; }
  .lg\:hover\:text-red-dark:hover {
    color: #cc1f1a; }
  .lg\:hover\:text-red:hover {
    color: #e3342f; }
  .lg\:hover\:text-red-light:hover {
    color: #ef5753; }
  .lg\:hover\:text-red-lighter:hover {
    color: #f9acaa; }
  .lg\:hover\:text-red-lightest:hover {
    color: #fcebea; }
  .lg\:hover\:text-orange-darkest:hover {
    color: #462a16; }
  .lg\:hover\:text-orange-darker:hover {
    color: #613b1f; }
  .lg\:hover\:text-orange-dark:hover {
    color: #de751f; }
  .lg\:hover\:text-orange:hover {
    color: #f6993f; }
  .lg\:hover\:text-orange-light:hover {
    color: #faad63; }
  .lg\:hover\:text-orange-lighter:hover {
    color: #fcd9b6; }
  .lg\:hover\:text-orange-lightest:hover {
    color: #fff5eb; }
  .lg\:hover\:text-yellow-darkest:hover {
    color: #453411; }
  .lg\:hover\:text-yellow-darker:hover {
    color: #684f1d; }
  .lg\:hover\:text-yellow-dark:hover {
    color: #f2d024; }
  .lg\:hover\:text-yellow:hover {
    color: #ffed4a; }
  .lg\:hover\:text-yellow-light:hover {
    color: #fff382; }
  .lg\:hover\:text-yellow-lighter:hover {
    color: #fff9c2; }
  .lg\:hover\:text-yellow-lightest:hover {
    color: #fcfbeb; }
  .lg\:hover\:text-green-darkest:hover {
    color: #0f2f21; }
  .lg\:hover\:text-green-darker:hover {
    color: #1a4731; }
  .lg\:hover\:text-green-dark:hover {
    color: #1f9d55; }
  .lg\:hover\:text-green:hover {
    color: #38c172; }
  .lg\:hover\:text-green-light:hover {
    color: #51d88a; }
  .lg\:hover\:text-green-lighter:hover {
    color: #a2f5bf; }
  .lg\:hover\:text-green-lightest:hover {
    color: #e3fcec; }
  .lg\:hover\:text-teal-darkest:hover {
    color: #0d3331; }
  .lg\:hover\:text-teal-darker:hover {
    color: #20504f; }
  .lg\:hover\:text-teal-dark:hover {
    color: #38a89d; }
  .lg\:hover\:text-teal:hover {
    color: #4dc0b5; }
  .lg\:hover\:text-teal-light:hover {
    color: #64d5ca; }
  .lg\:hover\:text-teal-lighter:hover {
    color: #a0f0ed; }
  .lg\:hover\:text-teal-lightest:hover {
    color: #e8fffe; }
  .lg\:hover\:text-blue-darkest:hover {
    color: #12283a; }
  .lg\:hover\:text-blue-darker:hover {
    color: #1c3d5a; }
  .lg\:hover\:text-blue-dark:hover {
    color: #2779bd; }
  .lg\:hover\:text-blue:hover {
    color: #3490dc; }
  .lg\:hover\:text-blue-light:hover {
    color: #6cb2eb; }
  .lg\:hover\:text-blue-lighter:hover {
    color: #bcdefa; }
  .lg\:hover\:text-blue-lightest:hover {
    color: #eff8ff; }
  .lg\:hover\:text-indigo-darkest:hover {
    color: #191e38; }
  .lg\:hover\:text-indigo-darker:hover {
    color: #2f365f; }
  .lg\:hover\:text-indigo-dark:hover {
    color: #5661b3; }
  .lg\:hover\:text-indigo:hover {
    color: #6574cd; }
  .lg\:hover\:text-indigo-light:hover {
    color: #7886d7; }
  .lg\:hover\:text-indigo-lighter:hover {
    color: #b2b7ff; }
  .lg\:hover\:text-indigo-lightest:hover {
    color: #e6e8ff; }
  .lg\:hover\:text-purple-darkest:hover {
    color: #21183c; }
  .lg\:hover\:text-purple-darker:hover {
    color: #382b5f; }
  .lg\:hover\:text-purple-dark:hover {
    color: #794acf; }
  .lg\:hover\:text-purple:hover {
    color: #9561e2; }
  .lg\:hover\:text-purple-light:hover {
    color: #a779e9; }
  .lg\:hover\:text-purple-lighter:hover {
    color: #d6bbfc; }
  .lg\:hover\:text-purple-lightest:hover {
    color: #f3ebff; }
  .lg\:hover\:text-pink-darkest:hover {
    color: #451225; }
  .lg\:hover\:text-pink-darker:hover {
    color: #6f213f; }
  .lg\:hover\:text-pink-dark:hover {
    color: #eb5286; }
  .lg\:hover\:text-pink:hover {
    color: #f66d9b; }
  .lg\:hover\:text-pink-light:hover {
    color: #fa7ea8; }
  .lg\:hover\:text-pink-lighter:hover {
    color: #ffbbca; }
  .lg\:hover\:text-pink-lightest:hover {
    color: #ffebef; }
  .lg\:focus\:text-transparent:focus {
    color: transparent; }
  .lg\:focus\:text-black:focus {
    color: #22292f; }
  .lg\:focus\:text-grey-darkest:focus {
    color: #3d4852; }
  .lg\:focus\:text-grey-darker:focus {
    color: #606f7b; }
  .lg\:focus\:text-grey-dark:focus {
    color: #8795a1; }
  .lg\:focus\:text-grey:focus {
    color: #b8c2cc; }
  .lg\:focus\:text-grey-light:focus {
    color: #dae1e7; }
  .lg\:focus\:text-grey-lighter:focus {
    color: #f1f5f8; }
  .lg\:focus\:text-grey-lightest:focus {
    color: #f8fafc; }
  .lg\:focus\:text-white:focus {
    color: #fff; }
  .lg\:focus\:text-red-darkest:focus {
    color: #3b0d0c; }
  .lg\:focus\:text-red-darker:focus {
    color: #621b18; }
  .lg\:focus\:text-red-dark:focus {
    color: #cc1f1a; }
  .lg\:focus\:text-red:focus {
    color: #e3342f; }
  .lg\:focus\:text-red-light:focus {
    color: #ef5753; }
  .lg\:focus\:text-red-lighter:focus {
    color: #f9acaa; }
  .lg\:focus\:text-red-lightest:focus {
    color: #fcebea; }
  .lg\:focus\:text-orange-darkest:focus {
    color: #462a16; }
  .lg\:focus\:text-orange-darker:focus {
    color: #613b1f; }
  .lg\:focus\:text-orange-dark:focus {
    color: #de751f; }
  .lg\:focus\:text-orange:focus {
    color: #f6993f; }
  .lg\:focus\:text-orange-light:focus {
    color: #faad63; }
  .lg\:focus\:text-orange-lighter:focus {
    color: #fcd9b6; }
  .lg\:focus\:text-orange-lightest:focus {
    color: #fff5eb; }
  .lg\:focus\:text-yellow-darkest:focus {
    color: #453411; }
  .lg\:focus\:text-yellow-darker:focus {
    color: #684f1d; }
  .lg\:focus\:text-yellow-dark:focus {
    color: #f2d024; }
  .lg\:focus\:text-yellow:focus {
    color: #ffed4a; }
  .lg\:focus\:text-yellow-light:focus {
    color: #fff382; }
  .lg\:focus\:text-yellow-lighter:focus {
    color: #fff9c2; }
  .lg\:focus\:text-yellow-lightest:focus {
    color: #fcfbeb; }
  .lg\:focus\:text-green-darkest:focus {
    color: #0f2f21; }
  .lg\:focus\:text-green-darker:focus {
    color: #1a4731; }
  .lg\:focus\:text-green-dark:focus {
    color: #1f9d55; }
  .lg\:focus\:text-green:focus {
    color: #38c172; }
  .lg\:focus\:text-green-light:focus {
    color: #51d88a; }
  .lg\:focus\:text-green-lighter:focus {
    color: #a2f5bf; }
  .lg\:focus\:text-green-lightest:focus {
    color: #e3fcec; }
  .lg\:focus\:text-teal-darkest:focus {
    color: #0d3331; }
  .lg\:focus\:text-teal-darker:focus {
    color: #20504f; }
  .lg\:focus\:text-teal-dark:focus {
    color: #38a89d; }
  .lg\:focus\:text-teal:focus {
    color: #4dc0b5; }
  .lg\:focus\:text-teal-light:focus {
    color: #64d5ca; }
  .lg\:focus\:text-teal-lighter:focus {
    color: #a0f0ed; }
  .lg\:focus\:text-teal-lightest:focus {
    color: #e8fffe; }
  .lg\:focus\:text-blue-darkest:focus {
    color: #12283a; }
  .lg\:focus\:text-blue-darker:focus {
    color: #1c3d5a; }
  .lg\:focus\:text-blue-dark:focus {
    color: #2779bd; }
  .lg\:focus\:text-blue:focus {
    color: #3490dc; }
  .lg\:focus\:text-blue-light:focus {
    color: #6cb2eb; }
  .lg\:focus\:text-blue-lighter:focus {
    color: #bcdefa; }
  .lg\:focus\:text-blue-lightest:focus {
    color: #eff8ff; }
  .lg\:focus\:text-indigo-darkest:focus {
    color: #191e38; }
  .lg\:focus\:text-indigo-darker:focus {
    color: #2f365f; }
  .lg\:focus\:text-indigo-dark:focus {
    color: #5661b3; }
  .lg\:focus\:text-indigo:focus {
    color: #6574cd; }
  .lg\:focus\:text-indigo-light:focus {
    color: #7886d7; }
  .lg\:focus\:text-indigo-lighter:focus {
    color: #b2b7ff; }
  .lg\:focus\:text-indigo-lightest:focus {
    color: #e6e8ff; }
  .lg\:focus\:text-purple-darkest:focus {
    color: #21183c; }
  .lg\:focus\:text-purple-darker:focus {
    color: #382b5f; }
  .lg\:focus\:text-purple-dark:focus {
    color: #794acf; }
  .lg\:focus\:text-purple:focus {
    color: #9561e2; }
  .lg\:focus\:text-purple-light:focus {
    color: #a779e9; }
  .lg\:focus\:text-purple-lighter:focus {
    color: #d6bbfc; }
  .lg\:focus\:text-purple-lightest:focus {
    color: #f3ebff; }
  .lg\:focus\:text-pink-darkest:focus {
    color: #451225; }
  .lg\:focus\:text-pink-darker:focus {
    color: #6f213f; }
  .lg\:focus\:text-pink-dark:focus {
    color: #eb5286; }
  .lg\:focus\:text-pink:focus {
    color: #f66d9b; }
  .lg\:focus\:text-pink-light:focus {
    color: #fa7ea8; }
  .lg\:focus\:text-pink-lighter:focus {
    color: #ffbbca; }
  .lg\:focus\:text-pink-lightest:focus {
    color: #ffebef; }
  .lg\:text-xs {
    font-size: .75rem; }
  .lg\:text-sm {
    font-size: .875rem; }
  .lg\:text-base {
    font-size: 1rem; }
  .lg\:text-lg {
    font-size: 1.125rem; }
  .lg\:text-xl {
    font-size: 1.25rem; }
  .lg\:text-2xl {
    font-size: 1.5rem; }
  .lg\:text-3xl {
    font-size: 1.875rem; }
  .lg\:text-4xl {
    font-size: 2.25rem; }
  .lg\:text-5xl {
    font-size: 3rem; }
  .lg\:italic {
    font-style: italic; }
  .lg\:roman {
    font-style: normal; }
  .lg\:uppercase {
    text-transform: uppercase; }
  .lg\:lowercase {
    text-transform: lowercase; }
  .lg\:capitalize {
    text-transform: capitalize; }
  .lg\:normal-case {
    text-transform: none; }
  .lg\:underline {
    text-decoration: underline; }
  .lg\:line-through {
    text-decoration: line-through; }
  .lg\:no-underline {
    text-decoration: none; }
  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .lg\:hover\:italic:hover {
    font-style: italic; }
  .lg\:hover\:roman:hover {
    font-style: normal; }
  .lg\:hover\:uppercase:hover {
    text-transform: uppercase; }
  .lg\:hover\:lowercase:hover {
    text-transform: lowercase; }
  .lg\:hover\:capitalize:hover {
    text-transform: capitalize; }
  .lg\:hover\:normal-case:hover {
    text-transform: none; }
  .lg\:hover\:underline:hover {
    text-decoration: underline; }
  .lg\:hover\:line-through:hover {
    text-decoration: line-through; }
  .lg\:hover\:no-underline:hover {
    text-decoration: none; }
  .lg\:hover\:antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .lg\:hover\:subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .lg\:focus\:italic:focus {
    font-style: italic; }
  .lg\:focus\:roman:focus {
    font-style: normal; }
  .lg\:focus\:uppercase:focus {
    text-transform: uppercase; }
  .lg\:focus\:lowercase:focus {
    text-transform: lowercase; }
  .lg\:focus\:capitalize:focus {
    text-transform: capitalize; }
  .lg\:focus\:normal-case:focus {
    text-transform: none; }
  .lg\:focus\:underline:focus {
    text-decoration: underline; }
  .lg\:focus\:line-through:focus {
    text-decoration: line-through; }
  .lg\:focus\:no-underline:focus {
    text-decoration: none; }
  .lg\:focus\:antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .lg\:focus\:subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .lg\:tracking-tight {
    letter-spacing: -0.05em; }
  .lg\:tracking-normal {
    letter-spacing: 0; }
  .lg\:tracking-wide {
    letter-spacing: .05em; }
  .lg\:select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .lg\:select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  .lg\:align-baseline {
    vertical-align: baseline; }
  .lg\:align-top {
    vertical-align: top; }
  .lg\:align-middle {
    vertical-align: middle; }
  .lg\:align-bottom {
    vertical-align: bottom; }
  .lg\:align-text-top {
    vertical-align: text-top; }
  .lg\:align-text-bottom {
    vertical-align: text-bottom; }
  .lg\:visible {
    visibility: visible; }
  .lg\:invisible {
    visibility: hidden; }
  .lg\:whitespace-normal {
    white-space: normal; }
  .lg\:whitespace-no-wrap {
    white-space: nowrap; }
  .lg\:whitespace-pre {
    white-space: pre; }
  .lg\:whitespace-pre-line {
    white-space: pre-line; }
  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap; }
  .lg\:break-words {
    word-wrap: break-word; }
  .lg\:break-normal {
    word-wrap: normal; }
  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .lg\:w-1 {
    width: .25rem; }
  .lg\:w-2 {
    width: .5rem; }
  .lg\:w-3 {
    width: .75rem; }
  .lg\:w-4 {
    width: 1rem; }
  .lg\:w-5 {
    width: 1.25rem; }
  .lg\:w-6 {
    width: 1.5rem; }
  .lg\:w-8 {
    width: 2rem; }
  .lg\:w-10 {
    width: 2.5rem; }
  .lg\:w-12 {
    width: 3rem; }
  .lg\:w-16 {
    width: 4rem; }
  .lg\:w-24 {
    width: 6rem; }
  .lg\:w-32 {
    width: 8rem; }
  .lg\:w-48 {
    width: 12rem; }
  .lg\:w-64 {
    width: 16rem; }
  .lg\:w-auto {
    width: auto; }
  .lg\:w-px {
    width: 1px; }
  .lg\:w-1\/2 {
    width: 50%; }
  .lg\:w-1\/3 {
    width: 33.33333%; }
  .lg\:w-2\/3 {
    width: 66.66667%; }
  .lg\:w-1\/4 {
    width: 25%; }
  .lg\:w-3\/4 {
    width: 75%; }
  .lg\:w-1\/5 {
    width: 20%; }
  .lg\:w-2\/5 {
    width: 40%; }
  .lg\:w-3\/5 {
    width: 60%; }
  .lg\:w-4\/5 {
    width: 80%; }
  .lg\:w-1\/6 {
    width: 16.66667%; }
  .lg\:w-5\/6 {
    width: 83.33333%; }
  .lg\:w-full {
    width: 100%; }
  .lg\:w-screen {
    width: 100vw; }
  .lg\:z-0 {
    z-index: 0; }
  .lg\:z-10 {
    z-index: 10; }
  .lg\:z-20 {
    z-index: 20; }
  .lg\:z-30 {
    z-index: 30; }
  .lg\:z-40 {
    z-index: 40; }
  .lg\:z-50 {
    z-index: 50; }
  .lg\:z-auto {
    z-index: auto; } }

@media (min-width: 1200px) {
  .xl\:list-reset {
    list-style: none;
    padding: 0; }
  .xl\:appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }
  .xl\:bg-fixed {
    background-attachment: fixed; }
  .xl\:bg-local {
    background-attachment: local; }
  .xl\:bg-scroll {
    background-attachment: scroll; }
  .xl\:bg-transparent {
    background-color: transparent; }
  .xl\:bg-black {
    background-color: #22292f; }
  .xl\:bg-grey-darkest {
    background-color: #3d4852; }
  .xl\:bg-grey-darker {
    background-color: #606f7b; }
  .xl\:bg-grey-dark {
    background-color: #8795a1; }
  .xl\:bg-grey {
    background-color: #b8c2cc; }
  .xl\:bg-grey-light {
    background-color: #dae1e7; }
  .xl\:bg-grey-lighter {
    background-color: #f1f5f8; }
  .xl\:bg-grey-lightest {
    background-color: #f8fafc; }
  .xl\:bg-white {
    background-color: #fff; }
  .xl\:bg-red-darkest {
    background-color: #3b0d0c; }
  .xl\:bg-red-darker {
    background-color: #621b18; }
  .xl\:bg-red-dark {
    background-color: #cc1f1a; }
  .xl\:bg-red {
    background-color: #e3342f; }
  .xl\:bg-red-light {
    background-color: #ef5753; }
  .xl\:bg-red-lighter {
    background-color: #f9acaa; }
  .xl\:bg-red-lightest {
    background-color: #fcebea; }
  .xl\:bg-orange-darkest {
    background-color: #462a16; }
  .xl\:bg-orange-darker {
    background-color: #613b1f; }
  .xl\:bg-orange-dark {
    background-color: #de751f; }
  .xl\:bg-orange {
    background-color: #f6993f; }
  .xl\:bg-orange-light {
    background-color: #faad63; }
  .xl\:bg-orange-lighter {
    background-color: #fcd9b6; }
  .xl\:bg-orange-lightest {
    background-color: #fff5eb; }
  .xl\:bg-yellow-darkest {
    background-color: #453411; }
  .xl\:bg-yellow-darker {
    background-color: #684f1d; }
  .xl\:bg-yellow-dark {
    background-color: #f2d024; }
  .xl\:bg-yellow {
    background-color: #ffed4a; }
  .xl\:bg-yellow-light {
    background-color: #fff382; }
  .xl\:bg-yellow-lighter {
    background-color: #fff9c2; }
  .xl\:bg-yellow-lightest {
    background-color: #fcfbeb; }
  .xl\:bg-green-darkest {
    background-color: #0f2f21; }
  .xl\:bg-green-darker {
    background-color: #1a4731; }
  .xl\:bg-green-dark {
    background-color: #1f9d55; }
  .xl\:bg-green {
    background-color: #38c172; }
  .xl\:bg-green-light {
    background-color: #51d88a; }
  .xl\:bg-green-lighter {
    background-color: #a2f5bf; }
  .xl\:bg-green-lightest {
    background-color: #e3fcec; }
  .xl\:bg-teal-darkest {
    background-color: #0d3331; }
  .xl\:bg-teal-darker {
    background-color: #20504f; }
  .xl\:bg-teal-dark {
    background-color: #38a89d; }
  .xl\:bg-teal {
    background-color: #4dc0b5; }
  .xl\:bg-teal-light {
    background-color: #64d5ca; }
  .xl\:bg-teal-lighter {
    background-color: #a0f0ed; }
  .xl\:bg-teal-lightest {
    background-color: #e8fffe; }
  .xl\:bg-blue-darkest {
    background-color: #12283a; }
  .xl\:bg-blue-darker {
    background-color: #1c3d5a; }
  .xl\:bg-blue-dark {
    background-color: #2779bd; }
  .xl\:bg-blue {
    background-color: #3490dc; }
  .xl\:bg-blue-light {
    background-color: #6cb2eb; }
  .xl\:bg-blue-lighter {
    background-color: #bcdefa; }
  .xl\:bg-blue-lightest {
    background-color: #eff8ff; }
  .xl\:bg-indigo-darkest {
    background-color: #191e38; }
  .xl\:bg-indigo-darker {
    background-color: #2f365f; }
  .xl\:bg-indigo-dark {
    background-color: #5661b3; }
  .xl\:bg-indigo {
    background-color: #6574cd; }
  .xl\:bg-indigo-light {
    background-color: #7886d7; }
  .xl\:bg-indigo-lighter {
    background-color: #b2b7ff; }
  .xl\:bg-indigo-lightest {
    background-color: #e6e8ff; }
  .xl\:bg-purple-darkest {
    background-color: #21183c; }
  .xl\:bg-purple-darker {
    background-color: #382b5f; }
  .xl\:bg-purple-dark {
    background-color: #794acf; }
  .xl\:bg-purple {
    background-color: #9561e2; }
  .xl\:bg-purple-light {
    background-color: #a779e9; }
  .xl\:bg-purple-lighter {
    background-color: #d6bbfc; }
  .xl\:bg-purple-lightest {
    background-color: #f3ebff; }
  .xl\:bg-pink-darkest {
    background-color: #451225; }
  .xl\:bg-pink-darker {
    background-color: #6f213f; }
  .xl\:bg-pink-dark {
    background-color: #eb5286; }
  .xl\:bg-pink {
    background-color: #f66d9b; }
  .xl\:bg-pink-light {
    background-color: #fa7ea8; }
  .xl\:bg-pink-lighter {
    background-color: #ffbbca; }
  .xl\:bg-pink-lightest {
    background-color: #ffebef; }
  .xl\:hover\:bg-transparent:hover {
    background-color: transparent; }
  .xl\:hover\:bg-black:hover {
    background-color: #22292f; }
  .xl\:hover\:bg-grey-darkest:hover {
    background-color: #3d4852; }
  .xl\:hover\:bg-grey-darker:hover {
    background-color: #606f7b; }
  .xl\:hover\:bg-grey-dark:hover {
    background-color: #8795a1; }
  .xl\:hover\:bg-grey:hover {
    background-color: #b8c2cc; }
  .xl\:hover\:bg-grey-light:hover {
    background-color: #dae1e7; }
  .xl\:hover\:bg-grey-lighter:hover {
    background-color: #f1f5f8; }
  .xl\:hover\:bg-grey-lightest:hover {
    background-color: #f8fafc; }
  .xl\:hover\:bg-white:hover {
    background-color: #fff; }
  .xl\:hover\:bg-red-darkest:hover {
    background-color: #3b0d0c; }
  .xl\:hover\:bg-red-darker:hover {
    background-color: #621b18; }
  .xl\:hover\:bg-red-dark:hover {
    background-color: #cc1f1a; }
  .xl\:hover\:bg-red:hover {
    background-color: #e3342f; }
  .xl\:hover\:bg-red-light:hover {
    background-color: #ef5753; }
  .xl\:hover\:bg-red-lighter:hover {
    background-color: #f9acaa; }
  .xl\:hover\:bg-red-lightest:hover {
    background-color: #fcebea; }
  .xl\:hover\:bg-orange-darkest:hover {
    background-color: #462a16; }
  .xl\:hover\:bg-orange-darker:hover {
    background-color: #613b1f; }
  .xl\:hover\:bg-orange-dark:hover {
    background-color: #de751f; }
  .xl\:hover\:bg-orange:hover {
    background-color: #f6993f; }
  .xl\:hover\:bg-orange-light:hover {
    background-color: #faad63; }
  .xl\:hover\:bg-orange-lighter:hover {
    background-color: #fcd9b6; }
  .xl\:hover\:bg-orange-lightest:hover {
    background-color: #fff5eb; }
  .xl\:hover\:bg-yellow-darkest:hover {
    background-color: #453411; }
  .xl\:hover\:bg-yellow-darker:hover {
    background-color: #684f1d; }
  .xl\:hover\:bg-yellow-dark:hover {
    background-color: #f2d024; }
  .xl\:hover\:bg-yellow:hover {
    background-color: #ffed4a; }
  .xl\:hover\:bg-yellow-light:hover {
    background-color: #fff382; }
  .xl\:hover\:bg-yellow-lighter:hover {
    background-color: #fff9c2; }
  .xl\:hover\:bg-yellow-lightest:hover {
    background-color: #fcfbeb; }
  .xl\:hover\:bg-green-darkest:hover {
    background-color: #0f2f21; }
  .xl\:hover\:bg-green-darker:hover {
    background-color: #1a4731; }
  .xl\:hover\:bg-green-dark:hover {
    background-color: #1f9d55; }
  .xl\:hover\:bg-green:hover {
    background-color: #38c172; }
  .xl\:hover\:bg-green-light:hover {
    background-color: #51d88a; }
  .xl\:hover\:bg-green-lighter:hover {
    background-color: #a2f5bf; }
  .xl\:hover\:bg-green-lightest:hover {
    background-color: #e3fcec; }
  .xl\:hover\:bg-teal-darkest:hover {
    background-color: #0d3331; }
  .xl\:hover\:bg-teal-darker:hover {
    background-color: #20504f; }
  .xl\:hover\:bg-teal-dark:hover {
    background-color: #38a89d; }
  .xl\:hover\:bg-teal:hover {
    background-color: #4dc0b5; }
  .xl\:hover\:bg-teal-light:hover {
    background-color: #64d5ca; }
  .xl\:hover\:bg-teal-lighter:hover {
    background-color: #a0f0ed; }
  .xl\:hover\:bg-teal-lightest:hover {
    background-color: #e8fffe; }
  .xl\:hover\:bg-blue-darkest:hover {
    background-color: #12283a; }
  .xl\:hover\:bg-blue-darker:hover {
    background-color: #1c3d5a; }
  .xl\:hover\:bg-blue-dark:hover {
    background-color: #2779bd; }
  .xl\:hover\:bg-blue:hover {
    background-color: #3490dc; }
  .xl\:hover\:bg-blue-light:hover {
    background-color: #6cb2eb; }
  .xl\:hover\:bg-blue-lighter:hover {
    background-color: #bcdefa; }
  .xl\:hover\:bg-blue-lightest:hover {
    background-color: #eff8ff; }
  .xl\:hover\:bg-indigo-darkest:hover {
    background-color: #191e38; }
  .xl\:hover\:bg-indigo-darker:hover {
    background-color: #2f365f; }
  .xl\:hover\:bg-indigo-dark:hover {
    background-color: #5661b3; }
  .xl\:hover\:bg-indigo:hover {
    background-color: #6574cd; }
  .xl\:hover\:bg-indigo-light:hover {
    background-color: #7886d7; }
  .xl\:hover\:bg-indigo-lighter:hover {
    background-color: #b2b7ff; }
  .xl\:hover\:bg-indigo-lightest:hover {
    background-color: #e6e8ff; }
  .xl\:hover\:bg-purple-darkest:hover {
    background-color: #21183c; }
  .xl\:hover\:bg-purple-darker:hover {
    background-color: #382b5f; }
  .xl\:hover\:bg-purple-dark:hover {
    background-color: #794acf; }
  .xl\:hover\:bg-purple:hover {
    background-color: #9561e2; }
  .xl\:hover\:bg-purple-light:hover {
    background-color: #a779e9; }
  .xl\:hover\:bg-purple-lighter:hover {
    background-color: #d6bbfc; }
  .xl\:hover\:bg-purple-lightest:hover {
    background-color: #f3ebff; }
  .xl\:hover\:bg-pink-darkest:hover {
    background-color: #451225; }
  .xl\:hover\:bg-pink-darker:hover {
    background-color: #6f213f; }
  .xl\:hover\:bg-pink-dark:hover {
    background-color: #eb5286; }
  .xl\:hover\:bg-pink:hover {
    background-color: #f66d9b; }
  .xl\:hover\:bg-pink-light:hover {
    background-color: #fa7ea8; }
  .xl\:hover\:bg-pink-lighter:hover {
    background-color: #ffbbca; }
  .xl\:hover\:bg-pink-lightest:hover {
    background-color: #ffebef; }
  .xl\:focus\:bg-transparent:focus {
    background-color: transparent; }
  .xl\:focus\:bg-black:focus {
    background-color: #22292f; }
  .xl\:focus\:bg-grey-darkest:focus {
    background-color: #3d4852; }
  .xl\:focus\:bg-grey-darker:focus {
    background-color: #606f7b; }
  .xl\:focus\:bg-grey-dark:focus {
    background-color: #8795a1; }
  .xl\:focus\:bg-grey:focus {
    background-color: #b8c2cc; }
  .xl\:focus\:bg-grey-light:focus {
    background-color: #dae1e7; }
  .xl\:focus\:bg-grey-lighter:focus {
    background-color: #f1f5f8; }
  .xl\:focus\:bg-grey-lightest:focus {
    background-color: #f8fafc; }
  .xl\:focus\:bg-white:focus {
    background-color: #fff; }
  .xl\:focus\:bg-red-darkest:focus {
    background-color: #3b0d0c; }
  .xl\:focus\:bg-red-darker:focus {
    background-color: #621b18; }
  .xl\:focus\:bg-red-dark:focus {
    background-color: #cc1f1a; }
  .xl\:focus\:bg-red:focus {
    background-color: #e3342f; }
  .xl\:focus\:bg-red-light:focus {
    background-color: #ef5753; }
  .xl\:focus\:bg-red-lighter:focus {
    background-color: #f9acaa; }
  .xl\:focus\:bg-red-lightest:focus {
    background-color: #fcebea; }
  .xl\:focus\:bg-orange-darkest:focus {
    background-color: #462a16; }
  .xl\:focus\:bg-orange-darker:focus {
    background-color: #613b1f; }
  .xl\:focus\:bg-orange-dark:focus {
    background-color: #de751f; }
  .xl\:focus\:bg-orange:focus {
    background-color: #f6993f; }
  .xl\:focus\:bg-orange-light:focus {
    background-color: #faad63; }
  .xl\:focus\:bg-orange-lighter:focus {
    background-color: #fcd9b6; }
  .xl\:focus\:bg-orange-lightest:focus {
    background-color: #fff5eb; }
  .xl\:focus\:bg-yellow-darkest:focus {
    background-color: #453411; }
  .xl\:focus\:bg-yellow-darker:focus {
    background-color: #684f1d; }
  .xl\:focus\:bg-yellow-dark:focus {
    background-color: #f2d024; }
  .xl\:focus\:bg-yellow:focus {
    background-color: #ffed4a; }
  .xl\:focus\:bg-yellow-light:focus {
    background-color: #fff382; }
  .xl\:focus\:bg-yellow-lighter:focus {
    background-color: #fff9c2; }
  .xl\:focus\:bg-yellow-lightest:focus {
    background-color: #fcfbeb; }
  .xl\:focus\:bg-green-darkest:focus {
    background-color: #0f2f21; }
  .xl\:focus\:bg-green-darker:focus {
    background-color: #1a4731; }
  .xl\:focus\:bg-green-dark:focus {
    background-color: #1f9d55; }
  .xl\:focus\:bg-green:focus {
    background-color: #38c172; }
  .xl\:focus\:bg-green-light:focus {
    background-color: #51d88a; }
  .xl\:focus\:bg-green-lighter:focus {
    background-color: #a2f5bf; }
  .xl\:focus\:bg-green-lightest:focus {
    background-color: #e3fcec; }
  .xl\:focus\:bg-teal-darkest:focus {
    background-color: #0d3331; }
  .xl\:focus\:bg-teal-darker:focus {
    background-color: #20504f; }
  .xl\:focus\:bg-teal-dark:focus {
    background-color: #38a89d; }
  .xl\:focus\:bg-teal:focus {
    background-color: #4dc0b5; }
  .xl\:focus\:bg-teal-light:focus {
    background-color: #64d5ca; }
  .xl\:focus\:bg-teal-lighter:focus {
    background-color: #a0f0ed; }
  .xl\:focus\:bg-teal-lightest:focus {
    background-color: #e8fffe; }
  .xl\:focus\:bg-blue-darkest:focus {
    background-color: #12283a; }
  .xl\:focus\:bg-blue-darker:focus {
    background-color: #1c3d5a; }
  .xl\:focus\:bg-blue-dark:focus {
    background-color: #2779bd; }
  .xl\:focus\:bg-blue:focus {
    background-color: #3490dc; }
  .xl\:focus\:bg-blue-light:focus {
    background-color: #6cb2eb; }
  .xl\:focus\:bg-blue-lighter:focus {
    background-color: #bcdefa; }
  .xl\:focus\:bg-blue-lightest:focus {
    background-color: #eff8ff; }
  .xl\:focus\:bg-indigo-darkest:focus {
    background-color: #191e38; }
  .xl\:focus\:bg-indigo-darker:focus {
    background-color: #2f365f; }
  .xl\:focus\:bg-indigo-dark:focus {
    background-color: #5661b3; }
  .xl\:focus\:bg-indigo:focus {
    background-color: #6574cd; }
  .xl\:focus\:bg-indigo-light:focus {
    background-color: #7886d7; }
  .xl\:focus\:bg-indigo-lighter:focus {
    background-color: #b2b7ff; }
  .xl\:focus\:bg-indigo-lightest:focus {
    background-color: #e6e8ff; }
  .xl\:focus\:bg-purple-darkest:focus {
    background-color: #21183c; }
  .xl\:focus\:bg-purple-darker:focus {
    background-color: #382b5f; }
  .xl\:focus\:bg-purple-dark:focus {
    background-color: #794acf; }
  .xl\:focus\:bg-purple:focus {
    background-color: #9561e2; }
  .xl\:focus\:bg-purple-light:focus {
    background-color: #a779e9; }
  .xl\:focus\:bg-purple-lighter:focus {
    background-color: #d6bbfc; }
  .xl\:focus\:bg-purple-lightest:focus {
    background-color: #f3ebff; }
  .xl\:focus\:bg-pink-darkest:focus {
    background-color: #451225; }
  .xl\:focus\:bg-pink-darker:focus {
    background-color: #6f213f; }
  .xl\:focus\:bg-pink-dark:focus {
    background-color: #eb5286; }
  .xl\:focus\:bg-pink:focus {
    background-color: #f66d9b; }
  .xl\:focus\:bg-pink-light:focus {
    background-color: #fa7ea8; }
  .xl\:focus\:bg-pink-lighter:focus {
    background-color: #ffbbca; }
  .xl\:focus\:bg-pink-lightest:focus {
    background-color: #ffebef; }
  .xl\:bg-bottom {
    background-position: bottom; }
  .xl\:bg-center {
    background-position: center; }
  .xl\:bg-left {
    background-position: left; }
  .xl\:bg-left-bottom {
    background-position: left bottom; }
  .xl\:bg-left-top {
    background-position: left top; }
  .xl\:bg-right {
    background-position: right; }
  .xl\:bg-right-bottom {
    background-position: right bottom; }
  .xl\:bg-right-top {
    background-position: right top; }
  .xl\:bg-top {
    background-position: top; }
  .xl\:bg-repeat {
    background-repeat: repeat; }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat; }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x; }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y; }
  .xl\:bg-auto {
    background-size: auto; }
  .xl\:bg-cover {
    background-size: cover; }
  .xl\:bg-contain {
    background-size: contain; }
  .xl\:border-transparent {
    border-color: transparent; }
  .xl\:border-black {
    border-color: #22292f; }
  .xl\:border-grey-darkest {
    border-color: #3d4852; }
  .xl\:border-grey-darker {
    border-color: #606f7b; }
  .xl\:border-grey-dark {
    border-color: #8795a1; }
  .xl\:border-grey {
    border-color: #b8c2cc; }
  .xl\:border-grey-light {
    border-color: #dae1e7; }
  .xl\:border-grey-lighter {
    border-color: #f1f5f8; }
  .xl\:border-grey-lightest {
    border-color: #f8fafc; }
  .xl\:border-white {
    border-color: #fff; }
  .xl\:border-red-darkest {
    border-color: #3b0d0c; }
  .xl\:border-red-darker {
    border-color: #621b18; }
  .xl\:border-red-dark {
    border-color: #cc1f1a; }
  .xl\:border-red {
    border-color: #e3342f; }
  .xl\:border-red-light {
    border-color: #ef5753; }
  .xl\:border-red-lighter {
    border-color: #f9acaa; }
  .xl\:border-red-lightest {
    border-color: #fcebea; }
  .xl\:border-orange-darkest {
    border-color: #462a16; }
  .xl\:border-orange-darker {
    border-color: #613b1f; }
  .xl\:border-orange-dark {
    border-color: #de751f; }
  .xl\:border-orange {
    border-color: #f6993f; }
  .xl\:border-orange-light {
    border-color: #faad63; }
  .xl\:border-orange-lighter {
    border-color: #fcd9b6; }
  .xl\:border-orange-lightest {
    border-color: #fff5eb; }
  .xl\:border-yellow-darkest {
    border-color: #453411; }
  .xl\:border-yellow-darker {
    border-color: #684f1d; }
  .xl\:border-yellow-dark {
    border-color: #f2d024; }
  .xl\:border-yellow {
    border-color: #ffed4a; }
  .xl\:border-yellow-light {
    border-color: #fff382; }
  .xl\:border-yellow-lighter {
    border-color: #fff9c2; }
  .xl\:border-yellow-lightest {
    border-color: #fcfbeb; }
  .xl\:border-green-darkest {
    border-color: #0f2f21; }
  .xl\:border-green-darker {
    border-color: #1a4731; }
  .xl\:border-green-dark {
    border-color: #1f9d55; }
  .xl\:border-green {
    border-color: #38c172; }
  .xl\:border-green-light {
    border-color: #51d88a; }
  .xl\:border-green-lighter {
    border-color: #a2f5bf; }
  .xl\:border-green-lightest {
    border-color: #e3fcec; }
  .xl\:border-teal-darkest {
    border-color: #0d3331; }
  .xl\:border-teal-darker {
    border-color: #20504f; }
  .xl\:border-teal-dark {
    border-color: #38a89d; }
  .xl\:border-teal {
    border-color: #4dc0b5; }
  .xl\:border-teal-light {
    border-color: #64d5ca; }
  .xl\:border-teal-lighter {
    border-color: #a0f0ed; }
  .xl\:border-teal-lightest {
    border-color: #e8fffe; }
  .xl\:border-blue-darkest {
    border-color: #12283a; }
  .xl\:border-blue-darker {
    border-color: #1c3d5a; }
  .xl\:border-blue-dark {
    border-color: #2779bd; }
  .xl\:border-blue {
    border-color: #3490dc; }
  .xl\:border-blue-light {
    border-color: #6cb2eb; }
  .xl\:border-blue-lighter {
    border-color: #bcdefa; }
  .xl\:border-blue-lightest {
    border-color: #eff8ff; }
  .xl\:border-indigo-darkest {
    border-color: #191e38; }
  .xl\:border-indigo-darker {
    border-color: #2f365f; }
  .xl\:border-indigo-dark {
    border-color: #5661b3; }
  .xl\:border-indigo {
    border-color: #6574cd; }
  .xl\:border-indigo-light {
    border-color: #7886d7; }
  .xl\:border-indigo-lighter {
    border-color: #b2b7ff; }
  .xl\:border-indigo-lightest {
    border-color: #e6e8ff; }
  .xl\:border-purple-darkest {
    border-color: #21183c; }
  .xl\:border-purple-darker {
    border-color: #382b5f; }
  .xl\:border-purple-dark {
    border-color: #794acf; }
  .xl\:border-purple {
    border-color: #9561e2; }
  .xl\:border-purple-light {
    border-color: #a779e9; }
  .xl\:border-purple-lighter {
    border-color: #d6bbfc; }
  .xl\:border-purple-lightest {
    border-color: #f3ebff; }
  .xl\:border-pink-darkest {
    border-color: #451225; }
  .xl\:border-pink-darker {
    border-color: #6f213f; }
  .xl\:border-pink-dark {
    border-color: #eb5286; }
  .xl\:border-pink {
    border-color: #f66d9b; }
  .xl\:border-pink-light {
    border-color: #fa7ea8; }
  .xl\:border-pink-lighter {
    border-color: #ffbbca; }
  .xl\:border-pink-lightest {
    border-color: #ffebef; }
  .xl\:hover\:border-transparent:hover {
    border-color: transparent; }
  .xl\:hover\:border-black:hover {
    border-color: #22292f; }
  .xl\:hover\:border-grey-darkest:hover {
    border-color: #3d4852; }
  .xl\:hover\:border-grey-darker:hover {
    border-color: #606f7b; }
  .xl\:hover\:border-grey-dark:hover {
    border-color: #8795a1; }
  .xl\:hover\:border-grey:hover {
    border-color: #b8c2cc; }
  .xl\:hover\:border-grey-light:hover {
    border-color: #dae1e7; }
  .xl\:hover\:border-grey-lighter:hover {
    border-color: #f1f5f8; }
  .xl\:hover\:border-grey-lightest:hover {
    border-color: #f8fafc; }
  .xl\:hover\:border-white:hover {
    border-color: #fff; }
  .xl\:hover\:border-red-darkest:hover {
    border-color: #3b0d0c; }
  .xl\:hover\:border-red-darker:hover {
    border-color: #621b18; }
  .xl\:hover\:border-red-dark:hover {
    border-color: #cc1f1a; }
  .xl\:hover\:border-red:hover {
    border-color: #e3342f; }
  .xl\:hover\:border-red-light:hover {
    border-color: #ef5753; }
  .xl\:hover\:border-red-lighter:hover {
    border-color: #f9acaa; }
  .xl\:hover\:border-red-lightest:hover {
    border-color: #fcebea; }
  .xl\:hover\:border-orange-darkest:hover {
    border-color: #462a16; }
  .xl\:hover\:border-orange-darker:hover {
    border-color: #613b1f; }
  .xl\:hover\:border-orange-dark:hover {
    border-color: #de751f; }
  .xl\:hover\:border-orange:hover {
    border-color: #f6993f; }
  .xl\:hover\:border-orange-light:hover {
    border-color: #faad63; }
  .xl\:hover\:border-orange-lighter:hover {
    border-color: #fcd9b6; }
  .xl\:hover\:border-orange-lightest:hover {
    border-color: #fff5eb; }
  .xl\:hover\:border-yellow-darkest:hover {
    border-color: #453411; }
  .xl\:hover\:border-yellow-darker:hover {
    border-color: #684f1d; }
  .xl\:hover\:border-yellow-dark:hover {
    border-color: #f2d024; }
  .xl\:hover\:border-yellow:hover {
    border-color: #ffed4a; }
  .xl\:hover\:border-yellow-light:hover {
    border-color: #fff382; }
  .xl\:hover\:border-yellow-lighter:hover {
    border-color: #fff9c2; }
  .xl\:hover\:border-yellow-lightest:hover {
    border-color: #fcfbeb; }
  .xl\:hover\:border-green-darkest:hover {
    border-color: #0f2f21; }
  .xl\:hover\:border-green-darker:hover {
    border-color: #1a4731; }
  .xl\:hover\:border-green-dark:hover {
    border-color: #1f9d55; }
  .xl\:hover\:border-green:hover {
    border-color: #38c172; }
  .xl\:hover\:border-green-light:hover {
    border-color: #51d88a; }
  .xl\:hover\:border-green-lighter:hover {
    border-color: #a2f5bf; }
  .xl\:hover\:border-green-lightest:hover {
    border-color: #e3fcec; }
  .xl\:hover\:border-teal-darkest:hover {
    border-color: #0d3331; }
  .xl\:hover\:border-teal-darker:hover {
    border-color: #20504f; }
  .xl\:hover\:border-teal-dark:hover {
    border-color: #38a89d; }
  .xl\:hover\:border-teal:hover {
    border-color: #4dc0b5; }
  .xl\:hover\:border-teal-light:hover {
    border-color: #64d5ca; }
  .xl\:hover\:border-teal-lighter:hover {
    border-color: #a0f0ed; }
  .xl\:hover\:border-teal-lightest:hover {
    border-color: #e8fffe; }
  .xl\:hover\:border-blue-darkest:hover {
    border-color: #12283a; }
  .xl\:hover\:border-blue-darker:hover {
    border-color: #1c3d5a; }
  .xl\:hover\:border-blue-dark:hover {
    border-color: #2779bd; }
  .xl\:hover\:border-blue:hover {
    border-color: #3490dc; }
  .xl\:hover\:border-blue-light:hover {
    border-color: #6cb2eb; }
  .xl\:hover\:border-blue-lighter:hover {
    border-color: #bcdefa; }
  .xl\:hover\:border-blue-lightest:hover {
    border-color: #eff8ff; }
  .xl\:hover\:border-indigo-darkest:hover {
    border-color: #191e38; }
  .xl\:hover\:border-indigo-darker:hover {
    border-color: #2f365f; }
  .xl\:hover\:border-indigo-dark:hover {
    border-color: #5661b3; }
  .xl\:hover\:border-indigo:hover {
    border-color: #6574cd; }
  .xl\:hover\:border-indigo-light:hover {
    border-color: #7886d7; }
  .xl\:hover\:border-indigo-lighter:hover {
    border-color: #b2b7ff; }
  .xl\:hover\:border-indigo-lightest:hover {
    border-color: #e6e8ff; }
  .xl\:hover\:border-purple-darkest:hover {
    border-color: #21183c; }
  .xl\:hover\:border-purple-darker:hover {
    border-color: #382b5f; }
  .xl\:hover\:border-purple-dark:hover {
    border-color: #794acf; }
  .xl\:hover\:border-purple:hover {
    border-color: #9561e2; }
  .xl\:hover\:border-purple-light:hover {
    border-color: #a779e9; }
  .xl\:hover\:border-purple-lighter:hover {
    border-color: #d6bbfc; }
  .xl\:hover\:border-purple-lightest:hover {
    border-color: #f3ebff; }
  .xl\:hover\:border-pink-darkest:hover {
    border-color: #451225; }
  .xl\:hover\:border-pink-darker:hover {
    border-color: #6f213f; }
  .xl\:hover\:border-pink-dark:hover {
    border-color: #eb5286; }
  .xl\:hover\:border-pink:hover {
    border-color: #f66d9b; }
  .xl\:hover\:border-pink-light:hover {
    border-color: #fa7ea8; }
  .xl\:hover\:border-pink-lighter:hover {
    border-color: #ffbbca; }
  .xl\:hover\:border-pink-lightest:hover {
    border-color: #ffebef; }
  .xl\:focus\:border-transparent:focus {
    border-color: transparent; }
  .xl\:focus\:border-black:focus {
    border-color: #22292f; }
  .xl\:focus\:border-grey-darkest:focus {
    border-color: #3d4852; }
  .xl\:focus\:border-grey-darker:focus {
    border-color: #606f7b; }
  .xl\:focus\:border-grey-dark:focus {
    border-color: #8795a1; }
  .xl\:focus\:border-grey:focus {
    border-color: #b8c2cc; }
  .xl\:focus\:border-grey-light:focus {
    border-color: #dae1e7; }
  .xl\:focus\:border-grey-lighter:focus {
    border-color: #f1f5f8; }
  .xl\:focus\:border-grey-lightest:focus {
    border-color: #f8fafc; }
  .xl\:focus\:border-white:focus {
    border-color: #fff; }
  .xl\:focus\:border-red-darkest:focus {
    border-color: #3b0d0c; }
  .xl\:focus\:border-red-darker:focus {
    border-color: #621b18; }
  .xl\:focus\:border-red-dark:focus {
    border-color: #cc1f1a; }
  .xl\:focus\:border-red:focus {
    border-color: #e3342f; }
  .xl\:focus\:border-red-light:focus {
    border-color: #ef5753; }
  .xl\:focus\:border-red-lighter:focus {
    border-color: #f9acaa; }
  .xl\:focus\:border-red-lightest:focus {
    border-color: #fcebea; }
  .xl\:focus\:border-orange-darkest:focus {
    border-color: #462a16; }
  .xl\:focus\:border-orange-darker:focus {
    border-color: #613b1f; }
  .xl\:focus\:border-orange-dark:focus {
    border-color: #de751f; }
  .xl\:focus\:border-orange:focus {
    border-color: #f6993f; }
  .xl\:focus\:border-orange-light:focus {
    border-color: #faad63; }
  .xl\:focus\:border-orange-lighter:focus {
    border-color: #fcd9b6; }
  .xl\:focus\:border-orange-lightest:focus {
    border-color: #fff5eb; }
  .xl\:focus\:border-yellow-darkest:focus {
    border-color: #453411; }
  .xl\:focus\:border-yellow-darker:focus {
    border-color: #684f1d; }
  .xl\:focus\:border-yellow-dark:focus {
    border-color: #f2d024; }
  .xl\:focus\:border-yellow:focus {
    border-color: #ffed4a; }
  .xl\:focus\:border-yellow-light:focus {
    border-color: #fff382; }
  .xl\:focus\:border-yellow-lighter:focus {
    border-color: #fff9c2; }
  .xl\:focus\:border-yellow-lightest:focus {
    border-color: #fcfbeb; }
  .xl\:focus\:border-green-darkest:focus {
    border-color: #0f2f21; }
  .xl\:focus\:border-green-darker:focus {
    border-color: #1a4731; }
  .xl\:focus\:border-green-dark:focus {
    border-color: #1f9d55; }
  .xl\:focus\:border-green:focus {
    border-color: #38c172; }
  .xl\:focus\:border-green-light:focus {
    border-color: #51d88a; }
  .xl\:focus\:border-green-lighter:focus {
    border-color: #a2f5bf; }
  .xl\:focus\:border-green-lightest:focus {
    border-color: #e3fcec; }
  .xl\:focus\:border-teal-darkest:focus {
    border-color: #0d3331; }
  .xl\:focus\:border-teal-darker:focus {
    border-color: #20504f; }
  .xl\:focus\:border-teal-dark:focus {
    border-color: #38a89d; }
  .xl\:focus\:border-teal:focus {
    border-color: #4dc0b5; }
  .xl\:focus\:border-teal-light:focus {
    border-color: #64d5ca; }
  .xl\:focus\:border-teal-lighter:focus {
    border-color: #a0f0ed; }
  .xl\:focus\:border-teal-lightest:focus {
    border-color: #e8fffe; }
  .xl\:focus\:border-blue-darkest:focus {
    border-color: #12283a; }
  .xl\:focus\:border-blue-darker:focus {
    border-color: #1c3d5a; }
  .xl\:focus\:border-blue-dark:focus {
    border-color: #2779bd; }
  .xl\:focus\:border-blue:focus {
    border-color: #3490dc; }
  .xl\:focus\:border-blue-light:focus {
    border-color: #6cb2eb; }
  .xl\:focus\:border-blue-lighter:focus {
    border-color: #bcdefa; }
  .xl\:focus\:border-blue-lightest:focus {
    border-color: #eff8ff; }
  .xl\:focus\:border-indigo-darkest:focus {
    border-color: #191e38; }
  .xl\:focus\:border-indigo-darker:focus {
    border-color: #2f365f; }
  .xl\:focus\:border-indigo-dark:focus {
    border-color: #5661b3; }
  .xl\:focus\:border-indigo:focus {
    border-color: #6574cd; }
  .xl\:focus\:border-indigo-light:focus {
    border-color: #7886d7; }
  .xl\:focus\:border-indigo-lighter:focus {
    border-color: #b2b7ff; }
  .xl\:focus\:border-indigo-lightest:focus {
    border-color: #e6e8ff; }
  .xl\:focus\:border-purple-darkest:focus {
    border-color: #21183c; }
  .xl\:focus\:border-purple-darker:focus {
    border-color: #382b5f; }
  .xl\:focus\:border-purple-dark:focus {
    border-color: #794acf; }
  .xl\:focus\:border-purple:focus {
    border-color: #9561e2; }
  .xl\:focus\:border-purple-light:focus {
    border-color: #a779e9; }
  .xl\:focus\:border-purple-lighter:focus {
    border-color: #d6bbfc; }
  .xl\:focus\:border-purple-lightest:focus {
    border-color: #f3ebff; }
  .xl\:focus\:border-pink-darkest:focus {
    border-color: #451225; }
  .xl\:focus\:border-pink-darker:focus {
    border-color: #6f213f; }
  .xl\:focus\:border-pink-dark:focus {
    border-color: #eb5286; }
  .xl\:focus\:border-pink:focus {
    border-color: #f66d9b; }
  .xl\:focus\:border-pink-light:focus {
    border-color: #fa7ea8; }
  .xl\:focus\:border-pink-lighter:focus {
    border-color: #ffbbca; }
  .xl\:focus\:border-pink-lightest:focus {
    border-color: #ffebef; }
  .xl\:rounded-none {
    border-radius: 0; }
  .xl\:rounded-sm {
    border-radius: .125rem; }
  .xl\:rounded {
    border-radius: .25rem; }
  .xl\:rounded-lg {
    border-radius: .5rem; }
  .xl\:rounded-full {
    border-radius: 9999px; }
  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .xl\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem; }
  .xl\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem; }
  .xl\:rounded-b-sm {
    border-bottom-right-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .xl\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem; }
  .xl\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .xl\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem; }
  .xl\:rounded-b {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .xl\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .xl\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem; }
  .xl\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem; }
  .xl\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .xl\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem; }
  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px; }
  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px; }
  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px; }
  .xl\:rounded-tl-none {
    border-top-left-radius: 0; }
  .xl\:rounded-tr-none {
    border-top-right-radius: 0; }
  .xl\:rounded-br-none {
    border-bottom-right-radius: 0; }
  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0; }
  .xl\:rounded-tl-sm {
    border-top-left-radius: .125rem; }
  .xl\:rounded-tr-sm {
    border-top-right-radius: .125rem; }
  .xl\:rounded-br-sm {
    border-bottom-right-radius: .125rem; }
  .xl\:rounded-bl-sm {
    border-bottom-left-radius: .125rem; }
  .xl\:rounded-tl {
    border-top-left-radius: .25rem; }
  .xl\:rounded-tr {
    border-top-right-radius: .25rem; }
  .xl\:rounded-br {
    border-bottom-right-radius: .25rem; }
  .xl\:rounded-bl {
    border-bottom-left-radius: .25rem; }
  .xl\:rounded-tl-lg {
    border-top-left-radius: .5rem; }
  .xl\:rounded-tr-lg {
    border-top-right-radius: .5rem; }
  .xl\:rounded-br-lg {
    border-bottom-right-radius: .5rem; }
  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .5rem; }
  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px; }
  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px; }
  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px; }
  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px; }
  .xl\:border-solid {
    border-style: solid; }
  .xl\:border-dashed {
    border-style: dashed; }
  .xl\:border-dotted {
    border-style: dotted; }
  .xl\:border-none {
    border-style: none; }
  .xl\:border-0 {
    border-width: 0; }
  .xl\:border-2 {
    border-width: 2px; }
  .xl\:border-4 {
    border-width: 4px; }
  .xl\:border-8 {
    border-width: 8px; }
  .xl\:border {
    border-width: 1px; }
  .xl\:border-t-0 {
    border-top-width: 0; }
  .xl\:border-r-0 {
    border-right-width: 0; }
  .xl\:border-b-0 {
    border-bottom-width: 0; }
  .xl\:border-l-0 {
    border-left-width: 0; }
  .xl\:border-t-2 {
    border-top-width: 2px; }
  .xl\:border-r-2 {
    border-right-width: 2px; }
  .xl\:border-b-2 {
    border-bottom-width: 2px; }
  .xl\:border-l-2 {
    border-left-width: 2px; }
  .xl\:border-t-4 {
    border-top-width: 4px; }
  .xl\:border-r-4 {
    border-right-width: 4px; }
  .xl\:border-b-4 {
    border-bottom-width: 4px; }
  .xl\:border-l-4 {
    border-left-width: 4px; }
  .xl\:border-t-8 {
    border-top-width: 8px; }
  .xl\:border-r-8 {
    border-right-width: 8px; }
  .xl\:border-b-8 {
    border-bottom-width: 8px; }
  .xl\:border-l-8 {
    border-left-width: 8px; }
  .xl\:border-t {
    border-top-width: 1px; }
  .xl\:border-r {
    border-right-width: 1px; }
  .xl\:border-b {
    border-bottom-width: 1px; }
  .xl\:border-l {
    border-left-width: 1px; }
  .xl\:cursor-auto {
    cursor: auto; }
  .xl\:cursor-default {
    cursor: default; }
  .xl\:cursor-pointer {
    cursor: pointer; }
  .xl\:cursor-wait {
    cursor: wait; }
  .xl\:cursor-move {
    cursor: move; }
  .xl\:cursor-not-allowed {
    cursor: not-allowed; }
  .xl\:block {
    display: block; }
  .xl\:inline-block {
    display: inline-block; }
  .xl\:inline {
    display: inline; }
  .xl\:table {
    display: table; }
  .xl\:table-row {
    display: table-row; }
  .xl\:table-cell {
    display: table-cell; }
  .xl\:hidden {
    display: none; }
  .xl\:flex {
    display: flex; }
  .xl\:inline-flex {
    display: inline-flex; }
  .xl\:flex-row {
    flex-direction: row; }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse; }
  .xl\:flex-col {
    flex-direction: column; }
  .xl\:flex-col-reverse {
    flex-direction: column-reverse; }
  .xl\:flex-wrap {
    flex-wrap: wrap; }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse; }
  .xl\:flex-no-wrap {
    flex-wrap: nowrap; }
  .xl\:items-start {
    align-items: flex-start; }
  .xl\:items-end {
    align-items: flex-end; }
  .xl\:items-center {
    align-items: center; }
  .xl\:items-baseline {
    align-items: baseline; }
  .xl\:items-stretch {
    align-items: stretch; }
  .xl\:self-auto {
    align-self: auto; }
  .xl\:self-start {
    align-self: flex-start; }
  .xl\:self-end {
    align-self: flex-end; }
  .xl\:self-center {
    align-self: center; }
  .xl\:self-stretch {
    align-self: stretch; }
  .xl\:justify-start {
    justify-content: flex-start; }
  .xl\:justify-end {
    justify-content: flex-end; }
  .xl\:justify-center {
    justify-content: center; }
  .xl\:justify-between {
    justify-content: space-between; }
  .xl\:justify-around {
    justify-content: space-around; }
  .xl\:content-center {
    align-content: center; }
  .xl\:content-start {
    align-content: flex-start; }
  .xl\:content-end {
    align-content: flex-end; }
  .xl\:content-between {
    align-content: space-between; }
  .xl\:content-around {
    align-content: space-around; }
  .xl\:flex-1 {
    flex: 1 1 0%; }
  .xl\:flex-auto {
    flex: 1 1 auto; }
  .xl\:flex-initial {
    flex: 0 1 auto; }
  .xl\:flex-none {
    flex: none; }
  .xl\:flex-grow {
    flex-grow: 1; }
  .xl\:flex-shrink {
    flex-shrink: 1; }
  .xl\:flex-no-grow {
    flex-grow: 0; }
  .xl\:flex-no-shrink {
    flex-shrink: 0; }
  .xl\:float-right {
    float: right; }
  .xl\:float-left {
    float: left; }
  .xl\:float-none {
    float: none; }
  .xl\:clearfix:after {
    content: "";
    display: table;
    clear: both; }
  .xl\:font-sans {
    font-family: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; }
  .xl\:font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif; }
  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace; }
  .xl\:font-hairline {
    font-weight: 100; }
  .xl\:font-thin {
    font-weight: 200; }
  .xl\:font-light {
    font-weight: 300; }
  .xl\:font-normal {
    font-weight: 400; }
  .xl\:font-medium {
    font-weight: 500; }
  .xl\:font-semibold {
    font-weight: 600; }
  .xl\:font-bold {
    font-weight: 700; }
  .xl\:font-extrabold {
    font-weight: 800; }
  .xl\:font-black {
    font-weight: 900; }
  .xl\:hover\:font-hairline:hover {
    font-weight: 100; }
  .xl\:hover\:font-thin:hover {
    font-weight: 200; }
  .xl\:hover\:font-light:hover {
    font-weight: 300; }
  .xl\:hover\:font-normal:hover {
    font-weight: 400; }
  .xl\:hover\:font-medium:hover {
    font-weight: 500; }
  .xl\:hover\:font-semibold:hover {
    font-weight: 600; }
  .xl\:hover\:font-bold:hover {
    font-weight: 700; }
  .xl\:hover\:font-extrabold:hover {
    font-weight: 800; }
  .xl\:hover\:font-black:hover {
    font-weight: 900; }
  .xl\:focus\:font-hairline:focus {
    font-weight: 100; }
  .xl\:focus\:font-thin:focus {
    font-weight: 200; }
  .xl\:focus\:font-light:focus {
    font-weight: 300; }
  .xl\:focus\:font-normal:focus {
    font-weight: 400; }
  .xl\:focus\:font-medium:focus {
    font-weight: 500; }
  .xl\:focus\:font-semibold:focus {
    font-weight: 600; }
  .xl\:focus\:font-bold:focus {
    font-weight: 700; }
  .xl\:focus\:font-extrabold:focus {
    font-weight: 800; }
  .xl\:focus\:font-black:focus {
    font-weight: 900; }
  .xl\:h-1 {
    height: .25rem; }
  .xl\:h-2 {
    height: .5rem; }
  .xl\:h-3 {
    height: .75rem; }
  .xl\:h-4 {
    height: 1rem; }
  .xl\:h-5 {
    height: 1.25rem; }
  .xl\:h-6 {
    height: 1.5rem; }
  .xl\:h-8 {
    height: 2rem; }
  .xl\:h-10 {
    height: 2.5rem; }
  .xl\:h-12 {
    height: 3rem; }
  .xl\:h-16 {
    height: 4rem; }
  .xl\:h-24 {
    height: 6rem; }
  .xl\:h-32 {
    height: 8rem; }
  .xl\:h-48 {
    height: 12rem; }
  .xl\:h-64 {
    height: 16rem; }
  .xl\:h-auto {
    height: auto; }
  .xl\:h-px {
    height: 1px; }
  .xl\:h-full {
    height: 100%; }
  .xl\:h-screen {
    height: 100vh; }
  .xl\:leading-none {
    line-height: 1; }
  .xl\:leading-tight {
    line-height: 1.25; }
  .xl\:leading-normal {
    line-height: 1.5; }
  .xl\:leading-loose {
    line-height: 2; }
  .xl\:m-0 {
    margin: 0; }
  .xl\:m-1 {
    margin: .25rem; }
  .xl\:m-2 {
    margin: .5rem; }
  .xl\:m-3 {
    margin: .75rem; }
  .xl\:m-4 {
    margin: 1rem; }
  .xl\:m-5 {
    margin: 1.25rem; }
  .xl\:m-6 {
    margin: 1.5rem; }
  .xl\:m-8 {
    margin: 2rem; }
  .xl\:m-10 {
    margin: 2.5rem; }
  .xl\:m-12 {
    margin: 3rem; }
  .xl\:m-16 {
    margin: 4rem; }
  .xl\:m-20 {
    margin: 5rem; }
  .xl\:m-24 {
    margin: 6rem; }
  .xl\:m-32 {
    margin: 8rem; }
  .xl\:m-auto {
    margin: auto; }
  .xl\:m-px {
    margin: 1px; }
  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .xl\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem; }
  .xl\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem; }
  .xl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem; }
  .xl\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem; }
  .xl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem; }
  .xl\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem; }
  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem; }
  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem; }
  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto; }
  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px; }
  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px; }
  .xl\:mt-0 {
    margin-top: 0; }
  .xl\:mr-0 {
    margin-right: 0; }
  .xl\:mb-0 {
    margin-bottom: 0; }
  .xl\:ml-0 {
    margin-left: 0; }
  .xl\:mt-1 {
    margin-top: .25rem; }
  .xl\:mr-1 {
    margin-right: .25rem; }
  .xl\:mb-1 {
    margin-bottom: .25rem; }
  .xl\:ml-1 {
    margin-left: .25rem; }
  .xl\:mt-2 {
    margin-top: .5rem; }
  .xl\:mr-2 {
    margin-right: .5rem; }
  .xl\:mb-2 {
    margin-bottom: .5rem; }
  .xl\:ml-2 {
    margin-left: .5rem; }
  .xl\:mt-3 {
    margin-top: .75rem; }
  .xl\:mr-3 {
    margin-right: .75rem; }
  .xl\:mb-3 {
    margin-bottom: .75rem; }
  .xl\:ml-3 {
    margin-left: .75rem; }
  .xl\:mt-4 {
    margin-top: 1rem; }
  .xl\:mr-4 {
    margin-right: 1rem; }
  .xl\:mb-4 {
    margin-bottom: 1rem; }
  .xl\:ml-4 {
    margin-left: 1rem; }
  .xl\:mt-5 {
    margin-top: 1.25rem; }
  .xl\:mr-5 {
    margin-right: 1.25rem; }
  .xl\:mb-5 {
    margin-bottom: 1.25rem; }
  .xl\:ml-5 {
    margin-left: 1.25rem; }
  .xl\:mt-6 {
    margin-top: 1.5rem; }
  .xl\:mr-6 {
    margin-right: 1.5rem; }
  .xl\:mb-6 {
    margin-bottom: 1.5rem; }
  .xl\:ml-6 {
    margin-left: 1.5rem; }
  .xl\:mt-8 {
    margin-top: 2rem; }
  .xl\:mr-8 {
    margin-right: 2rem; }
  .xl\:mb-8 {
    margin-bottom: 2rem; }
  .xl\:ml-8 {
    margin-left: 2rem; }
  .xl\:mt-10 {
    margin-top: 2.5rem; }
  .xl\:mr-10 {
    margin-right: 2.5rem; }
  .xl\:mb-10 {
    margin-bottom: 2.5rem; }
  .xl\:ml-10 {
    margin-left: 2.5rem; }
  .xl\:mt-12 {
    margin-top: 3rem; }
  .xl\:mr-12 {
    margin-right: 3rem; }
  .xl\:mb-12 {
    margin-bottom: 3rem; }
  .xl\:ml-12 {
    margin-left: 3rem; }
  .xl\:mt-16 {
    margin-top: 4rem; }
  .xl\:mr-16 {
    margin-right: 4rem; }
  .xl\:mb-16 {
    margin-bottom: 4rem; }
  .xl\:ml-16 {
    margin-left: 4rem; }
  .xl\:mt-20 {
    margin-top: 5rem; }
  .xl\:mr-20 {
    margin-right: 5rem; }
  .xl\:mb-20 {
    margin-bottom: 5rem; }
  .xl\:ml-20 {
    margin-left: 5rem; }
  .xl\:mt-24 {
    margin-top: 6rem; }
  .xl\:mr-24 {
    margin-right: 6rem; }
  .xl\:mb-24 {
    margin-bottom: 6rem; }
  .xl\:ml-24 {
    margin-left: 6rem; }
  .xl\:mt-32 {
    margin-top: 8rem; }
  .xl\:mr-32 {
    margin-right: 8rem; }
  .xl\:mb-32 {
    margin-bottom: 8rem; }
  .xl\:ml-32 {
    margin-left: 8rem; }
  .xl\:mt-auto {
    margin-top: auto; }
  .xl\:mr-auto {
    margin-right: auto; }
  .xl\:mb-auto {
    margin-bottom: auto; }
  .xl\:ml-auto {
    margin-left: auto; }
  .xl\:mt-px {
    margin-top: 1px; }
  .xl\:mr-px {
    margin-right: 1px; }
  .xl\:mb-px {
    margin-bottom: 1px; }
  .xl\:ml-px {
    margin-left: 1px; }
  .xl\:max-h-full {
    max-height: 100%; }
  .xl\:max-h-screen {
    max-height: 100vh; }
  .xl\:max-w-xs {
    max-width: 20rem; }
  .xl\:max-w-sm {
    max-width: 30rem; }
  .xl\:max-w-md {
    max-width: 40rem; }
  .xl\:max-w-lg {
    max-width: 50rem; }
  .xl\:max-w-xl {
    max-width: 60rem; }
  .xl\:max-w-2xl {
    max-width: 70rem; }
  .xl\:max-w-3xl {
    max-width: 80rem; }
  .xl\:max-w-4xl {
    max-width: 90rem; }
  .xl\:max-w-5xl {
    max-width: 100rem; }
  .xl\:max-w-full {
    max-width: 100%; }
  .xl\:min-h-0 {
    min-height: 0; }
  .xl\:min-h-full {
    min-height: 100%; }
  .xl\:min-h-screen {
    min-height: 100vh; }
  .xl\:min-w-0 {
    min-width: 0; }
  .xl\:min-w-full {
    min-width: 100%; }
  .xl\:-m-0 {
    margin: 0; }
  .xl\:-m-1 {
    margin: -0.25rem; }
  .xl\:-m-2 {
    margin: -0.5rem; }
  .xl\:-m-3 {
    margin: -0.75rem; }
  .xl\:-m-4 {
    margin: -1rem; }
  .xl\:-m-5 {
    margin: -1.25rem; }
  .xl\:-m-6 {
    margin: -1.5rem; }
  .xl\:-m-8 {
    margin: -2rem; }
  .xl\:-m-10 {
    margin: -2.5rem; }
  .xl\:-m-12 {
    margin: -3rem; }
  .xl\:-m-16 {
    margin: -4rem; }
  .xl\:-m-20 {
    margin: -5rem; }
  .xl\:-m-24 {
    margin: -6rem; }
  .xl\:-m-32 {
    margin: -8rem; }
  .xl\:-m-px {
    margin: -1px; }
  .xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0; }
  .xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0; }
  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem; }
  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem; }
  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem; }
  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem; }
  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem; }
  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem; }
  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem; }
  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem; }
  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem; }
  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem; }
  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem; }
  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem; }
  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem; }
  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem; }
  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem; }
  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem; }
  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem; }
  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem; }
  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem; }
  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem; }
  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem; }
  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem; }
  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem; }
  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem; }
  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem; }
  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px; }
  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px; }
  .xl\:-mt-0 {
    margin-top: 0; }
  .xl\:-mr-0 {
    margin-right: 0; }
  .xl\:-mb-0 {
    margin-bottom: 0; }
  .xl\:-ml-0 {
    margin-left: 0; }
  .xl\:-mt-1 {
    margin-top: -0.25rem; }
  .xl\:-mr-1 {
    margin-right: -0.25rem; }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem; }
  .xl\:-ml-1 {
    margin-left: -0.25rem; }
  .xl\:-mt-2 {
    margin-top: -0.5rem; }
  .xl\:-mr-2 {
    margin-right: -0.5rem; }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem; }
  .xl\:-ml-2 {
    margin-left: -0.5rem; }
  .xl\:-mt-3 {
    margin-top: -0.75rem; }
  .xl\:-mr-3 {
    margin-right: -0.75rem; }
  .xl\:-mb-3 {
    margin-bottom: -0.75rem; }
  .xl\:-ml-3 {
    margin-left: -0.75rem; }
  .xl\:-mt-4 {
    margin-top: -1rem; }
  .xl\:-mr-4 {
    margin-right: -1rem; }
  .xl\:-mb-4 {
    margin-bottom: -1rem; }
  .xl\:-ml-4 {
    margin-left: -1rem; }
  .xl\:-mt-5 {
    margin-top: -1.25rem; }
  .xl\:-mr-5 {
    margin-right: -1.25rem; }
  .xl\:-mb-5 {
    margin-bottom: -1.25rem; }
  .xl\:-ml-5 {
    margin-left: -1.25rem; }
  .xl\:-mt-6 {
    margin-top: -1.5rem; }
  .xl\:-mr-6 {
    margin-right: -1.5rem; }
  .xl\:-mb-6 {
    margin-bottom: -1.5rem; }
  .xl\:-ml-6 {
    margin-left: -1.5rem; }
  .xl\:-mt-8 {
    margin-top: -2rem; }
  .xl\:-mr-8 {
    margin-right: -2rem; }
  .xl\:-mb-8 {
    margin-bottom: -2rem; }
  .xl\:-ml-8 {
    margin-left: -2rem; }
  .xl\:-mt-10 {
    margin-top: -2.5rem; }
  .xl\:-mr-10 {
    margin-right: -2.5rem; }
  .xl\:-mb-10 {
    margin-bottom: -2.5rem; }
  .xl\:-ml-10 {
    margin-left: -2.5rem; }
  .xl\:-mt-12 {
    margin-top: -3rem; }
  .xl\:-mr-12 {
    margin-right: -3rem; }
  .xl\:-mb-12 {
    margin-bottom: -3rem; }
  .xl\:-ml-12 {
    margin-left: -3rem; }
  .xl\:-mt-16 {
    margin-top: -4rem; }
  .xl\:-mr-16 {
    margin-right: -4rem; }
  .xl\:-mb-16 {
    margin-bottom: -4rem; }
  .xl\:-ml-16 {
    margin-left: -4rem; }
  .xl\:-mt-20 {
    margin-top: -5rem; }
  .xl\:-mr-20 {
    margin-right: -5rem; }
  .xl\:-mb-20 {
    margin-bottom: -5rem; }
  .xl\:-ml-20 {
    margin-left: -5rem; }
  .xl\:-mt-24 {
    margin-top: -6rem; }
  .xl\:-mr-24 {
    margin-right: -6rem; }
  .xl\:-mb-24 {
    margin-bottom: -6rem; }
  .xl\:-ml-24 {
    margin-left: -6rem; }
  .xl\:-mt-32 {
    margin-top: -8rem; }
  .xl\:-mr-32 {
    margin-right: -8rem; }
  .xl\:-mb-32 {
    margin-bottom: -8rem; }
  .xl\:-ml-32 {
    margin-left: -8rem; }
  .xl\:-mt-px {
    margin-top: -1px; }
  .xl\:-mr-px {
    margin-right: -1px; }
  .xl\:-mb-px {
    margin-bottom: -1px; }
  .xl\:-ml-px {
    margin-left: -1px; }
  .xl\:opacity-0 {
    opacity: 0; }
  .xl\:opacity-25 {
    opacity: .25; }
  .xl\:opacity-50 {
    opacity: .5; }
  .xl\:opacity-75 {
    opacity: .75; }
  .xl\:opacity-100 {
    opacity: 1; }
  .xl\:overflow-auto {
    overflow: auto; }
  .xl\:overflow-hidden {
    overflow: hidden; }
  .xl\:overflow-visible {
    overflow: visible; }
  .xl\:overflow-scroll {
    overflow: scroll; }
  .xl\:overflow-x-auto {
    overflow-x: auto; }
  .xl\:overflow-y-auto {
    overflow-y: auto; }
  .xl\:overflow-x-hidden {
    overflow-x: hidden; }
  .xl\:overflow-y-hidden {
    overflow-y: hidden; }
  .xl\:overflow-x-visible {
    overflow-x: visible; }
  .xl\:overflow-y-visible {
    overflow-y: visible; }
  .xl\:overflow-x-scroll {
    overflow-x: scroll; }
  .xl\:overflow-y-scroll {
    overflow-y: scroll; }
  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch; }
  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto; }
  .xl\:p-0 {
    padding: 0; }
  .xl\:p-1 {
    padding: .25rem; }
  .xl\:p-2 {
    padding: .5rem; }
  .xl\:p-3 {
    padding: .75rem; }
  .xl\:p-4 {
    padding: 1rem; }
  .xl\:p-5 {
    padding: 1.25rem; }
  .xl\:p-6 {
    padding: 1.5rem; }
  .xl\:p-8 {
    padding: 2rem; }
  .xl\:p-10 {
    padding: 2.5rem; }
  .xl\:p-12 {
    padding: 3rem; }
  .xl\:p-16 {
    padding: 4rem; }
  .xl\:p-20 {
    padding: 5rem; }
  .xl\:p-24 {
    padding: 6rem; }
  .xl\:p-32 {
    padding: 8rem; }
  .xl\:p-px {
    padding: 1px; }
  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0; }
  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0; }
  .xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem; }
  .xl\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem; }
  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem; }
  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem; }
  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem; }
  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem; }
  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem; }
  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem; }
  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px; }
  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px; }
  .xl\:pt-0 {
    padding-top: 0; }
  .xl\:pr-0 {
    padding-right: 0; }
  .xl\:pb-0 {
    padding-bottom: 0; }
  .xl\:pl-0 {
    padding-left: 0; }
  .xl\:pt-1 {
    padding-top: .25rem; }
  .xl\:pr-1 {
    padding-right: .25rem; }
  .xl\:pb-1 {
    padding-bottom: .25rem; }
  .xl\:pl-1 {
    padding-left: .25rem; }
  .xl\:pt-2 {
    padding-top: .5rem; }
  .xl\:pr-2 {
    padding-right: .5rem; }
  .xl\:pb-2 {
    padding-bottom: .5rem; }
  .xl\:pl-2 {
    padding-left: .5rem; }
  .xl\:pt-3 {
    padding-top: .75rem; }
  .xl\:pr-3 {
    padding-right: .75rem; }
  .xl\:pb-3 {
    padding-bottom: .75rem; }
  .xl\:pl-3 {
    padding-left: .75rem; }
  .xl\:pt-4 {
    padding-top: 1rem; }
  .xl\:pr-4 {
    padding-right: 1rem; }
  .xl\:pb-4 {
    padding-bottom: 1rem; }
  .xl\:pl-4 {
    padding-left: 1rem; }
  .xl\:pt-5 {
    padding-top: 1.25rem; }
  .xl\:pr-5 {
    padding-right: 1.25rem; }
  .xl\:pb-5 {
    padding-bottom: 1.25rem; }
  .xl\:pl-5 {
    padding-left: 1.25rem; }
  .xl\:pt-6 {
    padding-top: 1.5rem; }
  .xl\:pr-6 {
    padding-right: 1.5rem; }
  .xl\:pb-6 {
    padding-bottom: 1.5rem; }
  .xl\:pl-6 {
    padding-left: 1.5rem; }
  .xl\:pt-8 {
    padding-top: 2rem; }
  .xl\:pr-8 {
    padding-right: 2rem; }
  .xl\:pb-8 {
    padding-bottom: 2rem; }
  .xl\:pl-8 {
    padding-left: 2rem; }
  .xl\:pt-10 {
    padding-top: 2.5rem; }
  .xl\:pr-10 {
    padding-right: 2.5rem; }
  .xl\:pb-10 {
    padding-bottom: 2.5rem; }
  .xl\:pl-10 {
    padding-left: 2.5rem; }
  .xl\:pt-12 {
    padding-top: 3rem; }
  .xl\:pr-12 {
    padding-right: 3rem; }
  .xl\:pb-12 {
    padding-bottom: 3rem; }
  .xl\:pl-12 {
    padding-left: 3rem; }
  .xl\:pt-16 {
    padding-top: 4rem; }
  .xl\:pr-16 {
    padding-right: 4rem; }
  .xl\:pb-16 {
    padding-bottom: 4rem; }
  .xl\:pl-16 {
    padding-left: 4rem; }
  .xl\:pt-20 {
    padding-top: 5rem; }
  .xl\:pr-20 {
    padding-right: 5rem; }
  .xl\:pb-20 {
    padding-bottom: 5rem; }
  .xl\:pl-20 {
    padding-left: 5rem; }
  .xl\:pt-24 {
    padding-top: 6rem; }
  .xl\:pr-24 {
    padding-right: 6rem; }
  .xl\:pb-24 {
    padding-bottom: 6rem; }
  .xl\:pl-24 {
    padding-left: 6rem; }
  .xl\:pt-32 {
    padding-top: 8rem; }
  .xl\:pr-32 {
    padding-right: 8rem; }
  .xl\:pb-32 {
    padding-bottom: 8rem; }
  .xl\:pl-32 {
    padding-left: 8rem; }
  .xl\:pt-px {
    padding-top: 1px; }
  .xl\:pr-px {
    padding-right: 1px; }
  .xl\:pb-px {
    padding-bottom: 1px; }
  .xl\:pl-px {
    padding-left: 1px; }
  .xl\:pointer-events-none {
    pointer-events: none; }
  .xl\:pointer-events-auto {
    pointer-events: auto; }
  .xl\:static {
    position: static; }
  .xl\:fixed {
    position: fixed; }
  .xl\:absolute {
    position: absolute; }
  .xl\:relative {
    position: relative; }
  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky; }
  .xl\:pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto; }
  .xl\:pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .xl\:pin-y {
    top: 0;
    bottom: 0; }
  .xl\:pin-x {
    right: 0;
    left: 0; }
  .xl\:pin-t {
    top: 0; }
  .xl\:pin-r {
    right: 0; }
  .xl\:pin-b {
    bottom: 0; }
  .xl\:pin-l {
    left: 0; }
  .xl\:resize-none {
    resize: none; }
  .xl\:resize-y {
    resize: vertical; }
  .xl\:resize-x {
    resize: horizontal; }
  .xl\:resize {
    resize: both; }
  .xl\:shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .xl\:shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .xl\:shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .xl\:shadow-outline {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .xl\:shadow-none {
    box-shadow: none; }
  .xl\:hover\:shadow:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .xl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .xl\:hover\:shadow-none:hover {
    box-shadow: none; }
  .xl\:focus\:shadow:focus {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08); }
  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08); }
  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06); }
  .xl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5); }
  .xl\:focus\:shadow-none:focus {
    box-shadow: none; }
  .xl\:table-auto {
    table-layout: auto; }
  .xl\:table-fixed {
    table-layout: fixed; }
  .xl\:text-left {
    text-align: left; }
  .xl\:text-center {
    text-align: center; }
  .xl\:text-right {
    text-align: right; }
  .xl\:text-justify {
    text-align: justify; }
  .xl\:text-transparent {
    color: transparent; }
  .xl\:text-black {
    color: #22292f; }
  .xl\:text-grey-darkest {
    color: #3d4852; }
  .xl\:text-grey-darker {
    color: #606f7b; }
  .xl\:text-grey-dark {
    color: #8795a1; }
  .xl\:text-grey {
    color: #b8c2cc; }
  .xl\:text-grey-light {
    color: #dae1e7; }
  .xl\:text-grey-lighter {
    color: #f1f5f8; }
  .xl\:text-grey-lightest {
    color: #f8fafc; }
  .xl\:text-white {
    color: #fff; }
  .xl\:text-red-darkest {
    color: #3b0d0c; }
  .xl\:text-red-darker {
    color: #621b18; }
  .xl\:text-red-dark {
    color: #cc1f1a; }
  .xl\:text-red {
    color: #e3342f; }
  .xl\:text-red-light {
    color: #ef5753; }
  .xl\:text-red-lighter {
    color: #f9acaa; }
  .xl\:text-red-lightest {
    color: #fcebea; }
  .xl\:text-orange-darkest {
    color: #462a16; }
  .xl\:text-orange-darker {
    color: #613b1f; }
  .xl\:text-orange-dark {
    color: #de751f; }
  .xl\:text-orange {
    color: #f6993f; }
  .xl\:text-orange-light {
    color: #faad63; }
  .xl\:text-orange-lighter {
    color: #fcd9b6; }
  .xl\:text-orange-lightest {
    color: #fff5eb; }
  .xl\:text-yellow-darkest {
    color: #453411; }
  .xl\:text-yellow-darker {
    color: #684f1d; }
  .xl\:text-yellow-dark {
    color: #f2d024; }
  .xl\:text-yellow {
    color: #ffed4a; }
  .xl\:text-yellow-light {
    color: #fff382; }
  .xl\:text-yellow-lighter {
    color: #fff9c2; }
  .xl\:text-yellow-lightest {
    color: #fcfbeb; }
  .xl\:text-green-darkest {
    color: #0f2f21; }
  .xl\:text-green-darker {
    color: #1a4731; }
  .xl\:text-green-dark {
    color: #1f9d55; }
  .xl\:text-green {
    color: #38c172; }
  .xl\:text-green-light {
    color: #51d88a; }
  .xl\:text-green-lighter {
    color: #a2f5bf; }
  .xl\:text-green-lightest {
    color: #e3fcec; }
  .xl\:text-teal-darkest {
    color: #0d3331; }
  .xl\:text-teal-darker {
    color: #20504f; }
  .xl\:text-teal-dark {
    color: #38a89d; }
  .xl\:text-teal {
    color: #4dc0b5; }
  .xl\:text-teal-light {
    color: #64d5ca; }
  .xl\:text-teal-lighter {
    color: #a0f0ed; }
  .xl\:text-teal-lightest {
    color: #e8fffe; }
  .xl\:text-blue-darkest {
    color: #12283a; }
  .xl\:text-blue-darker {
    color: #1c3d5a; }
  .xl\:text-blue-dark {
    color: #2779bd; }
  .xl\:text-blue {
    color: #3490dc; }
  .xl\:text-blue-light {
    color: #6cb2eb; }
  .xl\:text-blue-lighter {
    color: #bcdefa; }
  .xl\:text-blue-lightest {
    color: #eff8ff; }
  .xl\:text-indigo-darkest {
    color: #191e38; }
  .xl\:text-indigo-darker {
    color: #2f365f; }
  .xl\:text-indigo-dark {
    color: #5661b3; }
  .xl\:text-indigo {
    color: #6574cd; }
  .xl\:text-indigo-light {
    color: #7886d7; }
  .xl\:text-indigo-lighter {
    color: #b2b7ff; }
  .xl\:text-indigo-lightest {
    color: #e6e8ff; }
  .xl\:text-purple-darkest {
    color: #21183c; }
  .xl\:text-purple-darker {
    color: #382b5f; }
  .xl\:text-purple-dark {
    color: #794acf; }
  .xl\:text-purple {
    color: #9561e2; }
  .xl\:text-purple-light {
    color: #a779e9; }
  .xl\:text-purple-lighter {
    color: #d6bbfc; }
  .xl\:text-purple-lightest {
    color: #f3ebff; }
  .xl\:text-pink-darkest {
    color: #451225; }
  .xl\:text-pink-darker {
    color: #6f213f; }
  .xl\:text-pink-dark {
    color: #eb5286; }
  .xl\:text-pink {
    color: #f66d9b; }
  .xl\:text-pink-light {
    color: #fa7ea8; }
  .xl\:text-pink-lighter {
    color: #ffbbca; }
  .xl\:text-pink-lightest {
    color: #ffebef; }
  .xl\:hover\:text-transparent:hover {
    color: transparent; }
  .xl\:hover\:text-black:hover {
    color: #22292f; }
  .xl\:hover\:text-grey-darkest:hover {
    color: #3d4852; }
  .xl\:hover\:text-grey-darker:hover {
    color: #606f7b; }
  .xl\:hover\:text-grey-dark:hover {
    color: #8795a1; }
  .xl\:hover\:text-grey:hover {
    color: #b8c2cc; }
  .xl\:hover\:text-grey-light:hover {
    color: #dae1e7; }
  .xl\:hover\:text-grey-lighter:hover {
    color: #f1f5f8; }
  .xl\:hover\:text-grey-lightest:hover {
    color: #f8fafc; }
  .xl\:hover\:text-white:hover {
    color: #fff; }
  .xl\:hover\:text-red-darkest:hover {
    color: #3b0d0c; }
  .xl\:hover\:text-red-darker:hover {
    color: #621b18; }
  .xl\:hover\:text-red-dark:hover {
    color: #cc1f1a; }
  .xl\:hover\:text-red:hover {
    color: #e3342f; }
  .xl\:hover\:text-red-light:hover {
    color: #ef5753; }
  .xl\:hover\:text-red-lighter:hover {
    color: #f9acaa; }
  .xl\:hover\:text-red-lightest:hover {
    color: #fcebea; }
  .xl\:hover\:text-orange-darkest:hover {
    color: #462a16; }
  .xl\:hover\:text-orange-darker:hover {
    color: #613b1f; }
  .xl\:hover\:text-orange-dark:hover {
    color: #de751f; }
  .xl\:hover\:text-orange:hover {
    color: #f6993f; }
  .xl\:hover\:text-orange-light:hover {
    color: #faad63; }
  .xl\:hover\:text-orange-lighter:hover {
    color: #fcd9b6; }
  .xl\:hover\:text-orange-lightest:hover {
    color: #fff5eb; }
  .xl\:hover\:text-yellow-darkest:hover {
    color: #453411; }
  .xl\:hover\:text-yellow-darker:hover {
    color: #684f1d; }
  .xl\:hover\:text-yellow-dark:hover {
    color: #f2d024; }
  .xl\:hover\:text-yellow:hover {
    color: #ffed4a; }
  .xl\:hover\:text-yellow-light:hover {
    color: #fff382; }
  .xl\:hover\:text-yellow-lighter:hover {
    color: #fff9c2; }
  .xl\:hover\:text-yellow-lightest:hover {
    color: #fcfbeb; }
  .xl\:hover\:text-green-darkest:hover {
    color: #0f2f21; }
  .xl\:hover\:text-green-darker:hover {
    color: #1a4731; }
  .xl\:hover\:text-green-dark:hover {
    color: #1f9d55; }
  .xl\:hover\:text-green:hover {
    color: #38c172; }
  .xl\:hover\:text-green-light:hover {
    color: #51d88a; }
  .xl\:hover\:text-green-lighter:hover {
    color: #a2f5bf; }
  .xl\:hover\:text-green-lightest:hover {
    color: #e3fcec; }
  .xl\:hover\:text-teal-darkest:hover {
    color: #0d3331; }
  .xl\:hover\:text-teal-darker:hover {
    color: #20504f; }
  .xl\:hover\:text-teal-dark:hover {
    color: #38a89d; }
  .xl\:hover\:text-teal:hover {
    color: #4dc0b5; }
  .xl\:hover\:text-teal-light:hover {
    color: #64d5ca; }
  .xl\:hover\:text-teal-lighter:hover {
    color: #a0f0ed; }
  .xl\:hover\:text-teal-lightest:hover {
    color: #e8fffe; }
  .xl\:hover\:text-blue-darkest:hover {
    color: #12283a; }
  .xl\:hover\:text-blue-darker:hover {
    color: #1c3d5a; }
  .xl\:hover\:text-blue-dark:hover {
    color: #2779bd; }
  .xl\:hover\:text-blue:hover {
    color: #3490dc; }
  .xl\:hover\:text-blue-light:hover {
    color: #6cb2eb; }
  .xl\:hover\:text-blue-lighter:hover {
    color: #bcdefa; }
  .xl\:hover\:text-blue-lightest:hover {
    color: #eff8ff; }
  .xl\:hover\:text-indigo-darkest:hover {
    color: #191e38; }
  .xl\:hover\:text-indigo-darker:hover {
    color: #2f365f; }
  .xl\:hover\:text-indigo-dark:hover {
    color: #5661b3; }
  .xl\:hover\:text-indigo:hover {
    color: #6574cd; }
  .xl\:hover\:text-indigo-light:hover {
    color: #7886d7; }
  .xl\:hover\:text-indigo-lighter:hover {
    color: #b2b7ff; }
  .xl\:hover\:text-indigo-lightest:hover {
    color: #e6e8ff; }
  .xl\:hover\:text-purple-darkest:hover {
    color: #21183c; }
  .xl\:hover\:text-purple-darker:hover {
    color: #382b5f; }
  .xl\:hover\:text-purple-dark:hover {
    color: #794acf; }
  .xl\:hover\:text-purple:hover {
    color: #9561e2; }
  .xl\:hover\:text-purple-light:hover {
    color: #a779e9; }
  .xl\:hover\:text-purple-lighter:hover {
    color: #d6bbfc; }
  .xl\:hover\:text-purple-lightest:hover {
    color: #f3ebff; }
  .xl\:hover\:text-pink-darkest:hover {
    color: #451225; }
  .xl\:hover\:text-pink-darker:hover {
    color: #6f213f; }
  .xl\:hover\:text-pink-dark:hover {
    color: #eb5286; }
  .xl\:hover\:text-pink:hover {
    color: #f66d9b; }
  .xl\:hover\:text-pink-light:hover {
    color: #fa7ea8; }
  .xl\:hover\:text-pink-lighter:hover {
    color: #ffbbca; }
  .xl\:hover\:text-pink-lightest:hover {
    color: #ffebef; }
  .xl\:focus\:text-transparent:focus {
    color: transparent; }
  .xl\:focus\:text-black:focus {
    color: #22292f; }
  .xl\:focus\:text-grey-darkest:focus {
    color: #3d4852; }
  .xl\:focus\:text-grey-darker:focus {
    color: #606f7b; }
  .xl\:focus\:text-grey-dark:focus {
    color: #8795a1; }
  .xl\:focus\:text-grey:focus {
    color: #b8c2cc; }
  .xl\:focus\:text-grey-light:focus {
    color: #dae1e7; }
  .xl\:focus\:text-grey-lighter:focus {
    color: #f1f5f8; }
  .xl\:focus\:text-grey-lightest:focus {
    color: #f8fafc; }
  .xl\:focus\:text-white:focus {
    color: #fff; }
  .xl\:focus\:text-red-darkest:focus {
    color: #3b0d0c; }
  .xl\:focus\:text-red-darker:focus {
    color: #621b18; }
  .xl\:focus\:text-red-dark:focus {
    color: #cc1f1a; }
  .xl\:focus\:text-red:focus {
    color: #e3342f; }
  .xl\:focus\:text-red-light:focus {
    color: #ef5753; }
  .xl\:focus\:text-red-lighter:focus {
    color: #f9acaa; }
  .xl\:focus\:text-red-lightest:focus {
    color: #fcebea; }
  .xl\:focus\:text-orange-darkest:focus {
    color: #462a16; }
  .xl\:focus\:text-orange-darker:focus {
    color: #613b1f; }
  .xl\:focus\:text-orange-dark:focus {
    color: #de751f; }
  .xl\:focus\:text-orange:focus {
    color: #f6993f; }
  .xl\:focus\:text-orange-light:focus {
    color: #faad63; }
  .xl\:focus\:text-orange-lighter:focus {
    color: #fcd9b6; }
  .xl\:focus\:text-orange-lightest:focus {
    color: #fff5eb; }
  .xl\:focus\:text-yellow-darkest:focus {
    color: #453411; }
  .xl\:focus\:text-yellow-darker:focus {
    color: #684f1d; }
  .xl\:focus\:text-yellow-dark:focus {
    color: #f2d024; }
  .xl\:focus\:text-yellow:focus {
    color: #ffed4a; }
  .xl\:focus\:text-yellow-light:focus {
    color: #fff382; }
  .xl\:focus\:text-yellow-lighter:focus {
    color: #fff9c2; }
  .xl\:focus\:text-yellow-lightest:focus {
    color: #fcfbeb; }
  .xl\:focus\:text-green-darkest:focus {
    color: #0f2f21; }
  .xl\:focus\:text-green-darker:focus {
    color: #1a4731; }
  .xl\:focus\:text-green-dark:focus {
    color: #1f9d55; }
  .xl\:focus\:text-green:focus {
    color: #38c172; }
  .xl\:focus\:text-green-light:focus {
    color: #51d88a; }
  .xl\:focus\:text-green-lighter:focus {
    color: #a2f5bf; }
  .xl\:focus\:text-green-lightest:focus {
    color: #e3fcec; }
  .xl\:focus\:text-teal-darkest:focus {
    color: #0d3331; }
  .xl\:focus\:text-teal-darker:focus {
    color: #20504f; }
  .xl\:focus\:text-teal-dark:focus {
    color: #38a89d; }
  .xl\:focus\:text-teal:focus {
    color: #4dc0b5; }
  .xl\:focus\:text-teal-light:focus {
    color: #64d5ca; }
  .xl\:focus\:text-teal-lighter:focus {
    color: #a0f0ed; }
  .xl\:focus\:text-teal-lightest:focus {
    color: #e8fffe; }
  .xl\:focus\:text-blue-darkest:focus {
    color: #12283a; }
  .xl\:focus\:text-blue-darker:focus {
    color: #1c3d5a; }
  .xl\:focus\:text-blue-dark:focus {
    color: #2779bd; }
  .xl\:focus\:text-blue:focus {
    color: #3490dc; }
  .xl\:focus\:text-blue-light:focus {
    color: #6cb2eb; }
  .xl\:focus\:text-blue-lighter:focus {
    color: #bcdefa; }
  .xl\:focus\:text-blue-lightest:focus {
    color: #eff8ff; }
  .xl\:focus\:text-indigo-darkest:focus {
    color: #191e38; }
  .xl\:focus\:text-indigo-darker:focus {
    color: #2f365f; }
  .xl\:focus\:text-indigo-dark:focus {
    color: #5661b3; }
  .xl\:focus\:text-indigo:focus {
    color: #6574cd; }
  .xl\:focus\:text-indigo-light:focus {
    color: #7886d7; }
  .xl\:focus\:text-indigo-lighter:focus {
    color: #b2b7ff; }
  .xl\:focus\:text-indigo-lightest:focus {
    color: #e6e8ff; }
  .xl\:focus\:text-purple-darkest:focus {
    color: #21183c; }
  .xl\:focus\:text-purple-darker:focus {
    color: #382b5f; }
  .xl\:focus\:text-purple-dark:focus {
    color: #794acf; }
  .xl\:focus\:text-purple:focus {
    color: #9561e2; }
  .xl\:focus\:text-purple-light:focus {
    color: #a779e9; }
  .xl\:focus\:text-purple-lighter:focus {
    color: #d6bbfc; }
  .xl\:focus\:text-purple-lightest:focus {
    color: #f3ebff; }
  .xl\:focus\:text-pink-darkest:focus {
    color: #451225; }
  .xl\:focus\:text-pink-darker:focus {
    color: #6f213f; }
  .xl\:focus\:text-pink-dark:focus {
    color: #eb5286; }
  .xl\:focus\:text-pink:focus {
    color: #f66d9b; }
  .xl\:focus\:text-pink-light:focus {
    color: #fa7ea8; }
  .xl\:focus\:text-pink-lighter:focus {
    color: #ffbbca; }
  .xl\:focus\:text-pink-lightest:focus {
    color: #ffebef; }
  .xl\:text-xs {
    font-size: .75rem; }
  .xl\:text-sm {
    font-size: .875rem; }
  .xl\:text-base {
    font-size: 1rem; }
  .xl\:text-lg {
    font-size: 1.125rem; }
  .xl\:text-xl {
    font-size: 1.25rem; }
  .xl\:text-2xl {
    font-size: 1.5rem; }
  .xl\:text-3xl {
    font-size: 1.875rem; }
  .xl\:text-4xl {
    font-size: 2.25rem; }
  .xl\:text-5xl {
    font-size: 3rem; }
  .xl\:italic {
    font-style: italic; }
  .xl\:roman {
    font-style: normal; }
  .xl\:uppercase {
    text-transform: uppercase; }
  .xl\:lowercase {
    text-transform: lowercase; }
  .xl\:capitalize {
    text-transform: capitalize; }
  .xl\:normal-case {
    text-transform: none; }
  .xl\:underline {
    text-decoration: underline; }
  .xl\:line-through {
    text-decoration: line-through; }
  .xl\:no-underline {
    text-decoration: none; }
  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .xl\:hover\:italic:hover {
    font-style: italic; }
  .xl\:hover\:roman:hover {
    font-style: normal; }
  .xl\:hover\:uppercase:hover {
    text-transform: uppercase; }
  .xl\:hover\:lowercase:hover {
    text-transform: lowercase; }
  .xl\:hover\:capitalize:hover {
    text-transform: capitalize; }
  .xl\:hover\:normal-case:hover {
    text-transform: none; }
  .xl\:hover\:underline:hover {
    text-decoration: underline; }
  .xl\:hover\:line-through:hover {
    text-decoration: line-through; }
  .xl\:hover\:no-underline:hover {
    text-decoration: none; }
  .xl\:hover\:antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .xl\:hover\:subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .xl\:focus\:italic:focus {
    font-style: italic; }
  .xl\:focus\:roman:focus {
    font-style: normal; }
  .xl\:focus\:uppercase:focus {
    text-transform: uppercase; }
  .xl\:focus\:lowercase:focus {
    text-transform: lowercase; }
  .xl\:focus\:capitalize:focus {
    text-transform: capitalize; }
  .xl\:focus\:normal-case:focus {
    text-transform: none; }
  .xl\:focus\:underline:focus {
    text-decoration: underline; }
  .xl\:focus\:line-through:focus {
    text-decoration: line-through; }
  .xl\:focus\:no-underline:focus {
    text-decoration: none; }
  .xl\:focus\:antialiased:focus {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .xl\:focus\:subpixel-antialiased:focus {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto; }
  .xl\:tracking-tight {
    letter-spacing: -0.05em; }
  .xl\:tracking-normal {
    letter-spacing: 0; }
  .xl\:tracking-wide {
    letter-spacing: .05em; }
  .xl\:select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .xl\:select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text; }
  .xl\:align-baseline {
    vertical-align: baseline; }
  .xl\:align-top {
    vertical-align: top; }
  .xl\:align-middle {
    vertical-align: middle; }
  .xl\:align-bottom {
    vertical-align: bottom; }
  .xl\:align-text-top {
    vertical-align: text-top; }
  .xl\:align-text-bottom {
    vertical-align: text-bottom; }
  .xl\:visible {
    visibility: visible; }
  .xl\:invisible {
    visibility: hidden; }
  .xl\:whitespace-normal {
    white-space: normal; }
  .xl\:whitespace-no-wrap {
    white-space: nowrap; }
  .xl\:whitespace-pre {
    white-space: pre; }
  .xl\:whitespace-pre-line {
    white-space: pre-line; }
  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap; }
  .xl\:break-words {
    word-wrap: break-word; }
  .xl\:break-normal {
    word-wrap: normal; }
  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .xl\:w-1 {
    width: .25rem; }
  .xl\:w-2 {
    width: .5rem; }
  .xl\:w-3 {
    width: .75rem; }
  .xl\:w-4 {
    width: 1rem; }
  .xl\:w-5 {
    width: 1.25rem; }
  .xl\:w-6 {
    width: 1.5rem; }
  .xl\:w-8 {
    width: 2rem; }
  .xl\:w-10 {
    width: 2.5rem; }
  .xl\:w-12 {
    width: 3rem; }
  .xl\:w-16 {
    width: 4rem; }
  .xl\:w-24 {
    width: 6rem; }
  .xl\:w-32 {
    width: 8rem; }
  .xl\:w-48 {
    width: 12rem; }
  .xl\:w-64 {
    width: 16rem; }
  .xl\:w-auto {
    width: auto; }
  .xl\:w-px {
    width: 1px; }
  .xl\:w-1\/2 {
    width: 50%; }
  .xl\:w-1\/3 {
    width: 33.33333%; }
  .xl\:w-2\/3 {
    width: 66.66667%; }
  .xl\:w-1\/4 {
    width: 25%; }
  .xl\:w-3\/4 {
    width: 75%; }
  .xl\:w-1\/5 {
    width: 20%; }
  .xl\:w-2\/5 {
    width: 40%; }
  .xl\:w-3\/5 {
    width: 60%; }
  .xl\:w-4\/5 {
    width: 80%; }
  .xl\:w-1\/6 {
    width: 16.66667%; }
  .xl\:w-5\/6 {
    width: 83.33333%; }
  .xl\:w-full {
    width: 100%; }
  .xl\:w-screen {
    width: 100vw; }
  .xl\:z-0 {
    z-index: 0; }
  .xl\:z-10 {
    z-index: 10; }
  .xl\:z-20 {
    z-index: 20; }
  .xl\:z-30 {
    z-index: 30; }
  .xl\:z-40 {
    z-index: 40; }
  .xl\:z-50 {
    z-index: 50; }
  .xl\:z-auto {
    z-index: auto; } }

/*# sourceMappingURL=utilities.css.map */
.cell {
  position: relative; }

.decorator {
  position: absolute;
  text-align: center;
  width: 100%;
  color: rgba(105, 60, 60, 0.15);
  font-size: 200px;
  bottom: 0;
  pointer-events: none; }

.flip-card {
  perspective: 1000;
  border: 0;
  background: transparent; }
  .flip-card.hover .flip-card-inner {
    transform: rotateY(180deg); }

.flip-card,
.flip-card-inner-front,
.flip-card-inner-back {
  width: 100%;
  height: 26.5625rem; }

.flip-card-inner {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
  backface-visibility: hidden; }

.flip-card-inner-front,
.flip-card-inner-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.flip-card-inner-front {
  z-index: 2;
  display: flex; }

.flip-card-inner-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column; }

.button {
  font-weight: 600; }
  .button.brand, .button.brand-alt {
    color: #f0f0f0; }
    .button.brand:hover, .button.brand-alt:hover {
      color: #e6e6e6; }

.h1.brand, .h2.brand, .h3.brand, .h4.brand, .stat.brand, .text.brand {
  color: #60A03F; }

.h1.brand-alt, .h2.brand-alt, .h3.brand-alt, .h4.brand-alt, .stat.brand-alt, .text.brand-alt {
  color: #CC732A; }

.st0 {
  fill: #f0f0f0; }

.st1 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10; }

.st2 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.0701,4.0234; }

.st3 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.9075,3.9692; }

.st4 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.2284,4.0761; }

.st5 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.5286,3.8429; }

.st6 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.0544,4.0181; }

.st7 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 8.9747,2.9916; }

.st8 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 2.3036; }

.st9 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.1112,4.0371; }

.st10 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 10.1768,3.3923; }

.st11 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.3462,4.1154; }

.st12 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 8.6055,2.8685; }

.st13 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.004,4.0013; }

.st14 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.1464,4.0488; }

.st15 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.3536,3.7845; }

.st16 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 8.3551,2.785; }

.st17 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12.2995,4.0998; }

.st18 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.3641,3.788; }

.st19 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 13.7497,4.5832; }

.st20 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.5939,3.8646; }

.st21 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 11.7528,3.9176; }

.st22 {
  fill: none;
  stroke: #f0f0f0;
  stroke-miterlimit: 10;
  stroke-dasharray: 12,4; }

.st23 {
  font-family: 'Source Sans Pro';
  font-weight: 900; }

.st24 {
  font-size: 160px; }

.title-bar {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  z-index: 1000; }

@media print, screen and (min-width: 40em) {
  nav::after {
    content: "";
    display: block;
    background-color: #2b2b2b;
    width: 100%;
    height: 80px; } }

iframe {
  width: 100%;
  height: 50vh; }

.button {
  border-radius: 5px; }

.text-brand {
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  font-weight: 200; }

.text-huge {
  font-size: 300%; }

.text-tiny {
  font-size: 30%;
  line-height: 1rem; }

.wallpaper {
  height: 45vh;
  width: 100vw;
  background-size: cover;
  background-position: center bottom;
  position: fixed;
  z-index: -1;
  transform: scale(1);
  transition: all 50ms linear; }
  .wallpaper:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35); }
  @media print, screen and (min-width: 40em) {
    .wallpaper {
      height: 50vh; } }

#wiscramble_logo {
  height: 70%; }

.marqueecontainer {
  height: 40vh; }
  @media print, screen and (min-width: 40em) {
    .marqueecontainer {
      height: 50vh; } }

.marquee {
  margin-bottom: 1.5rem;
  height: 40vh;
  display: flex;
  flex: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem; }
  @media print, screen and (min-width: 40em) {
    .marquee {
      margin-bottom: 2.5rem; } }
  .marquee small {
    font-size: .5em; }

.warm-fuzzies {
  margin-bottom: 1.5rem; }
  @media print, screen and (min-width: 40em) {
    .warm-fuzzies {
      margin-bottom: 2.5rem; } }

.weak {
  font-weight: 200; }

.lead {
  font-weight: 200; }

#logo {
  height: 3.75rem; }

.soldout {
  color: #cacaca;
  position: relative; }
  .soldout:before {
    content: "Sold Out :(";
    color: #cc0000;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-15deg); }

.mb-0 {
  margin-bottom: 0; }

/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px; }

/* flip the pane when triggered */
.flip-container.activate .flipper {
  transform: rotateY(180deg); }

.flip-container, .front, .back {
  min-height: 584px;
  width: 100%;
  height: 100%; }

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative; }

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg); }

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg); }

.accordion.dark,
.tabs.dark {
  background-color: #2b2b2b; }
  .accordion.dark .tabs-title > a:focus,
  .accordion.dark .tabs-title > a[aria-selected='true'],
  .tabs.dark .tabs-title > a:focus,
  .tabs.dark .tabs-title > a[aria-selected='true'] {
    background-color: #60A03F; }

.top-bar {
  width: 100vw;
  min-height: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 50px 0 0;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 20; }
  .top-bar ul {
    background-color: transparent; }
    .top-bar ul.is-dropdown-submenu {
      background-color: #60A03F; }
  .top-bar-right {
    padding: 1.25rem; }
    .top-bar-right li a {
      line-height: 1.4rem; }
  @media print, screen and (min-width: 40em) {
    .top-bar {
      padding: 0; } }

.menu a {
  color: #f0f0f0; }

section {
  padding: 1.25rem;
  background-color: #f0f0f0;
  color: #0a0a0a; }
  @media print, screen and (min-width: 40em) {
    section {
      padding: 2.5rem; } }
  section.alt {
    background-color: #60A03F;
    color: #f0f0f0; }
  section.dark {
    background-color: #2b2b2b;
    color: #f0f0f0; }
  section.light {
    background-color: #e6e6e6;
    color: black; }

#theCountdown {
  background-color: transparent;
  color: #f0f0f0;
  text-align: center; }
  #theCountdown .countdown {
    display: flex;
    justify-content: center; }
    #theCountdown .countdown > div {
      width: 3.125rem; }
    #theCountdown .countdown .number {
      min-height: 1.5rem; }
    #theCountdown .countdown span {
      position: relative;
      display: block;
      font-size: 0.75rem;
      text-align: center; }
      #theCountdown .countdown span:before {
        content: '';
        position: absolute;
        top: -2px;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        width: 20px;
        height: 1px;
        background: #fff; }
  #theCountdown header img {
    height: 9.375rem;
    max-width: 12.5rem; }

.callout.hollow {
  background-color: transparent; }

.callout.light {
  border-color: #f0f0f0;
  color: #f0f0f0; }

.event-card {
  position: relative;
  margin-bottom: 1.5rem; }
  .event-card .event-buttons button {
    margin-bottom: 0; }
  .event-card .event-details {
    margin-bottom: 1rem; }
    .event-card .event-details p {
      margin-bottom: 0;
      line-height: 1.4rem; }
    .event-card .event-details svg {
      margin-right: 5px; }
    .event-card .event-details .date {
      font-weight: bold; }
    .event-card .event-details .address {
      font-size: .85rem; }

.date-card {
  box-shadow: #2b2b2b 1px 5px 15px -10px;
  border-radius: 5px;
  min-width: 9.375rem; }
  .date-card .header {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #60A03F;
    color: #f0f0f0;
    font-size: 1.25rem;
    font-weight: 300;
    padding: .25rem 0; }
  .date-card .main {
    font-weight: 700;
    padding: 0 1rem; }
    .date-card .main .date {
      line-height: 3.75rem;
      font-size: 3.5rem;
      margin-bottom: .25rem; }
    .date-card .main .day {
      color: #60A03F;
      font-size: .8rem;
      line-height: 0;
      margin-bottom: 1rem; }
  .date-card .footer .callToAction {
    width: 3rem;
    height: 3rem;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: #60A03F;
    color: #f0f0f0;
    padding-top: .5rem; }

.course-logo {
  width: 85%;
  position: absolute;
  top: 10px;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0%); }

.scorecard {
  text-align: center; }
  .scorecard img {
    width: 250px; }

.sprocket__image {
  position: relative;
  width: 100%;
  padding-bottom: 25%;
  overflow: hidden; }
  .sprocket__image img {
    width: 100%;
    position: absolute; }

#running-total {
  text-align: center;
  font-size: 2.25rem;
  position: fixed;
  top: 80px;
  right: -1px;
  border-radius: 5px 0 0 5px; }
  @media print, screen and (min-width: 40em) {
    #running-total {
      font-size: 3.125rem; } }

.event-group {
  transition: all 250ms ease-in;
  margin-left: 0; }
  .event-group li {
    list-style: none;
    position: relative; }
    .event-group li input[type="checkbox"] {
      margin-bottom: 0;
      position: absolute;
      top: 0.4375rem;
      left: 0.625rem; }
    .event-group li label {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0; }
  .event-group.error {
    border: 1px solid red;
    background: rgba(100, 0, 0, 0.1);
    padding: .5rem; }

.dateCard {
  text-align: center;
  border: 1px solid #60A03F; }
  .dateCard input {
    position: absolute; }
  .dateCard .month {
    color: #f0f0f0;
    background-color: #60A03F; }
  .dateCard .date {
    color: #CC732A;
    font-size: 1.5rem; }
  .dateCard .day {
    font-size: 0.75rem;
    font-weight: 200;
    color: #333; }
  .dateCard .place {
    padding: 0 0.3125rem;
    font-size: 0.75rem; }

#invite-code {
  width: 4rem; }

.user-registration .cell .input-group {
  display: flex;
  flex: row wrap;
  align-items: center; }

.user-registration .cell label {
  margin-left: 32px; }

.user-registration .cell input {
  margin-bottom: 0; }

.user-registration .cell svg {
  margin-right: .5rem; }

.user-registration input {
  transition: all 250ms ease-in; }
  .user-registration input.error {
    border: 1px solid red;
    background: rgba(100, 0, 0, 0.1); }

#add-golfer {
  margin-left: 24px; }

/** Page-specific styles */
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1; } }

#example5-paymentRequest {
  max-width: 500px;
  width: 100%;
  margin-bottom: 10px; }

.example {
  position: relative; }

.example.submitted form,
.example.submitting form {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none; }

.example.submitted .success,
.example.submitting .success {
  pointer-events: all; }

.example.submitting .success .icon {
  opacity: 1; }

.example.submitted .success > * {
  opacity: 1;
  transform: none !important; }

.example.submitted .success > :nth-child(2) {
  transition-delay: 0.1s; }

.example.submitted .success > :nth-child(3) {
  transition-delay: 0.2s; }

.example.submitted .success > :nth-child(4) {
  transition-delay: 0.3s; }

.example.submitted .success .icon .border,
.example.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important; }

.example.submitted form,
.example.submitting form {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none; }

.example.submitted .success,
.example.submitting .success {
  pointer-events: all; }

.example.submitting .success .icon {
  opacity: 1; }

.example.submitted .success > * {
  opacity: 1;
  transform: none !important; }

.example.submitted .success > :nth-child(2) {
  transition-delay: 0.1s; }

.example.submitted .success > :nth-child(3) {
  transition-delay: 0.2s; }

.example.submitted .success > :nth-child(4) {
  transition-delay: 0.3s; }

.example.submitted .success .icon .border,
.example.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important; }

.example * {
  margin: 0;
  padding: 0; }

.example .caption {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  padding: 15px 10px 0;
  color: #aab7c4;
  font-family: Roboto, "Open Sans", "Segoe UI", sans-serif;
  font-size: 15px;
  font-weight: 500; }

.example .caption * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

.example .caption .no-charge {
  color: #cfd7df;
  margin-right: 10px; }

.example .caption a.source {
  text-align: right;
  color: inherit;
  transition: color 0.1s ease-in-out;
  margin-left: 10px; }

.example .caption a.source:hover {
  color: #6772e5; }

.example .caption a.source:active {
  color: #43458b; }

.example .caption a.source svg {
  margin-right: 10px; }

.example .caption a.source svg path {
  fill: currentColor; }

.example form {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: auto;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.example form input::-webkit-input-placeholder {
  opacity: 1; }

.example form input::-moz-placeholder {
  opacity: 1; }

.example form input:-ms-input-placeholder {
  opacity: 1; }

.example .error {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 100%;
  margin-top: 20px;
  left: 0;
  padding: 0 15px;
  font-size: 13px !important;
  opacity: 0;
  transform: translateY(10px);
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.example .error.visible {
  opacity: 1;
  transform: none; }

.example .error .message {
  font-size: inherit; }

.example .error svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: -1px;
  margin-right: 10px; }

.example .success {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  pointer-events: none;
  overflow: hidden; }

@media (min-width: 670px) {
  .example .success {
    padding: 40px; } }

.example .success > * {
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(50px); }

.example .success .icon {
  margin: 15px 0 30px;
  transform: translateY(70px) scale(0.75); }

.example .success .icon svg {
  will-change: transform; }

.example .success .icon .border {
  stroke-dasharray: 251;
  stroke-dashoffset: 62.75;
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: spin 1s linear infinite; }

.example .success .icon .checkmark {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s; }

.example .success .title {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 8px; }

.example .success .message {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 1.6em; }

.example .success .message span {
  font-size: inherit; }

.example .success .reset:active {
  transition-duration: 0.15s;
  transition-delay: 0s;
  opacity: 0.65; }

.example .success .reset svg {
  will-change: transform; }

.stripe fieldset {
  border: 1px solid #5e5e5e;
  padding: 15px;
  border-radius: 6px;
  background-color: #2b2b2b; }
  .stripe fieldset legend {
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    color: #f0f0f0;
    background-color: #2b2b2b; }
    .stripe fieldset legend + * {
      clear: both; }

.stripe .card-only {
  display: block; }

.stripe .payment-request-available {
  display: none; }

.stripe .row {
  display: flex;
  margin: 0 0 10px; }

.stripe .field {
  position: relative;
  width: 100%; }
  .stripe .field + .field {
    margin-left: 10px; }

.stripe label {
  width: 100%;
  color: #ababab;
  font-size: 13px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.stripe .input {
  width: 100%;
  color: #f0f0f0;
  background: transparent;
  padding: 5px 0 6px 0;
  border-bottom: 1px solid #5e5e5e;
  transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  .stripe .input:focus {
    border: none;
    border-bottom: 1px solid;
    box-shadow: none;
    outline: none; }

.stripe .input::placeholder {
  color: #454545;
  font-style: italic; }

.stripe .input.StripeElement--focus,
.stripe .input:focus {
  border-color: #f0f0f0; }

.stripe .input.StripeElement--invalid {
  border-color: red; }

.stripe input:-webkit-autofill,
.stripe select:-webkit-autofill {
  -webkit-text-fill-color: #60A03F;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out; }

.stripe .StripeElement--webkit-autofill {
  background: transparent !important; }

.stripe input,
.stripe button,
.stripe select {
  -webkit-animation: 1ms void-animation-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  border-radius: 0; }

.stripe select.input,
.stripe select:-webkit-autofill {
  background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
  background-position: 100%;
  background-size: 10px 5px;
  background-repeat: no-repeat;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px; }

.stripe button {
  display: block;
  width: 100%;
  height: 40px;
  margin: 20px 0 0;
  border-radius: 6px;
  color: #2b2b2b;
  background-color: #f0f0f0;
  font-weight: 500;
  cursor: pointer; }

.stripe .error svg .base {
  fill: #fff; }

.stripe .error svg .glyph {
  fill: #9169d8; }

.stripe .error .message {
  color: #fff; }

.stripe .success .icon .border {
  stroke: #60A03F; }

.stripe .success .icon .checkmark {
  stroke: #60A03F; }

.stripe .success .title {
  color: #f0f0f0; }

.stripe .success .message {
  color: #f0f0f0; }

.stripe .success .reset path {
  fill: #f0f0f0; }

.pid, input.copyIt {
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  border: 1px solid #60A03F;
  background-color: rgba(255, 255, 255, 0.1); }

.input-group input {
  height: 36px; }

.scorecard th {
  width: 6.25rem; }

.scorecard tr:nth-of-type(2) {
  display: none; }

.scorecard tr:nth-of-type(1) {
  background: #60A03F;
  color: #f0f0f0; }

.scorecard td {
  width: 25px;
  height: 25px;
  position: relative;
  text-align: center; }

.scorecard .brd:after, .scorecard .egl:after {
  content: "";
  width: 25px;
  height: 25px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.scorecard .egl:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.scorecard .bgy:after, .scorecard .dblbgy:after {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.scorecard .dblbgy:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.slick-carousel div {
  width: 25%; }
